import { useState } from 'react';
import { UserModalType } from './types';

export const useUserModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [type, setType] = useState<UserModalType>(UserModalType.DELETE_USER);
  const setModalState = (isOpen: boolean, type = UserModalType.DELETE_USER) => {
    setOpen(isOpen);
    setType(type);
  };

  return {
    isOpen,
    type,
    setModalState,
  };
};
