import errorException from './errorException';

const getBrightness = (brightnessByte: number) : { min : number; max: number } => {
  const brLow = brightnessByte & 15;
  const brHigh = (brightnessByte & 240) >> 4;

  const brightnessMin = Math.floor(((Math.round(brHigh * 100) / 16) / 10) + 1) * 10;
  const brightnessMax = Math.floor(((Math.round(brLow * 100) / 16) / 10) + 1) * 10;

  return { min: brightnessMin, max: brightnessMax };
};

const getLedDigFlashingSpeed = (ledDigBlinkByte: number) : string => {
  const ledDigFlashingSpeed = ledDigBlinkByte & 15;

  let ledFlashing = '';

  if (ledDigFlashingSpeed <= 6) {
    ledFlashing = 'Fast';
  } else if (ledDigFlashingSpeed <= 10) {
    ledFlashing = 'Medium';
  } else {
    ledFlashing = 'Slow';
  }

  return ledFlashing;
};

const getLedFlashingSpeed = (blinkByte: number) => blinkByte & 15

const getStrobeBlink = (strobeBlinkByte: number) : { speed: string; series: string} => {
  const strbLow = strobeBlinkByte & 15;
  const strbHigh = (strobeBlinkByte & 240) >> 4;

  let strobeSpeed = '';

  if (strbLow <= 2) {
    strobeSpeed = 'Fast';
  } else {
    if (strbLow <= 8) {
      strobeSpeed = 'Medium';
    } else {
      strobeSpeed = 'Slow';
    }
  }

  let strobeSeries = '';

  if (strbHigh === 4) {
    strobeSeries = '2 Flashes';
  } else {
    if (strbHigh === 6) {
      strobeSeries = '3 Flashes';
    } else {
      if (strbHigh === 8) {
        strobeSeries = '4 Flashes';
      } else {
        strobeSeries = '5 Flashes';
      }
    }
  }

  return {
    speed: strobeSpeed,
    series: strobeSeries
  }
}

export default (cmdBuffer: any) => {
  if (!cmdBuffer.length) {
    return null;
  }

  try {
    const brightnessByte = cmdBuffer.readUInt8(0);
    const ledDigBlinkByte = cmdBuffer.readUInt8(1);
    const ledMsgBlinkByte = cmdBuffer.readUInt8(2);
    const beaconBlinkByte = cmdBuffer.readUInt8(3);
    const strobeBlinkByte = cmdBuffer.readUInt8(4);

    const parsedResult = {
      brightness: getBrightness(brightnessByte),
      ledDigBlink: getLedDigFlashingSpeed(ledDigBlinkByte),
      ledMsgBlink: getLedFlashingSpeed(ledMsgBlinkByte),
      ledBeaconBlink: getLedFlashingSpeed(beaconBlinkByte),
      ledStrobeBlink: getStrobeBlink(strobeBlinkByte)
    };

    return parsedResult;
  } catch (error) {
    console.error(error);

    errorException();
    return null;
  }
}
