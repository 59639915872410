import React, { useCallback, useEffect, useState } from 'react';

import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style.js';
import Feature from 'ol/Feature.js';
import { defaults } from 'ol/control/defaults';
import * as olProj from 'ol/proj';

import "ol/ol.css";

function MapWrapper({
  geoImg,
  latLong,
}: {
  geoImg?: string;
  latLong: number[];
}) {
  const [mapEleRef, setMapEleRef] = useState<HTMLDivElement>();
  const mapEleRefCB = useCallback(
    (node: HTMLDivElement) => setMapEleRef(node),
    []
  );
  // initialize map on first render
  useEffect(() => {
    if (!latLong || !mapEleRef) return;
    const iconFeature = new Feature(
      new Point(olProj.fromLonLat([+latLong[1], +latLong[0]]))
    );
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: geoImg || ' ',
        opacity: 1,
      }),
    });

    iconFeature.setStyle(iconStyle);

    const vectorSource = new VectorSource({
      features: [iconFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 9000,
    });

    new Map({
      target: mapEleRef,
      layers: [
        new TileLayer({
          preload: 4,
          source: new OSM(),
          zIndex: 90,
        }),
        vectorLayer,
      ],
      view: new View({
        center: olProj.transform(
          [+latLong[1], +latLong[0]],
          'EPSG:4326',
          'EPSG:3857'
        ),
        zoom: 15,
      }),
      controls: defaults(),
    });
    return () => {
      mapEleRef.innerHTML = '';
    };
  }, [latLong, geoImg, mapEleRef]);

  return (
    <>
      {!latLong[0] && !latLong[1] ? (
         <span
           style={{
             background: '#eee',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             height: '100%'
           }}
         >
           Location info not available
         </span>
      ) : (
        <div
          ref={mapEleRefCB}
          style={{ width: '100%', height: '100%' }}
          data-test={'mapInfo'}
        ></div>
      )}
    </>
  );
}

export default MapWrapper;
