import React from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { SHORTDATE_FORMAT } from '../../constants'
import FeaturedDatePicker from './FeaturedDatePicker'
import dayjs from 'dayjs';

interface RangeDatePickerProps {
  control: Control;
  startName: string;
  endName: string;
  minDate: dayjs.Dayjs;
  maxDate: dayjs.Dayjs;
}

const RangeDatePicker = ({
  control,
  startName,
  endName,
  minDate,
  maxDate,
}: RangeDatePickerProps) => {
  const startDateWatcher = useWatch({ control, name: startName });
  const endDateWatcher = useWatch({ control, name: endName });

  return (
    <>
      <Controller
        name={startName}
        control={control}
        render={({ field }) => (
          <FeaturedDatePicker
            minDate={minDate}
            maxDate={dayjs(endDateWatcher)}
            field={field}
            label={"Start Date"}
            styles={{
              marginRight: 5
            }}
            format={SHORTDATE_FORMAT} />
        )} />
      <Controller
        name={endName}
        control={control}
        render={({ field }) => (
          <FeaturedDatePicker
            minDate={dayjs(startDateWatcher)}
            maxDate={maxDate}
            field={field}
            label={"End Date"}
            format={SHORTDATE_FORMAT} />
        )} />
    </>
  )
}

export default RangeDatePicker;