import RadarCommand from './parsers/radarCommand';
import SignCMDParser from './parsers/index';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { TableWrapper, AccordionDetailsWrapper } from './styles';
import SystemInfo from './components/SystemInfo';
import LedBrightnessInfo from './components/LedBrightnessInfo';
import {
  DeviceInfoTable,
  DeviceInfoTableHeaderCell,
  DeviceInfoTableRow,
  DeviceInfoTableRowCell,
} from '../ShowInfo/styles';
import ScheduleInfo from './components/ScheduleInfo';
import _ from 'lodash';

export default function ParsedResponse({
  responseFromRadar,
}: {
  responseFromRadar: string;
}) {
  if (!responseFromRadar) {
    return null;
  }

  const command = new RadarCommand(responseFromRadar);
  const parsedResponse: any = SignCMDParser(command);

  if (_.isEmpty(parsedResponse)) {
    return null;
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography variant="h5">Parsed response</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetailsWrapper>
          {
            parsedResponse &&
            parsedResponse?.boardFuses &&
            parsedResponse?.fwFuses && <SystemInfo data={parsedResponse} />
          }
          {
            parsedResponse &&
            parsedResponse?.brightness &&
            parsedResponse?.ledStrobeBlink && (
              <LedBrightnessInfo data={parsedResponse} />
            )
          }
          {
            parsedResponse && parsedResponse?.dateTime && (
              <TableWrapper>
                <DeviceInfoTable>
                  <tbody>
                  <DeviceInfoTableRow>
                    <DeviceInfoTableHeaderCell>
                      Current time and date
                    </DeviceInfoTableHeaderCell>
                    <DeviceInfoTableRowCell data-test={'Current_time_and_date'}>
                      {parsedResponse?.dateTime}
                    </DeviceInfoTableRowCell>
                  </DeviceInfoTableRow>
                  </tbody>
                </DeviceInfoTable>
              </TableWrapper>
            )
          }
          {
            parsedResponse &&
            parsedResponse?.days &&
            parsedResponse?.displaySetting && (
              <ScheduleInfo data={parsedResponse} />
            )
          }
        </AccordionDetailsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}
