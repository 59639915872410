import React, { createContext, useContext, useState } from 'react';
import { AxiosInstance } from 'axios';
import apiInstance from './apiInstance';


const defaultContext = {
  apiInstance,
  loading: false,
  setLoading: (loading: boolean) => loading
}

const ApiContext = createContext<{
  apiInstance: AxiosInstance,
  loading: boolean,
  setLoading: any
}>(defaultContext);

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiContextProvider');
  }
  return context;
};

export const ApiContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const apiState = {
    apiInstance,
    loading,
    setLoading
  }

  return (
    <ApiContext.Provider value={apiState}>
      {children}
    </ApiContext.Provider>
  )
};
