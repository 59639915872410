import { ServiceModalWrap } from '../../styles';
import { DeleteUserModalContent } from './DeleteUserModalContent';
import { ResetPasswordModalContent } from './ResetRasswordModalContent';
import { UserModalContentProps, UserModalType } from './types';

export const UserModalContent = ({
  type,
  userId,
  handleClose,
}: UserModalContentProps) => (
  <ServiceModalWrap>
    {type === UserModalType.DELETE_USER && (
      <DeleteUserModalContent handleClose={handleClose} userId={userId} />
    )}
    {type === UserModalType.RESER_PASSWORD && (
      <ResetPasswordModalContent userId={userId} />
    )}
  </ServiceModalWrap>
);
