import { TextField } from '@mui/material';
import React from 'react';
import * as Types from '../../context/types';
import { FilterControlFieldProps } from '../types';

const TextInput = ({
  input,
  field,
}: FilterControlFieldProps<Types.TextInput>) => {
  return (
    <TextField
      variant="standard"
      size="small"
      label={input.label}
      {...field}
    />
  );
};

export default TextInput;
