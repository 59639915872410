import { TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Controller } from 'react-hook-form';
import { DcEditProps } from '../types';
const DcEditModalContent = ({ control, dcModelList }: DcEditProps) => {
  return (
    <>
      <Controller
        name="serial"
        control={control}
        rules={{
          required: 'CC Serial Number is required',
          minLength: {
            value: 8,
            message: 'The serial number must be at least 8 characters long',
          },
          pattern: {
            value: /^[A-F0-9]+$/i,
            message: 'Only hexadecimal values are allowed',
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="CC Serial Number"
            fullWidth
            required
            error={!!error}
            helperText={error ? error.message : null}
            inputProps={{
              maxLength: 8
            }}
            variant="standard"
            sx={{ marginBottom: 7 }}
          />
        )}
      />
       <Controller
        name="access_key"
        control={control}
        rules={{ required: 'Access key must be required' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            sx={{ marginBottom: 7 }}
            inputProps={{
              maxLength: 36
            }}
            label='Access key *'
            fullWidth
            variant="standard"
            {...field}
          />
        )}
      />
      <Controller
        name="device_serial"
        control={control}
        rules={{
          maxLength: {
            value: 22,
            message: 'Device serial must be up to 22 characters long',
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Device Serial"
            fullWidth
            error={!!error}
            helperText={error ? error.message : null}
            inputProps={{
              maxLength: 8
            }}
            variant="standard"
            sx={{ marginBottom: 7 }}
          />
        )}
      />
      <Controller
        name="sim"
        control={control}
        rules={{
          minLength: {
            value: 18,
            message: 'SIM number must be at least 18 digits long',
          },
          maxLength: {
            value: 23,
            message: 'SIM number must be no more than 22 digits long',
          },
          pattern: {
            value: /^\d*$/,
            message: 'Only numeric values are allowed',
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="SIM"
            fullWidth
            error={!!error}
            helperText={error ? error.message : null}
            variant="standard"
            inputProps={{
              minLength: 18,
              maxLength: 23,
              inputMode: 'numeric',
              pattern: '\\d*'
            }}
            sx={{ marginBottom: 7 }}
          />
        )}
      />
      <Controller
        name="model_id"
        control={control}
        rules={{
          required: 'Device model must be required'
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error}>
            <Select
              {...field}
              labelId="edit-modal-models-select"
              id="model-select"
              variant="standard"
              defaultValue={field.value || dcModelList[0]?.value}
              sx={{ marginBottom: 7 }}
              error={!!error}
            >
              {dcModelList?.map((model, index) => (
                <MenuItem value={model.value} defaultChecked key={index}>
                  {model.title.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText sx={{ marginTop: '-50px' }}>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="normal_voltage"
        control={control}
        rules={{
          min: {
            value: 1,
            message: 'Voltage must be at least 1',
          },
          max: {
            value: 100,
            message: 'Voltage must be no more than 100',
          },
          pattern: {
            value: /^\d*$/,
            message: 'Only numeric values are allowed',
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Normal battery voltage (V)"
            fullWidth
            variant="standard"
            inputProps={{
              type: 'number',
            }}
            sx={{ marginBottom: 9 }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </>
  );
};
export default DcEditModalContent;