import { styled } from '@mui/material';

export const ModuleSignWrap = styled('div')`
    position: relative;
`;

export const ModuleInput = styled('input')`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
`
export const ModuleIcon = styled('span')(
    ({ isActive, bgImg }: { isActive: boolean; bgImg: string; }) => ({
        content: '""',
        borderRadius: '5px',
        width: '48px',
        height: '48px',
        backgroundSize: 'calc(100% - 4px)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        backgroundColor: isActive ? 'rgba(59, 99, 141, .8)' : 'hsla(0,0%,62%,.8)',
        backgroundImage: `url(${bgImg})`,
        display: 'inline-block',
        transition: 'background-color .5s ease'
    })
  );

export const ModuleDesc = styled('p')`
    text-align: center;
    margin: 0;
    text-transform: capitalize;
    color: rgba(0, 0, 0, .54);
    padding-top: 5px;
`;