// modules/global.routes.js
import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AuthRoutes from './auth/authRoutes';
import AdminRoutes from './adminRoutes';
import { useAuth } from '../context/auth';
import HeaderNavigation from '../components/layout/header';
import Footer from '../components/layout/footer';
import { MainWrapper } from './pages/styles';

export default function GlobalRoutes() {
  const { state } = useAuth();

  return (
    <>
      <HeaderNavigation />
      <MainWrapper>
        {!state.isAuthenticated ? (
          <AuthRoutes />
        ) : (
          <AdminRoutes />
        )}
      </MainWrapper>
      <Footer />
    </>
  );
}

