import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CancelButton, SaveButton } from '../../../../styles';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { values } from 'lodash';
import { StyledInputWrapper } from '../../add-company/styles';
import FeaturedDatePicker from '../../../../../../components/DatePicker';
import { useEffect, useState } from 'react';
import LocationsDropdown from './LocationsDropdown';
import UsagePlansDropdown from './UsagePlansDropdown';
import UserDropdown from './UserDropdown';
import { TokenEditModalContentFormFields } from '../TokenModalContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useApi } from '../../../../../../context/api';

type TokenEditProps = {
  type: string;
  token?: string;
  onClose?: () => void;
};

type UsagePlan = { id: string; name: string };

type GetTokenDataResponse = {
  status: 'ERROR' | 'OK';
  apiKey: {
    aws: {
      id: string;
      value: string;
      name: string;
      description: string;
      enabled: false;
      usagePlans: UsagePlan[];
    };
    support: [
      {
        awsIdKey: string;
        token: string;
        userId: string;
        companyId: number;
        expiration: string;
        siteIds: string[];
        name: string;
      }
    ];
  };
};

export default function TokenEditModalContent({
  token,
  type,
  onClose,
}: TokenEditProps) {
  const { insert, update, errors } = useDataGrid();
  const { id } = useParams();
  const { apiInstance } = useApi();
  const methods = useForm<TokenEditModalContentFormFields>({
    mode: 'all',
    defaultValues: {
      enabled: false,
      name: 'New token',
      description: '',
      userId: '',
      expiration: dayjs().add(1, 'year').format('YYYY-MM-DD 00:00:00'),
      locationIds: [],
      usagePlans: [],
    },
  });
  const { control, setValue } = methods;
  const [isLocationsDisabled, setIsLocationsDisabled] = useState(true)

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return 'Edit token';
      case 'create':
        return 'Add new token';
    }
  };

  const saveTokenInfo = methods.handleSubmit((values) => {
    try {
      switch (type) {
        case 'edit':
          if (token)
            update(
              token,
              {
                ...values,
                companyId: id,
                locationIds: values.locationIds.join(),
                usagePlans: values.usagePlans.join(),
                enabled: Boolean(values.enabled),
              },
              onClose
            );
          break;
        case 'create':
          insert(
            {
              ...values,
              companyId: id,
              locationIds: values.locationIds.join(),
              usagePlans: values.usagePlans.join(),
              enabled: Boolean(values.enabled),
            },
            onClose
          );
          break;
      }
    } catch (e: any) {
      console.log(e);
    }
  });

  useEffect(() => {
    async function fetchTokenData() {
      const response: GetTokenDataResponse = await apiInstance.get(
        `aws/apikey/get/${token}`
      );
      const result = response.apiKey;

      setValue('description', result.aws.description);
      setValue('enabled', result.aws.enabled);
      setValue('expiration', result.support[0].expiration);
      setValue('locationIds', result.support[0].siteIds);
      setValue('name', result.aws.name);
      setValue(
        'usagePlans',
        result.aws?.usagePlans?.map((usagePlan) => usagePlan.id) || []
      );
      setValue('userId', result.support[0].userId);
    }
    try {
      if (token) {
        fetchTokenData();
      }
    } catch (e: any) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setValue]);

  return (
    <FormProvider {...methods}>
      <form style={{padding: '35px 50px'}} onSubmit={saveTokenInfo}>
        <Typography
          variant="h4"
          sx={{ marginTop: 2, marginBottom: 4, fontSize: '2.5rem', color: '#333' }}
          align="center"
        >
          {renderTitle()}
        </Typography>
        <Box sx={{display: 'flex', gap: '40px', flexDirection: 'column', paddingBottom: '40px'}}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
              minLength: {
                value: 3,
                message: 'Minimum 3 numbers are accepted',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : values(errors?.serial)}
                label="Token name*"
                data-test="input-token-name"
                fullWidth
                variant="standard"
                InputLabelProps={{sx: {color: '#3f51b5', fontWeight: '700'}}}
                inputProps={{
                  maxLength: 16,
                }}
                {...field}
              />
            )}
          />
          <Box sx={{display: 'flex', gap: '10px', justifyContent: 'space-between'}}>
            <StyledInputWrapper>
              <UserDropdown />
            </StyledInputWrapper>

            <StyledInputWrapper>
              <Controller
                name="expiration"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel sx={{fontSize: '14px', fontWeight: '700'}} htmlFor="expiration">Expiration</InputLabel>
                    <FeaturedDatePicker
                      key="expiration"
                      field={field}
                      styles={{ width: '100%', marginBottom: '0' }}
                    />
                  </>
                )}
              />
            </StyledInputWrapper>
          </Box>
          <UsagePlansDropdown setIsLocationsDisabled={setIsLocationsDisabled} />

          <LocationsDropdown isLocationsDisabled={isLocationsDisabled}/>

          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ height: '20px' }}
                    checked={!!field.value}
                    onChange={(e) => field.onChange(+e.target.checked)}
                    data-test="input-enabled"
                  />
                }
                label="Enabled"
                key="enabled"
                sx={{ height: '20px', color: "rgba(0, 0, 0, 0.6)"}}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                id="token-description"
                label="Token description"
                data-test="input-description"
                multiline
                inputProps={{
                  maxLength: 150,
                }}
                sx={{color: "rgba(0, 0, 0, 0.6)", borderTop: '1px solid rgba(0, 0, 0, .12)'}}
                rows={3}
                defaultValue={field.value}
                fullWidth
                variant="standard"
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            marginBottom: 5,
          }}
        >
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <SaveButton type="submit" variant="contained">
            Save
          </SaveButton>
        </Box>
      </form>
    </FormProvider>
  );
}
