import { Controller, useFormContext } from 'react-hook-form';
import { TokenEditModalContentFormFields } from '../TokenModalContent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useApi } from '../../../../../../context/api';

type User = {
  id: string;
  fullName: string;
};

type UsersListResponse = {
  status: 'ERROR' | 'OK';
  companiesUsers: User[];
};

export default function UserDropdown() {
  const { id } = useParams();
  const [userList, setUserList] = useState<User[]>([]);
  const { control } = useFormContext<TokenEditModalContentFormFields>();
  const { apiInstance } = useApi();
  useEffect(() => {
    async function fetchUserList() {
      const response: UsersListResponse = await apiInstance.get(
        `companies/users/list/${id}`
      );

      setUserList(Object.values(response.companiesUsers));
    }

    fetchUserList();
  }, [id, apiInstance]);

  return (
    <Controller
      name="userId"
      control={control}
      rules={{
        required: 'Required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputLabel sx={{fontSize: '14px', fontWeight: '700'}} htmlFor="user-select">User list*</InputLabel>
          <FormControl fullWidth error={!!error}>
            <Select
              fullWidth
              variant="standard"
              labelId="demo-simple-select-standard-label"
              id="user-select"
              label="User list"
              data-test="input-user"
              {...field}
            >
              {userList?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.fullName}
                </MenuItem>
              ))}
            </Select>
            {error?.message && (
              <FormHelperText sx={{ marginLeft: '0px' }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
}
