import { useEffect} from 'react';
import { Typography, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { useDataGrid } from '../../../../../components/generic/dataGrid';
import DcEditModalContent from './modalComponents/editModal-content';
import DcDeleteModalContent from './modalComponents/deleteModal-content';
import DcMoveModalContent from './modalComponents/moveModal-content';
import { CancelButton, SaveButton, ServiceModalWrap } from '../../../styles';
import { DcControledProps } from './types';
import { MODULES } from '../../../../../constants/Modules';
import DcSubscribeModalContent from './modalComponents/subscibeModal-content';

const DcModalContent = (
    { type, dc, values, dcModelList, onClose, companyId, id }: DcControledProps
) => {
    const { insert, update, move, errors } = useDataGrid();
    const defaultValues: any = {
      ...values,
      model_id: dcModelList[0]?.value
    };
    const {
      handleSubmit,
      control,
      setError
    } = useForm({
      mode: 'all',
      defaultValues,
    });

    useEffect(() => {
      Object.keys(errors).forEach(field => {
        setError(field, {
          type: "manual",
          message: _.values(errors[field])[0]
        });
      });
    }, [errors, setError]);

    const renderContent = () => {
      const defaultCompany = {id: values.newCompanyId, name: values.newCompanyName};
      const contents = {
        edit: <DcEditModalContent
          control={control}
          dcModelList={dcModelList}
        />,
        create: <DcEditModalContent
          control={control}
          dcModelList={dcModelList}
        />,
        move: <DcMoveModalContent
          dc={dc}
          control={control}
          defaultCompany={defaultCompany}
          loadCompaniesUrl={`/companies/dc/move/${id}/${dc.id}`}
        />,
        moveSelected: <DcMoveModalContent
          dc={dc}
          control={control}
          defaultCompany={defaultCompany}
          loadCompaniesUrl={`/companies/dc/move/bulk/${id}`}
        />,
        moveAll: <DcMoveModalContent
          dc={dc}
          control={control}
          defaultCompany={defaultCompany}
          loadCompaniesUrl={`/companies/dc/move/bulk/${id}`}
        />,
        subscribe: <DcSubscribeModalContent
          dc={dc}
          control={control}
        />,
        delete: <DcDeleteModalContent type={type}/>,
      }

      return contents[type];
    };

    const renderTitle = () => {
      const titles = {
        edit: 'Edit device',
        create: 'Add new device',
        delete: 'Confirm action',
        move: 'Moving to company',
        moveAll: 'Moving all dc to company',
        moveSelected: 'Moving selected dc to company',
        subscribe: ''
      }

      return titles[type] || '';
    }

    const saveDcInfo = handleSubmit(async (values) => {
      try {
        const { id } = dc;

        const request = {
          edit: () => update(id, values, onClose),
          create: () => insert(values, onClose),
          delete: () => move(`/companies/dc/delete/${companyId}`, { id }, onClose),
          move: () => move(`/companies/dc/move/${companyId}/${id}`, values, onClose),
          moveAll: () => move(`/companies/dc/move/bulk/${companyId}`, values, onClose),
          moveSelected: () => move(`/companies/dc/move/bulk/${companyId}`, values, onClose),
          subscribe: () => move(`/companies/subscription/${MODULES.MODULE_DC}/${companyId}/${id}`, values, onClose)
        };

        console.log('type', request[type])

        request[type]();
      } catch (e: any) {
        console.error(e);
      }
    });

    return (
      <ServiceModalWrap>
        <form
          onSubmit={saveDcInfo}
        >
          <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
            {renderTitle()}
          </Typography>
          {renderContent()}

          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row-reverse',
              flexWrap: 'wrap',
              marginBottom: 5
            }}
          >
            <CancelButton onClick={onClose}>
              { type === 'delete' ? 'Disagree' : 'Cancel' }
            </CancelButton>
            <SaveButton type="submit" variant="contained">
              { type === 'delete' ? 'Agree' : 'Save' }
            </SaveButton>
          </Box>
        </form>
      </ServiceModalWrap>
    );
};

export default DcModalContent;
