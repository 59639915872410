import { ModalWrap } from '../../account-management/modal/styles';
import KeyIcon from '@mui/icons-material/VpnKey';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { LeftSideWrap, ResetPasswordWrap, TitleLabel } from './styles';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../context/api';
import LoadPannelLoader from '../../../../components/LoadPanelLoader';

export const ResetPasswordModalContent = ({
  userId,
}: {
  userId: string | number;
}) => {
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [newPassword, setNewPassowrd] = useState('Generation...');
  const { apiInstance, loading, setLoading } = useApi();

  useEffect(() => {
    const resetPassword = async () => {
      try {
        setLoading(true);
        const { msg }: { msg: string } = await apiInstance.post(
          `/users/resetpassword/${userId}`
        );
        setLoading(false);
        setNewPassowrd(msg);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };

    resetPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(newPassword);
      setPasswordCopied(true);
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
    }
  };

  return (
    <ModalWrap>
      <TitleLabel>Your new password</TitleLabel>
      {loading ? <LoadPannelLoader/> : ''}
      <ResetPasswordWrap>
        <LeftSideWrap>
          <KeyIcon />
          <span>{passwordCopied ? 'Copy was successful' : newPassword}</span>
        </LeftSideWrap>
        <CopyIcon
          sx={{
            cursor: 'pointer',
            '&:hover': { opacity: '.5', transition: '.25s ease-in' },
          }}
          onClick={() => !passwordCopied && copyToClipboard()}
        />
      </ResetPasswordWrap>
    </ModalWrap>
  );
};
