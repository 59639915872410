import { useState } from 'react';
import ReportForm from './components/ReportForm';
import ReportTable from './components/ReportTable';
import { ReportDataType } from './types';

const Reports = () => {
  const [ reportData, setReportData ] = useState<ReportDataType | null>(null);
  
  return (
    <>
      {reportData &&
        <ReportTable data={reportData} setReportData={setReportData} />
      }
      <ReportForm hideForm={!!reportData} setReportData={setReportData} />
    </>
  );
};
export default Reports;
