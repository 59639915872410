import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LockIcon from '@mui/icons-material/LockOutlined';
import LockOpen from '@mui/icons-material/LockOpen';
import RestoreIcon from '@mui/icons-material/Restore';
import Table from '../../../components/Table';
import GenericModal from '../../../components/generic/modal';
import AddEditUser from './add-edit-user';
import { useDataGrid } from '../../../components/generic/dataGrid/context';
import { UserModalContent } from './modal/UserModalContent';
import { useUserModal } from './modal/useUserModal';
import { UserModalType } from './modal/types';

type CurrentUser = {id: string; state: string};

export default function Users() {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [snackbarMsg, setSnackbarMsg] = useState<string>();
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { isOpen, type, setModalState } = useUserModal();
  const { move, setErrors } = useDataGrid();

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, currentUser: CurrentUser) => {      
      setCurrentUser(currentUser);
      setAnchorEl(event.currentTarget);
    },
    [setCurrentUser]
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserModalClose = () => {    
    setModalState(false);
    setCurrentUser(undefined);
  }

  const table = useMemo(
    () => (
      <Table
        title="Users"
        toolbar={
          <Button
            color="secondary"
            onClick={() => {
              setCurrentUser(undefined);
              setIsAddUserDialogOpen(true);
              handleMenuClose();
            }}
          >
            + Add new User
          </Button>
        }
       filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global search',
            },
            {
              type: 'text',
              name: 'user_name',
              label: 'User Name',
            },
            [{
              type: 'checkbox',
              label: 'Super Admin',
              name:'super_admin',
            },
            {
              type: 'checkbox',
              label: 'Support Power User',
              name:'support_power_user',
            }],
            [{
              type: 'checkbox',
              label: 'Support User',
              name:'support_user',
            },
            {
              type: 'checkbox',
              label: 'Custom Companies',
              name:'support_custom_companies',
            }],
            [{
              type: 'checkbox',
              label: 'Staging User',
              name:'staging',
            }],
          ],
        }}
        options={{
          moduleName: 'users',
          apiUrl: '/users',
          dataSourceKey: 'users',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/users/add/` },
          update: { method: 'post', url: `/users/edit` },
        }}
        columns={[
          {
            dataField: 'username',
            caption: 'User Name',
          },
          {
            dataField: 'email',
            caption: 'Email',
          },
          {
            dataField: 'roles',
            caption: 'Role',
          },
          {
            caption: '',
            cellRender: (data: { data: {id: string; state: string;} }) => (
              <Button
                sx={{width: '100%', justifyContent: 'flex-end'}}
                onClick={(e) => handleMenuOpen(e, {id: data.data.id, state: data.data.state})}
              >
                <MoreVertIcon />
              </Button>
            ),
          },
        ]}
      />
    ),
    [handleMenuOpen]
  );

  const onDialogClose = useCallback(
    (info?: { showSnackbarMsg: boolean; message: string }) => {      
        setIsAddUserDialogOpen(false);
        setErrors({})
      if (info?.showSnackbarMsg) {
        setSnackbarMsg(info.message);
      }
    },
    [setErrors]
  );

  const isUserDisabled = useMemo(() => currentUser?.state === '0', [currentUser?.state]);

  const switchUserState = async () => {
    try {
      handleMenuClose();
      move(`users/${isUserDisabled ? 'enable' : 'disable'}/${currentUser?.id}`, {})
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      {table}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
            setIsAddUserDialogOpen(true);
            handleMenuClose();
          }}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => switchUserState()}>
          <ListItemIcon>
           {isUserDisabled
            ? <LockOpen fontSize="small" />
            : <LockIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{isUserDisabled ? 'Enable' : 'Disable'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
            setModalState(true, UserModalType.RESER_PASSWORD)
            handleMenuClose();
          }}>
          <ListItemIcon>
            <RestoreIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reset password</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
            setModalState(true, UserModalType.DELETE_USER)
            handleMenuClose();
          }}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
      {isAddUserDialogOpen
        && <AddEditUser onClose={onDialogClose} userId={currentUser?.id}/>
      }
      <GenericModal
        show={isOpen}
        closeButton
        onClose={handleUserModalClose}
      >
        <UserModalContent
          userId={currentUser?.id as string}
          handleClose={handleUserModalClose}
          type={type}
        />
      </GenericModal>
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={6000}
        message={snackbarMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarMsg('')}
      />
    </>
  );
}
