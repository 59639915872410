import { ReactNode } from "react";

export enum CompanyModalType {
    DELETE_COMPANY = 'delete',
    COMPANY_MODULES = 'modules'
}

export enum ModalBtnDesc {
    SAVE = 'save',
    CANCEL = 'cancel',
    ARGEE = 'agree',
    DISAGREE = 'disagree'
}

export type CompanyModalContentProps = {
    type: CompanyModalType;
    handleClose: () => void;
    companyId: string;
  };

export type DeleteCompanyModalContentProps = {
    handleClose: (msg?: string) => void;
    companyId: string;
}
export type MadalLayoutProps = {
    title: string | ReactNode;
    content: string | ReactNode;
    onReject: () => void;
    onSubmit: () => void;
    cancelBtnDesc?: string | ReactNode;
    submitBtnDesc?: string | ReactNode;
}

export type Module = {
    id: number;
    name: string;
    enabled: boolean;
}
export type CompanyModules = Array<Module & { lpr?: Module }>