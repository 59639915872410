import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Typography } from '@mui/material';
import _ from 'lodash';

import Table from '../../../../../components/Table';
import GenericModal from '../../../../../components/generic/modal';
import VmsModalContent from './vms-modal-content';
import VmsMenu from './VmsMenu';
import VmsMenuGeneral from './VmsMenuGeneral';
import { useDataGrid } from '../../../../../components/generic/dataGrid';

import dayjs from 'dayjs';
import { STARTDATE_FORMAT } from '../../../../../constants';
import { MODULES } from '../../../../../constants/Modules';

import {
  VmsCompaniesResponse,
  VmsModelEdit,
  VmsModelsResponse,
  SelectData,
  VmsLocationsResponse,
  VmsSubscriptionResponse,
  VMSModalType,
} from './types';
import { useApi } from '../../../../../context/api';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';

const CompanyVms = () => {
  const { apiInstance, loading, setLoading} = useApi();
  const { id } = useParams();
  const defaultVmsValues = {
    normal_voltage: '',
    serial: '',
    sign_model: 0,
    sim: '',
    device_serial: '',
  };
  const [vms, setVms] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<VMSModalType | ''>('');
  const [vmsModelList, setVmsModelList] = useState<SelectData[]>([]);
  const [vmsValues, setVmsValues] = useState<any>(defaultVmsValues);
  const { setErrors } = useDataGrid();
  const selectedRowsRef = useRef<string[]>([]);
  const { hasRoleAccess } = useAuth();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const responseVmsModels: VmsModelsResponse = await apiInstance.get(`/companies/vms/list/filters/vms-models/`);
      setLoading(false);
      if (_.size(responseVmsModels.vms_models) > 0) {
        setVmsModelList(
          responseVmsModels.vms_models.map((vmsModel) => ({
            value: vmsModel.id,
            title: vmsModel.name,
          }))
        );
      }
    }

    fetchData();
  }, [apiInstance, setLoading]);

  const handleModalOpen = useCallback(
    (type: VMSModalType, vms: any) => {
      async function fetchVmsData(vms: { id: number }) {
        if (_.isNumber(vms.id)) {
          setLoading(true);
          const response: VmsModelEdit = await apiInstance.get(`/companies/vms/edit/${id}/${vms.id}`);
          setLoading(false);
          setVmsValues({
            normal_voltage: response.vms_info.normal_voltage,
            serial: response.vms_info.serial,
            sign_model: response.vms_info.sign_model,
            device_serial: response.vms_info.device_serial,
            sim: response.vms_info.sim,
          });
          setVms(vms);
        }
      }

      async function fetchCompaniesData(vms: any) {
        setLoading(true);
        const response: VmsCompaniesResponse = await apiInstance.get(`/companies/vms/move/${id}/${vms.id}`);
        setLoading(false);
        setVmsValues({ newCompanyId: response.companies_list[0].id, newCompanyName: response.companies_list[0].name, });
        setVms(vms);
      }

      async function fetchCompaniesMoveData(type: VMSModalType) {
        setLoading(true);
        const response: VmsCompaniesResponse = await apiInstance.get(`/companies/vms/move/bulk/${id}`);
        setLoading(false);
        setVmsValues({
          newCompanyId: response.companies_list[0].id,
          newCompanyName: response.companies_list[0].name,
          selectAllCompanyVms: +(type === 'moveAll'),
          vmsIds: selectedRowsRef.current,
        });
      }

      async function fetchSubscriptionData(dc: any) {
        setLoading(true);
        const response: VmsSubscriptionResponse = await apiInstance.get(
          `/companies/subscription/${MODULES.MODULE_VMS}/${id}/${dc.id}`
        );
        setLoading(false);
        setVms(response.device_info);
        setVmsValues({
          start_subscription: dayjs().format(STARTDATE_FORMAT),
          end_subscription: dayjs().format(STARTDATE_FORMAT),
        });
      }

      function openModal() {
        setIsModalOpen(true);
      }

      setModalType(type);
      switch (type) {
        case 'edit':
        case 'create':
          fetchVmsData(vms).then(() => openModal());
          break;
        case 'moveSelected':
        case 'moveAll':
          fetchCompaniesMoveData(type).then(() => openModal());
          break;
        case 'move':
          fetchCompaniesData(vms).then(() => openModal());
          break;
        case 'subscribe':
          fetchSubscriptionData(vms).then(() => openModal());
          break;
        case 'delete':
        default:
          setVms(vms);
          openModal();
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, setVmsValues, apiInstance, selectedRowsRef]
  );

  const handleModalClose = () => {
    setErrors({});
    setVmsValues(defaultVmsValues);
    setIsModalOpen(false);
    setVms('');
  };

  const saveSelections = useCallback(
    (_event: React.MouseEvent<HTMLElement>, id: string) => {
      const selectedRows = selectedRowsRef.current;
      const currentIndex = _.indexOf(selectedRows, id);

      if (currentIndex > -1) {
        selectedRowsRef.current.splice(currentIndex, 1);
      } else {
        selectedRowsRef.current.push(id);
      }
    },
    [selectedRowsRef]
  );

  const table = useMemo(
    () => {
      return (
        <Table
          backButton="/companies/list"
          title="Company VMS "
          titleKey="company_name"
          toolbar={
            hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
              <VmsMenuGeneral
                vms={''}
                handleModalOpen={handleModalOpen}
                emptySelection={selectedRowsRef}
              />
            )
          }
          filter={{
            inputs: [
              {
                type: 'text',
                name: 'global',
                label: 'Global Search',
              },
              {
                type: 'text',
                name: 'serial',
                label: 'Serial Number',
              },
              {
                type: 'text',
                name: 'firmware_ver',
                label: 'Firmware Version',
              },
              [
                {
                  type: 'select',
                  name: 'location_id',
                  label: 'Locations',
                  fetch: {
                    url: `/companies/vms/list/filters/locations/${id}`,
                    formatter: (data: VmsLocationsResponse) =>
                      data.locations.map((location) => ({
                        value: location.id,
                        title: location.name,
                      }))
                  },
                },
              ],
              [
                {
                  type: 'select',
                  name: 'device_model',
                  label: 'Models',
                  fetch: {
                    url: '/companies/vms/list/filters/vms-models/',
                    formatter: (data: VmsModelsResponse) =>
                      data.vms_models.map((vmsModel) => ({
                        value: vmsModel.id,
                        title: vmsModel.name,
                      }))
                  },
                },
              ],
            ],
          }}
          options={{
            moduleName: 'companies/vms/list',
            apiUrl: `/companies/vms/list/${id}`,
            dataSourceKey: 'company_vms',
            dataStoreKey: 'id',
            insert: { method: 'post', url: `/companies/vms/add/${id}` },
            update: { method: 'post', url: `/companies/vms/edit/${id}` },
            remove: { method: 'post', url: `/companies/vms/delete/${id}` },
          }}
          columns={[
            {
              caption: '',
              cellRender: (data: { data: any }) => <Checkbox onClick={(e) => saveSelections(e, data.data.id)} />,
              width: '50px',
            },
            {
              dataField: 'deviceSerial',
              caption: 'Serial',
              width: '12%',
            },
            {
              dataField: 'firmware_ver',
              caption: 'Firmware version',
              width: '12%',
            },
            {
              dataField: 'device_model',
              caption: 'VMS model',
              width: '15%',
            },
            {
              dataField: 'location_name',
              caption: 'Location name',
              width: '17%',
            },
            {
              dataField: 'group_name',
              caption: 'Group',
              width: '12%',
            },
            {
              dataField: 'serial',
              caption: 'CC Serial Number',
              width: '12%',
            },
            {
              dataField: 'sim',
              caption: 'SIM',
              width: '17%',
            },
            {
              dataField: 'normal_voltage',
              caption: 'Normal Battery voltage',
              width: '16%',
              alignment: 'left',
            },
            {
              ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && {
                ...{
                  caption: '',
                  cellRender: (data: { data: any }) => <VmsMenu vms={data.data} handleModalOpen={handleModalOpen} />,
                  width: '70px',
                  alignment: 'right',
                },
              }),
            },
          ]}
        />
      );
    },
    [hasRoleAccess, handleModalOpen, id, saveSelections]
  );

  return (
    <>
      {table}

      <GenericModal show={isModalOpen} onClose={() => handleModalClose()}>
        <VmsModalContent
          type={modalType as VMSModalType}
          onClose={() => handleModalClose()}
          vms={vms}
          vmsModelList={vmsModelList}
          values={vmsValues}
          id={id as string}
          companyId={id as string}
        />
      </GenericModal>
    </>
  );
};

export default CompanyVms;
