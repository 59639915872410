import React, { memo, useEffect } from 'react';
import { Button } from '@mui/material';
import { useFiltersContext } from '../generic/filter/context';
import { ActionType } from '../generic/filter/context/actions';
import SortIcon from '@mui/icons-material/Sort';
import { MixedInput } from '../generic/filter/context/types';
import { ToolbarButtonWrapper } from './styles';
import _ from 'lodash';

interface FilterButtonProps {
  inputs: Array<MixedInput | Array<MixedInput>>;
  withPageSize?: boolean;
}

const FilterButton = ({
  inputs,
  withPageSize = true,
}: FilterButtonProps) => {
  const { dispatch, setFilters } = useFiltersContext();

  useEffect(() => {
    const copyOfFilterInputs = _.cloneDeep(inputs);

    if (withPageSize) {
      copyOfFilterInputs.push({ type: 'pagesize', name: 'itemsPerPage' });
    }
    setFilters(copyOfFilterInputs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const handleFilterClick = () =>
    dispatch({
      type: ActionType.ShowFilters,
    });

  return (
    <ToolbarButtonWrapper>
      <Button
        onClick={handleFilterClick}
        sx={{ color: 'black' }}
        endIcon={<SortIcon fontSize="small" />}
        data-test={'filterTable'}
      >
        Filters
      </Button>
    </ToolbarButtonWrapper>
  );
};

export default memo(FilterButton);
