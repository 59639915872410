import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';
import { useApi } from '../../../../../context/api';

const FetchSelect = ({
  input,
  field,
}: FilterControlFieldProps<Types.SelectInput>) => {
  const [options, setOptions] = useState<Types.SelectInput['options']>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { apiInstance } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      if (input.fetch) {
        try {
          setLoading(true);
          const response = await apiInstance.get(input.fetch.url);
          
          setOptions(input.fetch.formatter(response));
        } catch (error) {
          console.error('Error fetching options:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [input.fetch, apiInstance]);

  return (
    <FormControl
      variant="standard"
      sx={{
        height: '100%',
        '> div > *': { padding: '2px' },
      }}
    >
      <Select
        displayEmpty
        labelId={input.name}
        id={input.name}
        label={loading ? 'loading...' : input.label}
        disabled={loading}
        size="small"
        defaultValue={''}
        {...field}
      >
        <MenuItem value="">
          <em>{input.label}</em>
        </MenuItem>
        {options?.map((v) => (
          <MenuItem key={`${v.value}`} value={v.value}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FetchSelect;
