import { styled } from "@mui/material";

export const Wrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255,255,255,.5);
`