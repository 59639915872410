import { Box, Button, styled } from '@mui/material';

export const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 30px;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  background: #4a89dc;
  color: #fff;
  font-weight: 700;
`;

export const CancelButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin-left: 15px;
  font-weight: 700;
`;

export const TitleLabel = styled('h3')`
  text-align: center;
  padding: 0;
  color: #333;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 40px;
`;

export const FormError = styled('p')`
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 3px;
`;

export const StyledAllInputsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledInputWrapper = styled(Box)`
  padding: 5px;
  flex: 50%;
  box-sizing: border-box;
`;
