import { useEffect, useState } from 'react';
import { Bracket, WD, ST, HomeWrapper, UL, Li, Top, HiddenLi } from './styles';

export default function Home() {
  const [topStyle, setTopStyle] = useState<{
    opacity: number;
  }>();
  const [style, setStyle] = useState<{
    opacity: number;
    width: number | string;
  }>();
  useEffect(() => {
    setTimeout(() => {
      setStyle({
        opacity: 1,
        width: '100%',
      });
    }, 1000);
    setTimeout(() => {
      setTopStyle({ opacity: 1 });
    }, 2000);
  }, []);
  return (
    <HomeWrapper>
      <Top {...topStyle}>
        <WD>
          <Bracket>[</Bracket>Web Director
        </WD>
        <ST>
          Clients Support<Bracket>]</Bracket>
        </ST>
      </Top>
      <UL>
        <Li>S</Li>
        <HiddenLi {...style}>u</HiddenLi>
        <HiddenLi {...style}>p</HiddenLi>
        <HiddenLi {...style}>p</HiddenLi>
        <HiddenLi {...style}>o</HiddenLi>
        <HiddenLi {...style}>r</HiddenLi>
        <HiddenLi {...style}>t</HiddenLi>
        <Li>&nbsp;</Li>
        <Li>T</Li>
        <HiddenLi {...style}>o</HiddenLi>
        <HiddenLi {...style}>o</HiddenLi>
        <HiddenLi {...style}>l</HiddenLi>
      </UL>
    </HomeWrapper>
  );
}
