import React, { useEffect } from 'react'
import { FilterControlFieldProps } from '../../../../components/generic/filter/controls/types';
import { CustomInput } from '../../../../components/generic/filter/context/types';
import { useWatch } from 'react-hook-form';
import { FormControl, MenuItem, Select } from '@mui/material';

const daysArray = Array.from({ length: 30 }, (_, index) => index + 1);

const SubscriptionExpireControl: React.FC<FilterControlFieldProps<CustomInput>> = ({
  field,
  input,
  control
}) => {
  const communicationStatus = useWatch({ control, name: 'notCommunicated' });

  useEffect(() => {
    if (+communicationStatus !== 1) {
      field.onChange('');
    }
  }, [communicationStatus, field]);

  if (+communicationStatus !== 1) {
    return null;
  }

  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: '150px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '-20px'
      }}
    >
      <Select
        labelId={input.name}
        id={input.name}
        size="small"
        {...field}
      >
        <MenuItem value=""></MenuItem>
        {daysArray.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
      </Select>
      <span>{' days'}</span>
    </FormControl>
  );
}

export default SubscriptionExpireControl