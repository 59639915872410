import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import _ from 'lodash';
import { useApi } from '../../../../../../context/api';
import Box from '@mui/material/Box';
import { NodeWrap, ToggleBtn, TreeViewWrap, Triangle, Value } from './styles';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';

const TreeNode = ({ name, data }: { name: any; data: any }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleNode = () => setIsOpen(!isOpen);
  const countValue = useMemo(() => {
    const size = _.size(data);
    return _.isArray(data) ? `[${size}]` : _.isObject(data) ? `{${size}}` : '';
  }, [data]);

  return (
    <NodeWrap>
      {_.isObject(data) ? (
        <>
          <ToggleBtn onClick={toggleNode}>
            {isOpen ? <Triangle />  : <Triangle isRotated />}
          </ToggleBtn>
          <span style={{marginRight: '10px'}}>{name}</span><span>{countValue}</span>
          {isOpen && <TreeView data={data as TestTokenResponse} />}
        </>
      ) : (
        <>
          <span>{name}</span>
          <span style={{margin: '0 5px'}}>:</span>
          <Value isZeroValue={!data}>
              {!data ? 'null' : data}
          </Value>
        </>
      )}
    </NodeWrap>
  );
};

const TreeView = ({ data }: { data: any }) => (
  <TreeViewWrap>
    {Object.keys(data || {}).map((key) =>
      <TreeNode key={key} name={key} data={data[key]} />
    )}
  </TreeViewWrap>
);


type TestRunProps = {
  token: string;
  onClose?: () => void;
};

type Location = {
  address: string;
  city: string;
  country: string;
  direction: string;
  geocode: string;
  group_id: string;
  lid: string;
  name: string;
  polygon: string;
  state: string;
  timezone_id: string;
  tz: string;
  zip: string;
};

type TestTokenResponse = {
  status: 'ERROR' | 'OK';
  locations: Location[];
};

type Error = { error: { message: string}};

export default function TestRunModalContent({ token, onClose }: TestRunProps) {
  const [responseText, setResponseText] = useState<TestTokenResponse>();
  const { apiInstance, loading, setLoading } = useApi();

  useEffect(() => {
    async function fetchTokenData() {
      try {
        setLoading(true);
        const response: TestTokenResponse = await apiInstance.get(
          `aws/apikey/test/${token}`
        );
        setLoading(false);
        setResponseText(response);
      } catch (err) {
        setLoading(false);
        const { error: { message }} = err as Error;

        if (message) {
          onClose?.()
          toast.error(message, { toastId: 'run_token_error_msg'})
        }

        console.error(err);
      }
    }

    if (token) {
      fetchTokenData();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const data = useMemo(() => ({ object: responseText }), [responseText]);

  if(!responseText) {
    return null
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      {loading ? <LoadPannelLoader/> : ''}
      <AppBar position="sticky" sx={{ margin: 0 }}>
        <Toolbar sx={{background: '#4a89dc'}} variant="regular">
          <Typography
            variant="h4"
            sx={{ marginTop: 1, marginBottom: 1, flexGrow: 1, textAlign: 'left' }}
            align="center"
          >
            Success Test Token
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={onClose}
            color="inherit"
          >
            <CloseRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div style={{height: '515px', backgroundColor: '#272822'}}>{responseText && <TreeView data={data} />}</div>
    </Box>
  );
}
