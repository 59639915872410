import { styled } from '@mui/system';

export const Filter = styled('div')`
  display: flex;
  justify-content: space-around
`;

export const ImageWrap = styled('span')`
  display: inline-block;
  text-align: center;
`;

export const CellWrap = styled('div')`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;
export const Image = styled('img')<{ margin?: string }>(({ margin }) => ({
  display: margin ? 'initial' : 'block',
  margin: margin || 'auto',
  textAlign: 'center'
}));
export const MenuOptionWrap = styled('span')`
  display: inline-flex;
  align-items: center;
`;

