import {
    MobileDatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers';
import React from 'react';
import * as Types from '../../context/types';
import { FilterControlFieldProps } from '../types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {TextField} from "@mui/material";
import { FieldValues } from 'react-hook-form';
import dayjs from "dayjs";
const FULLDATE_FORMAT = 'YYYY-MM-DD';

const DateSelect = ({
  input,
  field,
}: FilterControlFieldProps<Types.TextInput>) => {
  return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
      >
        <MobileDatePicker
          {...field}
          label={input.label}
          value={dayjs(field.value)}
          onChange={(value: dayjs.Dayjs | null) =>
            field.onChange(value ? value.format(FULLDATE_FORMAT) : dayjs(field.value).format(FULLDATE_FORMAT))
          }
          slots={{
            textField: (params: FieldValues) =>
              <TextField
                data-test={`input-${input.label}`}
                variant='standard'
                {...params}
              />
          }}
        />
      </LocalizationProvider>
  );
};

export default DateSelect;
