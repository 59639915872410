import { SignModel } from '../types';

export const radarCommands = {
  CUSTOM: 0,
  SERIAL: 1,
  SCHEDULE: 4,
  LEDS_BRIGHTNESS_FREQUENCY: 5,
  SYNC_TIME: 16,
  GSM_APN_DOMAIN: 35,
  CONNECTION_PERIOD: 41,
  REBOOT: 42,
  SERIAL_EXPANDED: 69
};

export const systemCommands = {
  REBOOT: '!*!#'
};

export const messageList: { [property: string | number] : string} = {
  0: 'OFF',
  1: 'SPEED LIMIT',
  2: 'YOUR SPEED',
  3: 'SLOW DOWN',
  4: 'TOO FAST',
  5: 'Custom Msg #1',
  6: 'Custom Msg #2',
  7: 'Custom Msg #3',
  8: 'Custom Msg #4',
  9: 'Animation',
  10: 'Custom Full Msg #1',
  11: 'Custom Full Msg #2',
  12: 'Custom Full Msg #3',
  13: 'Custom Full Msg #4',
  14: 'Custom Single Msg #1',
  15: 'Custom Single Msg #2',
  16: 'Custom Single Msg #3',
  17: 'Custom Single Msg #4'
};

export const SCREEN_RESOLUTION = {
  MIN: {
    WIDTH: 1024,
    HEIGHT: 768
  }
};

export const FULLDATE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const DAY_WEEK_LABEL = ['MO', 'TU', 'WE', 'TH', 'FR', 'ST', 'SU'];
export const BIT_STEALTH = 6;

// const EXPRESS_MODE_DAY = 127;
// const EXPRESS_MODE_TIME = 0;
// const BIT_MACK_REC_MODE = 15;
// const BIT_DISPLAY_IS_ALWAYS_ON = 4;
// const BIT_STROBE_OFF_ABOVE_MAX_SPEED = 5;
// const BIT_ALTERNATE_SPEED_MIN = 0;
// const BIT_ALTERNATE_SPEED_TOLERATED = 1;
// const BIT_ALTERNATE_SPEED_MAXIMUM = 2;

export const SIGN_MODELS : SignModel = {
  1: 'SP100-SP470',
  2: 'SP500-SP570',
  3: 'SP600',
  4: 'SP800',
  5: 'SP700',
  6: 'EV12',
  7: 'EV15-SD-E',
  8: 'EV15-18 VMS (EV-18FM)',
  9: 'EV15-18 VMS - 3 lines matrix 32x3 + strobe (EV-15FM)',
  10: 'EV-12FM - matrix 26x18 (EV-12FM)',
  11: 'Big VMS - matrix 80x40',
  12: 'EV15-MC – 3 tri-color digits and matrix 64x16 FHD(1 line)',
  13: 'SP650',
  14: 'None'
};



export const COMPANIES_PER_PAGE = 50;