import { useState } from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import { VmsMenuProps, VMSModalType } from './types';
import { USERROLES } from '../../../../../constants/UserRoles';
import { useAuth } from '../../../../../context/auth';

const CompanyVmsMenu = ({ handleModalOpen, vms }: VmsMenuProps) => {
  const { hasRoleAccess } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (type: VMSModalType) => {
    handleModalOpen(type, vms);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={!hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]])}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChoose('subscribe')}>
          <ListItemIcon>
            <CardMembershipIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Subscription</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleChoose('edit')}>
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleChoose('move')}>
          <ListItemIcon>
            <SwapHorizIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Moving</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleChoose('delete')}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CompanyVmsMenu;
