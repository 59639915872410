import { useEffect} from 'react';
import { Typography, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { useDataGrid } from '../../../../../components/generic/dataGrid';
import VmsEditModalContent from './modalComponents/editModal-content';
import VmsDeleteModalContent from './modalComponents/deleteModal-content';
import VmsMoveModalContent from './modalComponents/moveModal-content';
import { CancelButton, SaveButton, ServiceModalWrap } from '../../../styles';
import { VmsControledProps } from './types';
import { MODULES } from '../../../../../constants/Modules';
import VmsSubscribeModalContent from './modalComponents/subscibeModal-content';

const VmsModalContent = (
    { type, vms, values, vmsModelList, onClose, companyId, id }: VmsControledProps
) => {
    const { insert, update, move, errors } = useDataGrid();
    const defaultValues: any = {
      ...values,
      sign_model: vmsModelList[0]?.value
    };
    const {
      handleSubmit,
      control,
      setError
    } = useForm({
      mode: 'all',
      defaultValues,
    });

    useEffect(() => {
      Object.keys(errors).forEach(field => {
        setError(field, {
          type: "manual",
          message: _.values(errors[field])[0]
        });
      });
    }, [errors, setError]);

    const renderContent = () => {
      const defaultCompany = {id: values.newCompanyId, name: values.newCompanyName}
      const contents = {
        edit: <VmsEditModalContent
          control={control}
          vmsModelList={vmsModelList}
        />,
        create: <VmsEditModalContent
          control={control}
          vmsModelList={vmsModelList}
        />,
        move: <VmsMoveModalContent
          vms={vms}
          control={control}
          loadCompaniesUrl={`/companies/vms/move/${id}/${vms.id}`}
          defaultCompany={defaultCompany}
        />,
        moveSelected: <VmsMoveModalContent
          vms={vms}
          control={control}
          loadCompaniesUrl={`/companies/vms/move/bulk/${id}`}
          defaultCompany={defaultCompany}
        />,
        moveAll: <VmsMoveModalContent
          vms={vms}
          control={control}
          loadCompaniesUrl={`/companies/vms/move/bulk/${id}`}
          defaultCompany={defaultCompany}
        />,
        subscribe: <VmsSubscribeModalContent
          vms={vms}
          control={control}
        />,
        delete: <VmsDeleteModalContent type={type}/>,
      }
      
      return contents[type];
    };

    const renderTitle = () => {
      const titles = {
        edit: 'Edit VMS',
        create: 'Add new VMS',
        delete: 'Confirm action',
        move: 'Moving to company',
        moveAll: 'Moving all vms to company',
        moveSelected: 'Moving selected vms to company',
        subscribe: ''
      }
      
      return titles[type] || '';
    }

    const saveVmsInfo = handleSubmit(async (values) => {
      try {
        const { id } = vms;

        const request = {
          edit: () => update(id, values, onClose),
          create: () => insert(values, onClose),
          delete: () => move(`/companies/vms/delete/${companyId}`, { id }, onClose),
          move: () => move(`/companies/vms/move/${companyId}/${id}`, values, onClose),
          moveAll: () => move(`/companies/vms/move/bulk/${companyId}/`, values, onClose),
          moveSelected: () => move(`/companies/vms/move/bulk/${companyId}/`, values, onClose),
          subscribe: () => move(`/companies/subscription/${MODULES.MODULE_VMS}/${companyId}/${id}`, values, onClose)
        };

        request[type]();
      } catch (e: any) {
        console.error(e);
      }
    });

    return (
      <ServiceModalWrap>
        <form
          onSubmit={saveVmsInfo}
        >
          <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
            {renderTitle()}
          </Typography>
          {renderContent()}

          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row-reverse',
              flexWrap: 'wrap',
              marginBottom: 5
            }}
          >
            <CancelButton onClick={onClose}>
              { type === 'delete' ? 'Disagree' : 'Cancel' }
            </CancelButton>
            <SaveButton type="submit" variant="contained">
              { type === 'delete' ? 'Agree' : 'Save' }
            </SaveButton>
          </Box>
        </form>
      </ServiceModalWrap>
    );
};

export default VmsModalContent;
