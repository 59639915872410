import React from 'react';

import {RolesList, UserEditProps} from '../types';
import {
    Box,
    Chip,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import _, { values } from 'lodash';
import { FormError } from '../../styles';

const localeList = [
    { id: 'en', name: 'English' },
    { id: 'fr', name: 'French' },
    { id: 'es', name: 'Spanish' }
];
export default function SignEditModalContent(
    { control, rolesList, setValue, errors }: UserEditProps
) {
    return (
      <>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={values(errors?.fullName)[0]}
              sx={{ marginBottom: 4 }}
              label='Username *'
              fullWidth
              variant="standard"
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={values(errors?.email)[0]}
              sx={{
                marginBottom: 4,
                marginRight: 3,
                width: '47%'
              }}
              label='Email *'
              variant="standard"
              {...field}
            />
          )}
        />
        <Controller
          name="login"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={values(errors?.login)[0]}
              sx={{ marginBottom: 3, width: '48%' }}
              label='Login *'
              variant="standard"
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={values(errors?.password)[0]}
              sx={{
                marginBottom: 4,
                marginRight: 3,
                width: '47%'
              }}
              type='password'
              label='Password *'
              variant="standard"
              {...field}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={values(errors?.confirmPassword)[0]}
              sx={{ marginBottom: 4,  width: '48%' }}
              type='password'
              label='Password repeat *'
              variant="standard"
              {...field}
            />
          )}
        />
        <Controller
          name="locale"
          control={control}
          render={({ field }) => (
            <TextField
              select
              sx={{ marginBottom: 7 }}
              fullWidth
              variant="standard"
              id="locale-select"
              label="Locale"
              {...field}
            >
              {localeList?.map((loc) => (
                <MenuItem value={loc.id} key={loc.id}>
                  {loc.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="roleIds"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginBottom: 7 }}>
              <InputLabel id='edit-modal-parking-select'>Access level</InputLabel>
                <Select
                  sx={{ marginBottom: 7 }}
                  fullWidth
                  variant="standard"
                  labelId="demo-simple-select-standard-label"
                  id="role-select"
                  label="Access level"
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {_.filter(rolesList, role =>
                        _.indexOf(selected, role.id) >= 0).map((item: RolesList) => (
                          <Chip
                            onMouseDown={(event) =>
                              event.stopPropagation()
                            }
                            deleteIcon={<DeleteIcon />}
                            onDelete={() =>
                              setValue('roleIds', _.filter(selected, role => role !== item.id))
                            }
                            label={item?.name}
                            key={item.id}
                          />
                      ))}
                    </Box>
                    )}
                  {...field}
                >
                   {rolesList?.map((role) => (
                      <MenuItem value={role.id} key={role.id}>
                        {role.name}
                      </MenuItem>
                   ))}
                </Select>
                {Boolean(values(errors?.roleIds).length) && <FormError>{values(errors?.roleIds)[0]}</FormError>}
            </FormControl>
          )}
        />
      </>
    );
};
