import { styled } from '@mui/material';

export const CompaniesWrap = styled('div')`
    position: relative;
    min-width: 480px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: #eee;
`
export const CompaniesList = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 400px;
    overflow-y: scroll;
`
export const Company = styled('label')`
    cursor: pointer;
    color: #0000008A;
    font-weight: 700;
    font-size: 14px;
`
export const StyledTitle = styled('h3')`
    font-weight: 700;
    font-size: 16px;
    color: #0000008A;
    text-align: center;
    margin: 0;
`
