import React, { useEffect } from 'react';

import {ParkingControledProps, ParkingInfo, ParkingUpdateBody} from '../types';
import { Typography, Box, Chip } from '@mui/material';
import { CancelButton, SaveButton, ServiceModalWrap } from '../../../../styles';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';
import { useForm } from 'react-hook-form';
import EditModalContent from './modalComponents/editModal-content';
import DeleteModalContent from './modalComponents/deleteModal-content';
import { useApi } from '../../../../../../context/api';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';
import { useParams } from 'react-router-dom';

export default function ModalContent(
    {
      type, parkingModelId, values, controllersList, onClose, isSensor, parkingsList
    }: ParkingControledProps
) {
    const { id } = useParams();
    const { loading } = useApi();
    const { insert, update, move, errors: validationErrors  } = useDataGrid();
    const defaultValues: any = values;
    const { handleSubmit, control, setValue, setError, clearErrors} = useForm({
        mode: 'all',
        defaultValues,
    });

    const renderContent = () => {
      switch (type) {
        case 'edit':
        case 'create':
          return (
            <EditModalContent
              control={control}
              controllersList={controllersList}
              clearErrors={clearErrors}
              parkingsList={parkingsList}
              setValue={setValue}
            />
          );
        case 'delete':
        default:
          return (<DeleteModalContent type={type}/>);
      }
    };

    const renderTitle = () => {
      switch (type) {
        case 'edit':
          return 'Edit virtual-parking';
        case 'create':
          return 'Add virtual-parking';
        case 'delete':
          return 'Confirm action';
      }
    }

    const saveVParkingInfo = handleSubmit(async (values) => {
        const cleanedValues: ParkingUpdateBody =  {
          isHide: values.isHide,
          levels: values.levels,
          name: values.name
        };
        const parkings = values?.parkingChosenList?.map((c: ParkingInfo) => ({
          parkingId: c.parkingId,
          masterLevel: c.masterLevel,
          parkingLevel: c.parkingLevel,
          params: c.params
        })) || [];
        if (values.isGroupCount) {
          cleanedValues.parkings = [...parkings];
        } else {
          cleanedValues.parkingControllerIds = [...values.assigned_parking_controllers];
          cleanedValues.display_type = values.display_type;
        }
        switch (type) {
          case 'edit':
            parkingModelId && await update(parkingModelId, cleanedValues, onClose);
            break;
          case 'create':
            insert(cleanedValues, onClose);
            break;
          case 'delete':
            move(`/companies/v-parkings/delete/${id}`, {virtualParkingId: parkingModelId}, onClose);
            break;
        }
    });
  
  useEffect(() => {
    for (const key in validationErrors) {
      setError(key as never, {
        message: Object.values(validationErrors[key])[0] as string,
      });
    }
  }, [setError, validationErrors]);
  
    return (
      <ServiceModalWrap>
        {isSensor &&
           <Chip
             label='Sensor'
             color="success"
             sx={{
               position: 'absolute',
               right: 0
             }}
           />
        }
        {loading ? <LoadPannelLoader/> : ''}
        <form onSubmit={saveVParkingInfo}>
          <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
            {renderTitle()}
          </Typography>
          {renderContent()}

          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row-reverse',
              flexWrap: 'wrap',
              marginBottom: 5,
              marginTop: 5
            }}
          >
            <CancelButton onClick={onClose}>
              { type === 'delete' ? 'Disagree' : 'Cancel' }
            </CancelButton>
            <SaveButton type="submit" variant="contained">
              { type === 'delete' ? 'Agree' : 'Save' }
            </SaveButton>
          </Box>
        </form>
      </ServiceModalWrap>
    );
};
