import { Box, Typography } from '@mui/material';

import { DcMoveProps } from '../types';
import { Select } from '../../../../../../components/Select';

const DcMoveModalContent = ({ dc, control, defaultCompany, loadCompaniesUrl }: DcMoveProps) => {
  return (
    <>
      {!!dc.serial && <Typography variant='h6' sx={{ marginTop: 5, marginBottom: 5 }}>
        Serial: {dc.serial}
      </Typography>}
      {!!dc.device_model && <Typography variant='h6' sx={{  marginBottom: 5 }}>
        Model: {dc.device_model}
      </Typography>}
      <Box sx={{ padding: '40px 0' }}>
        <Select
          label="Company"
          name="newCompanyId"
          defaultValue={defaultCompany}
          control={control}
          loadItemsUrl={loadCompaniesUrl}
        />
      </Box>   
    </>
  );
};

export default DcMoveModalContent;
