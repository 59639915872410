import {
  CardWrapper,
  Wrapper,
  Card,
  CardMedia,
  CardTitle,
  StyledLink,
} from './styles';
import SignIcon from '../../../../assets/img/index/signs.jpg';
import CameraIcon from '../../../../assets/img/index/camera2.jpg';
import ParkingIcon from '../../../../assets/img/index/parking1.jpg';
import { MODULES } from '../../../../constants/Modules';
import { useAuth } from '../../../../context/auth';
import { useMemo } from 'react';

const cardData = [
  {
    to: '/sign',
    img: SignIcon,
    title: 'Signs',
    testId: 'sign-link',
    module: MODULES.MODULE_SIGN
  },
  {
    to: '/tcam',
    img: CameraIcon,
    title: 'Cameras',
    testId: 'tcam-link',
    module: MODULES.MODULE_TCAM
  },
  {
    to: '/parking',
    img: ParkingIcon,
    title: 'Parking',
    testId: 'parking-link',
    module: MODULES.MODULE_PARKING
  },
  {
    to: '/dc',
    img: 'DCIcon',
    title: 'Data Collectors',
    testId: 'dc-link',
    module: MODULES.MODULE_DC
  },
  {
    to: '/vms',
    img: 'VMSIcon',
    title: 'VMS',
    testId: 'vms-link',
    module: MODULES.MODULE_VMS
  },
];

export default function DashboardHome() {
  const { state: { user } } = useAuth();
  
  return (
    <Wrapper className="dashboard-home" data-test="dashboard-home">
      {cardData.map((card, index) => (
        <CardWrapper className="card-wrapper" key={index}>
          <Card>
            <StyledLink to={card.to} data-test={card.testId}>
              <CardMedia img={card.img}></CardMedia>
              <CardTitle className="card-title">{card.title}</CardTitle>
            </StyledLink>
          </Card>
        </CardWrapper>
      ))}
    </Wrapper>
  );
}
