import { Box, IconButton } from '@mui/material';
import Table from '../../../../components/Table';
import { Wrapper } from './styles';
import DvrIcon from '@mui/icons-material/Dvr';
import { useCallback, useMemo, useState } from 'react';
import GenericModal from '../../../../components/generic/modal';
import { Location } from './types';
import Status from '../../../../components/Table/Columns/Status';
import ModalContent from './modal-content';
import { hasAccessRights } from '../../../../services/helpers';
import { useAuth } from '../../../../context/auth';
import _ from 'lodash';
import { Company } from '../../../../types';
import { useApi } from '../../../../context/api';

export default function Parking() {
  const {
    state: { user: loggedInUser },
  } = useAuth();
  const { apiInstance } = useApi();
  const fetchLocations = useCallback(async (text: string) => {
    const response: {
      locations: Location[];
      status: string;
    } = await apiInstance.get(`/locations?search=${text}`);

    return Promise.resolve(
      response.locations.map((l) => ({ id: l.locationId, title: l.name }))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = useMemo(
    () => (
      <Table
        title="Parking"
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Search',
            },
            {
              type: 'checkbox',
              name: 'lost_communication',
              label: 'Lost Communication',
            },
            {
              type: 'checkbox',
              name: 'no_device_data',
              label: 'No device data',
            },
            {
              type: 'autocomplete',
              name: 'location_id',
              label: 'Locations',
              cb: fetchLocations,
            },
            {
              type: 'select',
              name: 'company',
              label: 'Companies',
              fetch: {
                url: '/parking',
                formatter: (data: { companies: Company[] }) =>
                  data.companies.map((c) => ({ value: c.cid, title: c.name }))
              },
            },
          ],
        }}
        options={{
          moduleName: 'parking',
          apiUrl: '/parking',
          dataSourceKey: 'parkings',
          dataStoreKey: 'id',
        }}
        columns={[
          {
            name: '',
            caption: '',
            width: '40px',
            cellRender: ({ data }) =>
              hasAccessRights('1', loggedInUser?.roles) && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedParkingId(data.id);
                        setIsModalOpen(true);
                      }}
                      title="Show info"
                    >
                      <DvrIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </>
              ),
          },
          {
            dataField: 'parking_name',
            caption: 'Parking Name',
            cellRender: ({ data }) => (
              <div title={`${data.parking_name}`}>{data.parking_name}</div>
            ),
          },
          {
            dataField: 'location_name',
            caption: 'Location Name',
            cellRender: ({ data }) => (
              <div title={`${data.location_name}`}>{data.location_name}</div>
            ),
          },
          {
            dataField: 'company_name',
            caption: 'Company Name',
          },
          {
            dataField: 'lastCommunication',
            caption: 'Last Comm.',
            cellRender: ({ data }) => (
              <Status
                success={!data.brokenRecord}
              >
                {data.lastCommunication} {data.tz_hours_format}
              </Status>
            ),
          },
          {
            dataField: 'deviceTime',
            caption: 'Device Time',
            cellRender: ({ data }) => (
              <Status
                success={!data.brokenRecord}
              >
                {data.deviceTime}
              </Status>
            ),
          },
          {
            dataField: 'levels',
            caption: 'Levels',
          },
          {
            dataField: 'status',
            caption: 'Status',
            cellRender: ({ data }) => (
              <Status
                success={!data.brokenRecord}
              >
                {data.status}
              </Status>
            ),
          },
        ]}
      ></Table>
    ),
    [fetchLocations, loggedInUser?.roles]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedParkingId, setSelectedParkingId] = useState<number>(0);

  return (
    <Wrapper className="parking" data-test="parking">
      {table}
      <GenericModal size="lg" closeButton show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent id={selectedParkingId}></ModalContent>
      </GenericModal>
    </Wrapper>
  );
}
