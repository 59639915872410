import React from 'react';

import { SignMoveProps } from '../types';
import { Box, Typography } from '@mui/material';
import { Select } from '../../../../../../components/Select';

export default function SignMoveModalContent({
  sign,
  control,
  defaultCompany,
  loadCompaniesUrl,
}: SignMoveProps) {
  return (
    <>
      {!!sign.serial && (
        <Typography variant="h6" sx={{ marginTop: 5, marginBottom: 5 }}>
          Serial: {sign.serial}
        </Typography>
      )}
      {!!sign.device_model && (
        <Typography variant="h6" sx={{ marginBottom: 5 }}>
          Model: {sign.device_model}
        </Typography>
      )}
      <Box sx={{ padding: '40px 0' }}>
        <Select
          label="Company"
          name="newCompanyId"
          defaultValue={defaultCompany}
          control={control}
          loadItemsUrl={loadCompaniesUrl}
        />
      </Box>
    </>
  );
}
