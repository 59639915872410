import { styled } from '@mui/system';
import { List, ListItem, Box } from '@mui/material';

export const ListItemGroup = styled(ListItem)`
  display: block;
  text-align: left;
  padding: 2px;
`;
export const CustomListItem = styled(ListItem)`
  padding: 1px;
`;
export const CustomList = styled(List)`
  margin: 0;
  padding: 0;
  margin-left: 11px;
`;

export const ListItemWrapper = styled('div')`
  display: flex;
  justify-content: start;
`;

type BoxType = {
  background?: string;
}
export const ColorBox = styled('div')`
  width: 11px;
  height: 11px;
  min-width: 11px;
  min-height: 11px;
  background: ${(props: BoxType) => props.background ? props.background : 'transparent'};
  margin-right: 5px;
`
export const ItemTitle = styled('div')`
  text-align: left;
`

export const PieChartWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;
