import React, { useState } from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import { Control, Controller, useWatch } from 'react-hook-form'
import { SHORTDATE_FORMAT } from '../../../../../constants'
import FeaturedDatePicker from '../../../../DatePicker/FeaturedDatePicker'
import Paper from '@mui/material/Paper';


interface RangeDateSelectProps {
  control?: Control;
  input: any;
}
export const Wrapper = styled(Paper)`
  display: flex;  
  border: none;
  box-shadow: none;
  & > div {
    margin-bottom: 0
  }
`;

const RangeDateSelect = ({
   control,
   input,
  }: RangeDateSelectProps) => {
  const [ minDate, setMinDate ] = useState<dayjs.Dayjs>(dayjs().subtract(5, 'year'));
  const [ maxDate, setMaxDate ] = useState<dayjs.Dayjs>(dayjs());

  const startDateWatcher = useWatch({ control, name: input.startName });
  const endDateWatcher = useWatch({ control, name: input.endName });

  return (
    <Wrapper>
      <Controller
        name={input.startName}
        control={control}
        render={({ field }) => (
          <FeaturedDatePicker
            minDate={input.minDate || minDate}
            maxDate={dayjs(endDateWatcher)}
            field={field}
            label={"Start Date"}
            styles={{
              marginRight: 5
            }}
            format={SHORTDATE_FORMAT} />
        )} />
      <Controller
        name={input.endName}
        control={control}
        render={({ field }) => (
          <FeaturedDatePicker
            minDate={dayjs(startDateWatcher)}
            maxDate={input.maxDate || maxDate}
            field={field}
            label={"End Date"}
            format={SHORTDATE_FORMAT} />
        )} />
    </Wrapper>
  )
}

export default RangeDateSelect;