import React, { useEffect, useRef, useState } from 'react';
import PieChart, {
  Series,
  Tooltip,
  Legend
} from 'devextreme-react/pie-chart';
import { isNumber, find } from 'lodash';
import { useNavigate } from 'react-router-dom';

import CustomLegend from './CustomLegend';
import { PieChartWrapper } from './styles';

import {
  DictionaryType,
  PaletteType,
  ChartDataType,
  DataItemType,
  ChartDataItemType,
  LegendDataItemType,
  LegendDataType,
  TitleAndValuesType
} from './types';

function customizeTooltip(arg: { argumentText: string; seriesName: string; valueText: string; }) {
  return {
    text: `${arg.seriesName === 'Total' 
      ? arg.argumentText.slice(0, arg.argumentText.indexOf(':')) 
      : 
      '' + arg.argumentText}: ${arg.valueText.slice(1).replace(',', '')}`
  };
}

// eslint-disable-next-line
const customizePoint = (point: any) => {
  const color = point.seriesName === "Total" ? point.data.totalColor : point.data.color
  return { color };
}

const getTitlesAndValues = (item: string, data: DataItemType = {}, link: string): TitleAndValuesType => {
  const convertedData: TitleAndValuesType = {
    titles: [], // [title online, title notCommunicated/notAssigned]
    values: [],  // [value online, value notCommunicated/notAssigned, value total]
    links: [], // [link online , link notCommunicated/notAssigned, link total]
    type: '', // 'activeAssigned' or 'activeNotAssigned' or 'expired'
    originalLink: link
  }
  const expiredValue = 0;
  switch(item) {
    case 'activeAssigned':
      convertedData.titles = ['online',  'not communicated'];
      convertedData.values = [
        data.activeAssignedOnline || 0,  data.activeAssignedNotCommunicated || 0, data[item] || 0
      ];
      convertedData.links = [
        link + '?assigned=1&hasSubscription=1&notCommunicated=0',
        link + '?assigned=1&hasSubscription=1&notCommunicated=1',
        link + '?assigned=1&hasSubscription=1',
      ]
      convertedData.type = 'activeAssigned';
      break;
    case 'activeNotAssigned':
      if (isNumber(data[item])) {
        convertedData.titles = ['online',  'not communicated'];
        convertedData.values = [
          data.activeNotAssignedOnline || 0,  data.activeNotAssignedNotCommunicated || 0, data[item] || 0
        ];
        convertedData.links = [
          link + '?assigned=0&hasSubscription=1&notCommunicated=0',
          link + '?assigned=0&hasSubscription=1&notCommunicated=1',
          link + '?assigned=0&hasSubscription=1',
        ]
      }
      convertedData.type = 'activeNotAssigned';
      break;
    case 'expired':
      convertedData.titles = ['assigned to location',  'not assigned to location'];
      convertedData.values = [
        data.expiredAssigned || expiredValue,  data.expiredNotAssigned || expiredValue, data[item] || expiredValue
      ];
      convertedData.links = [
        link + '?hasSubscription=0&assigned=1',
        link + '?hasSubscription=0&assigned=0',
        link + '?hasSubscription=0',
      ]
      convertedData.type = 'expired';
      break;
  }
  return convertedData;
}

const convertData = (
  data: DataItemType,
  dictionary: DictionaryType,
  palette: PaletteType,
  link: string
) => {
  const convertedData: {
    chartData: ChartDataType,
    legendData: LegendDataType
  }  = {
    chartData: [],
    legendData: []
  };

  for(const item in dictionary) {
    const title = dictionary[item];
    const color = palette[item];
    const titlesAndValues = getTitlesAndValues(item, data, link);
    if (titlesAndValues.values.length > 0 && titlesAndValues.titles.length > 0) {
      const newChartDataElems: ChartDataItemType [] = [
        {
          title: `${title}: ${titlesAndValues.titles[0]}`,
          total: titlesAndValues.values[2],
          value: titlesAndValues.values[0],
          totalColor: color.totalColor,
          color: color.onlineColor,
          link: titlesAndValues.links[0],
          type: titlesAndValues.type,
          originalLink: titlesAndValues.originalLink
        },
        {
          title: `${title}: ${titlesAndValues.titles[1]}`,
          total: 0,
          value: titlesAndValues.values[1],
          totalColor: color.totalColor,
          color: color.offlineColor,
          link: titlesAndValues.links[1],
          type: titlesAndValues.type,
          originalLink: titlesAndValues.originalLink
        }
      ]
      const newLegendDataElems: LegendDataItemType = {
        title: `${title}`,
        total: titlesAndValues.values[2],
        color: color.totalColor,
        items: newChartDataElems,
        link: titlesAndValues.links[2],
        type: titlesAndValues.type,
        originalLink: titlesAndValues.originalLink
      };
      convertedData.chartData = [ ...convertedData.chartData, ...newChartDataElems ]
      convertedData.legendData = [ ...convertedData.legendData, ...[newLegendDataElems]]
    }
  }
  return convertedData;
};

// eslint-disable-next-line
const findlink = (legendData: LegendDataItemType[], data: any) => {
  const elem = find(legendData, (item: LegendDataItemType) => {
    if(item.items[0].title === data?.target?.data?.title) {
      return true;
    }
  })
  const link = typeof elem === "object" ? elem?.link : data?.target?.data?.link
  return link;
}

type PieChartOverviewPropsType = {
  data : DataItemType;
  dictionary : DictionaryType;
  palette : PaletteType;
  link: string;
};

const PieChartOverview = ({ data, dictionary, palette, link } : PieChartOverviewPropsType) => {
  const [addRerender, setAddRerender] = useState(false)
  const instanceRef: React.MutableRefObject<null> = useRef(null);
  const history = useNavigate();
  const {
    chartData ,
    legendData
  } = convertData(data, dictionary, palette, link);

  useEffect(() => {
    // we need a re-rendering to show the corresponding part on the chart when hovering over the legend
    setAddRerender(true);
  }, [addRerender]);
  return (
    <PieChartWrapper
      data-test={'pie-chart-overview'}
      className={'pie-chart-overview'}
    >
      <PieChart
        ref={instanceRef}
        id="pie"
        className={'pie-chart'}
        type="doughnut"
        innerRadius={0.3}
        palette="Ocean"
        dataSource={chartData}
        customizePoint={customizePoint}
        onIncidentOccurred={(error) => console.log(error)}
        startAngle={90}
        size={{
          height: 200,
          width: 200
        }}
        onPointClick={(data) => {
          data?.event?.stopImmediatePropagation();
          const link = data?.target?.series.name === 'Total' ? findlink(legendData, data) : data?.target?.data?.link;
          history(link);
        }}
      >
        <Series name="Value" argumentField="title" valueField="value"/>
        <Series name="Total" argumentField="title" valueField="total"/>

        <Tooltip enabled={true} format="currency" customizeTooltip={customizeTooltip}/>
        <Legend visible={false}/>
      </PieChart>
      <CustomLegend
        legendData={legendData}
        instanceEl={instanceRef.current}
      />
    </PieChartWrapper>
  );
};
export default PieChartOverview;
