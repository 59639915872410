import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  padding: 20px;
  box-sizing: border-box;
`;

export const CardWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardTitle = styled('div')`
  padding: 20px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  background: #fff;
  transition: background 0.3s, color 0.3s;
`;

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  visibility: visible;
  animation-delay: 0.4s;
  animation-name: fadeIn;
  min-width: 300px;

  &:hover .card-title {
    background: #4a89dc;
    color: #fff;
  }
`;

export const CardMedia = styled('div')<{ img: string }>(({ img }) => ({
  background: `url(${img}) no-repeat`,
  backgroundSize: 'cover',
  height: '250px',
  overflow: 'hidden',
}));

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333333;
  :active {
    color: inherit;
  }
`;