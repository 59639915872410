import { useEffect, useState } from 'react';
import GenericModal from '../../../../../../components/generic/modal';
import { Level, ParkingDevice, ParkingLevelSchedule } from '../../types';
import {
  DeviceInfoTable,
  DeviceInfoTableRow,
  DeviceInfoTableHeaderCell,
} from '../../../sign/ShowInfo/styles';
import { ShowLogTd } from '../styles';
import { useApi } from '../../../../../../context/api';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';

export function ParkingScheduleInfo({
  parkingId,
  parkingLevelScheduleId,
  onClose,
}: {
  parkingId: ParkingDevice['id'];
  parkingLevelScheduleId: Level['schedule_id'];
  onClose: () => void;
}) {
  const [schedules, setSchedules] = useState<ParkingLevelSchedule>();
  const { apiInstance, loading, setLoading } = useApi();
  useEffect(() => {
    async function getSchedule() {
      setLoading(true);
      const response: ParkingLevelSchedule = await apiInstance.get(
        `parking/detail/${parkingId}/schedule/${parkingLevelScheduleId}`
      );
      setLoading(false);
      setSchedules({ schedules: response.schedules });
    }

    getSchedule();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingId, parkingLevelScheduleId]);

  return (
    <GenericModal
      closeButton
      size="lg"
      title="Schedule"
      show={true}
      onClose={() => {
        onClose();
      }}
    >
      <DeviceInfoTable>
        <tbody>
        {loading ? <LoadPannelLoader/> : ''}
          <DeviceInfoTableRow>
            <DeviceInfoTableHeaderCell>ID</DeviceInfoTableHeaderCell>
            <DeviceInfoTableHeaderCell>Days</DeviceInfoTableHeaderCell>
            <DeviceInfoTableHeaderCell>Time ON</DeviceInfoTableHeaderCell>
            <DeviceInfoTableHeaderCell>Time OFF</DeviceInfoTableHeaderCell>
          </DeviceInfoTableRow>
          {schedules?.schedules.map((schedule) => (
            <DeviceInfoTableRow key={schedule.id}>
              <ShowLogTd>{schedule.id}</ShowLogTd>
              <ShowLogTd>{schedule.days}</ShowLogTd>
              <ShowLogTd>{schedule.time_on}</ShowLogTd>
              <ShowLogTd>{schedule.time_off}</ShowLogTd>
            </DeviceInfoTableRow>
          ))}
        </tbody>
      </DeviceInfoTable>
    </GenericModal>
  );
}
