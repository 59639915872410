import React, { useState } from 'react';
import { VirtualParking } from '../../../types';
import _ from 'lodash';
import {
  Box,
  Checkbox,
  Chip, CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem
} from '@mui/material';
import { MenuOptionWrap } from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useWatch } from 'react-hook-form';
import SyncGrid from './syncTable';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import apiInstance from '../../../../../../../../context/api/apiInstance';
import Paper from '@mui/material/Paper';

export default function GroupCountSettingsToEdit(
  { parkingsList, control, setValue }:
    { parkingsList: VirtualParking[], control: any, setValue: any }
) {
  const { id } = useParams();
  const [parkingsData, setParkingsData] = useState<VirtualParking[]>(parkingsList);
  const [isLoading, setIsLoading] = useState(false)
  const [isPagging, setIsPagging] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(2)
  
  const parkingChosenList = useWatch({ control, name: 'parkingChosenList' });
  const parkings = useWatch({ control, name: 'parkings' });

  const onMenuClick = (currentParking: VirtualParking) => {
    const { id, name } = currentParking;
    const condition = _.includes(parkingChosenList,
        (park: { parkingId: string }) => park.parkingId === id);
    const newList = condition ? _.filter(parkingChosenList,
      (park: { parkingId: string }) => park.parkingId !== id) :
      [...parkingChosenList, {
        parkingId: id,
        masterLevel: '',
        name: name,
        params: '0',
        parkingLevel: '',
        id: '',
        uniKey: _.reduce(parkingChosenList,
          (max, obj) => obj['uniKey'] > max ? obj['uniKey'] : max, 0) + 1
      }];

    setValue('parkings', _.map(newList, (c) => (c.parkingId)));
    setValue('parkingChosenList', newList);
  };
  
  const changeData = (
      e: { target: { value: string, checked: boolean }},
      data: { data: { [k: string]: string | number; } }
  ) => {
      const { value, checked } = e.target;
      const { uniKey, params } = data.data;
      const newArr = _.map(parkingChosenList, (park: { uniKey: number, params: string }) => {
          if (park.uniKey === uniKey) {
            park.params = checked ?
              _.toString(+params  + (+value)) :
              _.toString(+params  - (+value));
          }
          return park
      });
      setValue('parkingChosenList', [...newArr]);
  };
  
  const parkingsDataSearch = async (params: string, type: string) => {
    if (!params) {
      setParkingsData(parkingsList);
      setPages(1)
      setPage(1)

      return
    }

    switch (type) {
      case 'search':
        setIsLoading(true);
        break;
      case 'paging':
        setIsPagging(true);
        break;
    }
    
    try {
      const response: any & {
        current_page: number;
        pages: number;
        virtual_parkings: VirtualParking[];
      } = await apiInstance.get(
        `/companies/v-parkings/list/${id}?${params}`
      );
      
      setPage(response.current_page)
      setPages(response.pages)
      
      switch (type) {
        case 'search':
          setIsLoading(true);
          setParkingsData(response.virtual_parkings)
          break;
        case 'paging':
          setIsPagging(true);
          setParkingsData([...parkingsData ,...response.virtual_parkings])
          break;
      }

      setIsLoading(false);
      setIsPagging(false);
    } catch (e) {
      setIsLoading(false);
      setIsPagging(false);
      console.log(e);
    }
  }
  
  const parkingsDataScroll = (e: any) => {
    if (isPagging) return;

    if (page < pages && (e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight - 5) {
      parkingsDataSearch(`page=${page + 1}`, 'paging')
    }
  }
  
  const CustomPaper = ({ children } :any) => (
    <Paper>
      {isLoading ? (
        <div style={{textAlign: 'center'}}>
          <CircularProgress color="primary" sx={{margin: '10px'}} />
        </div>
      ) : (
        <div style={{maxHeight: '200px', overflow: 'auto'}} onScroll={parkingsDataScroll}>
          {children}
          {isPagging && (
            <span style={{color:'#ccc', padding: '0 15px 10px'}}>Loading more results...</span>
          )}
        </div>
      )}
    </Paper>
  )
  
  return (
      <>
          <Controller
            name="parkings"
            control={control}
            render={() => (
              <FormControl fullWidth sx={{ marginBottom: 7 }}>
                <Autocomplete
                  id='vparking-select'
                  multiple
                  disablePortal
                  PaperComponent={CustomPaper}
                  options={parkingsData}
                  getOptionLabel={(option: VirtualParking) => option.name}
                  renderTags={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {_.filter(parkingsData, parking =>
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          _.indexOf(selected, parking.id) >= 0).map((item: VirtualParking) => (
                          <Chip
                            onMouseDown={(event) =>
                              event.stopPropagation()
                            }
                            deleteIcon={<DeleteIcon />}
                            onDelete={() =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              setValue('parkings', _.filter(selected, parking => parking !== item.id))
                            }
                            label={item?.name}
                            key={item.id}
                          />
                        ))}
                      </Box>
                    )}
                  value={parkings}
                  renderOption={(props: any, option: VirtualParking)=> (
                    <MenuItem
                      value={option.id}
                      key={`MenuItem_${option.id}`}
                      onClick={() => onMenuClick(option)}
                      sx={{background: _.indexOf(parkings, option.id) !== -1 ? '#ddd' : ''}}
                    >
                      <MenuOptionWrap sx={{display: 'flex', width: '100%'}}>
                        <div style={{ flex: '50%' }}>
                          <span>Name: </span> <b>{option.name}</b>
                        </div>
                        &nbsp;
                        <div style={{ flex: '50%' }}>
                          <span>Location: </span> <b>{option.location}</b>
                        </div>
                      </MenuOptionWrap>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      onChange={(e: any) => {
                        parkingsDataSearch(e.target.value.length > 0 ? `global=${e.target.value}` : '', 'search')
                      }}
                      {...params}
                      label="Select v-parking"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
                </FormControl>
              )}
          />
          <SyncGrid
            receivedStore={parkingChosenList}
            columns={[
              {
                dataField: 'name',
                caption: 'V-parking name',
                width: '33%',
                allowEditing: false
              },
              {
                dataField: 'masterLevel',
                caption: 'Master',
                width: '15%',
                allowEditing: true
              },
              {
                dataField: 'parkingLevel',
                caption: 'Parking',
                width: '15%',
                allowEditing: true
              },
              {
                dataField: 'params',
                caption: 'Parameters',
                width: '37%',
                allowEditing: false,
                cellRender: (data: { data: any }) => (
                  <>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ height: '20px' }}
                            size="small"
                            value={'1'}
                            onChange={(e) => changeData(e, data)}
                            checked={_.includes(['1', '3', '5', '7'], data.data.params)}
                      />
                        }
                        label='In'
                        key={`In-${data.data.id}`}
                        sx={{ height: '20px' }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ height: '20px' }}
                            size="small"
                            value={'2'}
                            onChange={(e) => changeData(e, data)}
                            checked={_.includes(['2', '3', '6', '7'], data.data.params)}
                          />
                        }
                        label='Out'
                         key={`Out-${data.data.id}`}
                         sx={{ height: '20px' }}
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ height: '20px' }}
                            size="small"
                            value={'4'}
                            onChange={(e) => changeData(e, data)}
                            checked={_.includes(['4', '5', '6', '7'], data.data.params)}
                          />
                        }
                        label='Inside Only'
                        key={`Inside-${data.data.id}`}
                        sx={{ height: '20px' }}
                      />
                    </div>
                  </>
                )
              }
            ]}
            updateStore={setValue}
          />
      </>
  );
};
