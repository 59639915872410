import { Wrapper } from './styles';
import LoadPanel from 'devextreme-react/load-panel';

const LoadPannelLoader = ({text}:{text?:string}) => {
  return(
    <Wrapper data-test={'load-panel-loader'}>
      <LoadPanel
        visible={true}
        showPane={true}
        showIndicator={true}
        message={text || 'Loading...'}
      />
    </Wrapper>
  );
}

export default LoadPannelLoader;
