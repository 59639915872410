import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';

const RedirectToHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/', { state: {previousLocation: location}, replace: true});
    window.location.replace('/')
  }, [navigate, location]);

  return null;
};

export default RedirectToHome