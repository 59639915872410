import React from 'react';

import { DcSubscriptionProps } from '../types';
import { Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import FeaturedDatePicker from "../../../../../../components/DatePicker";

export default function DcSubscribeModalContent(
    { dc, control }: DcSubscriptionProps
) {
    return (
      <>
        <Typography variant='h4' sx={{ marginBottom: 5 }} align='center'>
          Add Subscription
        </Typography>
        {!!dc.serial && <Typography variant='h6' sx={{  marginBottom: 5 }}>
          Serial: {dc.serial}
        </Typography>}
        <Controller
          name="start_subscription"
          control={control}
          render={({ field }) => (
            <FeaturedDatePicker
              field={field}
              label={"Start Date"}
              styles={{
                marginRight: 5
              }}
            />
           )}
        />
        <Controller
          name="end_subscription"
          control={control}
          render={({ field }) => (
            <FeaturedDatePicker field={field} label={"End Date"}/>
          )}
        />
      </>
    );
};