import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';

const SimpleSelect = ({
  input,
  field,
}: FilterControlFieldProps<Types.SelectInput>) => {
  return (
    <FormControl
      variant="standard"
      sx={{
        height: '100%',
        '> div > *': { padding: '2px' },
      }}
    >
      <Select
        displayEmpty
        labelId={input.name}
        id={input.name}
        label={input.label}
        size="small"
        defaultValue={''}
        {...field}
      >
        <MenuItem value="">
          <em>{input.label}</em>
        </MenuItem>
        {(input as Types.SelectInput).options?.map((v) => (
          <MenuItem key={`${v.value}`} value={v.value}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SimpleSelect;
