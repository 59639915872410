import { InitialStateType, initialState } from '.';
import { ActionType, FiltersActions } from './actions';

export const reducer = (
  state: InitialStateType,
  action: FiltersActions
): InitialStateType => {
  switch (action.type) {
    case ActionType.SetDataGridRef:
      return { ...state, dataGridRef: action.payload };
    case ActionType.SetFilterInputs:
      return { ...state, inputs: [...action.payload] };
    case ActionType.ShowFilters:
      return { ...state, isShown: !state.isShown };
    case ActionType.SetActiveFilters:
      return {
        ...state,
        activeFilters: { ...action.payload.filters },
      };
    case ActionType.ResetFilters:
      return { ...state, activeFilters: {} };
    case ActionType.DestroyFilters:
      return { ...initialState };
    default:
      return state;
  }
};
