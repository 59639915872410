import React, { useEffect, useState } from "react";
import { useApi } from '../../../../context/api';
import { Controller, useForm } from "react-hook-form";
import {
  ReportTypesResponse,
  CompaniesResponse,
  ReportDataType,
  DateResponse, ReportResponseType
} from '../types';
import { MenuItem, Select, FormControl, InputLabel, Tooltip } from "@mui/material";
import { RangeDatePicker } from '../../../../components/DatePicker';
import { Wrapper, Title, LoadingWrap } from '../styles';
import { SaveButton, ButtonsWrapper } from '../../styles';
import { get, isBoolean } from 'lodash';
import WarningIcon from '@mui/icons-material/Warning';
import dayjs from 'dayjs';
import { SHORTDATE_FORMAT } from '../../../../constants';
import { toast } from 'react-toastify';
import LoadPannelLoader from '../../../../components/LoadPanelLoader';

const Reports = ({ setReportData, hideForm }: { setReportData: (data: ReportDataType) => void, hideForm: boolean }) => {
  const { apiInstance, loading, setLoading } = useApi();
  const [ isDataAvailable, setIsDataAvailable ] = useState<boolean |string>(false);
  const [ minDate, setMinDate ] = useState<dayjs.Dayjs>(dayjs());
  const [ maxDate, setMaxDate ] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'day'));
  const [ reportTypes, setReportTypes ] = useState<{ value: number; title: string }[]>([]);
  const [ companies, setCompanies ] = useState<{ value: number; title: string }[]>([]);

  const itemsPerPage = 10

  const defaultValues: any = {
    type: 0,
    accountId: 0,
    startDate: dayjs().subtract(1, 'day').format(SHORTDATE_FORMAT),
    endDate: dayjs().format(SHORTDATE_FORMAT)
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const handleLoadReport = handleSubmit(async (values) => {
    try {
      setLoading(true);
      const response: ReportResponseType = await apiInstance.post(`/report/create`, {
        ...values,
        itemsPerPage
      });
      setLoading(false);

      setReportData({
        ...response,
        requestData: {
          ...values,
          itemsPerPage
        }
      });
    } catch (error) {
      setLoading(false);
      toast.error(
        get(
          error,
          'error.message',
          'Something went wrong while processing your request'
        )
      );
    }
  });

  const selectedReportType = watch('type');
  const selectedAccountId = watch('accountId');

  useEffect(() => {
    async function fetchData() {
      const { reportsList }: ReportTypesResponse = await apiInstance.get('/report/list');
      const { accountList }: CompaniesResponse = await apiInstance.get(`/companies/names-list`);

      setReportTypes(reportsList?.map((tt) => ({ value: tt.type, title: tt.name })) || []);
      setCompanies(accountList?.map((tt) => ({ value: tt.id, title: tt.name })) || []);
    }

    fetchData();
  }, [apiInstance]);

    useEffect(() => {
      if (!!selectedAccountId && !!selectedReportType) {
        (async () => {
          try {
            const { dateRange, dataAvailable }: DateResponse =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await apiInstance.get(`/report/accountId/${selectedAccountId}/reportType/${selectedReportType}`);

            if (dataAvailable !== 0) {
              const [ startDate, endDate ] = dateRange[0].split(' - ');

              setMinDate(dayjs(startDate));
              setMaxDate(dayjs(endDate));
              setValue('startDate', dayjs(endDate).subtract(1, 'day').format(SHORTDATE_FORMAT));
              setValue('endDate', dayjs(endDate).format(SHORTDATE_FORMAT));
              setIsDataAvailable(!!dataAvailable);
            } else {
              setIsDataAvailable(dateRange[0]);
            }
          } catch (error) {
            console.error(error);
          }
        })();
      }
    }, [selectedAccountId, selectedReportType, setValue, apiInstance])

  if (hideForm) {
    return (<></>);
  }

  return (
    <Wrapper elevation={3} >
      {loading ? <LoadPannelLoader/> : ''}
      <form onSubmit={handleLoadReport} data-test={'ReportForm'}>
        <Title variant='h6'>
          Company Specific Report
        </Title>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl sx={{ width: '60%' }}>
              <InputLabel
                id='select-type'
                classes={{
                  root: 'labelReport'
                }}
              >Report Type</InputLabel>
              <Select
                sx={{ marginBottom: 7 }}
                fullWidth
                required
                variant="standard"
                label="Type"
                data-test="select-report-type"
                {...field}
              >
                <MenuItem disabled value={0}>
                  <em> Select report type </em>
                </MenuItem>
                {reportTypes?.map((type) => (
                  <MenuItem value={type.value} key={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="accountId"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel
                id='select-company'
                classes={{
                    root: 'labelReport'
                }}
              >Company</InputLabel>
              <Select
                disabled={!selectedReportType}
                sx={{ marginBottom: 7 }}
                fullWidth
                required
                variant="standard"
                label="Type"
                data-test="select-company"
                {...field}
              >
                <MenuItem disabled value={0}>
                  <em>
                    {!selectedReportType ?
                      'Select report type first of all' :
                      'Select company'
                    }
                  </em>
                </MenuItem>
                {companies?.map((company) => (
                  <MenuItem value={company.value} key={company.value}>
                    {company.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {isBoolean(isDataAvailable) ? isDataAvailable ?
          <>
            <RangeDatePicker
              startName="startDate"
              endName="endDate"
              minDate={minDate}
              maxDate={maxDate}
              control={control}
            />
            <ButtonsWrapper>
              <SaveButton disabled={formState.isSubmitting} type="submit" variant="contained">
                {formState.isSubmitting ? 'Building...' : 'Generate Report'}
              </SaveButton>
            </ButtonsWrapper>
          </> :
          '' :
          <LoadingWrap>
            <Tooltip title={isDataAvailable}><WarningIcon/></Tooltip>
          </LoadingWrap>
        }
      </form>
    </Wrapper>
  );
};
export default Reports;
