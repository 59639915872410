import _ from "lodash";
import { REACT_APP_BACKEND_URL } from "../../constants/Env.Constants";

export const getLocalAccessToken = () => {
  const user = window.localStorage.getItem("user");

  if (!user) {
    return "";
  }

  const parsedUser = JSON.parse(user);
  const token = _.get(parsedUser, "tokens.access_token", "");

  return token;
};

export const ContentHeader = {
  baseURL: `${REACT_APP_BACKEND_URL}`,
  headers: {
    "Content-Type":
      getLocalAccessToken() !== ""
        ? "multipart/form-data"
        : "application/json; charset=utf-8",
  },
};
