import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';
import { CheckboxFieldWrapper } from '../../styles';

const CheckboxField = ({
  field,
  input,
}: FilterControlFieldProps<Types.TextInput>) => {
  return (
    <CheckboxFieldWrapper>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ height: '20px', fontSize: '12px', fontWeight: '500' }}
            size="small"
            onChange={(e) => field.onChange(e.target.checked)}
            checked={field.value ? true : false}
          />
        }
        label={input.label}
        key={input.label}
        sx={{ height: '20px' }}
      />
    </CheckboxFieldWrapper>
  );
};

export default CheckboxField;
