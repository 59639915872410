import { useCallback, useMemo, ReactNode } from 'react';
import DataGrid, {Column, IColumnProps} from 'devextreme-react/data-grid';
import TableWrapper from '../Table/TableWrapper';
import * as FilterContext from '../generic/filter/context';

type Filter = FilterContext.InitialStateType & {
    withPageSize?: boolean;
}

const GridTable = ({
  title,
  filter,
  toolbar,
  columns,
  backButton,
  receivedStore,
  keyExpr,
  dataRowRender
}:
  {
    title?: string | ReactNode;
    toolbar?: ReactNode;
    filter?: Filter;
    withPaging?: boolean;
    withSorting?: boolean;
    backButton?: string;
    columns: IColumnProps[],
    receivedStore: any,
    keyExpr: string,
    dataRowRender?: (params: any) => ReactNode;
  }) => {
  const columnComponent = useCallback((column: IColumnProps, index: number) => {
    const columnProps = { ...column };

    return (
        <Column
          key={index}
          { ...columnProps }
        />
    )
  }, []);

  if (receivedStore[0].changedKeys?.length === 0 && receivedStore[0].age &&
    !(receivedStore[0].isNew && receivedStore[0].isOld)) {
    receivedStore.splice(0,1);
  }

  const table =  useMemo(
    () => {
      return (
        <DataGrid
          dataSource={receivedStore}
          keyExpr={keyExpr}
          dataRowRender={dataRowRender}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          sorting={{mode: 'none'}}
        >
          {columns.map(columnComponent)}
        </DataGrid>
        )
    },
    [
        columns,
        receivedStore,
        columnComponent,
        keyExpr,
        dataRowRender
    ]
  );

  return (
      <TableWrapper
        title={title}
        filter={filter}
        toolbar={toolbar}
        backButton={backButton}
      >
        {table}
      </TableWrapper>)
};

export default GridTable;
