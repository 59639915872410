import React from 'react';
import Login from './components/login';
import { Route, Routes } from 'react-router-dom';
import RedirectToHome from '../../components/RedirectToHome';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" index element={<Login />} />
      <Route path="*" element={<RedirectToHome />} />
    </Routes>
  );
};
export default AuthRoutes;
