import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DeviceInfoTable,
  DeviceInfoTableHeaderCell,
  DeviceInfoTableRow,
  DeviceInfoTableRowCell,
} from '../ShowInfo/styles';
import { ServiceModalWrap } from '../../../styles';
import { TableWrapper } from './styles';

import SystemInfo from './components/SystemInfo';
import ScheduleInfo from './components/ScheduleInfo';
import LedBrightnessInfo from './components/LedBrightnessInfo';
import { SignCommand } from '../types';

import { useApi } from '../../../../../context/api';

import SignCMDParser from './parsers/index';
import RadarCommand from './parsers/radarCommand';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';


const ShowInfoCommand = (
  { id,  commandsId, onClose }: { id: number | null, commandsId: string | undefined, onClose: () => void }
) => {
  const [info, setInfo] = useState<SignCommand | null>(null);
  const { apiInstance, loading, setLoading } = useApi();

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const result: { sign_command: SignCommand } = await apiInstance.get(`/sign-commands/detail/${id}`);
      setLoading(false)
      setInfo(result.sign_command);
    }

    fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, commandsId]);

  const parsedResponse = useMemo(() => {
    if (info === null) {
      return null;
    }

    // region LedBrightnessInfo
    // const sign_command = {
    //   "id":175,
    //   "serial":"12345670",
    //   "cmd_code":0,
    //   "cmd_text":"!0500",
    //   "stop_proc":0,
    //   "time_req":"2022-04-04 12:16:36",
    //   "time_proc":"2022-04-04 15:17:16",
    //   "server_id":23298,
    //   "cmd_text_formatted":"Get Brightness",
    //   "has_response":true,
    //   "response_text":"!0505011032A101.",
    //   "command_provider":"scs"
    // }
    // const command = new RadarCommand(sign_command?.response_text);
    // endregion

    // region dateTimeInfo
    // const sign_command = {
    //   "id":174,
    //   "serial":"12345670",
    //   "cmd_code":0,
    //   "cmd_text":"!1000",
    //   "stop_proc":0,
    //   "time_req":"2022-04-04 12:16:36",
    //   "time_proc":"2022-04-04 15:17:16",
    //   "server_id":23298,
    //   "cmd_text_formatted":"Get time",
    //   "has_response":true,
    //   "response_text":"!100610120D070915.",
    //   "command_provider":"scs"
    // }
    // const command = new RadarCommand(sign_command?.response_text);
    // endregion

    // region Get Schedule
    // const sign_command = {
    //   "id":173,
    //   "serial":"12345670",
    //   "cmd_code":0,
    //   "cmd_text":"!0400",
    //   "stop_proc":0,
    //   "time_req":"2022-04-04 12:16:36",
    //   "time_proc":"2022-04-04 15:17:16",
    //   "server_id":23298,
    //   "cmd_text_formatted":"Get Schedule",
    //   "has_response":true,
    //   "response_text":"!04127F00002C05A037786A676304030201000012.",
    //   "command_provider":"scs"
    // }
    // const command = new RadarCommand(sign_command?.response_text);
    // endregion

    const command = new RadarCommand(info?.response_text);
    const parsedResponse: any = SignCMDParser(command);
    return parsedResponse;
  },[info]);

  return (
      <ServiceModalWrap>
        {loading ? <LoadPannelLoader/> : ''}
        <Box
          sx={{ cursor: 'pointer', position: 'absolute', right: 0 }}
          onClick={onClose}
        >
          <CloseIcon data-test={'closeInfo'}/>
        </Box>
        <Typography variant='h4' sx={{ marginTop: 3, marginBottom: 8 }} align='center'>
          Command info
        </Typography>

        <DeviceInfoTable>
          <tbody>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Cmd code</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'cmd_codeValue'}>
                  {info?.cmd_code}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Cmd text</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'cmd_text_formattedValue'}>
                  {info?.cmd_text_formatted}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Stop process</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'stop_procValue'}>
                  {info?.stop_proc}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Time request</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'time_reqValue'}>
                  {info?.time_req}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Sevice id</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'server_idValue'}>
                  {info?.server_id}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Time process text</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'time_procValue'}>
                  {info?.time_proc}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>Response text</DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'response_textValue'}>
                  {info?.response_text}
                </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
          </tbody>
        </DeviceInfoTable>
        {
          parsedResponse &&
          parsedResponse?.boardFuses &&
          parsedResponse?.fwFuses &&
          <SystemInfo data={parsedResponse}/>
        }
        {
          parsedResponse &&
          parsedResponse?.brightness &&
          parsedResponse?.ledStrobeBlink &&
          <LedBrightnessInfo data={parsedResponse}/>
        }
        {
          parsedResponse &&
          parsedResponse?.dateTime &&
          <TableWrapper>
            <DeviceInfoTable>
              <tbody>
              <DeviceInfoTableRow>
                <DeviceInfoTableHeaderCell>
                  Current time and date
                </DeviceInfoTableHeaderCell>
                <DeviceInfoTableRowCell data-test={'Current_time_and_date'}>
                  {parsedResponse?.dateTime}
                </DeviceInfoTableRowCell>
              </DeviceInfoTableRow>
              </tbody>
            </DeviceInfoTable>
          </TableWrapper>
        }
        {
          parsedResponse &&
          parsedResponse?.days &&
          parsedResponse?.displaySetting &&
          <ScheduleInfo data={parsedResponse}/>
         }
      </ServiceModalWrap>
  )
}

export default ShowInfoCommand;