import { AxiosInstance } from 'axios';
import { LogintowdCredentialsResponse } from './types';

export const LOGIN_TO_WD3_URL = '/service/logintowdcredentials/';

export const loginToWD3 = (api: AxiosInstance, companyId: string) => {
  async function getLogintowdCredentials() {
    const result: LogintowdCredentialsResponse = await api.get(
      `${LOGIN_TO_WD3_URL}${companyId}`
    );    
    if (result.status === 'ERROR') return false;
    const win = window.open(
      `https://${result.logixOnCloudHost}?referer=${result.account.wd_login}&hash=${result.account.token}`,
      '_blank'
    );
    win?.focus();
  }

  getLogintowdCredentials();
};
