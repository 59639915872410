import * as React from 'react';
import { STARTDATE_FORMAT } from '../../constants';
import dayjs from 'dayjs';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { FieldValues } from "react-hook-form";
import { TextField } from "@mui/material";
import _ from 'lodash';

interface FeaturedDatePickerProps {
  field: {
    value: dayjs.Dayjs | null | string,
    onChange: (value: dayjs.Dayjs | null | string) => void
  };
  label?:  string;
  styles?: {[k: string]: unknown;};
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  format?: string;
  callbackAccept?: () => void;
}

const FeaturedDatePicker = ({
    field,
    label,
    styles,
    minDate,
    maxDate,
    format = STARTDATE_FORMAT,
    callbackAccept
}: FeaturedDatePickerProps) => {
  return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
      >
        <MobileDatePicker
          sx={{ width: '45%', marginBottom: 7, ...styles }}
          label={label}
          value={dayjs(field.value)}
          onChange={(value: dayjs.Dayjs | null) =>
            field.onChange(value ? value.format(format) : dayjs(field.value).format(format))
          }
          onAccept={callbackAccept ? callbackAccept : _.noop}
          minDate={minDate}
          maxDate={maxDate}
          slots={{
            textField: (params: FieldValues) =>
              <TextField
                data-test={`input-${label}`}
                variant='standard'
                {...params}
              />
          }}
        />
      </LocalizationProvider>
  );
};

export default FeaturedDatePicker;
