import { useDataGrid } from "../../../../components/generic/dataGrid";
import { ModalLayout } from "./ModalLayout"
import { DeleteCompanyModalContentProps, ModalBtnDesc } from "./types";

export const DeleteCompanyModalContent = ({ handleClose, companyId }: DeleteCompanyModalContentProps) => {
    const { move } = useDataGrid();
    const handleDeleteCompany = async () => {
        move('/companies/delete/', {id: companyId}, handleClose)
    }
    return (
        <ModalLayout
            title="Confirm action"
            content="Are you sure you want to delete this company?"
            onReject={handleClose}
            onSubmit={handleDeleteCompany}
            submitBtnDesc={ModalBtnDesc.ARGEE}
            cancelBtnDesc={ModalBtnDesc.DISAGREE}
        />)
}