import React, { useCallback, useMemo, useState } from 'react';
import Table from '../../../../../components/Table';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useParams } from 'react-router-dom';
import GenericModal from '../../../../../components/generic/modal';
import TokenModalContent from './TokenModalContent';
import TokensMenuGeneral from './TokensMenuGeneral';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function CompanyTokens() {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [modalType, setModalType] = useState('');

  const handleModalOpen = useCallback((type: string, token: string) => {
    setToken(token);
    setIsModalOpen(true);
    setModalType(type);
  }, []);

  const handleModalClose = () => {
    setToken('');
    setIsModalOpen(false);
  };

  const copyToClipboard = (obj: { apiKey: string; companyId: string }, row: HTMLElement) => {
     row.classList.add('is-copied');
     
     const timeout = setTimeout(() => {
       row.classList.remove('is-copied');
       clearTimeout(timeout)
     }, 900)
    
    const el = document.createElement('textarea');
    el.value = JSON.stringify(obj);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        title={<Typography variant="h5">Company tokens</Typography>}
        toolbar={<TokensMenuGeneral handleModalOpen={handleModalOpen} />}
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
          ],
        }}
        options={{
          moduleName: 'companies/tokens/list',
          apiUrl: `/aws/apikey/list/${id}`,
          dataSourceKey: 'apiKeys',
          dataStoreKey: 'token',
          insert: { method: 'post', url: `aws/apikey/add` },
          update: { method: 'post', url: `/aws/apikey/edit` },
        }}
        columns={[
          {
            caption: '',
            cellRender: (data: { data: any, cellElement: any }) => (
              <LightTooltip title="Test run">
                <IconButton
                  onClick={() => {
                    handleModalOpen('testRun', data?.data?.token);
                    const rowStyle = data.cellElement.offsetParent.
                      children[1].childNodes[0].style;

                    rowStyle.transition = 'background-color 1s ease-in';
                    rowStyle.backgroundColor = 'rgba(106, 179, 68, 0.5)';
                    
                    setTimeout(() => {
                      rowStyle.transition = 'background-color 1s ease-in';
                      rowStyle.backgroundColor = 'white';
                    }, 2000);
                  }}
                >
                  <TouchAppIcon fontSize="small" color="inherit" />
                </IconButton>
              </LightTooltip>
            ),
            width: '3%',
          },
          {
            caption: '',
            cellRender: (data: { data: any, cellElement: any }) => (
              <LightTooltip title="Copy">
                <IconButton
                  onClick={() => {
                    copyToClipboard(
                      {
                        apiKey: data.data.token,
                        companyId: data.data.companyId,
                      },
                      data.cellElement.offsetParent
                    )}
                  }
                >
                  <ContentCopyIcon fontSize="small" color="inherit" />
                </IconButton>
              </LightTooltip>
            ),
            width: '3%',
          },

          {
            dataField: 'token',
            caption: 'Token',
            width: '41%',
          },
          {
            dataField: 'login',
            caption: 'User Name',
            width: '13%',
          },
          {
            dataField: 'expiration',
            caption: 'Expiration',
            width: '20%',
          },
          {
            dataField: 'userIp',
            caption: 'User IP',
            width: '14%',
          },
          {
            caption: '',
            cellRender: (data: { data: any }) => (
              <LightTooltip title="Edit">
                <IconButton
                  data-test="table-btn-edit"
                  onClick={() => {
                    handleModalOpen('edit', data?.data?.token);
                  }}
                >
                  <ModeEditIcon fontSize="small" color="inherit" />
                </IconButton>
              </LightTooltip>
            ),
            width: '3%',
          },
        ]}
      />
    );
  }, [id, handleModalOpen]);

  return (
    <>
      {table}

      <GenericModal customContentStyles={{padding: 0}} show={isModalOpen} onClose={() => handleModalClose()}>
        <TokenModalContent type={modalType} onClose={() => handleModalClose()} token={token} />
      </GenericModal>
    </>
  );
}
