import TestRunModalContent from './modalComponents/TestRunModalContent';
import TokenEditModalContent from './modalComponents/TokenEditModalContent';

export type TokenEditModalContentFormFields = {
  enabled: boolean;
  name: string;
  description: string;
  userId: string;
  expiration: string;
  locationIds: string[];
  usagePlans: string[];
};

export type TokenControledProps = {
  type: string;
  onClose?: () => void;
  token: string;
};

export default function TokenModalContent({
  onClose,
  type,
  token,
}: TokenControledProps) {
  return (
      type === 'testRun' ? (
        <TestRunModalContent token={token} onClose={onClose} />
      ) : (
        <TokenEditModalContent type={type} token={token} onClose={onClose} />
      )
    
  );
}
