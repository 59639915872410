import { Box, Typography } from '@mui/material';

import { VmsMoveProps } from '../types';
import { Select } from '../../../../../../components/Select';

const VmsMoveModalContent = ({ vms, control, loadCompaniesUrl, defaultCompany }: VmsMoveProps) => {
  return (
    <>
      {!!vms.serial && <Typography variant='h6' sx={{ marginTop: 5, marginBottom: 5 }}>
        Serial: {vms.serial}
      </Typography>}
      {!!vms.device_model && <Typography variant='h6' sx={{  marginBottom: 5 }}>
        Model: {vms.device_model}
      </Typography>}
      <Box sx={{ padding: '40px 0' }}>
        <Select
          label="Company"
          name="newCompanyId"
          defaultValue={defaultCompany}
          control={control}
          loadItemsUrl={loadCompaniesUrl}
        />
      </Box>
    </>
  );
};

export default VmsMoveModalContent;
