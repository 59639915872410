import { useMemo } from 'react';
import { Switch } from '@mui/material';

import { VmsDevice } from './types';

import { Wrapper } from '../styles';
import Table from '../../../../components/Table';

import Status from '../../../../components/Table/Columns/Status';
import Value from '../../../../components/Table/Columns/Value';
import { MODULES } from "../../../../constants/Modules";
import { fullFiltersOverview, generalFilters } from '../filters';

export default function Vms() {
  const renderChild = (value: number | string | null) => value ? value : 'no data';

  const table = useMemo(
    () => (
      <Table
        title="VMS"
        exportData={{
          csv: {
            url: `/devices/${MODULES.MODULE_VMS}/export-csv`
          }
        }}
        filter={{
          inputs: [
            ...generalFilters(MODULES.MODULE_VMS),
            ...fullFiltersOverview
          ]
        }}
        options={{
          moduleName: 'vms',
          apiUrl: `/devices/${MODULES.MODULE_VMS}/search`,
          dataSourceKey: 'items',
          dataStoreKey: 'id',
          searchKey: ''
        }}
        columns={[
          {
            caption: 'Acc. Number',
            dataField: 'companyId',
            calculateSortValue: 'companyId',
            width: '5%',
            minWidth: 40,
            alignment: 'right',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.companyId}/>
          },
          {
            caption: 'Device Serial',
            dataField: 'deviceSerial',
            calculateSortValue: 'deviceSerial',
            width: '10%',
            minWidth: 100,
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.deviceSerial}/>
          },
          {
            dataField: 'model',
            caption: 'Model',
            calculateSortValue: 'model',
            width: '6%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.model}/>
          },
          {
            dataField: 'location',
            caption: 'Location',
            calculateSortValue: 'location',
            width: '9%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.location}/>
          },
          {
            dataField: 'companyName',
            caption: 'Company',
            calculateSortValue: 'companyName',
            width: '10%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.companyName}/>
          },
          {
            caption: 'State',
            dataField: 'state',
            calculateSortValue: 'state',
            width: '7%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.state}/>
          },
          {
            caption: 'CC Serial',
            dataField: 'ccSerial',
            calculateSortValue: 'ccSerial',
            width: '6%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.ccSerial}/>
          },
          {
            caption: 'SIM',
            dataField: 'sim',
            calculateSortValue: 'sim',
            width: '6%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.sim}/>
          },
          {
            caption: 'Contact e-mail',
            dataField: 'contactEmail',
            calculateSortValue: 'contactEmail',
            width: '9%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.contactEmail}/>
          },
          {
            caption: 'Renewal date',
            dataField: 'subscriptionExpiresAt',
            calculateSortValue: 'subscriptionExpiresAt',
            width: '10%',
            cellRender: ({ data } : { data: VmsDevice }) => <Value data={data.subscriptionExpiresAt}/>
          },
          {
            caption: 'Last comm.',
            dataField: 'lastCommunicatedAt',
            calculateSortValue: 'lastCommunicatedAt',
            width: '12%',
            cellRender: ({ data } : { data: VmsDevice }) => (
              <Status
                success={!!data.timeStatus}
                disabled={!data.lostCommunication}
              >
                {renderChild(data.lastCommunicatedAt)}
              </Status>
            ),
          },
          {
            dataField: 'battery',
            caption: 'Battery',
            calculateSortValue: 'battery',
            alignment: 'left',
            width: '8%',
            cellRender: ({ data } : { data: VmsDevice }) => (
              <Status
                success={!!data.batteryStatus}
                disabled={!data.lostCommunication}
              >
                {`${renderChild(data.battery)} / ${data.normalVoltage}v`}
              </Status>
            ),
          },
          {
            type: 'buttons',
            name: '',
            caption: '',
            width: '4%',
            alignment: 'left',
            cssClass:'padding-left-1',
            cellRender: ({ data } : { data: VmsDevice }) =>
              <Switch disabled={true} data-test={`switchDevice_${data.id}`}/>,
          }
        ]}
      />
    ),
    []
  );

  return (
    <Wrapper className="vms" data-test="vms">
      {table}
    </Wrapper>
  );
}
