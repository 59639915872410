import {
  Infolabel,
  DeviceInfoTable,
  DeviceInfoTableRow,
  DeviceInfoTableHeaderCell,
  DeviceInfoTableRowCell,
  Warning
} from '../styles';
import { SignDetail } from '../../types';
import { Tooltip } from '@mui/material';

export default function ShowCompanyInfo({ sign }: { sign: SignDetail | undefined }) {
  return (
      <>
        <Infolabel>Company / location info</Infolabel>
        <DeviceInfoTable data-test={'companyInfo'}>
          <tbody>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>Company</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.owner}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Company Timezone
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.companyTz}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Company speed unit
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.companySpeedUnitsParam}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Invalidation Interval, seconds
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.companyReadingsIntervalParam}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Info for the last
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.companyFastStatParam}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
          </tbody>
        </DeviceInfoTable>

        <Infolabel sx={{ marginTop: '15px' }}>Sign info</Infolabel>
        <DeviceInfoTable data-test={'deviceInfo'}>
          <tbody>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Last communication
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell color={sign?.lastCommunicationStatus}>
                {`${sign?.sign.last}  `}
                {!!sign?.radarHasFutureDates &&
                  <Tooltip title="Future dates detected">
                    <Warning/>
                  </Tooltip>
                }
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>
                Location
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.location}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Last radar data
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell color={sign?.lastCommunicationStatus}>
                {`${sign?.sign.lastRadarTime}  `}
                {!!sign?.radarHasFutureDates &&
                  <Tooltip title="Future dates detected">
                    <Warning/>
                  </Tooltip>
                }
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>
                Sign Timezone
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.timeZone}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Speed Limit
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.speedLimit}
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>Serial</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.serial}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Cars Counter
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.counter}
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>Model</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.model}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Power Supply
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.battery}
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>
                Sign speed unit
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.speedUnit}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Firmware Version
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.firmwareVer}
              </DeviceInfoTableRowCell>
              <DeviceInfoTableHeaderCell>
                Board Version
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {sign?.sign.boardVer}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
          </tbody>
        </DeviceInfoTable>
      </>
  );
}
