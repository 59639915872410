import {useCallback, useMemo} from 'react';
import DataGrid, {Column, IColumnProps, Editing, RequiredRule, PatternRule} from 'devextreme-react/data-grid';
import { ParkingInfo } from '../../../types';
import _ from 'lodash';

const SyncGrid = ({
  columns,
  receivedStore,
  updateStore
}:
  {
    columns: IColumnProps[],
    receivedStore: ParkingInfo[] | null | undefined,
    updateStore: any
  }) => {
  const columnComponent = useCallback((column: IColumnProps, index: number) => {
    const columnProps = { ...column };

    return (
        <Column
          key={index}
          { ...columnProps }
        >
          {columnProps.allowEditing && <RequiredRule />}
          {columnProps.allowEditing && <PatternRule
            message={'Only numbers are allowed'}
            type={'numeric'}
            pattern={/\d+/g}
          />}
        </Column>
    )
  }, []);

  return useMemo(
    () => {
      const updateRow = ({ data }: {data: ParkingInfo}) => {
        updateStore('parkingChosenList',
          _.map(receivedStore, item => {
            if (item.uniKey === data.uniKey) {
              item = {...data}
            }
            return item;
          })
        );
      };
      const deleteRow = ({ data }: {data: ParkingInfo}) => {
        updateStore('parkingChosenList',
          _.filter(receivedStore, item => item.uniKey !== data.uniKey)
        );
      };
      return (
        <DataGrid
          dataSource={receivedStore}
          keyExpr="uniKey"
          showBorders={true}
          columnAutoWidth={true}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          onRowUpdated={updateRow}
          onRowRemoved={deleteRow}
        >
          {columns.map(columnComponent)}
          <Editing
            mode="row"
            allowDeleting={true}
            allowUpdating={true}
          />
        </DataGrid>
        )
    },
    [
        columns,
        receivedStore,
        columnComponent,
        updateStore
    ]
);
};

export default SyncGrid;
