import { Box, InputLabel, TextField, styled } from '@mui/material';

export const Label = styled(InputLabel)`
    color: #357bd8;
    font-size: 12px;
    font-weight: 700;
`

export const ServiceText = styled(Box)`
    padding: 4px 16px;
    font-size: 16px;
    user-select: none;
`

export const LoadingStatusText = styled(ServiceText)`
    color: #999;
    text-transform: capitalize;
`

export const SearchInput = styled(TextField)`
    width: 98%;
    padding: 5px;
`