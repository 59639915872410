import { DEVICE_COMMANDS } from "../../../../../constants";
import ByteParser from "../../../../../services/ByteParser";
import { Buffer } from 'buffer';

export const commandParser = {
  [DEVICE_COMMANDS.GSM_APN_DOMAIN]: ByteParser.str2hex,
  [DEVICE_COMMANDS.SCHEDULE]: ByteParser.dec2hex,
  [DEVICE_COMMANDS.CONNECTION_PERIOD]: ByteParser.dec2hex,
  [DEVICE_COMMANDS.CUSTOM]: (value: string) => value
};

export const commandRules = {
  [DEVICE_COMMANDS.CUSTOM]: {
    rules: {
      label: 'Write the full command here (e.g. !050302AFF3)',
      required: {
        value: true,
        message: 'Required',
      },
      maxLength: {
        value: 128,
        message: 'Incorrect value',
      },
    },
    type: 'text'
  },
  [DEVICE_COMMANDS.SERIAL]: {
    rules: { required: false },
    type: 'text'
  },
  [DEVICE_COMMANDS.SCHEDULE]: {
    type: 'number',
    rules: {
      label: 'Write the schedule number from 0 to 15',
      required: {
        value: true,
        message: 'Required',
      },
      min: {
        value: 0,
        message: 'Incorrect value',
      },
      max: {
        value: 15,
        message: 'Incorrect value',
      },
    },
  },
  [DEVICE_COMMANDS.LEDS_BRIGHTNESS_FREQUENCY]: {
    rules: { required: false }
  },
  [DEVICE_COMMANDS.SYNC_TIME]: {
    rules: { required: false }
  },
  [DEVICE_COMMANDS.GSM_APN_DOMAIN]: {
    rules: {
      label: 'Write APN Domain here',
      required: {
        value: true,
        message: 'Required',
      },
      maxLength: {
        value: 40,
        message: 'Incorrect value',
      },
    },
  },
  [DEVICE_COMMANDS.CONNECTION_PERIOD]: {
    type: 'number',
    rules: {
      label: 'Write the connection period from 1 to 255 minutes as an integer',
      required: {
        value: true,
        message: 'Required',
      },
      maxLength: {
        value: 3,
        message: 'Incorrect value',
      },
      min: {
        value: 1,
        message: 'Incorrect value',
      },
      max: {
        value: 255,
        message: 'Incorrect value',
      },
    },
  },
  [DEVICE_COMMANDS.REBOOT]: {
    rules: { required: false }
  },
  [DEVICE_COMMANDS.SERIAL_EXPANDED]: {
    rules: { required: false }
  }
}

const nullTerminatedStringCommands = [DEVICE_COMMANDS.GSM_APN_DOMAIN];

export default class CommandHelpers {
  static convertValue(cmd: number, value: never) {
    try {
      if (value) {
        if (commandParser[cmd]) {
          return commandParser[cmd](value);
        }

        return value;
      }

      return '';
    } catch (error) {
      console.error(error);
    }
  }

  static getValueLength(value: string) {
    try {
      console.log('valuevalue', value);
      if (value) {
        const buffer = Buffer.from(value, 'hex');

        return buffer.byteLength.toString(16).padStart(2, '0');
      }

      return '00';
    } catch (error) {
      console.error(error);
    }
  }

  static getCommand(cmd: number, value: string) {
    try {
      switch (+cmd) {
        case +DEVICE_COMMANDS.CUSTOM:
          return value;
        case +DEVICE_COMMANDS.REBOOT:
          return DEVICE_COMMANDS.REBOOT;
        default:
          break;
      }

      let cmdValue = CommandHelpers.convertValue(+cmd, value as never);

      if (nullTerminatedStringCommands.includes(+cmd)) {
        cmdValue += '00';
      }

      const length = CommandHelpers.getValueLength(cmdValue as string);

      return `!${(+cmd).toString(16).padStart(2, '0')}${length}${cmdValue}`;
    } catch (error) {
      console.error(error);
    }
  }
}