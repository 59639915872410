import { DataGrid } from 'devextreme-react';
import { FiltersType } from '.';
import { MixedInput } from './types';

export enum ActionType {
  SetFilterInputs = 'SET_FILTERINPUTS',
  ShowFilters = 'SHOW_FILTERS',
  SetActiveFilters = 'SET_ACTIVE_FILTERS',
  ResetFilters = 'RESET_FILTERS',
  DestroyFilters = 'DESTROY_FILTERS',
  SetDataGridRef = "SET_DATAGRID_REF"
}

export interface SetFilterInputs {
  type: ActionType.SetFilterInputs;
  payload: Array<MixedInput | Array<MixedInput>>;
}

export interface SetDataGridRef {
  type: ActionType.SetDataGridRef;
  payload: { current: DataGrid };
}

export interface ShowFilters {
  type: ActionType.ShowFilters;
}

export interface SetActiveFilters {
  type: ActionType.SetActiveFilters;
  payload: { filters: FiltersType };
}

export interface ResetFilters {
  type: ActionType.ResetFilters;
  payload: FiltersType;
}

export interface DestroyFilters {
  type: ActionType.DestroyFilters;
}

export type FiltersActions =
  | SetDataGridRef
  | SetFilterInputs
  | ShowFilters
  | SetActiveFilters
  | ResetFilters
  | DestroyFilters;
