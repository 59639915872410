import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useFiltersContext } from '../generic/filter/context';
import { ToolbarButtonWrapper } from './styles';
import _ from 'lodash';
import { DownloadCsvType, ExportToCSVProps } from './types';
import { useApi } from '../../context/api';
import qs from 'qs';
import { toast } from 'react-toastify';

const ExportToCSV = ({
  url,
}: ExportToCSVProps) => {
  const { state: { activeFilters } } = useFiltersContext();
  const { apiInstance } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  
  const downloadFile = (data: DownloadCsvType) => {
    const url = window.URL.createObjectURL(new Blob([data.fileData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', data.fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  
  const handleExportCSV = async () => {
    try {
      setLoading(true);
      const params = _.size(activeFilters) ? `?${qs.stringify(activeFilters)}` : '';
      
      const response: DownloadCsvType = await apiInstance.get(
        `${url}${params}`,
      );
      
      downloadFile(response)
    } catch (error) {
      console.log(error);
      toast.error(_.get(error, 'error.message', 'Error while generating'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ToolbarButtonWrapper>
      <Button
        disabled={loading}
        variant="contained"
        onClick={handleExportCSV}
        data-test={'exportCSV'}
        sx={{
          textTransform: 'none',
          background: '#4a89dc'
        }}
        size={'small'}
      >
        {loading ? 'Generating...' : 'Export to CSV'}
      </Button>
    </ToolbarButtonWrapper>
  );
};

export default ExportToCSV;
