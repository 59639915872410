import {
  DictionaryType,
  PaletteType,
  ResponseChartDataType,
} from '../../../components/pieChartOverview'

export const chartData: ResponseChartDataType = {
  "1": {
    "moduleId": 1,
    "activeAssigned": 55,
    "activeAssignedOnline":55,
    "activeNotAssigned": 103,
    "activeNotAssignedOnline":100,
    "activeNotAssignedNotCommunicated":3,
    "expired": 3,
    "expiredAssigned": 3,
  },
  "4": {
    "moduleId": 4,
    "activeAssigned": 19,
    "activeAssignedOnline":19,
    "activeNotAssigned": 25,
    "activeNotAssignedOnline":25,
  },
  "5": {
    "moduleId": 5,
    "activeAssigned": 10,
    "activeAssignedOnline": 10,
    "activeNotAssigned": 8,
    "activeNotAssignedOnline":8,
    "expired": 2,
    "expiredAssigned": 2,
  },
  "6": {
    "moduleId": 6,
    "activeAssigned": 1000,
    "activeAssignedOnline":900,
    "activeAssignedNotCommunicated":100,
    "activeNotAssigned": 200,
    "activeNotAssignedOnline":150,
    "activeNotAssignedNotCommunicated":50,
    "expired": 80,
    "expiredAssigned": 20,
    "expiredNotAssigned": 60,
  }
};

export const dictionary: DictionaryType = {
  activeAssigned: 'Active devices assigned to location',
  activeNotAssigned: 'Active devices not assigned to location',
  expired: 'Devices with expired subscription'
};

export const palette: PaletteType = {
  activeAssigned: {
    totalColor: '#00AF52',
    onlineColor: '#93D250',
    offlineColor: '#C4DFB4'
  },
  activeNotAssigned: {
    totalColor: '#FFC000',
    onlineColor: '#FFDA67',
    offlineColor: '#FFE69B'
  },
  expired: {
    totalColor: '#7F7F7F',
    onlineColor: '#B0AAAA',
    offlineColor: '#D0CECF'
  }
};
