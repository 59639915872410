import { FooterWrapper } from './styles';

export default function Footer() {
  return (
    <FooterWrapper>
      <p>
        <span>&copy;</span>
        {new Date().getFullYear()} by BDLogix Inc. All rights reserved.
      </p>
    </FooterWrapper>
  );
}
