import { Store } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import _, {size} from 'lodash';
import { useApi } from '../../../context/api';

interface DataGridContextI {
  dataSource: DataSource | null;
  setDataSource: (dataSource: DataSource) => void;
  insert: (values: unknown, callback?: () => void) => void;
  update: (key: number | string, values: unknown, callback?: () => void) => void;
  move: (url: string, values: unknown, callback?: () => void) => void;
  remove: (values: unknown, callback?: () => void) => void;
  errors: any;
  setErrors: (errors: any) => void;
  eraseError: (field: string) => void;
};

export const initialState = {
  dataSource: null,
  setDataSource: (dataSource: DataSource) => dataSource
};

export const DataGridContext = createContext<DataGridContextI | undefined>(undefined);

function DataGridContextProvider({ children }: { children: React.ReactNode }) {
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const [errors, setErrors] = useState<any>({});
  const { apiInstance, setLoading } = useApi();
  const insert = (values: unknown, callback?: () => void) => {
    setLoading(true);
    dataSource?.store().insert(values).then(() => {
      dataSource.reload();
      setLoading(false);
      setErrors({});
      if (callback) {
        callback();
      }
    }).catch((e) => {
      setLoading(false);
      setErrors(
        JSON.parse(e.message).error?.validationErrors || {}
      )
    })
  };

  const update = (key: number | string, values: unknown, callback?: () => void) => {
    setLoading(true);
    return dataSource?.store().update(key, values).then(() => {
      dataSource.reload();
      setLoading(false);
      setErrors({});
      if (callback) {
        callback();
      }
    }).catch((e) => {
      setLoading(false);
      setErrors(JSON.parse(e.message).error?.validationErrors || {})
    })
  };

  const move = async (url: string, values: unknown, callback?: () => void) => {
    try {
      setLoading(true);
      const response: any = await apiInstance.post(url, values);

      dataSource?.store().push([{
        type: 'insert',
        data: response
      }]);

      setErrors({});
      dataSource?.reload();
      setLoading(false);

      toast.success(_.get(response, 'msg', 'Saved successfully'));

      if (callback) {
        callback();
      }

      return response;
    } catch (error: any) {
      setLoading(false);
      toast.error(
        _.get(
          error, 'data.error.message', 'Error while saving. Please check the parameters'
        ));
      setErrors(JSON.parse(error.message).error?.validationErrors || {})

      throw error;
    }
  };

  const remove = (values: unknown, callback?: () => void) => {
    setLoading(true);
    dataSource?.store().remove(values).then(() => {
      dataSource.reload();
      if (callback) {
        callback();
      }
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
    })
  }

  const eraseError = (field: string): void => {
    if (size(errors[field]) > 0) {
      setErrors({...errors, [field]: ''});
    }
  };

  return (
    <DataGridContext.Provider
      value={{
        dataSource,
        setDataSource,
        insert,
        update,
        remove,
        move,
        errors,
        setErrors,
        eraseError
      }}>
      {children}
    </DataGridContext.Provider>
  );
}

function useDataGrid() {
  const context = React.useContext(DataGridContext);
  if (context === undefined) {
    throw new Error(
      'useDataGrid must be used within a DataGridContextProvider'
    );
  }
  return context;
}

export { DataGridContextProvider, useDataGrid };
