export enum ReportType {
  LoginActivity = 28,
  LossCommunication = 29,
  UserActivity = 30,
  GeneralActivity = 31,
}

export type ReportTypesResponse = {
  status: string;
  reportsList: Array<{
    name: string;
    type: number;
  }>;
}

export type CompaniesResponse = {
  status: string;
  accountList: Array<{
    name: string;
    id: number;
  }>;
}

type ReportBaseType = {
  headerData: {
    accountId: number;
    accountName: string;
    reportName: string;
    reportPeriod: string;
  };
  reportData: Record<string, string | number | boolean | string[]>[];
  pageData?: {
    pages: number;
    currentPage: number;
    total: number;
    itemsPerPage: number;
  }
}

export type ReportDataType = ReportBaseType & {
  requestData: {
    type: number;
    accountId: number;
    endDate: string;
    startDate: string;
  };
}

export type DateResponse = {
  status: string;
  type: number;
  dateRange: Array<string>;
  dataAvailable: number;
  minDate: string,
  maxDate: string
}

export type CompaniesQuery = {
  isLoading: boolean;
  data?: Array<{
    value: string | number;
    title: string;
  }>
}

export type ReportResponseType = ReportBaseType & {
  status: string;
}

export type DownloadCsvType = {
  fileData: string,
  fileName: string
}
