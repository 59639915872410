import _ from 'lodash';
import { IColumnProps } from 'devextreme-react/data-grid';

export const compareObjects = (obj1: { [k: string]: any; }, obj2: { [k: string]: any; }) => {
  const changedKeys = [];

  for (const key in obj1) {
    if (_.has(obj1, key) && _.has(obj2, key)) {
      if (obj1[key] !== obj2[key]) {
        changedKeys.push(key);
      }
    }
  }

  return changedKeys;
};

export const specifiedStore = (data: any, deprecatedList?: string []) => {
  if (deprecatedList && deprecatedList.length) {
    deprecatedList.forEach(item => {
      if(_.isObject(data.new) && _.has(data.new, item)) {
        delete data.new[item];
      }
      if(_.isObject(data.old) && _.has(data.old, item)) {
        delete data.old[item];
      }
    })
  }
  const featuredCompany = {...data};

  const changedKeys = compareObjects(featuredCompany.new, featuredCompany.old);
  featuredCompany.diff = [
    {...featuredCompany.old, changedKeys, age: true, isNew: !!data.new, isOld: !!data.old},
    {...featuredCompany.new, changedKeys, isNew: !!data.new, isOld: !!data.old}
  ];

  return featuredCompany;
};

export const FULLDATE_FORMAT = 'YYYY-MM-DD';

export const getKeysList = (columnsList: IColumnProps []) => columnsList.map((item) => item.dataField || "")