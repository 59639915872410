import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Typography } from '@mui/material';
import _ from 'lodash';

import Table from '../../../../../components/Table';
import GenericModal from '../../../../../components/generic/modal';
import DcModalContent from './dc-modal-content';
import DcMenu from './DcMenu';
import DcMenuGeneral from './DcMenuGeneral';
import { useDataGrid } from '../../../../../components/generic/dataGrid';

import {
  DcCompaniesResponse,
  DcModelEdit,
  DcModelsResponse,
  SelectData,
  DcLocationsResponse,
  DcSubscriptionResponse,
  DCModalType,
} from './types';
import dayjs from 'dayjs';
import { STARTDATE_FORMAT } from '../../../../../constants';
import { MODULES } from '../../../../../constants/Modules';
import { useApi } from '../../../../../context/api';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';

const CompanyDc = () => {
  const { id } = useParams();
  const defaultDcValues = {
    normal_voltage: '',
    serial: '',
    model_id: 0,
    access_key: '',
    sim: '',
    device_serial: '',
  };
  const { apiInstance } = useApi();
  const [dc, setDc] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<DCModalType | ''>('');
  const [dcModelList, setDcModelList] = useState<SelectData[]>([]);
  const [dcValues, setDcValues] = useState<any>(defaultDcValues);
  const { setErrors } = useDataGrid();
  const selectedRowsRef = useRef<string[]>([]);
  const { hasRoleAccess } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const responseDcModels: DcModelsResponse = await apiInstance.get(`/companies/dc/list/filters/dc-models/`);
      if (_.size(responseDcModels.dc_models) > 0) {
        setDcModelList(
          responseDcModels.dc_models.map((dcModel) => ({
            value: dcModel.id,
            title: dcModel.name,
          }))
        );
      }
    }

    fetchData();
  }, [apiInstance]);

  const handleModalOpen = useCallback(
    (type: DCModalType, dc: any) => {
      async function fetchDcData(dc: { id: number }) {
        if (_.isNumber(dc.id)) {
          const response: DcModelEdit = await apiInstance.get(`/companies/dc/get/${id}/${dc.id}`);
          setDcValues({
            normal_voltage: response.device_info.normal_voltage,
            serial: response.device_info.serial,
            model_id: response.device_info.model_id,
            access_key: response.device_info.access_key,
            device_serial: response.device_info.device_serial,
            sim: response.device_info.sim,
          });
          setDc(dc);
        }
      }

      async function fetchCompaniesData(dc: any) {
        const response: DcCompaniesResponse = await apiInstance.get(`/companies/dc/move/${id}/${dc.id}`);
        setDcValues({ newCompanyId: response.companies_list[0].id, newCompanyName: response.companies_list[0].name, });
        setDc(dc);
      }

      async function fetchCompaniesMoveData(type: string) {
        const response: DcCompaniesResponse = await apiInstance.get(`/companies/dc/move/bulk/${id}`);
        setDcValues({
          newCompanyId: response.companies_list[0].id,
          newCompanyName: response.companies_list[0].name,
          selectAllCompanyDevices: +(type === 'moveAll'),
          deviceIds: selectedRowsRef.current,
        });
      }

      async function fetchSubscriptionData(dc: any) {
        const response: DcSubscriptionResponse = await apiInstance.get(
          `/companies/subscription/${MODULES.MODULE_DC}/${id}/${dc.id}`
        );
        setDc(response.device_info);
        setDcValues({
          start_subscription: dayjs().format(STARTDATE_FORMAT),
          end_subscription: dayjs().format(STARTDATE_FORMAT),
        });
      }

      function openModal() {
        setIsModalOpen(true);
      }

      setModalType(type);
      switch (type) {
        case 'edit':
        case 'create':
          fetchDcData(dc).then(() => openModal());
          break;
        case 'moveSelected':
        case 'moveAll':
          fetchCompaniesMoveData(type).then(() => openModal());
          break;
        case 'move':
          fetchCompaniesData(dc).then(() => openModal());
          break;
        case 'delete':
        case 'subscribe':
          fetchSubscriptionData(dc).then(() => openModal());
          break;
        default:
          setDc(dc);
          openModal();
          break;
      }
    },
    [id, setDcValues, apiInstance, selectedRowsRef]
  );

  const handleModalClose = () => {
    setErrors({});
    setDcValues(defaultDcValues);
    setIsModalOpen(false);
    setDc('');
  };

  const saveSelections = useCallback(
    (_event: React.MouseEvent<HTMLElement>, id: string) => {
      const selectedRows = selectedRowsRef.current;
      const currentIndex = _.indexOf(selectedRows, id);

      if (currentIndex > -1) {
        selectedRowsRef.current.splice(currentIndex, 1);
      } else {
        selectedRowsRef.current.push(id);
      }
    },
    [selectedRowsRef]
  );

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        title="Company DC "
        titleKey="company_name"
        toolbar={
          hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN],[USERROLES.ROLE_STAGING]]) && (
            <DcMenuGeneral
              dc={''}
              handleModalOpen={handleModalOpen}
              emptySelection={selectedRowsRef}
            />
          )
        }
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
            {
              type: 'text',
              name: 'serial',
              label: 'Serial Number',
            },
            {
              type: 'text',
              name: 'firmware_ver',
              label: 'Firmware Version',
            },
            [
              {
                type: 'select',
                name: 'location_id',
                label: 'Locations',
                fetch: {
                  url: `/companies/dc/list/filters/locations/${id}`,
                  formatter: (data: DcLocationsResponse) =>
                    data.locations.map((location) => ({
                      value: location.id,
                      title: location.name,
                    }))
                },
              },
            ],
            [
              {
                type: 'select',
                name: 'device_model',
                label: 'Models',
                fetch: {
                  url: '/companies/dc/list/filters/dc-models/',
                  formatter: (data: DcModelsResponse) =>
                    data.dc_models.map((model) => ({
                      value: model.id,
                      title: model.name,
                    }))
                },
              },
            ],
          ],
        }}
        options={{
          moduleName: 'companies/dc/list',
          apiUrl: `/companies/dc/list/${id}`,
          dataSourceKey: 'company_dc',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/companies/dc/add/${id}` },
          update: { method: 'post', url: `/companies/dc/edit/${id}` },
          remove: { method: 'post', url: `/companies/dc/delete/${id}` },
        }}
        columns={[
          {
            caption: '',
            cellRender: (data: { data: any }) => <Checkbox onClick={(e) => saveSelections(e, data.data.id)} />,
            width: '55px',
            type: 'buttons',
          },
          {
            dataField: 'deviceSerial',
            caption: 'Serial',
            width: '12%',
          },
          {
            dataField: 'firmware_ver',
            caption: 'Firmware version',
            width: '12%',
          },
          {
            dataField: 'device_model',
            caption: 'Device model',
            width: '15%',
          },
          {
            dataField: 'location_name',
            caption: 'Location name',
            width: '17%',
          },
          {
            dataField: 'group_name',
            caption: 'Group',
            width: '12%',
          },
          {
            dataField: 'serial',
            caption: 'CC Serial Number',
            width: '12%',
          },
          {
            dataField: 'sim',
            caption: 'SIM',
            width: '17%',
          },
          {
            dataField: 'normal_voltage',
            caption: 'Normal battery voltage',
            width: '120px',
            alignment: 'center',
          },
          {
            ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && {
              ...{
                caption: '',
                cellRender: (data: { data: any }) => <DcMenu dc={data.data} handleModalOpen={handleModalOpen} />,
                width: '70px',
                alignment: 'right',
              },
            }),
          },
        ]}
      />
    );
  }, [id, handleModalOpen, saveSelections, hasRoleAccess]);

  return (
    <>
      {table}

      <GenericModal show={isModalOpen} onClose={() => handleModalClose()}>
        <DcModalContent
          type={modalType as DCModalType}
          onClose={() => handleModalClose()}
          dc={dc}
          dcModelList={dcModelList}
          values={dcValues}
          companyId={id as string}
          id={id as string}
        />
      </GenericModal>
    </>
  );
};

export default CompanyDc;
