import { AuthDataType } from './types';

export enum ActionType {
  SetAuthData = 'SET_AUTH_DATA',
}

export interface SetAuthData {
  type: ActionType.SetAuthData;
  payload: AuthDataType;
}

export type AuthContextActions = SetAuthData;
