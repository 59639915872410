import React from 'react';
import { Checkbox } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import {
  InfoTitle,
  SignSystemInfo,
  SystemInfoWrapper,
  Value,
  Label,
  OptionItem
} from '../styles';

export type SystemInfoDataType = {
  serialNumber: number;
  signModel: string | number;
  firmware: string | number;
  boardVersion: string | number;
  boardFuses: {
    statistic: boolean;
    relay: boolean;
    slMsg: boolean;
    beacon: boolean;
  },
  fwFuses: {
    extRadar: boolean;
    dccStatistic: boolean;
    gprs: boolean;
    listen: boolean;
    push: boolean;
    mode3g: boolean;
  }
}


const Option = (
  { checked, label } : {checked: boolean, label: string}
) => {
  return (
    <OptionItem>
      <Checkbox
        checked={checked}
        disableRipple
        size="small"
      />
      <Label>{label}</Label>
    </OptionItem>
  )
}
const SystemInfo = ({ data } : { data: SystemInfoDataType }) => {

  return (
    <>
      <SystemInfoWrapper data-test={'system-info'}>
        <SignSystemInfo>
          <InfoTitle>Serial & Model:</InfoTitle>
          <List>
            <ListItem>
              Serial: <Value>{data?.serialNumber}</Value>
            </ListItem>
            <ListItem>
              Model: <Value>{data?.signModel}</Value>
            </ListItem>
          </List>
        </SignSystemInfo>
        <SignSystemInfo>
          <InfoTitle>Firmware & Board:</InfoTitle>
          <List>
            <ListItem>
              Firmware: <Value>{data?.firmware}</Value>
            </ListItem>
            <ListItem>
              Model: <Value>{data?.boardVersion}</Value>
            </ListItem>
          </List>
        </SignSystemInfo>
        <SignSystemInfo>
          <InfoTitle>Options</InfoTitle>
          <List>
            <Option
              checked={data?.boardFuses?.statistic}
              label={'Allow Traffic Statistics (board fuse 0x10)'}
            />
            <Option
              checked={data?.boardFuses?.relay}
              label={'Allow External Relay Control (board fuse 0x10)'}
            />
            <Option
              checked={data?.boardFuses?.slMsg}
              label={'SL with Custom Messages (board fuse 0x40)'}
            />
            <Option
              checked={data?.boardFuses?.beacon}
              label={'Allow Beacons Control (board fuse 0x80)'}
            />
            <Option
              checked={data?.fwFuses?.extRadar}
              label={'Use TL Radar Protocol (firmware fuse 0x10)'}
            />
          </List>
        </SignSystemInfo>
        <SignSystemInfo>
          <InfoTitle>GSM Options</InfoTitle>
          <List>
            <Option
              checked={data?.fwFuses?.dccStatistic}
              label={'Allow WD Statistics (firmware fuse 0x01)'}
            />
            <Option
              checked={data?.fwFuses?.gprs}
              label={'Handle GSM Modem (firmware fuse 0x02)'}
            />
            <Option
              checked={data?.fwFuses?.listen}
              label={'Connection Listen Mode (firmware fuse 0x04)'}
            />
            <Option
              checked={data?.fwFuses?.push}
              label={'WD Connection Push Mode (firmware fuse 0x08)'}
            />
            <Option
              checked={data?.fwFuses?.mode3g}
              label={'Use 3G Modem (firmware fuse 0x20)'}
            />
          </List>
        </SignSystemInfo>
      </SystemInfoWrapper>
    </>
  )
}

export default SystemInfo;