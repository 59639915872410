import React from 'react';
import { InfoTitle, LedBrightnessInfoWrapper } from '../styles';
import {
  DeviceTable,
  DeviceTableRow,
  DeviceTableRowLeftCell,
  DeviceTableRowRightCell
} from '../../ShowInfo/styles';

export type LedBrightnessInfoDataType = {
  brightness: {
    min: number,
    max: number,
  },
  ledDigBlink: string,
  ledMsgBlink: number,
  ledBeaconBlink: number,
  ledStrobeBlink: {
    speed: string,
    series: string
  }
}

const LedBrightnessInfo = ({ data } : { data: LedBrightnessInfoDataType }) => {

  return (
    <LedBrightnessInfoWrapper data-test={'led-brightness-info'}>
      <InfoTitle>LEDs brightness and frequency settings:</InfoTitle>
      <DeviceTable>
        <tbody>
          <DeviceTableRow>
            <DeviceTableRowLeftCell>
              Min-Max Brightness:
            </DeviceTableRowLeftCell>
            <DeviceTableRowRightCell>
              From {data?.brightness.min}% To {data?.brightness.max}%
            </DeviceTableRowRightCell>
          </DeviceTableRow>
          <DeviceTableRow>
            <DeviceTableRowLeftCell>
              Digits Blinking Frequency:
            </DeviceTableRowLeftCell>
            <DeviceTableRowRightCell>
              {data?.ledDigBlink}
            </DeviceTableRowRightCell>
          </DeviceTableRow>
          <DeviceTableRow>
            <DeviceTableRowLeftCell>
              Message Blinking Frequency:
            </DeviceTableRowLeftCell>
            <DeviceTableRowRightCell>
              {data?.ledMsgBlink}
            </DeviceTableRowRightCell>
          </DeviceTableRow>
          <DeviceTableRow>
            <DeviceTableRowLeftCell>
              School Zone Beacon Blinking Frequency:
            </DeviceTableRowLeftCell>
            <DeviceTableRowRightCell>
              {data?.ledBeaconBlink}
            </DeviceTableRowRightCell>
          </DeviceTableRow>
          <DeviceTableRow>
            <DeviceTableRowLeftCell>
              Strobe Light Blinking Frequency:
            </DeviceTableRowLeftCell>
            <DeviceTableRowRightCell>
              Speed: {data?.ledStrobeBlink.speed}, Series: {data?.ledStrobeBlink.series}
            </DeviceTableRowRightCell>
          </DeviceTableRow>
        </tbody>
      </DeviceTable>
    </LedBrightnessInfoWrapper>
  )
}

export default LedBrightnessInfo;