import * as React from 'react';
import CardElement from './Card';
import { FindWrapper } from './styles';
export default function FindUnassignedDevices() {

  return (
    <FindWrapper>
      <CardElement title='Signs' img='signs' href='/sign-lost'/>
      {/*<CardElement title='Lost Events' img='camera2'href='/sign/lost-events'/>*/}
    </FindWrapper>
  );
}
