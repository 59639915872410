import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from '@mui/material';

import Table from '../../../../components/Table';
import ModuleParking from '../../../../assets/img/module_parking.png';
import ModuleSign from '../../../../assets/img/module_signs.png';
import ModuleTCam from '../../../../assets/img/module_tcam.png';
import ModuleVms from '../../../../assets/img/module_vms.png';
import ModuleDC from '../../../../assets/img/module_dtc.png';
import { ModuleButtonStyled, DeviceCount } from './styles';
import { useAuth } from '../../../../context/auth';
import { CompanyAccount } from './types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Settings from '@mui/icons-material/Settings';
import AddCompany from './add-company';
import { Link, useNavigate, useLocation, Location } from 'react-router-dom';
import GenericModal from '../../../../components/generic/modal';
import { CompanyModalContent } from '../modal/CompanyModalContent';
import { useCompanyModal } from '../modal/useCompanyModal';
import { CompanyModalType } from '../modal/types';
import { loginToWD3 } from '../../login-to-wd/loginToWD3';
import { useApi } from '../../../../context/api';
import { USERROLES } from '../../../../constants/UserRoles';
import { useFiltersContext } from '../../../../components/generic/filter/context';
import { ActionType } from '../../../../components/generic/filter/context/actions';

export default function Companies() {
  const { hasRoleAccess, hasModuleAcces } = useAuth();
  const [triggerReloadData, setTriggerReloadData] = useState(false);
  const [companyId, setCompanyId] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, type, setModalState } = useCompanyModal();
  const { apiInstance } = useApi();
  const locationSearch = useRef<string>();
  const locationRef = useRef<Location>();
  const { dispatch, setFilters } = useFiltersContext();

  useEffect(() => {
    if (location.search) {
      locationSearch.current = location.search;
      locationRef.current = location;
    }
    if (locationRef.current?.pathname === location.pathname
      && locationRef.current?.search === location.search
      && locationRef.current?.state?.backButton
    ) {
      filterClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    locationSearch.current = location.search;
    if (locationRef.current?.pathname === location.pathname
        && locationRef.current?.search !== location.search
        && locationRef.current?.state?.backButton
    ) {
      locationRef.current.state.backButton = false;
      navigate(location.pathname + location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    triggerReloadData && setTriggerReloadData(false);
  }, [triggerReloadData]);

  const filterClick = () =>
    dispatch({
      type: ActionType.ShowFilters,
    });

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      setCompanyId(id);
      setAnchorEl(event.currentTarget);
    },
    [setCompanyId]
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCompanyModalClose = (msg?: string) => {
    setModalState(false);
    setCompanyId('');

    if (_.isString(msg)) {
      setSnackbarMsg(msg);
      setTriggerReloadData(true);
    }
  };

  const addNewCompany = () => {
    setIsAddCompanyDialogOpen(true);
    setCompanyId('');
  }

  const search = location.state?.backButton && locationSearch.current ? locationSearch.current : '';

  const table = useMemo(
    () => (
      <Table
        title="Companies"
        toolbar={
          (hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]])) && (
          <Button
            color="secondary"
            onClick={addNewCompany}
          >
            {' '}
            + Add new Company
          </Button>)
        }
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global search',
            },
            {
              type: 'checkbox',
              name: 'show_only_deleted',
              label: 'Show deleted',
            },
          ],
        }}
        options={{
          moduleName: 'companies/list',
          apiUrl: `/companies/list${search}`,
          dataSourceKey: 'account_management_companies',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/companies/add/` },
          update: { method: 'post', url: `/companies/edit` },
          triggerReloadData
        }}
        columns={[
          {
            caption: '',
            visible: hasRoleAccess([
              [USERROLES.ROLE_SUPER_ADMIN]
            ]),
            cellRender: (data: { data: any }) => (
              <Link
                to={`/companies/${data.data.id}/audit`}
                style={{
                  paddingTop: '6px',
                  display: 'block',
                  color: '#0000008a',
                }}
              >
                <AccessTimeIcon fontSize="small" color="inherit" />
              </Link>
            ),
            width: '34px',
          },
          {
            dataField: 'name',
            caption: 'Name',
          },
          {
            dataField: 'address',
            caption: 'Address',
          },
          {
            dataField: 'city',
            caption: 'City',
          },
          {
            dataField: 'state',
            caption: 'State',
          },
          {
            dataField: 'country',
            caption: 'Country',
          },
          {
            dataField: 'zip',
            caption: 'Zip',
          },
          {
            dataField: 'tz',
            caption: 'Time zone',
          },
          {
            dataField: 'number_signs',
            caption: 'Signs',
            alignment: 'left',
            width: '75px',
            visible: hasModuleAcces([1]) &&
              (hasRoleAccess([
                [USERROLES.ROLE_SUPER_ADMIN],
                [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
                [USERROLES.ROLE_STAGING]
              ])),
            cellRender: (data: { data: CompanyAccount }) => (
              <ModuleButtonStyled
                disabled={!data?.data.modules.find((x) => x.name === 'signs')?.enabled}
                onClick={
                  () => navigate(`/companies/signs/list/${data.data.id}`, { state: {previousLocation: location}})
                }
              >
                <img src={ModuleSign} />
                <DeviceCount>{data?.data.modules.find((x) => x.name === 'signs')?.count}</DeviceCount>
              </ModuleButtonStyled>
            ),
          },
          {
            dataField: 'number_parkings',
            caption: 'Parking',
            alignment: 'left',
            width: '75px',
            visible: hasModuleAcces([3]) &&
              (hasRoleAccess([
                [USERROLES.ROLE_SUPER_ADMIN],
                [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
                [USERROLES.ROLE_STAGING]
              ])),
            cellRender: (data: { data: CompanyAccount }) => (
              <ModuleButtonStyled
                disabled={!data?.data.modules.find((x) => x.name === 'parking')?.enabled}
                onClick={
                  () => navigate(`/companies/v-parkings/list/${data.data.id}`, { state: {previousLocation: location}})
                }
              >
                <img src={ModuleParking} />
                <DeviceCount>{data?.data.modules.find((x) => x.name === 'parking')?.count}</DeviceCount>
              </ModuleButtonStyled>
            ),
          },
          {
            dataField: 'number_cameras',
            caption: 'Tcam',
            alignment: 'left',
            width: '75px',
            visible: hasModuleAcces([4]) &&
              (hasRoleAccess([
                [USERROLES.ROLE_SUPER_ADMIN],
                [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
                [USERROLES.ROLE_STAGING]
              ])),
            cellRender: (data: { data: CompanyAccount }) => (
              <ModuleButtonStyled
                disabled={!data?.data.modules.find((x) => x.name === 'tcam')?.enabled}
                onClick={
                  () => navigate(`/companies/tcam/list/${data.data.id}`,{ state: {previousLocation: location}})
                }
              >
                <img src={ModuleTCam} />
                <DeviceCount>{data?.data.modules.find((x) => x.name === 'tcam')?.count}</DeviceCount>
              </ModuleButtonStyled>
            ),
          },
          {
            dataField: 'number_vms',
            caption: 'Vms',
            alignment: 'left',
            width: '75px',
            visible: hasModuleAcces([5]) &&
              (hasRoleAccess([
                [USERROLES.ROLE_SUPER_ADMIN],
                [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
                [USERROLES.ROLE_STAGING]
              ])),
            cellRender: (data: { data: CompanyAccount }) => (
              <ModuleButtonStyled
                disabled={!data?.data.modules.find((module) => module.name === 'vms')?.enabled}
                onClick={
                  () => navigate(`/companies/vms/list/${data.data.id}`, { state: {previousLocation: location}})
                }
              >
                <img src={ModuleVms} />
                <DeviceCount>{data?.data.modules.find((x) => x.name === 'vms')?.count}</DeviceCount>
              </ModuleButtonStyled>
            ),
          },
          {
            dataField: 'number_dc',
            caption: 'DC',
            alignment: 'left',
            width: '75px',
            visible: hasModuleAcces([6]) &&
            (hasRoleAccess([
              [USERROLES.ROLE_SUPER_ADMIN],
              [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
              [USERROLES.ROLE_STAGING]
            ])),
            cellRender: (data: { data: CompanyAccount }) => (
              <ModuleButtonStyled
                disabled={!data?.data.modules.find((module) => module.name === 'dtc')?.enabled}
                onClick={
                  () => navigate(`/companies/dc/list/${data.data.id}`, { state: {previousLocation: location}})
                }
              >
                <img src={ModuleDC} />
                <DeviceCount>{data?.data.modules.find((x) => x.name === 'dtc')?.count}</DeviceCount>
              </ModuleButtonStyled>
            ),
          },
          {
            caption: '',
            visible: hasRoleAccess([
              [USERROLES.ROLE_SUPER_ADMIN],
              [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
              [USERROLES.ROLE_POWER_USER],
              [USERROLES.ROLE_POWER_USER, USERROLES.ROLE_RESTRICTED],
              [USERROLES.ROLE_STAGING]
            ]),
            cellRender: (data: { data: CompanyAccount }) => (
              <Button onClick={(e) => handleMenuOpen(e, data.data.id)}>
                <MoreVertIcon />
              </Button>
            ),
          },
        ]}
      ></Table>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasRoleAccess, hasModuleAcces, handleMenuOpen, navigate, location, search,triggerReloadData]
  );

  const [snackbarMsg, setSnackbarMsg] = useState<string>();
  const [isAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState(false);
  const onDialogClose = useCallback((info?: { showSnackbarMsg: boolean; message: string }) => {
    setIsAddCompanyDialogOpen(false);
    if (info?.showSnackbarMsg) {
      setSnackbarMsg(info.message);
    }
  }, []);

  return (
    <>
      {table}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN],
          [USERROLES.ROLE_STAGING],
        ]) && (
            <MenuItem onClick={() => {
              setIsAddCompanyDialogOpen(true);
              handleMenuClose();
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN],
          [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
          [USERROLES.ROLE_STAGING],
        ]) && (
            <MenuItem
              onClick={() => {
                setModalState(true, CompanyModalType.COMPANY_MODULES);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <ListIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Modules</ListItemText>
            </MenuItem>
          )}
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN],
          [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
          [USERROLES.ROLE_STAGING],
        ]) && (
            <MenuItem
              onClick={() => {
                navigate(`/companies/users/list/${companyId}`);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
                <ListItemText>Users</ListItemText>
            </MenuItem>
          )}
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN],
          [USERROLES.ROLE_POWER_USER],
          [USERROLES.ROLE_STAGING],
        ]) && (
            <MenuItem onClick={() => navigate(`/aws/apikey/list/${companyId}`)}>
              <ListItemIcon>
                <DynamicFormIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Tokens</ListItemText>
            </MenuItem>
          )}
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN],
          [USERROLES.ROLE_POWER_USER],
          [USERROLES.ROLE_STAGING]
        ]) && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Link
                to={`/companies/tcam/settings/${companyId}`}
                style={{
                  color: '#000000DE',
                  textDecoration: 'none',
                }}
              >
                TCAM settings
              </Link>
            </ListItemText>
          </MenuItem>
        )}
        {!hasRoleAccess([
          [USERROLES.ROLE_USER],
          [USERROLES.ROLE_USER, USERROLES.ROLE_RESTRICTED],
        ]) && (
            <MenuItem
              onClick={() => {
                companyId && loginToWD3(apiInstance, companyId);
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <PowerSettingsNewIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Login to WD3</ListItemText>
            </MenuItem>
          )}
        {hasRoleAccess([
          [USERROLES.ROLE_SUPER_ADMIN]
        ]) && (
          <MenuItem
            onClick={() => {
              setModalState(true, CompanyModalType.DELETE_COMPANY);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        )}
      </Menu>
      {isAddCompanyDialogOpen && (
        <AddCompany onClose={onDialogClose} companyId={companyId}></AddCompany>
      )}
      <GenericModal show={isOpen} closeButton onClose={handleCompanyModalClose}>
        <CompanyModalContent
          companyId={companyId as string}
          handleClose={handleCompanyModalClose}
          type={type}
        />
      </GenericModal>
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={6000}
        message={snackbarMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarMsg('')}
      />
    </>
  );
}
