import React, { createContext, Dispatch, useCallback, useEffect, useReducer, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FiltersActions, ActionType } from './actions';
import { reducer } from './reducer';
import { MixedInput } from './types';
import qs from 'qs';
import _ from 'lodash';
import { DataGrid } from 'devextreme-react';

export type FiltersType = {
  [k: string]: string | boolean | number | string[];
};

export type FilterInputsType = Array<MixedInput | Array<MixedInput>>;

export type InitialStateType = {
  isShown?: boolean;
  inputs: FilterInputsType;
  activeFilters?: FiltersType;
  dataGridRef?: { current: DataGrid | null };
};

export const initialState = {
  isShown: false,
  inputs: [],
  activeFilters: {},
  dataGridRef: { current: null }
};

export const FiltersContext = createContext<
  | {
      state: InitialStateType;
      dispatch: Dispatch<FiltersActions>;
      setFilters: (inputs: FilterInputsType) => void;
      setActiveFilters: (filters: FiltersType) => void;
      reset: (defaultValues?: FiltersType) => void;
      destroy: () => void;
    }
  | undefined
>(undefined);

function FiltersContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const prevPathRef = useRef<string>(location.pathname);

  const setActiveFilters = (filters: FiltersType) => {
    setSearchParams(params => {
      const page = params.get('page') || {};
      const sort = params.get('sort') || {};
      const formattedFilters = Object.entries(filters)
        .filter(([_key, value]) => value !== '')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      return qs.stringify({ ...formattedFilters, page, sort });
    });

    state.dataGridRef?.current?.instance?.refresh();
  };

  const reset = (defaultValues: FiltersType = {}) => {
    searchParams.delete('sort');
    state.dataGridRef?.current?.instance.clearSorting();
    setActiveFilters(defaultValues);
    dispatch({
      type: ActionType.ResetFilters,
      payload: defaultValues,
    });
  };

  const destroy = useCallback(() => {
    dispatch({
      type: ActionType.DestroyFilters
    });
  }, []);

  const setFilters = useCallback((inputs: FilterInputsType) => {
    dispatch({
      type: ActionType.SetFilterInputs,
      payload: inputs,
    });
  }, []);

  useEffect(() => {
    const filters: { [key: string]: number | string } = {};

    searchParams.forEach((val, key) => {
      if (key !== 'sort' && key !== 'page') {
        filters[key] = val;
      }
    });

    dispatch({
      type: ActionType.SetActiveFilters,
      payload: {
        filters,
      },
    });
  }, [searchParams]);

  return (
    <FiltersContext.Provider
      value={{
        state,
        dispatch,
        setFilters,
        setActiveFilters,
        reset,
        destroy
      }}>
      {children}
    </FiltersContext.Provider>
  );
}

function useFiltersContext() {
  const context = React.useContext(FiltersContext);
  if (context === undefined) {
    throw new Error(
      'useFiltersContext must be used within a FiltersContextProvider'
    );
  }
  return context;
}

export { FiltersContextProvider, useFiltersContext };
