import { ReportDataType, ReportType, DownloadCsvType } from "../types";
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import './styles.scss'
import SimpleDataGrid from "../../../../components/generic/dataGrid/SimpleDataGrid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  columnsLoginActivity,
  columnsLossCommunicationHistory,
  columnsUserActivity,
  columnsGeneralActivity
} from './columns';
import { useApi } from '../../../../context/api';
import _ from "lodash";
import { toast } from "react-toastify";

const Reports = ({ data, setReportData }: { data: ReportDataType, setReportData: any }) => {
  const { apiInstance } = useApi();
  
  const [columns, setColumns] = useState<{ dataField: string; caption: string; }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    headerData,
    reportData,
    requestData,
    pageData
  } = data;
  
  const downloadFile = (data: DownloadCsvType) => {
    const url = window.URL.createObjectURL(new Blob([data.fileData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', data.fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  
  const handleExportCSV = async () => {
    try {
      setLoading(true);
      const { type, accountId, startDate, endDate } = requestData;
      const param = `type=${type}&accountId=${accountId}&startDate=${startDate}&endDate=${endDate}`;
      
      const response: DownloadCsvType = await apiInstance.get(
        `/report/create/export-csv?${param}`,
      );

      downloadFile(response)
    } catch (error) {
      toast.error(_.get(error, 'error.message', 'Error while Generating'));
    } finally {
      setLoading(false);
    }
  }

  const changePage = async (newPageNumber: number) => {
    const result: any = await apiInstance.post(
      `/report/create`,
      {
        ...requestData,
        page: newPageNumber
      }
    );

    setReportData({
      ...result,
      requestData
    })
  }

  useEffect(() => {
    switch (requestData.type) {
      case ReportType.LoginActivity:
        setColumns(columnsLoginActivity);
        break
      case ReportType.LossCommunication:
        setColumns(columnsLossCommunicationHistory);
        break
      case ReportType.UserActivity:
        setColumns(columnsUserActivity);
        break
      case ReportType.GeneralActivity:
        setColumns(columnsGeneralActivity);
        break
      default:
        break
    }
  }, [requestData.type]);
  
  return (
    <div className={'reportTable'}>
      <div className={'reportTable-header'}>
        <Button
          onClick={() => setReportData(null)}
          data-test={'backTable'}
        >
          <ChevronLeftIcon sx={{ color: 'black' }} />
        </Button>
        <div className={'reportTable-header-content'}>
          <div className={'reportTable-header-content-left'}>
            <Typography variant="h6">{headerData.reportName}</Typography>
            <div>
              Period: {headerData.reportPeriod}
            </div>
          </div>
          <div>
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleExportCSV}
              data-test={'exportCSV'}
              className={'reportTable-exportCSV'}
              sx={{
                textTransform: 'none',
                background: '#4a89dc'
              }}
              size={'small'}
            >
              {loading ? 'Generating...' : 'Export to CSV'}
            </Button>
            <div>Account number: {headerData.accountId}</div>
            <div>Account name: {headerData.accountName}</div>
          </div>
        </div>
      </div>
      <SimpleDataGrid
        data={reportData}
        columns={columns}
        withPaging={_.get(pageData, 'total', 0) > 1}
        page={pageData?.currentPage || 1}
        totalPages={pageData?.pages}
        changePageTo={changePage}
        data-test={'ReportTable'}
      />
    </div>
  );
};

export default Reports;
