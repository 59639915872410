import { useCallback, useRef, useState } from 'react';
import { SignModel } from '../../modules/pages/find-unassigned-devices/signs/types';
import { SignCompaniesResponse } from '../../modules/pages/account-management/companies/signs/types';
import apiInstance from '../../context/api/apiInstance';

const defaultPageInfo = { currentPage: 0, total: 1 };

export function useFetchOptions(loadItemsUrl: string, itemsPerPage: number) {
  const [options, setOptions] = useState<SignModel[]>([]);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleSearchValue = (value: string) => setSearchValue(value);
  const handleClearState = () => {
    setOptions([]);
    setPageInfo(defaultPageInfo);
    setSearchValue('');
  };
  const timeoutRef = useRef<any>(null);
  const fetchOptions = useCallback(
    (searchParam = '', fetchFirstPage = false) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(async () => {
        const nextPage = fetchFirstPage ? 1 : pageInfo.currentPage + 1;
        setLoading(true);
        const name = fetchFirstPage ? searchParam : searchValue;

        try {
          const response: SignCompaniesResponse & {
            current_page: number;
            pages: number;
          } = await apiInstance.get(
            `${loadItemsUrl}?page=${nextPage}&items_per_page=${itemsPerPage}&name=${name}`
          );
          setOptions((prev) =>
            response.pages === 1 || response.current_page === 1
              ? response.companies_list
              : [...prev, ...response.companies_list]
          );
          setPageInfo({
            currentPage: response.current_page,
            total: response.pages,
          });
        } catch (error) {
          console.error('Failed to fetch options:', error);
        } finally {
          setLoading(false);
        }
      }, 500);
    },
    [itemsPerPage, pageInfo.currentPage, searchValue, loadItemsUrl]
  );
  return {
    options,
    loading,
    pageInfo,
    searchValue,
    fetchOptions,
    handleSearchValue,
    handleClearState,
  };
}
