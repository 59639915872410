import React from 'react';
import TableBody from './tableBody';
import TableHeader from './tableHeader';
import type { SchedulesTypes } from './types';

type TableProps = {
  data: SchedulesTypes;
};

const ScheduleInfoTable = ({
 data
}: TableProps): React.ReactElement<'table'> => {

  return (
    <table
      className="tableDefault tableDefaultTable signs-schedule-table"
      data-test='signs-schedule-table'
    >
      <TableHeader data={data} />
      <TableBody data={data} />
    </table>
  );
};

export default ScheduleInfoTable;
