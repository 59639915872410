import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import {Controller, useWatch} from 'react-hook-form';
import ParkingSettingsToEdit from './edit-parking/parking-content';
import GroupCountSettingsToEdit from './edit-parking/groupCount-content';
import _ from 'lodash';
import { SignEditProps } from '../../types';
import { useEffect } from 'react';

export default function ParkingEditModalContent(
    { control, controllersList, setValue, parkingsList, clearErrors}: SignEditProps
) {
    const isGroupCount = useWatch({ control, name: 'isGroupCount' });
  
  useEffect(() => {
    clearErrors();
  }, [controllersList, clearErrors]);

    return (
      <>
        <Controller
          name="isHide"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ height: '20px' }}
                  checked={Boolean(+field.value)}
                  onChange={(e) => field.onChange(_.toString(+e.target.checked))}
                />
              }
              label='Hide'
              key=''
              sx={{ height: '20px', marginBottom: 7, width: '45%' }}
            />
          )}
        />
        <Controller
          name="isGroupCount"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ height: '20px' }}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              }
              label='Group Count'
              key=''
              sx={{ height: '20px', marginBottom: 7, width: '50%' }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: {invalid, error}}) => (
            <TextField
              sx={{ marginBottom: 7, width: '41%', marginRight: 3 }}
              label='Name *'
              fullWidth
              variant="standard"
              error={invalid}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="levels"
          control={control}
          render={({ field, fieldState: {invalid, error} }) => (
            <TextField
              sx={{ marginBottom: 7, width: '50%'  }}
              inputProps={{
                maxLength: 8
              }}
              label='Level(s) *'
              fullWidth
              variant="standard"
              error={invalid}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        {isGroupCount ?
          <GroupCountSettingsToEdit
            setValue={setValue}
            parkingsList={parkingsList}
            control={control}
          />
          :
          <ParkingSettingsToEdit
            setValue={setValue}
            control={control}
            controllersList={controllersList}
          />
        }
      </>
    );
};
