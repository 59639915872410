import { Button } from '@mui/material';

type TokensMenuProps = {
  handleModalOpen: (type: string, token: string) => void;
};

export default function CompanyTokensMenu({
  handleModalOpen,
}: TokensMenuProps) {
  return (
    <Button color="secondary" onClick={() => handleModalOpen('create', '')}>
      + Add new token
    </Button>
  );
}
