import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';

const PageSizeInput = ({
  field,
}: FilterControlFieldProps<Types.PageSizeInput>) => {
  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: '150px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography variant='body2' sx={{ marginRight: '5px' }}>Number of items:</Typography>
      <Select
        labelId="pageSize"
        label="Number of items:"
        size="small"
        {...field}
      >
        <MenuItem value="10">10</MenuItem>
        <MenuItem value="50">50</MenuItem>
        <MenuItem value="100">100</MenuItem>
        <MenuItem value="250">250</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PageSizeInput;
