import { Box, Button, FormControlLabel, RadioGroup, styled } from '@mui/material';

export const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 30px;
  width: 540px;
  height: 700px;
  justify-content: space-between;
`;

export const ChooseAccessCompanyBtnWrap = styled('div')`
    position: relative;
    height: 16px;
`;

export const ActionBtn = styled('span')`
    color: #ff4081;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover {
        color: #23527c;
    }
 `;

 export const ModulesWrap = styled('div')`
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding-bottom: 5px;
    position: relative;
 `;

 export const StyledLabel = styled('span')`
    font-weight: 700;
    font-size: 14px;
    color: #0000008A;
 `

 export const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5px;
    position: relative;
 `

 export const StyledControlLabel = styled(FormControlLabel)`
    display: flex;
    gap: 5px;
    margin: 0;
 `

 export const FormError = styled('p')`
  color: #d32f2f;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  margin: 0;
  width: 100%;
`;

export const StyledAllInputsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 0;
`;

export const StyledInputWrapper = styled(Box)`
  padding: 5px;
  flex: 50%;
  box-sizing: border-box;
`;

export const ErrorMsgWrap = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  background: #4a89dc;
  color: #fff;
  font-weight: 700;
`;

export const CancelButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin-left: 15px;
  font-weight: 700;
`;

export const TitleLabel = styled('h3')`
  text-align: center;
  padding: 0;
  color: #333;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 40px;
`;