import { useEffect, useMemo, useState } from 'react';
import { ModalLayout } from './ModalLayout';
import {
  CompanyModules,
  DeleteCompanyModalContentProps,
  ModalBtnDesc,
  Module,
} from './types';
import { FormLabel, Checkbox } from '@mui/material';
import { ModuleItem, ModulesWrap } from './styles';
import { useApi } from '../../../../context/api';
import LoadPannelLoader from '../../../../components/LoadPanelLoader';

export const CompanyModulesModalContent = ({
  handleClose,
  companyId,
}: DeleteCompanyModalContentProps) => {
  const [modules, setModules] = useState<CompanyModules>([]);
  const { apiInstance, loading, setLoading } = useApi();

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoading(true);
        const { modules }: { modules: CompanyModules } = await apiInstance.get(
          `companies/modules/${companyId}`
        );
        setLoading(false);
        setModules(modules);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    if (companyId !== '') {
      fetchModules();
    }
  }, [apiInstance,setLoading, companyId]);

  const handleChangeModules = async () => {
    try {
      setLoading(true);
      const { msg }: { msg: string; } = await apiInstance.post(`/companies/modules/${companyId}`, {
        id: companyId,
        module: modules,
      });
      setLoading(false);
      handleClose(msg);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const modulesList = useMemo(() => {
    const changeModuleState = (index: number, isLpr?: boolean) => {
      setModules((prev) => {
        const prevCopy = [...prev];
        const currentItem = prevCopy[index];

        prevCopy[index] = isLpr
          ? {
              ...currentItem,
              lpr: {
                ...(currentItem.lpr as Module),
                enabled: !currentItem.lpr?.enabled,
              },
            }
          : {
              ...currentItem,
              enabled: !currentItem.enabled,
              ...(currentItem.lpr && currentItem.enabled && {
                lpr: { ...(currentItem.lpr as Module), enabled: false },
              }),
            };

        return prevCopy;
      });
    };

    return (
      <ModulesWrap>
        {modules.map(({ id, name, enabled, lpr }, index) => (
          <ModuleItem key={id}>
            <FormLabel>
              <Checkbox
                onChange={() => changeModuleState(index)}
                checked={enabled}
              />
              {name}
            </FormLabel>
            {lpr && enabled && (
              <FormLabel>
                <Checkbox
                  checked={lpr.enabled}
                  onChange={() => changeModuleState(index, true)}
                />
                {lpr.name}
              </FormLabel>
            )}
          </ModuleItem>
        ))}
      </ModulesWrap>
    );
  }, [modules]);

  return (
    <>
      {loading && <LoadPannelLoader/>}
      <ModalLayout
        title="Company modules"
        content={modulesList}
        onReject={handleClose}
        onSubmit={handleChangeModules}
        submitBtnDesc={ModalBtnDesc.SAVE}
        cancelBtnDesc={ModalBtnDesc.CANCEL}
      />
    </>
  );
};
