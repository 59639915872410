import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 10px;
`;

export const ButtonsWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const SaveButton = styled(Button)`
  background: #4a89dc;
  color: #fff;
  
`;

export const CancelButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin-left: 15px;
`;

export const TitleLabel = styled('span')`
  display: block;
  text-align: center;
  color: #333;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 40px;
  padding: 30px 0 15px;
`;

export const FormError = styled('p')`
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 3px;
`;