export enum UserModalType {
    DELETE_USER = 'delete',
    RESER_PASSWORD = 'reset_password'
}
export type UserModalContentProps = {
    type: UserModalType;
    handleClose: () => void;
    userId: string | number;
}

export type DeleteUserModalContentProps = {
    handleClose: () => void;
    userId: string | number;
}