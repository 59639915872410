import { CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';
import { CancelButton, SaveButton, ServiceModalForm, ButtonsWrapper } from '../../../../styles';
import { OcrStatusType } from '../types';
import EditOcrContent from './modalComponents/editOcr-content';
import { useApi } from '../../../../../../context/api';

import _ from 'lodash';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';

type CameraModalProps = {
  ocrStatusId: string | number | null;
  companyId?: string | number;
  onClose: () => void;
  type: null | string;
};

type OcrStatusResponseType = {
  company_id: string;
  status: 'OK' | 'ERROR';
  ocr_info: OcrStatusType;
}

const OcrStatusModal = ({ ocrStatusId, companyId, onClose, type }: CameraModalProps) => {
  const { insert, update, move, errors } = useDataGrid();
  const isEdit = type === 'edit';
  const { apiInstance } = useApi();

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const defaultValues: OcrStatusType = {
    status: '',
    color: '000000'
  };

  const { handleSubmit, control, setValue } = useForm({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (isEdit) {
      (async () => {
        setLoading(true);
        try {
          const { ocr_info }: OcrStatusResponseType =
            await apiInstance.get(`/companies/tcam/ocr/edit/${companyId}/${ocrStatusId}`);

            for (const key of Object.keys(ocr_info) as Array<keyof OcrStatusType>) {
              setValue(key, ocr_info[key] as string);
            }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })()
    }
  }, [ocrStatusId, isEdit, companyId, apiInstance, setValue]);

  useEffect(() => {
    if (_.size(errors)) {
      setSaving(false);
    }
  }, [errors, setSaving])


  const handleClose = () => {
    setSaving(false);
    onClose();
  }

  const handleSave = handleSubmit(async (values) => {
    try {
      setSaving(true);
      switch (type) {
        case 'edit':
          if (ocrStatusId) {
            update(ocrStatusId, values, handleClose);
          }
          break;
        case 'create':
          insert(values, handleClose);
          break;
        case 'delete':
          move(`/companies/tcam/ocr/delete/${companyId}`, { ocrStatusId }, handleClose);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  });

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return 'Edit Status';
      case 'create':
        return 'Add Status'
      case 'delete':
      default:
        return '';
    }
  };

  return (
    <ServiceModalForm
      onSubmit={handleSave}
      sx={{ bgcolor: 'background.paper', p: 6 }}
    >
      <Typography id="modal-modal-title" sx={{ marginBottom: 7 }} align='center' variant="h4" component="h3">
        {renderTitle()}
      </Typography>
      {saving || loading ? <LoadPannelLoader/> : ''}
      {
        loading
          ? ''
          : (
          <>
            {type === 'delete' ?
              <Typography
                sx={{ marginBottom: 5 }}
                data-test={'subtitleModal'}
                align='center'
              >
                Are you sure you want to delete the ocr?
              </Typography> :
              <EditOcrContent control={control} errors={errors}/>
            }
            <ButtonsWrapper>
              <CancelButton onClick={onClose}>{type === 'delete' ? 'Disagree' : 'Cancel'}</CancelButton>
              <SaveButton
                type="submit"
                disabled={saving}
                variant="contained"
              >
                {type === 'delete' ? 'Agree' : 'Save'}
              </SaveButton>
            </ButtonsWrapper>
          </>
        )
      }
    </ServiceModalForm>

  )
}

export default OcrStatusModal;