import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import { IColumnProps } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom';

import GridTable from '../../../../../components/GridTable/gridTable';
import CompareRow from './row/CompareRow';
import { specifiedStore, FULLDATE_FORMAT, getKeysList } from './audit-utils';
import Table from '../../../../../components/Table';

const additionalColumns: IColumnProps [] = [
  {
    dataField: 'company_id',
    caption: 'company_id',
    alignment: 'center'
  },
  {
    dataField: 'serial',
    caption: 'serial',
    alignment: 'center'
  },
  {
    dataField: 'firmware_ver',
    caption: 'firmware_ver',
    alignment: 'center'
  },
  {
    dataField: 'board_ver',
    caption: 'board_ver',
    alignment: 'center'
  },
  {
    dataField: 'location_id',
    caption: 'location_id',
    alignment: 'center'
  },
  {
    dataField: 'sign_model',
    caption: 'sign_model',
    alignment: 'center'
  },
  {
    dataField: 'normal_voltage',
    caption: 'normal_voltage',
    alignment: 'center'
  },
  {
    dataField: 'communication',
    caption: 'communication',
    alignment: 'center'
  }
];

export default function SignsAudit() {
  const { id, companyId} = useParams();

  const [ store, setStore ] = useState<Array<any>>([]);


  // we use a default period of 1 week for data on server
  const [ dateStart, setDateStart ] =
    useState<string>(moment().subtract(1, 'weeks').format(FULLDATE_FORMAT));
  const [ dateEnd, setDateEnd ] =
    useState<string>(moment().format(FULLDATE_FORMAT));

  return useMemo(
    () => (
      <div data-test={'sign-audit'}>
        <Table
          backButton={`/companies/signs/list/${companyId}`}
          title={
            <Typography variant="h5" data-test={'back-button-title'}>
              Sign {id} audit
            </Typography>
          }
          options={{
            moduleName: '',
            apiUrl: `/signs/${id}/audit`,
            dataSourceKey: 'signs_history',
            dataStoreKey: '',
            searchKey: ''
          }}
          filter={{
            withPageSize: false,
            inputs: [
              {
                type: 'rangeDatePicker',
                name: 'rangeDatePicker',
                label: 'Pick dates interval to filter data',
                startName: 'date_from',
                endName: 'date_to',
                dateRangeStart: dateStart,
                dateRangeEnd: dateEnd,
              }
            ],
          }}
          receivedStore={store}
          withSorting={false}
          noDataText={'No matching records found. Try to choose the correct filters.'}
          columns={[
            {
              caption: 'Who changed',
              cellRender: (data) => {
                return(
                  <span>{data.data.user_id ? data.data.user_id : 'Unknown'}</span>
                )
              },
              width: '8%'
            },
            {
              caption: 'Application',
              cellRender: (data) => {
                return(
                  <span>{data.data.application ? data.data.application : 'Unknown'}</span>
                )
              },
              width: '8%'
            },
            {
              dataField: 'description',
              caption: 'Operation',
              width: '8%'
            },
            {
              dataField: 'change_time',
              caption: 'When Made',
              width: '8%'
            },
            {
              dataField: 'Diff',
              cellRender: (data) => {
                return(
                  <GridTable
                    receivedStore={specifiedStore(data.data, ['conf_sync', 'time_zone'])?.diff}
                    columns={additionalColumns}
                    keyExpr='company_id'
                    dataRowRender={(data) => CompareRow(data, getKeysList(additionalColumns))}
                  />
                )
              },
              caption: 'Diff'
            }
          ]}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store, dateStart, dateEnd]
  );
}

