import { useDataGrid } from '../../../../components/generic/dataGrid';
import { ModalLayout } from '../../account-management/modal/ModalLayout';
import { ModalBtnDesc } from '../../account-management/modal/types';
import { DeleteUserModalContentProps } from './types';

export const DeleteUserModalContent = ({
  handleClose,
  userId,
}: DeleteUserModalContentProps) => {
  const { move } = useDataGrid();
  const handleDeleteCompany = async () => {
    move(`/users/delete/${userId}`, {}, handleClose);
  };

  return (
    <ModalLayout
      title="Confirm action"
      content="Are you sure you want to delete the user?"
      onReject={handleClose}
      onSubmit={handleDeleteCompany}
      submitBtnDesc={ModalBtnDesc.ARGEE}
      cancelBtnDesc={ModalBtnDesc.DISAGREE}
    />
  );
};
