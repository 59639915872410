import { Buffer } from 'buffer';

export default class ByteParser {
  static dec2hex(dec: number, pad = 2): string {
    try {
      return (+dec).toString(16).padStart(pad, '0').toUpperCase();
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  static str2hex(line: string): string {
    try {
      const buf = Buffer.from(line, 'utf8');
      
      return buf.toString('hex').toUpperCase();
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e.message);
      } else {
        console.error('Unknown error:', e);
      }
      return '';
    }
  }

  static hex2dec(hex: string): number | null {
    if (hex && typeof hex === 'string') {
      return parseInt(hex, 16);
    }

    return null;
  }
}
