import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const AssignButton = styled(Button)`
  display: block;
  color: #8b8b8b;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 900;
`;

export const EmptyLocLabel = styled('div')`
  font-size: 10px;
  text-transform: lowercase;
  font-weight: 400;
  text-decoration: overline;
  color: #8b8b8b;
`;
