import React from 'react';

import { TCAMEditProps } from './types';
import { TextField, FormControl, Input, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import {values} from "lodash";

export default function OcrEditModalContent(
    { control, errors }: { control: any, errors: any }
) {

    return (
      <>
       <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <TextField
            helperText={values(errors?.status)}
            fullWidth
            label="Status"
            variant="standard"
            sx={{ marginBottom: 5 }}
            {...field}
          />
        )}
      />
      <Controller
        name="color"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id='edit-modal-models-select'>Color</InputLabel>
            <Input
              id='osr-color'
              {...field}
              value={`#${field.value}`}
              onChange={(event) =>
                field.onChange(event.target.value.replace(/#/g, ''))}
              type='color'
              sx={{ marginBottom: 7 }}
            />
          </FormControl>
        )}
      />
      </>
    );
};