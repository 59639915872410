import { CMD } from './types'
import { messageList, DAY_WEEK_LABEL, BIT_STEALTH } from './constants';

export const getSpeedUnitLabel = () => 'km/h';

export const adjustMessage = (signType: number, message: number) => {
  let _message = message;

  if (signType === 4) { // SP800
    if (message === 13) { // animation
      _message = 9;
    } else if (message >= 9 && message <= 12) { // custom full msg #1..#4
      _message++;
    }
  }

  return _message;
};

export const getTimeFromMinutes = (minutes: number) => {
  const time = (minutes / 60);
  const convertedHours = Math.floor(time).toString().padStart(2, '0');
  const _minutes = time % 1;

  let convertedMinutes = '00';

  if (_minutes) {
    const minVal = (_minutes / 100 * 60).toString().split('.');

    convertedMinutes = minVal[1].slice(0, 2).padEnd(2, '0');
  }

  return `${convertedHours}:${convertedMinutes}`;
};



export default (cmd: CMD)=> {
  try {
    const { buffer } = cmd.getCommandValue();

    const speedLabel = getSpeedUnitLabel();

    const scheduleLength = buffer.length;

    let _speedRed = 0;

    let [
      redAboveMin,
      redAboveLimit,
      redAboveTolerated,
      redAboveMax,
      alternateSpeedMin,
      alternateSpeedTolerated,
      alternateSpeedMaximum
    ] = [0, 0, 0, 0, 0, 0, 0];

    let [
      colorDigitSpeedMin,
      colorDigitSpeedTolerated,
      colorDigitSpeedMaximum
    ] = [0, 0, 0];

    if (scheduleLength > 15) {
      _speedRed = buffer.readUInt8(15);

      if (scheduleLength > 16) {
        [
          redAboveMin,
          redAboveLimit,
          redAboveTolerated,
          redAboveMax,
          alternateSpeedMin,
          alternateSpeedTolerated,
          alternateSpeedMaximum
        ] = cmd.getBitmapOf(16, 7);

        if (scheduleLength > 17) {
          [colorDigitSpeedMin, colorDigitSpeedTolerated, colorDigitSpeedMaximum] = cmd.getBitmapOf(17, 4);
        }
      }
    }

    const parsedSchedule: any = {
      sDay: buffer.readUInt8(0),
      sTime: buffer.readUInt16BE(1),
      recMode: buffer.readUInt8(3),
      minSpeed: buffer.readUInt8(4),
      maxSpeed: buffer.readUInt8(5),
      speedLimit: buffer.readUInt8(6),
      speedTolerated: buffer.readUInt8(7),
      blinkDigSpeed: buffer.readUInt8(8),
      blinkMsgSpeed: buffer.readUInt8(9),
      speedStrobe: buffer.readUInt8(10),
      msgAboveMin: buffer.readUInt8(11),
      msgAboveLimit: buffer.readUInt8(12),
      msgAboveTolerated: buffer.readUInt8(13),
      msgAboveMax: buffer.readUInt8(14),
      speedRed: _speedRed,
      redAboveMin,
      redAboveLimit,
      redAboveTolerated,
      redAboveMax,
      alternateSpeedMin,
      alternateSpeedTolerated,
      alternateSpeedMaximum,
      colorDigitSpeedMin,
      colorDigitSpeedTolerated,
      colorDigitSpeedMaximum
    };

    const weekDays: { [proprerty: string] : any} = {};
    const isMoreThanAll = parsedSchedule.sDay <= 127;

    DAY_WEEK_LABEL.forEach((day: string, index: number) => {
      weekDays[day] = isMoreThanAll ? !!(parsedSchedule.sDay & (1 << index)) : false;
    });

    const dayTime = `Activated at ${getTimeFromMinutes(parsedSchedule.sTime)}`;
    const stealth = (parsedSchedule.recMode & (1 << BIT_STEALTH)) ? 1 : 0;

    const speedRed = !stealth && parsedSchedule.speedRed
      ? `${parsedSchedule.speedRed} ${speedLabel}`
      : 'OFF';
    const speedRedStatus = !stealth && parsedSchedule.speedRedStatus
      ? 'ON'
      : 'OFF';

    const dataMessages: { [property: string] : any } = {};
    const messagesConvert = ['msgAboveMin', 'msgAboveLimit', 'msgAboveTolerated', 'msgAboveMax'];

    messagesConvert.forEach(message => {
      dataMessages[message] = adjustMessage(1, parsedSchedule[message]);
    });

    const displaySetting = [
      {
        min: `${parsedSchedule.minSpeed} ${speedLabel}`,
        limit: `${parsedSchedule.speedLimit} ${speedLabel}`,
        allowed: `${parsedSchedule.speedTolerated} ${speedLabel}`,
        max: `${parsedSchedule.maxSpeed} ${speedLabel}`,
        red: _speedRed ? speedRed : null
      },
      {
        min: messageList[dataMessages.msgAboveMin],
        redAboveMin: parsedSchedule.redAboveMin,
        limit: messageList[dataMessages.msgAboveLimit],
        redAboveLimit: parsedSchedule.redAboveLimit,
        allowed: messageList[dataMessages.msgAboveTolerated],
        redAboveTolerated: parsedSchedule.redAboveTolerated,
        max: messageList[dataMessages.msgAboveMax],
        redAboveMax: parsedSchedule.redAboveMax,
        red: _speedRed ? speedRedStatus : null
      }
    ];

    const flashing = {
      digits: !stealth && parsedSchedule.blinkDigSpeed
        ? `${parsedSchedule.blinkDigSpeed} ${speedLabel}`
        : 'OFF',
      strobe: parsedSchedule.speedStrobe
        ? `${parsedSchedule.speedStrobe} ${speedLabel}`
        : 'OFF',
      message: !stealth && parsedSchedule.blinkMsgSpeed
        ? `${parsedSchedule.blinkMsgSpeed} ${speedLabel}`
        : 'OFF'
    };

    return {
      days: [weekDays, dayTime],
      displaySetting,
      flashing
    };
  } catch (e) {
    console.log(e);

    return {};
  }
};
