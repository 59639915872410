import  Reports from './ReportTable'

export const columnsLoginActivity = [
  {
    dataField: 'timestamp',
    caption: 'Timestamp',
  },
  {
    dataField: 'userEmail',
    caption: 'User Login',
  },
  {
    dataField: 'userName',
    caption: 'Username',
  },
  {
    dataField: 'ipAddress',
    caption: 'IP address',
  }
];

export const columnsLossCommunicationHistory = [
  {
    dataField: 'ccSerial',
    caption: 'CC SN',
  },
  {
    dataField: 'deviceSerial',
    caption: 'Device SN',
  },
  {
    dataField: 'model',
    caption: 'Model',
  },
  {
    dataField: 'sim',
    caption: 'SIM',
  },
  {
    dataField: 'fwVer',
    caption: 'FW',
  },
  
  {
    dataField: 'lastCommunication',
    caption: 'Last communication',
  },
  {
    dataField: 'number10HoursPeriods',
    width: '12%',
    alignment: 'center',
    caption: 'Number of 10 hour periods device(s) didn’t communicate in the selected range',
  },
  {
    dataField: 'number72HoursPeriods',
    width: '12%',
    alignment: 'center',
    caption: 'Number of 72 hour periods device(s) didn’t communicate in the selected range',
  },

];

export const columnsUserActivity = [
  {
    dataField: 'reportName',
    caption: 'Report Name',
  },
  {
    dataField: 'moduleName',
    caption: 'Module name',
  },
  {
    dataField: 'userName',
    caption: 'Username',
  },
  {
    dataField: 'userEmail',
    caption: 'User Login',
  },
  {
    dataField: 'count',
    width: '12%',
    alignment: 'center',
    caption: 'Number of time',
  },
  {
    dataField: 'subscription',
    width: '260px',
    alignment: 'center',
    caption: 'Subscription to automatic report',
    cellRender: ({ data }: {data: {subscription: null | string[]}}) => (
      <div>{!data.subscription ? '-' : (
        data.subscription.map((i:string, index: number) => <div key={index}>{i}</div>)
      )}</div>
    )
  },
];

export const columnsGeneralActivity = [
  {
    dataField: 'pageName',
    caption: 'Page name',
  },
  {
    dataField: 'moduleName',
    caption: 'Module name',
  },
  {
    dataField: 'userName',
    caption: 'Username',
  },
  {
    dataField: 'userEmail',
    caption: 'User Login',
  },
  {
    dataField: 'count',
    width: '12%',
    alignment: 'center',
    caption: 'Number of visits',
  }
];
