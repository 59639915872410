import { Box, Dialog, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  ChangeCredentialsAPIResponse,
  ChangeCredentialsDefaultValues,
} from './types';
import {
  ButtonsWrapper,
  CancelButton,
  FormError,
  FormWrapper,
  SaveButton,
  TitleLabel,
} from './styles';
import { useCallback } from 'react';
import { useApi } from '../../../../context/api';

export function ChangeCredentialsComponent({
  onClose,
  defaultValues,
}: {
  defaultValues: ChangeCredentialsDefaultValues;
  onClose: (info?: { showSnackbarMsg: boolean; message: string }) => void;
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues,
  });
  const { apiInstance } = useApi();
  const onSubmit = useCallback(
    (data: ChangeCredentialsDefaultValues) => {
      async function saveData() {
        const result: ChangeCredentialsAPIResponse = await apiInstance.post(
          `/savewdaccount`,
          {
            wd_name: data.wd_name,
            wd_login: data.wd_login,
            wd_password: data.wd_password,
          }
        );
        if (result.error) {
          onClose({ showSnackbarMsg: true, message: result.error });
          return;
        }
        onClose({ showSnackbarMsg: true, message: result.message });
      }

      saveData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose]
  );

  return (
    <Dialog open={true} onClose={() => onClose()}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <FormWrapper>
          <TitleLabel>Change credentials</TitleLabel>
          <Box sx={{ width: 1 }}>
            <Controller
              control={control}
              name="wd_name"
              defaultValue={defaultValues.wd_name}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
                minLength: {
                  value: 3,
                  message: 'Minimum 3 characters are accepted',
                },
                maxLength: {
                  value: 16,
                  message: 'Maximum 16 characters are accepted',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  variant="standard"
                  {...field}
                />
              )}
            ></Controller>
            {errors?.wd_name?.message && (
              <FormError>{errors.wd_name.message}</FormError>
            )}
          </Box>
          <Box sx={{ width: 1 }}>
            <Controller
              control={control}
              name="wd_login"
              defaultValue={defaultValues.wd_login}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
                minLength: {
                  value: 3,
                  message: 'Minimum 3 characters are accepted',
                },
                maxLength: {
                  value: 16,
                  message: 'Maximum 16 characters are accepted',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Login"
                  variant="standard"
                  {...field}
                />
              )}
            ></Controller>
            {errors?.wd_login?.message && (
              <FormError>{errors.wd_login.message}</FormError>
            )}
          </Box>
          <Box sx={{ width: 1 }}>
            <Controller
              control={control}
              name="wd_password"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
                minLength: {
                  value: 6,
                  message: 'Minimum 6 characters are accepted',
                },
                maxLength: {
                  value: 16,
                  message: 'Maximum 16 characters are accepted',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Password"
                  variant="standard"
                  type="password"
                  {...field}
                />
              )}
            ></Controller>
            {errors?.wd_password?.message && (
              <FormError>{errors.wd_password.message}</FormError>
            )}
          </Box>
          <ButtonsWrapper>
            <SaveButton
              type="submit"
              variant="contained"
              //disabled={!!Object.keys(errors).length}
            >
              Save
            </SaveButton>
            <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          </ButtonsWrapper>
        </FormWrapper>
      </form>
    </Dialog>
  );
}
