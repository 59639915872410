import {Box, IconButton, Switch} from '@mui/material';
import Table from '../../../../components/Table';
import { Wrapper } from './styles';
import DvrIcon from '@mui/icons-material/Dvr';
import { useMemo, useState } from 'react';
import GenericModal from '../../../../components/generic/modal';
import ModalContent from './modal-content';
import { TcamDevice } from '../../../../types';
import Value from '../../../../components/Table/Columns/Value';
import Status from '../../../../components/Table/Columns/Status';
import { lowBatteryFilter, partFiltersOverview } from '../filters';
import { MODULES } from '../../../../constants/Modules';
import { USERROLES } from '../../../../constants/UserRoles';
import { useAuth } from '../../../../context/auth';

export default function TCam() {
  const { hasRoleAccess } = useAuth();
  const renderChild = (value: number | string | null) => value ? value : 'no data';

  const table = useMemo(
    () => (
      <Table
        title="Companies with cameras"
        exportData={{
          csv: {
            url: `/devices/${MODULES.MODULE_TCAM}/export-csv`
          }
        }}
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
            {
              type: 'text',
              name: 'ccSerial',
              label: 'MAC',
            },
            {
              type: 'text',
              name: 'companyId',
              label: 'Account number',
            },
            {
              type: 'text',
              name: 'state',
              label: 'State',
            },
            {
              type: 'select',
              name: 'companyId',
              label: 'Company',
              fetch: {
                url: '/companies/names-list',
                formatter: (data =>
                  data.accountList.map(({ id, name }: { id: number; name: string }) => ({ value: id, title: name }))
                )
              }
            },
            {
              type: 'select',
              name: 'modelId',
              label: 'Model',
              fetch: {
                url: `/devices/${MODULES.MODULE_TCAM}/models`,
                formatter: (data =>
                  data.deviceModels.map(({ id, name }: { id: number; name: string }) => ({ value: id, title: name }))
                )
              }
            },
            lowBatteryFilter,
            ...partFiltersOverview
          ],
        }}
        options={{
          moduleName: 'tcam',
          apiUrl: '/tcam/search',
          dataSourceKey: 'items',
          dataStoreKey: 'id',
          searchKey: ''
        }}
        columns={[
          {
            name: '',
            caption: '',
            width: '40px',
            visible: hasRoleAccess([
              [USERROLES.ROLE_SUPER_ADMIN],
              [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
              [USERROLES.ROLE_POWER_USER],
              [USERROLES.ROLE_POWER_USER, USERROLES.ROLE_RESTRICTED],
              [USERROLES.ROLE_STAGING]
            ]),
            cellRender: ({ data }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedTcamId(data.id);
                  }}
                >
                  <DvrIcon fontSize="inherit" />
                </IconButton>
              </Box>
            ),
          },
          {
            dataField: 'companyId',
            caption: 'Acc. number',
            width: '70px',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.companyId}/>
          },
          {
            dataField: 'ccSerial',
            caption: 'MAC',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.ccSerial}/>,
            width: '140px'
          },
          {
            dataField: 'model',
            caption: 'Model',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.model}/>,
            width: '100px'
          },
          {
            dataField: 'location',
            caption: 'Location',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.location}/>,
            width: '190px'
          },
          {
            dataField: 'companyName',
            caption: 'Company',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.companyName}/>,
            width: '140px'
          },
          {
            dataField: 'state',
            caption: 'State',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.state}/>,
            width: '60px'
          },
          {
            dataField: 'sim',
            caption: 'SIM',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.sim}/>,
            width: '60px'
          },
          {
            dataField: 'contactEmail',
            caption: 'Contact e-mail',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.contactEmail}/>,
            width: '200px'
          },
          {
            dataField: 'subscriptionExpiresAt',
            caption: 'Renewal date',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.subscriptionExpiresAt}/>,
            width: '100px'
          },
          {
            dataField: 'lastCommunicatedAt',
            caption: 'Last comm.',
            cellRender: ({ data } : { data: TcamDevice }) => (
              <Status
                success={!!data.timeStatus}
                disabled={!data.lostCommunication}
              >
                {renderChild(data.lastCommunicatedAt)}
              </Status>
            ),
            width: '190px'
          },
          {
            dataField: 'uptime',
            caption: 'Uptime',
            alignment: 'left',
            cellRender: ({ data } : { data: TcamDevice }) => <Value data={data.uptime}/>,
            width: '80px'
          },
          {
            dataField: 'battery',
            caption: 'Battery',
            alignment: 'left',
            cellRender: ({ data } : { data: TcamDevice }) => (
              <Status
                success={!!data.batteryStatus}
                disabled={!data.lostCommunication}
              >
                {`${renderChild(data.battery)} / ${data.normalVoltage}v`}
              </Status>
            ),
            width: '100px'
          },
          {
            type: 'buttons',
            name: '',
            caption: '',
            cellRender: ({ data } : { data: TcamDevice }) =>
              <Switch disabled={true} data-test={`switchDevice_${data.id}`}/>,
          }
        ]}
      ></Table>
    ),
    [hasRoleAccess]
  );
  const [selectedTcamId, setSelectedTcamId] = useState<number | null>(null);

  return (
    <Wrapper  className="tcam" data-test="tcam">
      {table}
      <GenericModal size="lg" closeButton show={!!selectedTcamId} onClose={() => setSelectedTcamId(null)}>
        {selectedTcamId && <ModalContent id={selectedTcamId}></ModalContent>}
      </GenericModal>
    </Wrapper>
  );
}
