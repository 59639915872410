import React, { useCallback, useMemo, useState } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import Table from '../../../../../components/Table';
import AddSignCommand, { CommandTypesType } from './AddCommandModal';
import GenericModal from '../../../../../components/generic/modal';
import Status from '../../../../../components/Table/Columns/Status';

import DvrIcon from '@mui/icons-material/Dvr';
import _ from 'lodash';
import ShowInfoCommand from "./ShowInfoModal";

export default function SignCommands() {
  const { id } = useParams();
  const [modal, setModal] = useState<string | null>(null);
  const [commandTypes, setCommandTypes] = useState<CommandTypesType[]>([]);
  const [info, setInfo] = useState<number | null>(null);

  const handleAddClick = (type: string | null, data?: number) => {
    setModal(type);
    if(data) {
      setInfo(data);
    }
  }

  const handleModalClose = () => {
    setModal(null);
    setInfo(null);
  };

  const setCommandTypesOptions = useCallback(({ command_types }: { command_types?: CommandTypesType[] }) => {
    if (command_types && !_.size(commandTypes)) {
      setCommandTypes(command_types)
    }
  }, [commandTypes])

  const table = useMemo(() => (
    <Table
      title="Sign Commands"
      backButton='/sign'
      withSorting={true}
      options={{
        moduleName: "ocr",
        apiUrl: `/sign-commands/${id}`,
        dataSourceKey: "sign_commands",
        dataStoreKey: "id",
        insert: { method: 'post', url: `/sign-commands/${id}/add` },
      }}
      onDataChange={setCommandTypesOptions}
      columns={[
        {
          name: '',
          caption: '',
          alignment: 'center',
          cellRender: ({ data }) => (
            <Tooltip title='Show info'>
              <IconButton onClick={() => handleAddClick('info', data.id)} size="small">
                <DvrIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ),
        },
        {
          dataField: 'cmd_code',
          width: 80,
          alignment: 'left',
          caption: 'Cmd Code'
        },
        {
          dataField: 'cmd_text_formatted',
          caption: 'Cmd Text',
          cellRender: (data) => {
            return(
              <span>{`${data.data.cmd_text_formatted} (${data.data.cmd_text})`}</span>
            )
          },
        },
        {
          dataField: 'stop_proc',
          caption: 'Stop Proc',
          width: 80,
          alignment: 'left',
        },
        {
          dataField: 'time_req',
          caption: 'Time Request'
        },
        {
          dataField: 'time_proc',
          caption: 'Time Processed'
        },
        {
          dataField: 'has_response',
          width: 100,
          caption: 'Has Response',
          cellRender: ({ data }) => (
            <Status
              dangerColor='#ffc107'
              success={!!data.has_response}
              disabled={false}
            >
              {data.has_response ? 'Yes' : 'No'}
            </Status>
          ),
        },
        {
          dataField: 'server_id',
          alignment: 'left',
          caption: 'Server ID'
        },
        {
          dataField: 'command_provider',
          width: 70,
          caption: 'Sent Via'
        },
      ]}
      toolbar={<Button color='secondary' onClick={() => handleAddClick('add')}> + Add new command</Button>}
    />
    ), [id, setCommandTypesOptions]);

  const genericModal =  useMemo(() => {
    switch(modal){
      case 'info':
        return (<ShowInfoCommand id={info} commandsId={id} onClose={() => handleModalClose()}/>)
      case 'add':
        return (
          <AddSignCommand
            commandTypes={commandTypes}
            open={!!modal}
            onClose={handleModalClose}
          />
        );
      default:
        break;
    }
  }, [modal, info, commandTypes, id]);

  return (
    <>
      {table}
      {!!modal && (
        <GenericModal
          show={!!modal}
          size={'md'}
          onClose={() => handleModalClose()}
        >
          {genericModal}
        </GenericModal>

      )}

    </>
  );
}
