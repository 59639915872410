import RadarCommand from './radarCommand';
import { toUpper } from 'lodash';
import { SIGN_MODELS } from './constants';
import errorException from './errorException';
import { CMD } from './types'

const serialParse = (cmd: CMD) => {
  try {
    const { buffer } = cmd.getCommandValue();

    const serialNumber = toUpper(buffer.readUInt32BE(0).toString(16));
    const boardVersion = toUpper(buffer.readUInt8(4).toString(16));
    const firmware = toUpper(buffer.readUInt8(6).toString(16));

    const MSBboardFusesByte = (buffer.readUInt8(5) & 240) >> 4;
    const LSBboardFusesByte = buffer.readUInt8(5) & 15;

    const signModel = SIGN_MODELS[LSBboardFusesByte];

    const boardFusesMap = RadarCommand.getBitmap(MSBboardFusesByte, 32);
    const firmwareFusesMap = cmd.getBitmapOf(7, 32);

    const fwFuses = {
      dccStatistic: !!firmwareFusesMap[0],
      gprs: !!firmwareFusesMap[1],
      listen: !!firmwareFusesMap[2],
      push: !!firmwareFusesMap[3],
      extRadar: !!firmwareFusesMap[4],
      mode3g: !!firmwareFusesMap[5]
    };

    const boardFuses = {
      statistic: !!boardFusesMap[0],
      relay: !!boardFusesMap[1],
      slMsg: !!boardFusesMap[2],
      beacon: !!boardFusesMap[3]
    };

    return {
      serialNumber,
      signModel,
      boardVersion,
      boardFuses,
      firmware,
      fwFuses
    };
  } catch (e) {
    console.log(e);
    errorException();

    return {};
  }
};

export default serialParse;
