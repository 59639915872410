import GlobalRoutes from './modules/global.routes';
import { GlobalProviders } from './context/providers';
import { AppWrapper } from './App-styles';
import GlobalStyle from './globalStyles';

function App() {
  return (
    <GlobalProviders>
      <AppWrapper>
        <GlobalRoutes />
        <GlobalStyle />
      </AppWrapper>
    </GlobalProviders>
  );
}

export default App;