import moment from 'moment';
import errorException from './errorException';

const dateTimeParse = (cmdBuffer: any) => {
  try {
    const dateTime = moment()
      .year(2000 + cmdBuffer.readUInt8(5))
      .month(cmdBuffer.readUInt8(4) - 1)
      .date(cmdBuffer.readUInt8(3))
      .hours(cmdBuffer.readUInt8(2))
      .minutes(cmdBuffer.readUInt8(1))
      .seconds(cmdBuffer.readUInt8(0));

    return {
      dateTime: dateTime.format('YYYY-MM-DD HH:MM:ss')
    };
  } catch (e) {
    console.log(e);
    errorException();

    return null;
  }
};

export default dateTimeParse;
