import React from 'react';
import { WrapperStatus } from '../../styles';

const CustomColorCell = (
  { data }: { data: { deleted: number; login: string; } }
) => {
    const statusColor = data?.deleted ? 'red' : 'inherit';

    return (
      <WrapperStatus sx={{color: statusColor}}>
        { data?.login ? data.login : 'no data' }
      </WrapperStatus>
    );
};

export default CustomColorCell;
