import React, { Dispatch, createContext, useContext, useEffect, useReducer, useCallback, useRef } from 'react';
import moment from 'moment';
import { AuthDataType } from './types';
import { reducer } from './reducer';
import { ActionType, AuthContextActions } from './actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Credentials, LoginResponseType } from '../commonInterface';
import { LOGIN_ROUTE, RENEW_ROUTE } from '../../services/service.constants';
import { REACT_APP_BACKEND_URL } from '../../constants/Env.Constants';
import _ from 'lodash';
import { useApi } from '../api';
import axios from 'axios';

const _isAuthenticated = !!localStorage.getItem('user');

const initialState: AuthDataType = {
  user: null,
  tokens: null,
  isAuthenticated: _isAuthenticated,
};

interface AuthContextInterface {
  state: AuthDataType;
  dispatch: Dispatch<AuthContextActions>;
  logout: () => void;
  signIn: (credentials: { login: string; password: string }) => Promise<void>;
  hasRoleAccess: (arr: number[][]) => boolean;
  hasModuleAcces: (arr: number[]) => boolean;
}

export const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

function AuthContextProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);
  const renewTimer = useRef<NodeJS.Timeout | null>(null);
  const { apiInstance } = useApi();

  const setUserData = (userData: AuthDataType) => {
    dispatch({
      type: ActionType.SetAuthData,
      payload: {
        isAuthenticated: true,
        user: userData.user,
        tokens: userData.tokens,
      },
    });

    localStorage.setItem(
      'user',
      JSON.stringify({
        user: userData.user,
        tokens: userData.tokens,
      })
    );
  };

  const clearTimer = () => {
    if (renewTimer.current) {
      clearTimeout(renewTimer.current);
      renewTimer.current = null;
    }
  };

  const logout = useCallback(() => {
    dispatch({
      type: ActionType.SetAuthData,
      payload: {
        isAuthenticated: false,
        user: null,
        tokens: null,
      },
    });
    window.localStorage.removeItem('user');
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const signIn = useCallback(
    async (credentials: Credentials) => {
      try {
        const response: LoginResponseType = await apiInstance.post(`${LOGIN_ROUTE}`, credentials, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const token = response.tokens?.access_token as string;

        setUserData(response);

        if (token && location.state?.previousLocation?.pathname) {
            const { pathname, search } = location.state.previousLocation;
            navigate(pathname +  search);
        } else {
            navigate('/home');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const renew = useCallback(async () => {
    const tokens = state.tokens;
    if (tokens) {
      try {
        const response: LoginResponseType = await axios.post(
          `${REACT_APP_BACKEND_URL}${RENEW_ROUTE}`,
          {
            access_token: tokens.access_token,
            refresh_token: tokens.refresh_token,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        setUserData({
          user: state.user,
          tokens: _.get(response, 'data.data.tokens', null),
        });
      } catch (error) {
        console.log(error);
        logout();
      } finally {
        clearTimer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tokens, state.user]);

  const initRenew = useCallback(() => {
    const accessTokenExp = state.tokens?.access_token_expire;

    if (accessTokenExp) {
      const refreshIn = accessTokenExp - moment().unix() - 10;

      renewTimer.current = setTimeout(renew, refreshIn * 1000);
    }
  }, [renew, state.tokens?.access_token_expire]);

  useEffect(() => {
    const currentUser = localStorage.getItem('user');

    if (currentUser) {
      const user: AuthDataType = JSON.parse(currentUser);
      setUserData(user);
    }
  }, []);

  useEffect(() => {
    if (state.tokens && !renewTimer.current) {
      initRenew();
    }

    return () => {
      clearTimer();
    };
  }, [state.tokens, initRenew]);

  useEffect(() => {
    if (!localStorage.getItem('user') && state.user) {
      logout();
    }
  }, [logout, state.user]);

  const hasRoleAccess = useCallback(
    (rolesToCheck: number[][]) => {
      
      const nerRoleUser = [state.user?.roles.join('')];
      const newRolesToCheck = rolesToCheck.map((role) =>
        role.join(''));

      return nerRoleUser.reduce(
        (acc, role) => {
          return acc || newRolesToCheck.includes(role || '0');
        },
        false
      );
    },
    [state.user?.roles]
  );

  const hasModuleAcces = useCallback(
    (userModules: number[]) => {
      for (const module of userModules) {
        if (state.user?.access_to_modules.includes(module)) {
          return true;
        }
      }

      return false;
    },
    [state.user?.access_to_modules]
  );

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        logout,
        signIn,
        hasRoleAccess,
        hasModuleAcces,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }
  return context;
}

function useLoggedInUser() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context.state.user;
}

export { AuthContextProvider, useAuth, useLoggedInUser };
