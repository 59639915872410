import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  ButtonsWrapper,
  FilterWrapper,
  FormWrapper,
  ResetButton,
  SearchButton,
} from './styles';
import { FiltersType, useFiltersContext } from './context';
import FilterControls from './controls';
import {Box, Typography} from '@mui/material';
import GenericModal from '../modal';


export default function Filters(): JSX.Element {
  const {
    state,
    reset: resetFilters,
    setActiveFilters
  } = useFiltersContext();
  const { inputs, activeFilters } = state;
  const [ dialog, setDialog ] = useState<boolean | null>(null);
  const location = useLocation();

  const initValues = useMemo(() => {
    return inputs.flat().reduce((prev, current) => {
      switch (current.type) {
        case 'checkbox':
          return { ...prev, [current.name]: false };
        case 'datePicker':
          return { ...prev, [current.name]: current.dateRange };
        case 'rangeDatePicker':
          return {
            ...prev,
            [current.startName]: current.dateRangeStart,
            [current.endName]: current.dateRangeEnd
          };
        case 'selectionCheckbox':
          return { ...prev, [current.name]: null };
        case 'pagesize':
          return { ...prev, [current.name]: 10 };
        default:
          return { ...prev, [current.name]: '' };
      }
    }, {});
  }, [inputs]);

  const defaultValues = useMemo(() => {
    return { ...initValues, ...activeFilters }
  }, [activeFilters, initValues]);

  const { handleSubmit, reset, control } = useForm({
    mode: 'all',
    defaultValues,
  });

  const resetHandler = useCallback(() => {
    if (location.state) {
      location.state.backButton = false;
      location.search = '';
    }
    reset(initValues);
    resetFilters({});
  }, [reset, location, resetFilters, initValues]);

  const closeFilters = useCallback(() => {
    showFilters(null);
  }, []);

  const onSubmit = useCallback(
    (data: FiltersType) => {
      const selectedFilters: FiltersType = {};

      for (const key of Object.keys(data)) {
        if (data[key]) {
          selectedFilters[key] = typeof data[key] === 'boolean' ? +data[key] : data[key];
        }
      }

      setActiveFilters(selectedFilters);
      closeFilters();
    },
    [setActiveFilters, closeFilters]
  );

  const showFilters = (value: boolean | null) => setDialog(value);

  const filtersArr = inputs.length > 4 ? inputs.slice(0,4) : inputs;

  return (
    <FilterWrapper sx={{ width: '100%' }}>
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            '> *': { flexBasis: `${100 / filtersArr.length}%` },
          }}
        >
          <FilterControls inputs={filtersArr} control={control} />
        </Box>
        <ButtonsWrapper>
          {inputs.length > 4 &&
            <ResetButton
              data-test={`showFilter`}
              variant="contained"
              onClick={() => showFilters(true)}
            >
              Show All Filters
            </ResetButton>
          }
          <ResetButton
            data-test={`resetFilter`}
            variant="contained"
            onClick={resetHandler}
          >
            Reset
          </ResetButton>
          <SearchButton
            data-test={`applyFilter`}
            type="submit"
            variant="contained"
          >
            Search
          </SearchButton>
        </ButtonsWrapper>
      </FormWrapper>
      <GenericModal
        size="sm"
        closeButton
        show={dialog !== null}
        onClose={closeFilters}
        customContentStyles={{ padding: '0' }}
      >
        <Typography variant='h4' sx={{ marginTop: 3, marginBottom: 2 }} align='center'>
          Filters
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 3
          }}
        >
          <FilterControls inputs={inputs} control={control} showAll />
        </Box>

        <ButtonsWrapper
          sx={{
            justifyContent: "space-around",
            padding: '15px 0',
            position: 'sticky',
            bottom: '0',
            background: 'white',
            boxShadow: '0px 2px 6px 0px rgba(148, 148, 148, 1)'
          }}
        >
          <ResetButton
            data-test={`resetFilterFull`}
            variant="contained"
            onClick={resetHandler}
          >
            Reset
          </ResetButton>
          <SearchButton
            data-test={`applyFilterFull`}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Search
          </SearchButton>
        </ButtonsWrapper>
      </GenericModal>
    </FilterWrapper>
  );
}
