import React from 'react';

import { UserControlledProps } from './types';
import { Typography, Box } from '@mui/material';
import { CancelButton, SaveButton, ServiceModalWrap } from '../styles';
import _ from 'lodash';
import { useDataGrid } from '../../../components/generic/dataGrid';
import { useForm } from 'react-hook-form';
import UserEditModalContent from './modalComponents/editModal-content';
import UserDeleteModalContent from './modalComponents/deleteModal-content';
import { useApi } from '../../../context/api';
import LoadPannelLoader from '../../../components/LoadPanelLoader';

export default function UserModalContent(
    { type, user, values, rolesList, onClose, companyId }: UserControlledProps
) {
    const { loading} = useApi();
    const { insert, update, move, errors, setErrors } = useDataGrid();
    const defaultValues: any = values;

    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'all',
        defaultValues,
    });

    const handleClose = () => {
      if(type === 'create' || type === 'edit') {
        setErrors({});
        reset();
      }

      onClose?.();
    }

    const renderContent = () => {
      switch (type) {
        case 'edit':
        case 'create':
          return (
            <UserEditModalContent
              control={control}
              rolesList={rolesList}
              setValue={setValue}
              errors={errors}
            />
          );
        case 'reset':
        case 'delete':
        default:
          return (<UserDeleteModalContent type={type}/>);
      }
    };

    const renderTitle = () => {
       const titles: { [key: string]: string } = {
        edit: 'Edit user',
        create: 'Add new user',
        delete: 'Confirm action',
        reset: 'Confirm action',
      }

      return titles[type] || '';
    }

    const saveSignInfo = handleSubmit((values) => {
        const { id } = user;

        try {
          const request: { [key: string]: () => void } = {
            edit: () => update(id, values, handleClose),
            create: () => insert(values, handleClose),
            delete: () => move(`/companies/users/delete/${companyId}`, { id }, handleClose),
            reset: () => move(`/companies/users/resetAgreement/${companyId}`, { userId: id }, handleClose),
          };

          request[type]();
        } catch (error: any) {
            console.error(error);
        }
    });

    return (
      <ServiceModalWrap>
        <form
          onSubmit={saveSignInfo}
        >
          <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
            {renderTitle()}
          </Typography>
          {loading ? <LoadPannelLoader/> : ''}
          {renderContent()}

          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row-reverse',
              flexWrap: 'wrap',
              marginBottom: 5
            }}
          >
            <CancelButton onClick={handleClose}>
              { _.indexOf(['delete', 'reset'], type) != -1 ? 'Disagree' : 'Cancel' }
            </CancelButton>
            <SaveButton
              type="submit"
              variant="contained"
              disabled={loading}
            >
              { _.indexOf(['delete', 'reset'], type) != -1 ? 'Agree' : 'Save' }
            </SaveButton>
          </Box>
        </form>
      </ServiceModalWrap>
    );
};