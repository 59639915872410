import { ServiceModalWrap } from '../../styles';
import { CompanyModulesModalContent } from './CompanyModulesModalContent';
import { DeleteCompanyModalContent } from './DeleteCompanyModalContent';
import { CompanyModalContentProps, CompanyModalType } from './types';

export const CompanyModalContent = ({
  type,
  companyId,
  handleClose,
}: CompanyModalContentProps) => (
  <ServiceModalWrap>
    {type === CompanyModalType.DELETE_COMPANY && (
      <DeleteCompanyModalContent
        handleClose={handleClose}
        companyId={companyId}
      />
    )}
    {type === CompanyModalType.COMPANY_MODULES && (
      <CompanyModulesModalContent
        handleClose={handleClose}
        companyId={companyId}
      />
    )}
  </ServiceModalWrap>
);
