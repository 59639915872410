import { Buffer } from 'buffer';
import _ from 'lodash';

export default class RadarCommand {
  static getBitmap(int: any, bits = 32) {
    return [...Array(bits)].map((_, index) => (int >> index) & 1);
  }
  commandValue: any;
  origin: any;
  command: any;
  commandLength: any;
  constructor(cmd: any) {
    if (!cmd) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return new Error('vCommand is empty')
    }

    const cmdLength = cmd.length;

    if (cmdLength === 1 && cmd === '.') {
      this.origin = {
        hex: cmd,
        buffer: Buffer.from(cmd, 'hex')
      };
      this.command = cmd;
      this.commandLength = 0;
      this.commandValue = { ...this.origin };
    } else {
      const isSystemCommand = cmdLength < 5;
      const cleanedCmd = cmd.substring(1, cmdLength > 5 && cmd[cmdLength - 1] === '.' ? cmdLength - 1 : cmdLength);
      const cleanedCmdLength = cleanedCmd.length;

      this.origin = {
        hex: cmd,
        buffer: Buffer.from(cmd, 'hex')
      };
      this.command = parseInt(cleanedCmd.slice(0, isSystemCommand ? 1 : 2), 16);
      this.commandLength = parseInt(isSystemCommand ? 0 : cleanedCmd.slice(2, 4), 16);
      this.commandValue = {
        hex: isSystemCommand ? '' : cleanedCmd.slice(4, cleanedCmdLength),
        buffer: isSystemCommand ? [] : Buffer.from(cleanedCmd.slice(4, cleanedCmdLength), 'hex')
      };
    }
  }

  getOrigin() {
    return this.origin;
  }

  getCommand() {
    return this.command;
  }

  getCommandLength() {
    return this.commandLength;
  }

  getCommandValue() {
    return this.commandValue;
  }

  getBitmapOf(offset: number, bites = 32) {
    const buffer = _.get(this.commandValue, 'buffer', []);

    if (offset < buffer.length) {
      const int = this.commandValue.buffer.readUInt8(offset);

      return [...Array(bites)].map((_, index) => (int >> index) & 1);
    }

    return null;
  }


}
