import React from 'react';
import _ from 'lodash';
import { storeType } from '../types';

export default function CompareRow(data: storeType, keysList?: string []){
  const keys = keysList ? keysList : _.filter(_.keys(data.data),
    key => !_.includes(['changedKeys', 'age'], key));

  const isNewAndOLd = data?.data?.isNew && data?.data?.isOld;

  return (
    <React.Fragment
      key={data?.rowIndex}
    >
        <tr className="main-row" style={{
            backgroundColor: `${data?.data?.changedKeys?.length === 0 && !isNewAndOLd
              ? 'rgba(92,184,92,.15' : 'inherit'}`,
            color: `${data?.data?.changedKeys?.length === 0 && !isNewAndOLd ? '#3c943c' : 'inherit'}`,
            fontWeight: `${data?.data?.changedKeys?.length === 0 && !isNewAndOLd ? 'bold' : 'inherit'}`
        }}>
            {
              _.map(keys, key => {
                return(
                  <td
                    key={key}
                    style={{
                     height: '96px',
                     borderBottom: '1px solid rgba(0, 0, 0, .12)',
                     backgroundColor:
                       `${_.includes(data.data.changedKeys, key) ?
                         data.data.age ?
                           'rgba(217,83,79,.3)' : 'rgba(92,184,92,.3)' : 'inherit'}`,
                     fontWeight: `${_.includes(data.data.changedKeys, key) ? 'bold' : 'inherit'}`,
                     color: `${_.includes(data.data.changedKeys, key) ?
                         data.data.age ? '#d9534f' : '#3c943c' : 'inherit'}`

                    }}
                  >
                    {data.data[key] === null || data.data[key] === undefined ? "no data" : _.toString(data.data[key])}
                  </td>
                )
              })
            }
        </tr>
    </React.Fragment>
  )
}