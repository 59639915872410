import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home';
import Sign from './pages/dashboard/sign';
import Parking from './pages/dashboard/parking';
import TCam from './pages/dashboard/tcam';
import DashboardHome from './pages/dashboard/home';
import DataColectors from './pages/dashboard/dataColectors';
import Vms from './pages/dashboard/vms';
import SyncLog from './pages/dashboard/sign/syncLog';
// import OcrServices from './pages/ocr/services';
import UnassignedSigns from './pages/find-unassigned-devices/signs';
import LoginToWD from './pages/login-to-wd';
// import OcrServicesConfig from './pages/ocr/services/configs';
import Companies from './pages/account-management/companies';
import CompanySigns from './pages/account-management/companies/signs/Signs';
import CompanyVms from './pages/account-management/companies/vms/Vms';
import CompanyCameras from './pages/account-management/companies/tcam/Cameras';
import CompanyDc from './pages/account-management/companies/dc/Dc';
import CompanyOcrStatuses from './pages/account-management/companies/tcam/OcrStatuses';
import SignCommands from './pages/dashboard/sign/commands';
// import UrlMasks from './pages/ocr/url-masks';
// import Urls from './pages/ocr/urls';
// import CompanyDeviceAudit from './pages/account-management/companies/audit/Audit';
import CompanyAudit from './pages/account-management/companies/audit/CompanyAudit';
import SignsAudit from './pages/account-management/companies/audit/SignsAudit';
import CompanyParking from './pages/account-management/companies/parking/vparking/Parking';
import UsersAudit from './pages/account-management/companies/audit/UsersAudit';
import FindUnassignedDevices from './pages/find-unassigned-devices';
import CompaniesUsers from './pages/companies-users';
import NoMatch from './pages/no-match';
import CompanyCameraSettings from './pages/account-management/companies/tcam/CameraSettings';
import Overview from './pages/overview';
import Reports from './pages/reports';
import CompanyTokens from './pages/account-management/companies/tokens/Tokens';
import Users from './pages/users';
import { CompanyControllers } from './pages/account-management/companies/parking/controller/Controllers';

const routes = [
  { path: '/home', element: <Home /> },
  { path: '/', element: <Home /> },
  { path: '/dashboard', element: <DashboardHome /> },
  { path: '/sign', element: <Sign /> },
  { path: '/sign/sync-log/:id', element: <SyncLog /> },
  { path: '/sign-commands/:id', element: <SignCommands /> },
  { path: '/tcam', element: <TCam /> },
  { path: '/parking', element: <Parking /> },
  { path: '/dc', element: <DataColectors /> },
  { path: '/vms', element: <Vms /> },
  // { path: '/ocr-services/list', element: <OcrServices /> },
  // { path: '/ocr-config/list/:id', element: <OcrServicesConfig /> },
  // { path: '/ocr-urls-masks/list', element: <UrlMasks /> },
  // { path: '/ocr-urls/list', element: <Urls /> },
  { path: '/sign-lost', element: <UnassignedSigns /> },
  { path: '/logintowd', element: <LoginToWD /> },
  { path: '/companies/list', element: <Companies /> },
  { path: '/companies/signs/list/:id', element: <CompanySigns /> },
  { path: '/companies/v-parkings/list/:id', element: <CompanyParking /> },
  { path: '/companies/parking-controllers/list/:id', element: <CompanyControllers /> },
  { path: '/companies/vms/list/:id', element: <CompanyVms /> },
  { path: '/companies/tcam/list/:id', element: <CompanyCameras /> },
  { path: '/companies/dc/list/:id', element: <CompanyDc /> },
  { path: '/companies/tcam/ocr/list/:id', element: <CompanyOcrStatuses /> },
  { path: '/companies/tcam/settings/:id', element: <CompanyCameraSettings /> },
  { path: '/aws/apikey/list/:id', element: <CompanyTokens /> },
  // { path: '/companies/device/audit/:id', element: <CompanyDeviceAudit /> },
  { path: '/companies/:id/audit', element: <CompanyAudit /> },
  { path: '/signs/:id/audit/:companyId', element: <SignsAudit /> },
  { path: '/find-unassigned-device', element: <FindUnassignedDevices /> },
  { path: '/companies/users/list/:id', element: <CompaniesUsers /> },
  { path: '/companies/:companyId/users/:userId/audit', element: <UsersAudit /> },
  { path: '/reports', element: <Reports /> },
  { path: '/overview', element: <Overview /> },
  { path: '/users', element: <Users /> },
  { path: '*', element: <NoMatch /> },
];

const AdminRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AdminRoutes;
