import { Box, IconButton, Switch, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import InputIcon from '@mui/icons-material/Input';
import DvrIcon from '@mui/icons-material/Dvr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Table from '../../../../components/Table';

import { Wrapper } from './styles';
import Status from '../../../../components/Table/Columns/Status';
import Value from '../../../../components/Table/Columns/Value';
import { useMemo, useState } from 'react';
import GenericModal from '../../../../components/generic/modal';
import ShowInfoModal from './ShowInfo';
import { fullFiltersOverview, generalFilters, lowBatteryFilter } from '../filters';
import { MODULES } from '../../../../constants/Modules';
import { USERROLES } from '../../../../constants/UserRoles';
import { useAuth } from '../../../../context/auth';

export default function Sign() {
  const { hasRoleAccess } = useAuth();
  const [signId, setSignId] = useState<number | null>(null)

  const showInfo = (id: number) => () => {
    setSignId(id);
  };
  const renderChild = (value: number | string | null) => value ? value : 'no data';

  const table = useMemo(
    () => (
      <Table
        title="Signs"
        exportData={{
          csv: {
            url: `/devices/${MODULES.MODULE_SIGN}/export-csv`
          }
        }}
        filter={{
          inputs: [
            ...generalFilters(MODULES.MODULE_SIGN),
            lowBatteryFilter,
            ...fullFiltersOverview
          ]
        }}
        options={{
          moduleName: 'sign',
          apiUrl: '/sign/search',
          dataSourceKey: 'items',
          dataStoreKey: 'id',
          searchKey: ''
        }}
        columns={[
          {
            name: '',
            caption: '',
            width: '100px',
            cellRender: ({ data }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                {hasRoleAccess([
                  [USERROLES.ROLE_SUPER_ADMIN],
                  [USERROLES.ROLE_POWER_USER]
                ]) && (
                  <Link to={`/sign-commands/${data.id}`}>
                    <Tooltip title='Commands'>
                      <IconButton data-test={`commandsTable_${data.id}`} size="small" aria-label="sort">
                        <InputIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}

                <Tooltip title='Show info'>
                  <IconButton data-test={`infoTable_${data.id}`} onClick={showInfo(data.id)} size="small">
                    <DvrIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                
                {hasRoleAccess([
                  [USERROLES.ROLE_SUPER_ADMIN],
                  [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_RESTRICTED],
                  [USERROLES.ROLE_POWER_USER],
                  [USERROLES.ROLE_POWER_USER, USERROLES.ROLE_RESTRICTED],
                ]) && (
                  <Link to={`sync-log/${data.locationId}`}>
                    <Tooltip title='Show Communication log'>
                      <IconButton data-test={`logTable_${data.id}`}  size="small">
                        <AccessTimeIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}
                
              </Box>
            ),
          },
          {
            caption: 'Acc. Number',
            dataField: 'companyId',
            calculateSortValue: 'companyId',
            width: '5%',
            cellRender: ({ data }) => <Value data={data.companyId}/>
          },
          {
            caption: 'Device Serial',
            dataField: 'deviceSerial',
            calculateSortValue: 'deviceSerial',
            width: '6%',
            cellRender: ({ data }) => <Value data={data.deviceSerial}/>
          },
          {
            dataField: 'model',
            caption: 'Model',
            calculateSortValue: 'model',
            width: '6%',
            cellRender: ({ data }) => <Value data={data.model}/>
          },
          {
            dataField: 'location',
            caption: 'Location',
            calculateSortValue: 'location',
            width: '8%',
            cellRender: ({ data }) => <Value data={data.location}/>
          },
          {
            dataField: 'companyName',
            caption: 'Company',
            calculateSortValue: 'companyName',
            width: '8%',
            cellRender: ({ data }) => <Value data={data.companyName}/>
          },
          {
            caption: 'State',
            dataField: 'state',
            calculateSortValue: 'state',
            width: '5%',
            cellRender: ({ data }) => <Value data={data.state}/>
          },
          {
            caption: 'CC Serial',
            dataField: 'ccSerial',
            calculateSortValue: 'ccSerial',
            width: '6%',
            cellRender: ({ data }) => <Value data={data.ccSerial}/>
          },
          {
            caption: 'SIM',
            dataField: 'sim',
            calculateSortValue: 'sim',
            width: '6%',
            cellRender: ({ data }) => <Value data={data.sim}/>
          },
          {
            caption: 'Contact e-mail',
            dataField: 'contactEmail',
            calculateSortValue: 'contactEmail',
            width: '9%',
            cellRender: ({ data }) => <Value data={data.contactEmail}/>
          },
          {
            caption: 'Renewal date',
            dataField: 'subscriptionExpiresAt',
            calculateSortValue: 'subscriptionExpiresAt',
            width: '10%',
            cellRender: ({ data }) => <Value data={data.subscriptionExpiresAt}/>
          },
          {
            caption: 'Last comm.',
            dataField: 'last',
            calculateSortValue: 'lastCommunicatedAt',
            width: '12%',
            cellRender: ({ data }) => (
              <Status
                success={!!data.timeStatus}
                disabled={!data.lostCommunication}
              >
                {renderChild(data.lastCommunicatedAt)}
              </Status>
            ),
          },
          {
            dataField: 'battery',
            caption: 'Battery',
            calculateSortValue: 'battery',
            width: '8%',
            alignment: 'left',
            cellRender: ({ data }) => (
              <Status
                success={!!data.batteryStatus}
                disabled={!data.lostCommunication}
              >
                {`${renderChild(data.battery)} / ${data.normalVoltage}v`}
              </Status>
            ),
          },
          {
            type: 'buttons',
            name: '',
            caption: '',
            width: '4%',
            alignment: 'left',
            cssClass:'padding-left-1',
            cellRender: ({ data }) => <Switch disabled={true} data-test={`switchDevice_${data.id}`}/>,
          },
        ]}
      />
    ),
    [hasRoleAccess]
  );

  return (
    <Wrapper  className="sign" data-test="sign">
      {table}
      <GenericModal size="lg" closeButton show={signId !== null} onClose={() => setSignId(null)}>
        <ShowInfoModal id={signId}></ShowInfoModal>
      </GenericModal>
    </Wrapper>
  );
}
