function checkAccessRights(accessToCheck: string, userRoles: Array<number>) {
  for (const access of userRoles) {
    if (accessToCheck.includes(access.toString())) {
      return true;
    }
  }

  return false;
}

export function hasAccessRights(
  accessToCheck: string,
  userRoles: Array<number> = []
) {
  return checkAccessRights(accessToCheck, userRoles);
}