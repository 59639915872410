import React, { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../../assets/img/Logo-ST.png';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  NavBar,
  NavWrapper,
  StyledMenuLink,
  StyledNavItem,
  StyledNavLink,
  StyledSubNavLink,
} from './styles';
import { USERROLES } from '../../../constants/UserRoles';
import { Link, useLocation } from 'react-router-dom';
import { useFiltersContext } from '../../generic/filter/context';
import Filters from '../../generic/filter';
import { useAuth } from '../../../context/auth';
import { MODULES } from '../../../constants/Modules';
import GenericModal from '../../generic/modal';
import UserProfileContent from './modalComponents/UserProfileContent';
import AboutContent from './modalComponents/AboutContent';
import Snackbar from '@mui/material/Snackbar/Snackbar';

export default function HeaderNavigation() {
  const { state: { isAuthenticated, user }, logout } = useAuth();
  const {
    state: { isShown: isFiltersShown },
  } = useFiltersContext();

  const [drawerStatus, setDrawerStatus] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean | string>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerStatus((x) => !x);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const hideAndShowBurgerAndDotIcon = useCallback(() => {
    if (isAuthenticated) {
      return true;
    }
    return false;
  }, [isAuthenticated]);

  type USERROLEIDs = typeof USERROLES[keyof typeof USERROLES];

  type UserNavigationType = {
    label: string;
    icon?: string;
    uri: string;
    emptyURI?: boolean;
    roles?: USERROLEIDs[] | null;
    hasAccess?: () => boolean;
    onClick?: () => void;
  };
  type HeaderNavigationType = {
    label: string;
    uri: string;
    roles?: USERROLEIDs[] | null;
    pages?: UserNavigationType[];
    hasAccess?: () => boolean;
  };
  let UserNavigation: UserNavigationType[] = useMemo(
    () => [
      {
        label: 'User profile',
        icon: '<i class="material-icons">perm_identity</i>',
        uri: '/profile',
        emptyURI: true,
        roles: null,
        onClick: () => setModalOpen('profile')
      },
      {
        label: 'User management',
        icon: '<i class="material-icons">supervisor_account</i>',
        uri: '/users',
        roles: [USERROLES.ROLE_SUPER_ADMIN],
      },
      {
        label: 'About Support Tool',
        icon: '<i class="material-icons">fingerprint</i>',
        uri: '',
        emptyURI: true,
        roles: null,
        onClick: () => setModalOpen('version')
      },
      {
        label: 'Logout',
        icon: '<i class="material-icons">exit_to_app</i>',
        uri: '/',
        roles: null,
        onClick: logout,
      },
    ],
    [logout]
  );

  let HeaderNavigation: HeaderNavigationType[] = useMemo(
    () => [
      {
        label: 'Overview',
        uri: '/overview',
        roles: null
      },
      {
        label: 'Dashboard',
        uri: '/dashboard',
        roles: null,
        pages: [
          {
            label: 'Signs',
            uri: '/sign',
            icon: '/media/img/sign-page-icon.png',
            roles: [
              USERROLES.ROLE_SUPER_ADMIN,
              USERROLES.ROLE_POWER_USER,
              USERROLES.ROLE_USER,
              USERROLES.ROLE_STAGING,
            ],
            hasAccess: () =>
              user?.access_to_modules.indexOf(MODULES.MODULE_SIGN) !==
              -1,
          },
          {
            label: 'Data Collectors',
            uri: '/dc',
            roles: [
              USERROLES.ROLE_SUPER_ADMIN,
              USERROLES.ROLE_POWER_USER,
              USERROLES.ROLE_USER,
              USERROLES.ROLE_STAGING,
            ],
            hasAccess: () =>
                user?.access_to_modules.indexOf(MODULES.MODULE_DC) !== -1,
          },
          {
            label: 'VMS',
            uri: '/vms',
            roles: [
              USERROLES.ROLE_SUPER_ADMIN,
              USERROLES.ROLE_POWER_USER,
              USERROLES.ROLE_USER,
              USERROLES.ROLE_STAGING,
            ],
            hasAccess: () =>
                user?.access_to_modules.indexOf(MODULES.MODULE_VMS) !== -1,
          },
          {
            label: 'Cameras',
            uri: '/tcam',
            icon: '/media/img/cameras-icon.png',
            roles: [
              USERROLES.ROLE_SUPER_ADMIN,
              USERROLES.ROLE_STAGING,
              USERROLES.ROLE_USER,
              USERROLES.ROLE_POWER_USER
            ],
            hasAccess: () =>
              user?.access_to_modules.indexOf(MODULES.MODULE_TCAM) !==
              -1,
          },
          {
            label: 'Parking',
            uri: '/parking',
            icon: '/media/img/parking.png',
            roles: [USERROLES.ROLE_SUPER_ADMIN, USERROLES.ROLE_STAGING],
            hasAccess: () =>
              user?.access_to_modules.indexOf(
                MODULES.MODULE_PARKING
              ) !== -1,
          }
        ],
      },
      {
        label: 'Reports',
        uri: '/reports',
        roles: [
          USERROLES.ROLE_SUPER_ADMIN,
          USERROLES.ROLE_USER,
          USERROLES.ROLE_POWER_USER,
          USERROLES.ROLE_RESTRICTED
        ],
      },
      // {
      //   label: 'Ocr',
      //   uri: '/ocr',
      //   roles: null,
      //   pages: [
      //     {
      //       label: 'Services',
      //       uri: '/ocr-services/list',
      //       icon: '/media/img/services.png',
      //       roles: [USERROLES.ROLE_SUPER_ADMIN],
      //       hasAccess: () =>
      //         user?.roles.indexOf(USERROLES.ROLE_RESTRICTED) === -1,
      //     },
      //     {
      //       label: 'Urls',
      //       uri: '/ocr-urls/list',
      //       icon: '/media/img/services.png',
      //       roles: [USERROLES.ROLE_SUPER_ADMIN],
      //       hasAccess: () =>
      //         user?.roles.indexOf(USERROLES.ROLE_RESTRICTED) === -1,
      //     },
      //     {
      //       label: 'Urls Masks',
      //       uri: '/ocr-urls-masks/list',
      //       icon: '/media/img/services.png',
      //       roles: [USERROLES.ROLE_SUPER_ADMIN],
      //       hasAccess: () =>
      //         user?.roles.indexOf(USERROLES.ROLE_RESTRICTED) === -1,
      //     },
      //   ],
      // },
      {
        label: 'Find unassigned devices',
        uri: '/find-unassigned-device',
        roles: null,
        pages: [
          {
            label: 'Signs',
            uri: '/sign-lost',
            icon: '/media/img/sign-page-icon.png',
            roles: [
              USERROLES.ROLE_SUPER_ADMIN,
              USERROLES.ROLE_POWER_USER,
              USERROLES.ROLE_STAGING,
            ],
            hasAccess: () =>
              user?.access_to_modules.indexOf(MODULES.MODULE_SIGN) !== -1,
          },
          // {
          //   label: 'Lost Events',
          //   uri: '/sign/lost-events',
          //   icon: '/media/img/sign-page-icon.png',
          //   roles: [
          //     USERROLES.ROLE_SUPER_ADMIN,
          //     USERROLES.ROLE_POWER_USER,
          //     USERROLES.ROLE_STAGING,
          //   ],
          //   hasAccess: () => user?.access_to_modules.indexOf(MODULES.MODULE_SIGN) !== -1
          //     && user?.roles.indexOf(USERROLES.ROLE_RESTRICTED) === -1
          // },
        ],
      },
      {
        label: 'Login to WD',
        uri: '/logintowd',
        roles: [
          USERROLES.ROLE_SUPER_ADMIN,
          USERROLES.ROLE_POWER_USER,
          USERROLES.ROLE_STAGING,
        ],
      },
      {
        label: 'Accounts Management',
        uri: '/companies/list',
        roles: [
          USERROLES.ROLE_SUPER_ADMIN,
          USERROLES.ROLE_POWER_USER,
          USERROLES.ROLE_USER,
          USERROLES.ROLE_STAGING,
        ],
      },
    ],
    [user]
  );

  const isRoleAllowed = useCallback(
    (userRole: number[] | undefined, allowedRoles?: number[]) => {
      if (!allowedRoles || !userRole) {
        return true;
      }

      return allowedRoles.some(role => userRole?.includes(role))
    },
    []
  );

  const isAllowedNavigation = useCallback(
    (navigationList: HeaderNavigationType[]) => {
      const userRole = user?.roles;
  
      return navigationList.reduce((allowedNavList: HeaderNavigationType[], nav) => {
        if (nav.pages) {
          const filteredPages = isAllowedNavigation(nav.pages);
          if (filteredPages.length > 0) {
            allowedNavList.push({ ...nav, pages: filteredPages });
          }
          return allowedNavList;
        }

        const hasRoleAccess = nav.roles ? isRoleAllowed(userRole, nav.roles) : true;
        const hasAccess = typeof nav.hasAccess === 'function' ? nav.hasAccess() : true;

        if (hasRoleAccess && hasAccess) {
          allowedNavList.push(nav);
        }
  
        return allowedNavList;
      }, []);
    },
    [user, isRoleAllowed]
  );


  HeaderNavigation = useMemo(() => {
    return isAllowedNavigation(HeaderNavigation);
  }, [isAllowedNavigation, HeaderNavigation]);

  UserNavigation = useMemo(() => {
    return isAllowedNavigation(UserNavigation);
  }, [isAllowedNavigation, UserNavigation]);

  const sidebar = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      data-test={'sidebar'}
    >
      <Box sx={{ position: 'relative', height: 160 }}>
        <img
          src={logo}
          height={75}
          alt="logo"
          style={{
            position: 'absolute',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            height: 75,
          }}
        />
      </Box>
      <Divider sx={{ borderColor: '#4a89dc' }}></Divider>

      <List>
        {HeaderNavigation.map((navigation) => (
          <StyledSubNavLink to={navigation.uri} end key={navigation.label}>
            <ListItem key={navigation.label} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={navigation.label}
                  sx={{ color: '#757575' }}
                />
              </ListItemButton>
            </ListItem>
          </StyledSubNavLink>
        ))}
        {UserNavigation.map((navigation) => (
          navigation.emptyURI ?
          <ListItem key={navigation.label} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={navigation.label}
                sx={{ color: '#757575', padding: '0 16px'}}
                onClick={navigation.onClick}
              />
            </ListItemButton>
          </ListItem> :
            <StyledSubNavLink to={navigation.uri} end key={navigation.label}>
              <ListItem key={navigation.label} disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={navigation.label}
                    sx={{ color: '#757575' }}
                    onClick={navigation.onClick}
                  />
                </ListItemButton>
              </ListItem>
            </StyledSubNavLink>
        ))}
      </List>
    </Box>
  );

  function GetSubMenu(props: { menu: HeaderNavigationType }): JSX.Element {
    const { menu } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const location = useLocation();

    return (
      <StyledMenuLink
        className={
          menu.pages?.map((x) => x.uri).includes(location.pathname)
            ? 'active'
            : ''
        }
        key={menu.label}
      >
        <span onClick={handleClick} style={{ display: 'inline-block' }}>
          {menu.label}
        </span>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          style={{ padding: 0 }}
        >
          {menu.pages?.map((x) => (
            <MenuItem
              key={x.label}
              onClick={handleClose}
              style={{ padding: '0 12px' }}
            >
              <StyledSubNavLink end to={x.uri}>
                {x.label}
              </StyledSubNavLink>
            </MenuItem>
          ))}
        </Menu>
      </StyledMenuLink>
    );
  }

  function renderModalContent(){
    switch (modalOpen) {
      case 'profile':
        return (
          <UserProfileContent
            onClose={(msg) => {
              setSnackbarMessage(msg);
              setModalOpen(false);
            }}
          />
        );
      case 'version':
        return (<AboutContent onClose={() => setModalOpen(false)}/>);
      default:
        return ''
    }
  }

  return (
    <>
      <Box data-test={'header'}>
        <AppBar
          position="static"
          sx={{
            bgcolor: '#fff',
            color: '#757575',
            opacity: 1,
            height: 64,
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }} data-test={'tool-bar'}>
            {hideAndShowBurgerAndDotIcon() && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon>Toggle</MenuIcon>
              </IconButton>
            )}

            <NavWrapper
              data-test={'nav-wrapper'}
              style={{
                justifyContent: hideAndShowBurgerAndDotIcon()
                  ? 'space-between'
                  : 'center',
              }}
            >
              <Link to={'/'}  data-test={'link-home-page'}>
                <img src={logo} alt="app-logo" title="logo" />
              </Link>
              {hideAndShowBurgerAndDotIcon() && (
                <>
                  {!isFiltersShown ? (
                    <NavBar>
                      {HeaderNavigation.map((navigation) =>
                        navigation.pages ? (
                          <GetSubMenu
                            key={navigation.label}
                            menu={navigation}
                          />
                        ) : (
                          <StyledNavLink
                            to={`${navigation.uri}`}
                            key={navigation.label}
                          >
                            {navigation.label}
                          </StyledNavLink>
                        )
                      )}
                    </NavBar>
                  ) : (
                    <Filters />
                  )}
                </>
              )}
            </NavWrapper>

            <Box sx={{ width: '50px' }}>
              {hideAndShowBurgerAndDotIcon() && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2, margin: 0 }}
                  id="basic-button"
                  aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? 'true' : undefined}
                  onClick={handleMenuIconClick}
                >
                  <MoreIcon />
                </IconButton>
              )}
              <Menu
                id="basic-menu"
                data-test={'basic-menu'}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}

                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {UserNavigation.map((x) => (
                  <MenuItem
                    sx={{padding: 0}}
                    key={x.label}
                    onClick={() => {
                      handleMenuClose();
                      x.onClick?.();
                    }}>
                      {x.emptyURI
                        ? <StyledNavItem>{x.label}</StyledNavItem>
                        : <StyledSubNavLink to={`${x.uri}`} end>
                            {x.label}
                          </StyledSubNavLink>}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <GenericModal
        show={Boolean(hideAndShowBurgerAndDotIcon() && modalOpen)}
        onClose={() => setModalOpen(false)}
        customContentStyles={{ padding: 0 }}
      >
        {renderModalContent()}
      </GenericModal>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarMessage('')}
      />
      {hideAndShowBurgerAndDotIcon() && (
        <Drawer
          data-test={'drawer'}
          open={drawerStatus}
          onClose={toggleDrawer}
        >
          {sidebar}
        </Drawer>
      )}
    </>
  );
}
