import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import { StyledDialogTitle } from './styles';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

type Props = {
  fullWidth?: boolean;
  show: unknown;
  closeButton?: boolean;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  customContentStyles?: Record<string, string | number>;
};

const Modal = ({
  show,
  title,
  children,
  onClose,
  closeButton,
  fullWidth = true,
  size = 'sm',
  customContentStyles = {padding: 0}
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsOpen(!!show);
  }, [show])

  const handleAction = (callback?: () => void) => {
    setIsOpen(false);

    if (_.isFunction(callback)) {
      callback();
    }
  }

  return (
    <Dialog
      // fullScreen={!fullWidth ? fullScreen : false}
      maxWidth={size}
      open={isOpen}
      // sx={{ width: '100%' }}
      onClose={() => handleAction(onClose)}
      fullWidth={fullWidth}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledDialogTitle>
        {title}
        {closeButton && (
          <IconButton
            aria-label="close"
            onClick={() => handleAction(onClose)}
            data-test={'closeDialog'}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 9,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </StyledDialogTitle>
      <DialogContent sx={customContentStyles ? { ...customContentStyles } : {}}>
        <Box>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
