import React from 'react';
import ScheduleInfoTable from '../../../../../../components/schedulesTable';

import {
  ScheduleInfoTitle
} from '../styles';

const ScheduleInfo = ({ data } : { data: any }) => {

  return (
    <div style={{marginLeft: '-20px'}} data-test={'schedule-info'}>
      <ScheduleInfoTitle>Schedule</ScheduleInfoTitle>
      <ScheduleInfoTable data={data}/>
    </div>
  )
}

export default ScheduleInfo;