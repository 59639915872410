import React, { useState } from 'react';

import { SignControledProps } from './types';
import { Typography, Box } from '@mui/material';
import { CancelButton, SaveButton, ServiceModalWrap } from '../../../styles';
import { useDataGrid } from '../../../../../components/generic/dataGrid';
import { useForm } from 'react-hook-form';
import SignEditModalContent from './modalComponents/editModal-content';
import SignDeleteModalContent from './modalComponents/deleteModal-content';
import SignMoveModalContent from './modalComponents/moveModal-content';
import SignSubscribeModalContent from './modalComponents/subscibeModal-content';
import { MODULES } from '../../../../../constants/Modules';

export default function SignModalContent(
    { type, sign, values, signModelList, onClose, companyId }: SignControledProps
) {
    const { insert, update, move, errors } = useDataGrid();
    const defaultValues: any = values;
    const [ errorSerial, setErrorSerial ] = useState<string>('');

    const { handleSubmit, control, setValue } = useForm({
        mode: 'all',
        defaultValues,
    });

    const renderContent = () => {
      switch (type) {
        case 'edit':
        case 'create':
          return (
            <SignEditModalContent
              control={control}
              signModelList={signModelList}
              errorSerial={errorSerial}
              errors={errors}
              setValue={setValue}
            />
          );
        case 'move':
        case 'moveSelected':
        case 'moveAll':
          return (
            <SignMoveModalContent
              loadCompaniesUrl={type === 'move'
                ? `/companies/signs/move/${companyId}/${sign.id}`
                : `/companies/signs/move/bulk/${companyId}`}
              sign={sign}
              control={control}
              defaultCompany={{id: values.newCompanyId, name: values.newCompanyName}}
          />
          )
        case 'subscribe':
          return (
            <SignSubscribeModalContent
              sign={sign}
              control={control}
              setValue={setValue}
            />
        );
        case 'delete':
        default:
          return (<SignDeleteModalContent type={type}/>);
      }
    };

    const renderTitle = () => {
      switch (type) {
        case 'edit':
          return 'Edit sign';
        case 'create':
          return 'Add new sign';
        case 'delete':
          return 'Confirm action';
        case 'move':
          return 'Moving to company';
        case 'moveAll':
          return 'Moving all signs to company';
        case 'moveSelected':
          return 'Moving selected signs to company';
      }
    }

    const saveSignInfo = handleSubmit((values) => {
        const { id } = sign;

        try {
          switch (type) {
            case 'edit':
              update(id, values, onClose);
              break;
            case 'create':
              insert(values, onClose);
              break;
            case 'delete':
              move(`/companies/signs/delete/${companyId}`, {id}, onClose);
              break;
            case 'move':
              move(`/companies/signs/move/${companyId}/${id}`, values, onClose);
              break;
            case 'moveAll':
            case 'moveSelected':
              move(`/companies/signs/move/bulk/${companyId}/`, values, onClose);
              break;
            case 'subscribe':
              move(`/companies/subscription/${MODULES.MODULE_SIGN}/${companyId}/${id}`, values, onClose);
              break;
          }
        } catch (e: any) {
          setErrorSerial(e?.error?.message);
        }
    });

    return (
      <ServiceModalWrap>
        <form
          onSubmit={saveSignInfo}
        >
          <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
            {renderTitle()}
          </Typography>
          {renderContent()}

          <Box
            sx={{
              padding: '8px',
              display: 'flex',
              flexDirection: 'row-reverse',
              flexWrap: 'wrap',
              marginBottom: 5
            }}
          >
            <CancelButton onClick={onClose}>
              { type === 'delete' ? 'Disagree' : 'Cancel' }
            </CancelButton>
            <SaveButton type="submit" variant="contained">
              { type === 'delete' ? 'Agree' : 'Save' }
            </SaveButton>
          </Box>
        </form>
      </ServiceModalWrap>
    );
};
