import React, { useEffect, useState } from 'react';

import {
  ControllerDisplayLinesResponse,
  ControllerDisplayLinesType,
  ControllerDisplayType,
  ControllerType,
} from '../../types';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import {Controller, useWatch} from 'react-hook-form';
import _ from 'lodash';
import { ControllerTypes } from '../Controllers';
import { useApi } from '../../../../../../../context/api';


export default function ControllerEditModalContent(
    { control, controllerTypes, displayTypes, clearErrors }: any
) {
  const {  apiInstance } = useApi();
  
  const currenControllerType = useWatch({ control, name: "controller_type" })
  const [displayLines, setDisplayLines] = useState<ControllerDisplayLinesType[]>([]);

  useEffect(() => {
    clearErrors();
  }, [currenControllerType, clearErrors]);
  
  useEffect(() => {
    const getDisplayLines = async () => {
    try {
      const {display_lines}: ControllerDisplayLinesResponse = await apiInstance.get(
        `/companies/parking-controllers/filters/list-display-lines`
      );
      
      setDisplayLines(display_lines)
    } catch (e) {
      console.log(e);
    }
  }
    
    getDisplayLines();
  }, [apiInstance]);
  
  return (
    <>
      <Controller
        name="serial"
        control={control}
        render={({ field, fieldState: {invalid, error}}) => (
          <TextField
            sx={{ marginBottom: 7 }}
            inputProps={{
              maxLength: 8
            }}
            label="Serial number *"
            fullWidth
            variant="standard"
            error={invalid}
            helperText={error?.message}
            {...field}
          />
        )}
      />
      
      <Controller
        name="controller_type"
        control={control}
        render={({ field, fieldState: {invalid, error} }) => (
          <TextField
            select
            label='Type'
            sx={{ marginBottom: 7 }}
            fullWidth
            variant="standard"
            error={invalid}
            helperText={error?.message}
            {...field}
          >
            {controllerTypes?.map((type: ControllerType) => (
              <MenuItem value={type.id} key={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      
      {currenControllerType !== ControllerTypes.Sensor && (
        <Controller
          name="gsm_module"
          control={control}
          render={({ field, fieldState: {invalid, error}  }) => (
            <FormControl error={invalid} fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ height: '20px' }}
                    value={Number(field.value)}
                    checked={
                      Boolean(+field.value) ||
                      currenControllerType === ControllerTypes.MultiLotDisplay ||
                      currenControllerType === ControllerTypes.ParkTrak
                    }
                    onChange={(e) => field.onChange(_.toString(+e.target.checked))}
                    disabled={
                      currenControllerType === ControllerTypes.MultiLotDisplay ||
                      currenControllerType === ControllerTypes.ParkTrak
                    }
                  />
                }
                label='GSM Module'
                key=''
                sx={{ height: '20px', marginBottom: 7, width: '45%' }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      )}
      
      {(currenControllerType === ControllerTypes.Display ||
        currenControllerType === ControllerTypes.MultiLotDisplay) && (
          <>
            <Controller
              name="display_lines"
              control={control}
              render={({ field, fieldState: {invalid, error}}) => (
                <TextField
                  type="tel"
                  sx={{ marginBottom: 7 }}
                  inputProps={{
                    max: _.max(displayLines),
                    min: _.min(displayLines)
                  }}
                  label="Display line(s)"
                  fullWidth
                  variant="standard"
                  error={invalid}
                  helperText={error?.message}
                  {...field}
                  onChange={(e) => {
                    if (displayLines.indexOf(+e.target.value) == -1 && e.target.value.length !== 0) return;
                    
                    field.onChange(e)
                  }}
                />
              )}
            />
            <Controller
              name="display_type"
              control={control}
              render={({ field, fieldState: {invalid, error} }) => (
                <TextField
                  select
                  label='Display type'
                  sx={{ marginBottom: 7 }}
                  fullWidth
                  variant="standard"
                  error={invalid}
                  helperText={error?.message}
                  {...field}
                >
                  {displayTypes?.map((type: ControllerDisplayType) => (
                    <MenuItem value={type.id} key={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </>
      )}
      
      {currenControllerType === ControllerTypes.ParkTrak && (
        <>
          <Controller
            name="serial_PT"
            control={control}
            render={({ field, fieldState: {invalid, error}}) => (
              <TextField
                sx={{ marginBottom: 7 }}
                label="Serial"
                fullWidth
                variant="standard"
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            )}
          />
          
          <Controller
            name="access_key_PT"
            control={control}
            render={({ field, fieldState: {invalid, error}}) => (
              <TextField
                sx={{ marginBottom: 7 }}
                label="Access Key"
                fullWidth
                variant="standard"
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        </>
      )}
    </>
  );
};
