import DataGrid, { Column, Pager, IColumnProps } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import './dataGrid.scss';
import { GenericDataGridProps, CommonFields, ModuleResponseType } from './types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { useFiltersContext } from '../filter/context';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { useDataGrid } from './context';
import DataSource from 'devextreme/data/data_source';
import { toast } from 'react-toastify';
import { ChangedOptionInfo } from 'devextreme/events';
import { useApi } from '../../../context/api';
import { RequestMethod } from '../../../context/api/types';
import { ActionType } from '../filter/context/actions';

const GenericDataGrid = ({
  columns,
  isPagerRequired = true,
  withPaging = true,
  withSorting = true,
  pageSize = 10,
  noDataText = 'No matching records found',
  onDataChange,
  dataFormatter,
  options,
}: GenericDataGridProps) => {
  const { state: { inputs, dataGridRef: _filterDataGrid }, destroy, dispatch } = useFiltersContext();
  const { dataSource, setDataSource } = useDataGrid();
  const [searchParams, setSearchParams] = useSearchParams();
  const { apiInstance } = useApi();
  const sortParam = searchParams.get('sort');
  const dataGridRef = useRef(null);

  const loadData = useCallback(async (loadOptions: LoadOptions): Promise<{ data: unknown[]; totalCount: number }> => {
    const params = new URLSearchParams({});
    if (withPaging && loadOptions.skip) {
      params.append('page', String(Math.floor(loadOptions.skip / pageSize) + 1));
    }

    if (withSorting && loadOptions.sort) {
      const sortOpt = loadOptions.sort as { selector: string, desc: boolean }[];
      params.append('sort', `${sortOpt[0].desc ? '-' : ''}${sortOpt[0].selector}`);
    }

    let queryString = params.toString();
    let rangeDatePicker: string [] = [];
    let filtersName: any [] = inputs.flat().map((input) => {
      if (input.type === 'rangeDatePicker') {
        rangeDatePicker = [input.startName, input.endName];
        return input.name;
      } else {
        return input.name;
      }
    });
    filtersName = filtersName.concat(rangeDatePicker);
    const parsedSearch = qs.parse(location.search.substring(1));

    for (const key in parsedSearch) {
      if (!filtersName.includes(key)) {
        delete parsedSearch[key];
      }
    }

    if (_.size(parsedSearch)) {
      queryString += `&${options.searchKey ? options.searchKey : ''}${qs.stringify(parsedSearch)}`;
    }

    try {
      const url = `${options.apiUrl}${queryString ? '?' + queryString : ''}`;
      const response: ModuleResponseType & CommonFields = await apiInstance.get(url);
      const data = dataFormatter ? dataFormatter(response) : Object.values(_.get(response, options.dataSourceKey, {}));
      const totalCount = _.get(response, `${options.paginationKey}.pages`, response.pages) * pageSize;

      onDataChange && onDataChange(response);
      return { data, totalCount };
    } catch (err: any) {
      throw new Error(err?.message || err?.error?.message || 'Unknown error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, dataFormatter, onDataChange, options, pageSize, withPaging, withSorting]);

  const onOptionChanged = useCallback((event: ChangedOptionInfo) => {
    if (event.fullName) {
      const operationName = event.fullName.split('.');

      if (operationName && operationName[1]) {
        const operation = operationName[1].substring(0, 4);

        let formattedValue = '';

        switch (operation) {
          case 'sort': {
            const column = columns[+(event.fullName?.match(/\d+/g) || 1)];
            formattedValue =
              (event.value === 'desc' ? '-' : '') + (column.calculateSortValue || column.dataField);
            break;
          }
          case 'page':
            formattedValue = `${+event.value + 1}`;
            break;
          default:
            break;
        }

        if (formattedValue) {
          setSearchParams((params => {
            params.delete(operation);
            params.append(operation, formattedValue);

            return params
          }));
        }
      }
    }
  }, [columns, setSearchParams]);

  const crudRequest = async (
    reqOptions: { method: RequestMethod, url: string },
    values?: unknown
  ) => {
    try {
      const res = await apiInstance[reqOptions.method](reqOptions.url, values || undefined);
      toast.success(_.get(res, 'msg', 'Saved successfully'));
      return res;
    } catch (error) {
      toast.error(
        _.get(
          error, 'error.message', 'Error while saving. Please check the parameters'
        ));
      throw new Error(JSON.stringify(error));
    }
  };

  useEffect(() => {
    const customStoreOpts: /* StoreOptions & { load: any } */ any = {
      key: options.dataStoreKey,
      load: loadData,
      ...(options.insert &&
        { insert: (values: unknown) => crudRequest(options.insert!, values) }),
      ...(options.update &&
        { update: (key: number, values: unknown) =>
          crudRequest({ ...options.update!, url: `${options.update!.url}/${key}` }, values) }),
      ...(options.remove &&
        { remove: (key: number) => crudRequest({ ...options.remove!, url: `${options.remove!.url}/${key}` })}),
    };

    const dataSource = new DataSource({
      store: new CustomStore(customStoreOpts)
    });

    const pageNumber = _.toNumber(searchParams.get('page')) || 1;
    const defaultPageIndex = pageNumber - 1;
    dataSource.pageIndex(defaultPageIndex);

    setDataSource(dataSource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  useEffect(() => {
    if (dataGridRef.current && !_filterDataGrid?.current) {
      dispatch({
        type: ActionType.SetDataGridRef,
        payload: dataGridRef as unknown as {
          current: DataGrid<any, any>;
        }
      });
    }
  }, [dataGridRef, _filterDataGrid, dispatch]);

  useEffect(() => {
    return () => {
      destroy();
    }
  }, [destroy]);

  const columnComponent = useCallback((column: IColumnProps, index: number) => {
    const columnProps = { ...column };

    if (sortParam) {
      const sortType = sortParam?.charAt(0) === '-' ? 'desc' : 'asc';
      const sortField = sortType === 'desc' ? sortParam?.substring(1) : sortParam;

      if ((column.calculateSortValue || column.dataField) === sortField) {
        columnProps.defaultSortOrder = sortType;
      }
    }

    return (
      <Column
        key={`${columnProps.dataField}_${index}`}
        { ...columnProps }
      />
    )
  }, [sortParam]);

  return useMemo(
    () => (
      <DataGrid
        ref={dataGridRef}
        dataSource={dataSource}
        className={'genericDataGrid'}
        onOptionChanged={onOptionChanged}
        showBorders={true}
        noDataText={noDataText}
        columnAutoWidth={true}
        allowColumnReordering={true}
        remoteOperations={{
          paging: withPaging,
          sorting: withSorting,
        }}
        paging={{
          enabled: withPaging,
          pageSize: pageSize,
        }}
        pager={{
          showPageSizeSelector: false,
          showInfo: false,
          showNavigationButtons: true,
        }}
        rowAlternationEnabled={true}
        loadPanel={{
          showPane: true,
          showIndicator: true,
          text: 'Loading...',
        }}
        sorting={{mode: withSorting ? 'single' : 'none'}}
      >
        {columns.map(columnComponent)}
        {withPaging && isPagerRequired && (
          <Pager
            showPageSizeSelector={true}
            showNavigationButtons={true}
            allowedPageSizes={[10, 15]}
          />
        )}
      </DataGrid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataSource,
      columns,
      pageSize,
      isPagerRequired,
      columnComponent,
      onOptionChanged,
      withPaging,
      withSorting
    ]
  );
};

export default memo(GenericDataGrid);
