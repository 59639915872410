import { useState } from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AppsIcon from '@mui/icons-material/Apps';

import { DcMenuProps, DCModalType } from './types';
import { USERROLES } from '../../../../../constants/UserRoles';
import { useAuth } from '../../../../../context/auth';
import _ from 'lodash';
import { useApi } from '../../../../../context/api';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';
import { useDataGrid } from '../../../../../components/generic/dataGrid';

const CompanyDcMenuGeneral = ({ dc, handleModalOpen, emptySelection }: DcMenuProps) => {
  const { hasRoleAccess } = useAuth();
  const { loading} = useApi();
  const { dataSource } = useDataGrid();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  if (!hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]])) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (type: DCModalType) => {
    handleModalOpen(type, dc);
    handleClose();
  };

  const dcCount = dataSource?.items().length;

  return (
    <>
      {loading ? <LoadPannelLoader/> : ''}
      <Button
        color='secondary'
        onClick={() => handleModalOpen('create', '')}
      >
        + Add device
      </Button>
      <Button
        color='secondary'
        onClick={handleClick}
      >
        <AppsIcon fontSize='small' /> Actions
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChoose('moveAll')} disabled={!dcCount}>
          <ListItemIcon>
            <SwapHorizIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Move all to company</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleChoose('moveSelected')}
          disabled={!(_.size(emptySelection.current) > 1)}
        >
          <ListItemIcon>
            <SwapHorizIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Move selected to company</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CompanyDcMenuGeneral;
