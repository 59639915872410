import React from 'react';

import { Typography } from '@mui/material';

export default function UserDeleteModalContent({ type }: {type: string}) {
  const renderTitle = () => {
    switch (type) {
      case 'delete':
        return 'Are you sure you want to delete the user?';
      case 'reset':
        return 'Are you really want to reset user\'s agreement?';
      default:
        break;
    }
  };
  return (
    <Typography variant='subtitle1'  sx={{ marginBottom: 8 }} align='center'>
      {renderTitle()}
    </Typography>
    );
};