import { Checkbox, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Table from '../../../../../components/Table';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, useParams } from 'react-router-dom';
import {
  SignCompaniesResponse,
  SignModelEdit,
  SignModelsResponse,
  SelectData,
  SignLocationsResponse,
  SignSubscriptionResponse,
} from './types';
import GenericModal from '../../../../../components/generic/modal';
import SignModalContent from './sign-modal-content';
import SignsMenu from './SignsMenu';
import SignsMenuGeneral from './SignsMenuGeneral';
import _ from 'lodash';
import dayjs from 'dayjs';
import { STARTDATE_FORMAT } from '../../../../../constants';
import { MODULES } from '../../../../../constants/Modules';
import { useDataGrid } from '../../../../../components/generic/dataGrid';
import { useApi } from '../../../../../context/api';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';

export default function CompanySigns() {
  const { id } = useParams();
  const defaultSignValues = {
    serial: '',
    sign_model: 2,
    normal_voltage: '',
    beacon: 0,
    device_serial: '',
  };
  const { apiInstance } = useApi();
  const [sign, setSign] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [signModelList, setSignModelList] = useState<SelectData[]>([]);
  const [signValues, setSignValues] = useState<any>(defaultSignValues);
  const { setErrors } = useDataGrid();
  const selectedRowsRef = useRef<string[]>([]);
  const { hasRoleAccess } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const responseSignModels: SignModelsResponse = await apiInstance.get(
        `/companies/signs/list/filters/sign-models/`
      );

      if (_.size(responseSignModels?.signs_models) > 0) {
        setSignModelList(responseSignModels.signs_models.map((c) => ({ value: c.id, title: c.name })));
      }
    }

    fetchData();
  }, [apiInstance]);

  const handleModalOpen = useCallback(
    (type: string, sign: any) => {
      async function fetchSignData(signId: string) {
        if (_.isNumber(signId)) {
          const response: SignModelEdit = await apiInstance.get(`/companies/signs/edit/${id}/${signId}`);
          setSign(sign);
          setSignValues({
            beacon: +response.sign_info.beacon,
            normal_voltage: response.sign_info.normal_voltage,
            serial: response.sign_info.serial,
            sign_model: response.sign_info.sign_model,
            device_serial: response.sign_info.device_serial,
            sim: response.sign_info.sim,
          });
        }
      }

      async function fetchCompaniesData(sign: any) {
        const response: SignCompaniesResponse = await apiInstance.get(`/companies/signs/move/${id}/${sign.id}`);
        setSignValues({ newCompanyId: response.companies_list[0].id, newCompanyName: response.companies_list[0].name });
        setSign(sign);
      }

      async function fetchCompaniesMoveData(type: string) {
        const response: SignCompaniesResponse = await apiInstance.get(`/companies/signs/move/bulk/${id}`);
        setSignValues({
          newCompanyId: response.companies_list[0].id,
          newCompanyName: response.companies_list[0].name,
          selectAllCompanysSigns: +(type === 'moveAll'),
          signIds: selectedRowsRef.current,
        });
      }

      async function fetchSubscriptionData(sign: any) {
        const response: SignSubscriptionResponse = await apiInstance.get(
          `/companies/subscription/${MODULES.MODULE_SIGN}/${id}/${sign.id}`
        );
        setSign(response.device_info);
        setSignValues({
          start_subscription: dayjs().format(STARTDATE_FORMAT),
          end_subscription: dayjs().format(STARTDATE_FORMAT),
        });
      }

      function openModal() {
        setIsModalOpen(true);
      }

      setModalType(type);

      switch (type) {
        case 'edit':
        case 'create':
          fetchSignData(sign.id).then(() => openModal());
          break;
        case 'moveSelected':
        case 'moveAll':
          fetchCompaniesMoveData(type).then(() => openModal());
          break;
        case 'move':
          fetchCompaniesData(sign).then(() => openModal());
          break;
        case 'subscribe':
          fetchSubscriptionData(sign).then(() => openModal());
          break;
        case 'delete':
          setSign(sign);
          openModal();
          break;
        default:
          openModal();
          break;
      }
    },
    [id, setSignValues, apiInstance, selectedRowsRef]
  );

  const handleModalClose = () => {
    setErrors({});
    setSignValues(defaultSignValues);
    setIsModalOpen(false);
    setSign('');
  };

  const saveSelections = useCallback(
    (e: React.MouseEvent<HTMLElement>, id: string) => {
      const selectedRows = selectedRowsRef.current;
      const currentIndex = _.indexOf(selectedRows, id);

      if (currentIndex > -1) {
        selectedRowsRef.current.splice(currentIndex, 1);
      } else {
        selectedRowsRef.current.push(id);
      }
    },
    [selectedRowsRef]
  );

  const table = useMemo(
    () => {
      return (
        <Table
          backButton="/companies/list"
          title="Company signs "
          titleKey="company_name"
          toolbar={
            hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
              <SignsMenuGeneral
                sign={''}
                handleModalOpen={handleModalOpen}
                emptySelection={selectedRowsRef}
              />
            )
          }
          filter={{
            inputs: [
              {
                type: 'text',
                name: 'global',
                label: 'Global Search',
              },
              {
                type: 'text',
                name: 'serial',
                label: 'Serial Number',
              },
              {
                type: 'text',
                name: 'firmware_ver',
                label: 'Firmware Version',
              },
              [
                {
                  type: 'select',
                  name: 'location_id',
                  label: 'Locations',
                  fetch: {
                    url: `/companies/signs/list/filters/locations/${id}`,
                    formatter: (data: SignLocationsResponse) =>
                      data.locations.map((location) => ({
                        value: location.id,
                        title: location.name,
                      }))
                  },
                },
              ],
              [
                {
                  type: 'select',
                  name: 'device_model',
                  label: 'Models',
                  fetch: {
                    url: '/companies/signs/list/filters/sign-models/',
                    formatter: (data: SignModelsResponse) =>
                      data.signs_models.map((model) => ({
                        value: model.id,
                        title: model.name,
                      }))
                  },
                },
              ],
            ],
          }}
          options={{
            moduleName: 'companies/signs/list',
            apiUrl: `/companies/signs/list/${id}`,
            dataSourceKey: 'company_signs',
            dataStoreKey: 'id',
            insert: { method: 'post', url: `/companies/signs/add/${id}` },
            update: { method: 'post', url: `/companies/signs/edit/${id}` },
            remove: { method: 'post', url: `/companies/signs/delete/${id}` },
          }}
          columns={[
            {
              caption: '',
              visible: hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN]]) ,
              cellRender: (data: { data: any }) => (
                <Link
                  to={`/signs/${data.data.id}/audit/${id}`}
                  style={{
                    paddingTop: '6px',
                    display: 'block',
                    color: '#0000008a',
                  }}
                >
                  <AccessTimeIcon fontSize="small" color="inherit" />
                </Link>
              ),
              width: '3%',
            },
            {
              caption: '',
              cellRender: (data: { data: any }) => <Checkbox onClick={(e) => saveSelections(e, data.data.id)} />,
              width: '50px',
            },
            {
              dataField: 'deviceSerial',
              caption: 'Serial',
              width: '10%',
            },
            {
              dataField: 'firmware_ver',
              caption: 'Firmware version',
              width: '10%',
            },
            {
              dataField: 'device_model',
              caption: 'Sign model',
              width: '10%',
            },
            {
              dataField: 'location_name',
              caption: 'Location name',
              width: '14%',
              cellRender: (data: { data: any }) => (
                <>{data.data?.location_name?.length > 0 ? data.data.location_name : 'No assign to location'}</>
              ),
            },
            {
              dataField: 'group_name',
              caption: 'Group',
              width: '9%',
            },
            {
              dataField: 'serial',
              caption: 'CC Serial Number',
              width: '9%',
            },
            {
              dataField: 'sim',
              caption: 'SIM',
              width: '11%',
            },
            {
              dataField: 'normal_voltage',
              caption: 'Normal Battery voltage',
              width: '9%',
              alignment: 'center',
            },
            {
              dataField: 'beacon',
              caption: 'With TL Beacons System',
              width: '7%',
              alignment: 'center',
            },
            {
              ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && {
                ...{
                  caption: '',
                  cellRender: (data: { data: any }) => <SignsMenu sign={data.data} handleModalOpen={handleModalOpen} />,
                  width: '70px',
                },
              }),
            },
          ]}
        />
      );
    },
    [id, handleModalOpen, saveSelections, hasRoleAccess]
  );

  return (
    <>
      {table}

      <GenericModal show={isModalOpen} onClose={() => handleModalClose()}>
       <SignModalContent
         type={modalType}
         onClose={() => handleModalClose()}
         sign={sign}
         signModelList={signModelList}
         values={signValues}
         companyId={id as string}
       />
      </GenericModal>
    </>
  );
}
