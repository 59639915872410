import { Button, Container, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../../../components/Table';
import CompanyTcamMenu from './modal/CompanyTcamMenu';
import ModalContent from './modal/ModalCameras';
import _ from 'lodash';
import NavTabs from '../../../../../components/NavTabs';
import GenericModal from '../../../../../components/generic/modal';
import { useParams } from 'react-router-dom';
import { useDataGrid } from '../../../../../components/generic/dataGrid';
import { CameraResponseType, TcamLocationsResponse, TcamTypesResponse } from './types';
import { DEFAULT_CAMERA_TYPE } from '../../../../../constants/index';
import { SelectData } from '../vms/types';
import { useApi } from '../../../../../context/api';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';

export default function CompanyCameras() {
  const { id } = useParams();
  const defaultTcamValues = {
    normal_voltage: '',
    serial: '',
    type: DEFAULT_CAMERA_TYPE,
    sim: '',
  };

  const { apiInstance } = useApi();
  const [cameraId, setCameraId] = useState<string | number | null>(null);
  const [tcamValues, setTcamValues] = useState<any>(defaultTcamValues);
  const [type, setType] = useState<string | null>(null);
  const [tcamTypes, setTcamTypesList] = useState<SelectData[] | null>(null);

  const { setErrors } = useDataGrid();
  const { hasRoleAccess } = useAuth();
  
  useEffect(() => {
    async function fetchData() {
      const { tcam_types }: TcamTypesResponse = await apiInstance.get('/companies/tcam/list/filters/tcam-types');

      setTcamTypesList(tcam_types?.map((tt) => ({ value: tt.id, title: tt.name })) || []);
    }

    fetchData();
  }, [id, apiInstance]);

  const handleModalClose = () => {
    setErrors({});
    setType(null);
    setCameraId(null);
    setTcamValues(defaultTcamValues);
  };

  const handleModalOpen = useCallback(
    async (type: string, deviceId: string, tcam: { id: number }) => {
      async function fetchTcamData(tcam: { id: number }) {
        if (_.isNumber(tcam.id) && type === 'edit') {
          const response: CameraResponseType = await apiInstance.get(`companies/tcam/edit/${id}/${deviceId}`);
          setTcamValues({
            normal_voltage: response.tcam_info.normal_voltage,
            serial: response.tcam_info.serial,
            type: response.tcam_info.type,
            sim: response.tcam_info.sim,
          });
        }
      }

      await fetchTcamData(tcam);
      setCameraId(deviceId);
      setType(type);
    },
    [id, apiInstance]
  );

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        title="Company cameras "
        titleKey="company_name"
        toolbar={
          hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
            <Button color="secondary" onClick={() => setType('create')}>
              {' '}
              + Add Camera
            </Button>
          )
        }
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
            {
              type: 'text',
              name: 'serial',
              label: 'MAC Address',
            },
            {
              type: 'select',
              name: 'location',
              label: 'Locations',
              fetch: {
                url: `/companies/tcam/list/filters/locations/${id}`,
                formatter: (data: TcamLocationsResponse) =>
                  data.locations.map((location) => ({
                    value: location.id,
                    title: location.name,
                  }))
              },
            },
            {
              type: 'select',
              name: 'type',
              label: 'Types',
              fetch: {
                url: '/companies/tcam/list/filters/tcam-types/',
                formatter: (data: TcamTypesResponse) =>
                  data.tcam_types.map((model) => ({
                    value: model.id,
                    title: model.name,
                  }))
              },
            },
          ],
        }}
        options={{
          moduleName: 'companies/tcam/list',
          apiUrl: `companies/tcam/list/${id}`,
          dataSourceKey: 'company_tcam',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/companies/tcam/add/${id}` },
          update: { method: 'post', url: `/companies/tcam/edit/${id}` },
          remove: { method: 'post', url: `/companies/tcam/delete/${id}` },
        }}
        columns={[
          {
            caption: '',
          },
          {
            dataField: 'serial',
            caption: 'MAC Address',
            width: '20%',
            calculateSortValue: 'serial',
          },
          {
            dataField: 'location',
            caption: 'Location',
            width: '15%',
            calculateSortValue: 'location',
          },
          {
            dataField: 'groupName',
            caption: 'Group',
            width: '15%',
            calculateSortValue: 'groupName',
          },
          {
            dataField: 'sim',
            caption: 'SIM',
            width: '15%',
            calculateSortValue: 'sim',
          },
          {
            dataField: 'voltage',
            caption: 'Voltage',
            width: '15%',
            calculateSortValue: 'voltage',
            alignment: 'left',
          },
          {
            dataField: 'type',
            caption: 'Type',
            width: '15%',
            calculateSortValue: 'type',
          },
          {
              ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN],[USERROLES.ROLE_STAGING]]) && {
              ...{
                caption: '',
                cellRender: (data: { data: any }) => (
                  <CompanyTcamMenu handleModalOpen={handleModalOpen} signId={data.data.id} tcam={data.data} />
                ),
                width: '70px',
              },
            }),
          },
        ]}
      />
    );
  }, [id, handleModalOpen, hasRoleAccess]);

  const tabs = [
    {
      label: 'Cameras',
      value: '/companies/tcam/list/:id',
      to: `/companies/tcam/list/${id}`,
    },
    {
      label: 'OCR Statuses',
      value: '/companies/tcam/ocr/list/:id',
      to: `/companies/tcam/ocr/list/${id}`,
    },
    {
      label: 'TCAM Settings',
      value: '/companies/tcam/settings/:id',
      to: `/companies/tcam/settings/${id}`,
    },
  ];
  return (
    <>
      <Container maxWidth="xl">
        <NavTabs tabs={tabs} />
      </Container>
      
      {table}
      <GenericModal show={!!type} onClose={handleModalClose}>
        <ModalContent
          cameraId={cameraId}
          type={type}
          tcamTypes={tcamTypes || []}
          onClose={handleModalClose}
          values={tcamValues}
          deviceId={id}
        />
      </GenericModal>
    </>
  );
}
