import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import GenericModal from '../../../../../../components/generic/modal';
import Table from '../../../../../../components/Table';
import CompanyParkingMenu from '../ParkingMenu';
import ModalContent from './ModalContent';
import { useParams } from 'react-router-dom';
import { CompanyInfo, ParkingControllers, ParkingControllersResponse, ParkingEditResponse } from '../types';
import _ from 'lodash';
import { useApi } from '../../../../../../context/api';
import { useAuth } from '../../../../../../context/auth';
import { USERROLES } from '../../../../../../constants/UserRoles';
import { ParkingNav } from '../ParkingNav';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';

export default function CompanyParking() {
  const { hasRoleAccess } = useAuth();
  const { id } = useParams();
  const defaultParkingValues = {
    isHide: '',
    isGroupCount: false,
    name: '',
    levels: '1',
    assigned_parking_controllers: [],
    parkings: [],
    display_type: 'all',
    parkingChosenList: [],
  };
  const { apiInstance } = useApi();
  const { setErrors } = useDataGrid();
  const [parkingModelId, setParkingModelId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showSensor, setShowSensor] = useState<boolean>(false);
  const [parkingValues, setParkingValues] = useState<any>(defaultParkingValues);
  const [controllersList, setControllersList] = useState<ParkingControllers[]>([]);
  const [parkingsList, setParkingsList] = useState<any[]>([]);
  const [modalType, setModalType] = useState<string>('');

  const handleModalClose = () => {
    setParkingValues(defaultParkingValues);
    setIsModalOpen(false);
    setParkingModelId('');
    setErrors({});
  };

  const handleModalOpen = useCallback(
    (type: string, deviceId?: string) => {
      async function fetchCompaniesData() {
        const responseControllers: ParkingControllersResponse = await apiInstance.get(
          `/companies/parking-controllers/list/${id}/unassigned`
        );
        let simplifyAssignedList: ParkingControllers[] = [];
        if (deviceId) {
          const response: ParkingEditResponse = await apiInstance.get(`/companies/v-parkings/edit/${id}/${deviceId}`);
          simplifyAssignedList =
            response?.virtual_parking_info?.assigned_parking_controllers.map((c: ParkingControllers) => ({
              ...c,
              controller_type: c.controller_type_id,
            })) || [];
          const info = response.virtual_parking_info;

          setShowSensor(info.isSensor);
          setParkingValues({
            display_type: 'all',
            isHide: info.isHide,
            name: info.name,
            levels: info.levels,
            assigned_parking_controllers: info.assigned_parking_controllers.map((c) => c.id) || [],
            parkings: info?.parkings?.map((c) => c.parkingId) || [],
            parkingChosenList:
              info?.parkings?.map((c, index) => ({
                ...c,
                uniKey: index + 1,
              })) || [],
            isGroupCount: _.size(info?.parkings),
          });
        }
        setControllersList([...responseControllers.parking_controllers, ...simplifyAssignedList]);
      }
      function openModal() {
        setModalType(type);
        setIsModalOpen(true);
        deviceId && setParkingModelId(deviceId);
      }

      type === 'delete' ? openModal() : fetchCompaniesData().then(() => openModal());
    },
    [id, apiInstance]
  );

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        onDataChange={(data: { [k: string]: unknown }) => {
          const companyInfo = data as CompanyInfo;
          setParkingsList(companyInfo.virtual_parkings);
        }}
        title="Virtual parking "
        titleKey="company_name"
        toolbar={
          hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
            <Button color="secondary" onClick={() => handleModalOpen('create')}>
              {' '}
              + Add V-Parking
            </Button>
          )
        }
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
          ],
        }}
        options={{
          moduleName: 'companies/signs/list',
          apiUrl: `companies/v-parkings/list/${id}`,
          dataSourceKey: 'virtual_parkings',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/companies/v-parkings/add/${id}` },
          update: { method: 'post', url: `/companies/v-parkings/edit/${id}` },
          move: { method: 'post', url: `/companies/v-parkings/delete/${id}` },
        }}
        columns={[
          {
            dataField: 'name',
            caption: 'V-parking name',
            width: '25%',
          },
          {
            dataField: 'repeater',
            caption: 'Repeater',
            width: '10%',
          },
          {
            dataField: 'display',
            caption: 'Display',
            width: '10%',
          },
          {
            dataField: 'sensor',
            caption: 'Sensor',
            width: '10%',
          },
          {
            dataField: 'level',
            caption: 'Parking levels',
            width: '10%',
          },
          {
            dataField: 'location',
            caption: 'Location',
            width: '20%',
          },
          {
            dataField: 'groupName',
            caption: 'Group',
            width: '12%',
          },
          {
            ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && {
              ...{
                caption: '',
                cellRender: (data: { data: any }) => (
                  <CompanyParkingMenu handleModalOpen={handleModalOpen} signId={data.data.id} />
                ),
                width: '6%',
              },
            }),
          },
        ]}
      />
    );
  }, [id, handleModalOpen, hasRoleAccess]);

  return (
    <>
      <ParkingNav />
      {table}
      <GenericModal show={isModalOpen} onClose={() => handleModalClose()}>
        <ModalContent
          type={modalType}
          parkingModelId={parkingModelId}
          values={parkingValues}
          controllersList={controllersList}
          parkingsList={parkingsList}
          onClose={handleModalClose}
          isSensor={showSensor}
        />
      </GenericModal>
    </>
  );
}
