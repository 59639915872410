import { ChangeEvent, useEffect, useState, memo } from 'react';
import { Dialog, Radio, TextField, Checkbox } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { useDataGrid } from '../../../../components/generic/dataGrid';
import { AddUserFormDefaultValues, Content } from './types';
import {
  ActionBtn,
  ButtonsWrapper,
  CancelButton,
  ChooseAccessCompanyBtnWrap,
  ErrorMsgWrap,
  FormError,
  FormWrapper,
  ModulesWrap,
  SaveButton,
  StyledAllInputsWrapper,
  StyledControlLabel,
  StyledInputWrapper,
  StyledLabel,
  StyledRadioGroup,
  TitleLabel,
} from './styles';
import { Sign } from '../sign';
import { CustomCompanies } from '../CustomCompanies';
import { useApi } from '../../../../context/api';
import LoadPannelLoader from '../../../../components/LoadPanelLoader';

const SUPPORT_CUSTOM_COMPANIES_ROLE = '4';
const STAGING_ROLE = '5';

export default memo(function AddEditUser({
  onClose,
  userId,
}: {
  onClose: (info?: { showSnackbarMsg: boolean; message: string }) => void;
  userId?: string;
}) {
  const { apiInstance, loading, setLoading } = useApi();
  const { insert, update, errors: validationErrors } = useDataGrid();
  const [content, setContent] = useState<Content>();
  const [isCustomCompaniesListOpen, setCustomCompaniesListOpen] =
    useState(false);
  const defaultValues: AddUserFormDefaultValues = {
    username: '',
    password: '',
    password_repeat: '',
    email: '',
    roles: [],
    access_to_modules: [1, 3, 4, 5, 6],
    access_to_companies: [],
  };
  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    async function fetchUserData() {
      setLoading(true);
      const { companies, modules, roles, user }: Content = await apiInstance.get(
        `users/edit/${userId}/`
      );
      setLoading(false);

      setContent({ companies, modules, roles });
      if (user) {
        setValue('username', user.username);
        setValue('email', user.email);
        setValue('password', user.wd_password);
        setValue('password_repeat', user.wd_password);
        setValue(
          'access_to_companies',
          user.access_to_companies.map((item: string) => Number(item))
        );
        setValue('access_to_modules', user.access_to_modules);
        setValue('roles', user.roles);
      }
    }

    const getUsers = async () => {
      setLoading(true);
      const res: Content = await apiInstance.get('/users/add');
      setLoading(false);
      setContent(res);
    };
    userId ? fetchUserData() : getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const access_to_modules = watch('access_to_modules');
  const access_to_companies = watch('access_to_companies');

  useEffect(() => {
    for (const key in validationErrors) {
      setError(key as never, {
        message: Object.values(validationErrors[key])[0] as string,
      });
    }
  }, [setError, validationErrors]);

  const onSubmit = async (data: AddUserFormDefaultValues) => {
    try {
      isCustomCompaniesListOpen && setCustomCompaniesListOpen(false);
      userId ? update(userId, data, onClose) : insert(data, onClose);
    } catch (error) {
      console.error(error);
    }
  };

  function radioGroupOnChangeHandler(
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ): void {
    const { roles } = getValues();

    if (value === STAGING_ROLE) {
      return setValue('roles', [STAGING_ROLE]);
    }

    setValue(
      'roles',
      roles.includes(SUPPORT_CUSTOM_COMPANIES_ROLE)
        ? [value, SUPPORT_CUSTOM_COMPANIES_ROLE]
        : [value]
    );
  }

  function onRoleChange(
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    const { roles } = getValues();

    setValue(
      'roles',
      checked
        ? roles.length
          ? [...roles, SUPPORT_CUSTOM_COMPANIES_ROLE]
          : [SUPPORT_CUSTOM_COMPANIES_ROLE]
        : roles.filter((role) => role !== SUPPORT_CUSTOM_COMPANIES_ROLE)
    );
  }

  const onSelectCustomCompany = ({
    target: { value, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    const { access_to_companies } = getValues();
    const _value = Number(value);

    setValue(
      'access_to_companies',
      checked
        ? [...access_to_companies, _value]
        : access_to_companies.filter((item: number) => item !== _value)
    );
  };

  const onModuleAccesChange = ({
    target: { value, checked },
  }: ChangeEvent<HTMLInputElement>): void => {
    const { access_to_modules } = getValues();
    const _value = Number(value);

    setValue(
      'access_to_modules',
      checked
        ? [...access_to_modules, _value]
        : access_to_modules.filter((item: number) => item !== _value)
    );
  };

  return (
    <Dialog open onClose={() => onClose()}>
      <FormWrapper
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
        noValidate
        autoComplete="off"
      >
        <TitleLabel>{userId ? 'Edit user' : 'Add new user'}</TitleLabel>
        {loading ? <LoadPannelLoader/> : ''}
        {isCustomCompaniesListOpen ? (
          <CustomCompanies
            handleChange={onSelectCustomCompany}
            companies={content?.companies as Content['companies']}
            selectedCompanies={access_to_companies}
            goBack={() => setCustomCompaniesListOpen(false)}
          />
        ) : (
          <StyledAllInputsWrapper>
            <StyledInputWrapper>
              <ErrorMsgWrap>
                <Controller
                  control={control}
                  name="username"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="Username*"
                      variant="standard"
                      {...field}
                    />
                  )}
                />
                <FormError>{errors?.username?.message}</FormError>
              </ErrorMsgWrap>
            </StyledInputWrapper>
            <StyledInputWrapper>
              <ErrorMsgWrap>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      type="email"
                      label="Email*"
                      variant="standard"
                      {...field}
                    />
                  )}
                />
                <FormError>{errors?.email?.message}</FormError>
              </ErrorMsgWrap>
            </StyledInputWrapper>
            <StyledInputWrapper>
              <ErrorMsgWrap>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      type="password"
                      label="Password*"
                      variant="standard"
                      disabled={Boolean(userId)}
                      {...field}
                    />
                  )}
                />
                <FormError>{errors?.password?.message}</FormError>
              </ErrorMsgWrap>
            </StyledInputWrapper>
            <StyledInputWrapper>
              <ErrorMsgWrap>
                <Controller
                  control={control}
                  name="password_repeat"
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      type="password"
                      label="Password repeat*"
                      variant="standard"
                      disabled={Boolean(userId)}
                      {...field}
                    />
                  )}
                />
                <FormError>{errors?.password_repeat?.message}</FormError>
              </ErrorMsgWrap>
            </StyledInputWrapper>
            {Boolean(content?.modules) && (
              <ErrorMsgWrap sx={{ margin: '30px 0', textAlign: 'center' }}>
                <ModulesWrap>
                  {content?.modules.map(
                    ({ id, name }: { id: number; name: string }) =>
                      id === 7 ? null : (
                        <Sign
                          key={id}
                          module={{
                            id: String(id),
                            name,
                            upperCase: id === 5 || id === 6,
                            isActive: access_to_modules.includes(id),
                          }}
                          onChange={onModuleAccesChange}
                        />
                      )
                  )}
                </ModulesWrap>
                <FormError>{errors?.access_to_modules?.message}</FormError>
              </ErrorMsgWrap>
            )}
            <Controller
              control={control}
              name="roles"
              render={({ field }) => {
                const { roles } = getValues();
                const isSupportCustomCompanies = roles.includes(
                  SUPPORT_CUSTOM_COMPANIES_ROLE
                );

                return (
                  <StyledRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={field.value}
                    value={field.value.filter(
                      (role) => role !== SUPPORT_CUSTOM_COMPANIES_ROLE
                    )}
                    aria-multiselectable
                    name="radio-buttons-group"
                    style={{ width: '100%' }}
                    onChange={radioGroupOnChangeHandler}
                  >
                    {content?.roles?.map((role) => (
                      <StyledControlLabel
                        key={role.id}
                        value={role.id}
                        control={
                          role.id === SUPPORT_CUSTOM_COMPANIES_ROLE ? (
                            <Checkbox
                              sx={{ padding: 0 }}
                              onChange={onRoleChange}
                              checked={isSupportCustomCompanies}
                              disabled={roles.includes(STAGING_ROLE)}
                            />
                          ) : (
                            <Radio sx={{ padding: 0 }} />
                          )
                        }
                        label={
                          <StyledLabel>{`${role.role} (${role.description})`}</StyledLabel>
                        }
                      />
                    ))}
                    <FormError>{errors?.roles?.message}</FormError>
                    <FormError>
                      {errors?.access_to_companies?.message}
                    </FormError>

                    <ChooseAccessCompanyBtnWrap>
                      {isSupportCustomCompanies && (
                        <ActionBtn
                          onClick={() => setCustomCompaniesListOpen(true)}
                        >
                          Choose user access company
                        </ActionBtn>
                      )}
                    </ChooseAccessCompanyBtnWrap>
                  </StyledRadioGroup>
                );
              }}
            />
          </StyledAllInputsWrapper>
        )}

        <ButtonsWrapper>
          <SaveButton
            type="submit"
            variant="contained"
            disabled={loading}
          >
            Save
          </SaveButton>
          <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
        </ButtonsWrapper>
      </FormWrapper>
    </Dialog>
  );
});
