import React from 'react';

import { Typography } from '@mui/material';

export default function SignDeleteModalContent({ type }: {type: string}) {
  const renderTitle = () => {
    switch (type) {
      case 'delete':
        return 'Are you sure you want to delete the sign?';
    }
  };
  return (
    <Typography variant='subtitle1'  sx={{ marginBottom: 8 }} align='center'>
      {renderTitle()}
    </Typography>
    );
};