import { ModuleDesc, ModuleIcon, ModuleInput, ModuleSignWrap } from './styles';
import signsIcon from '../../../../assets/img/module_signs.png';
import parkingIcon from '../../../../assets/img/module_parking.png';
import tcamIcon from '../../../../assets/img/module_tcam.png';
import vmsIcon from '../../../../assets/img/module_vms.png';
import dtcIcon from '../../../../assets/img/module_dtc.png';

const MODULE_ICONS_MAP: { [key: string]: string } = {
  '1': signsIcon,
  '3': parkingIcon,
  '4': tcamIcon,
  '5': vmsIcon,
  '6': dtcIcon,
};

type Props = {
  module: {
    id: string;
    name: string;
    isActive: boolean;
    upperCase?: boolean;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Sign = ({
  module: { id, name, upperCase, isActive },
  onChange,
}: Props) => (
  <ModuleSignWrap>
    <ModuleInput
      checked={isActive}
      onChange={onChange}
      type="checkbox"
      value={id}
    />
    <ModuleIcon isActive={isActive} bgImg={MODULE_ICONS_MAP[id]} />
    <ModuleDesc sx={upperCase ? { textTransform: 'uppercase' } : null}>
      {name}
    </ModuleDesc>
  </ModuleSignWrap>
);
