import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import { IColumnProps } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom';

import GridTable from '../../../../../components/GridTable/gridTable';
import CompareRow from './row/CompareRow';
import { specifiedStore, FULLDATE_FORMAT, getKeysList } from './audit-utils';
import Table from '../../../../../components/Table';

const additionalColumns: IColumnProps [] = [
  {
    dataField: 'company_id',
    caption: 'company_id',
    alignment: 'center'
  },
  {
    dataField: 'name',
    caption: 'name',
    alignment: 'center'
  },
  {
    dataField: 'address',
    caption: 'address',
    alignment: 'center'
  },
  {
    dataField: 'city',
    caption: 'city',
    alignment: 'center'
  },
  {
    dataField: 'state',
    caption: 'state',
    alignment: 'center'
  },
  {
    dataField: 'country',
    caption: 'country',
    alignment: 'center'
  },
  {
    dataField: 'zip',
    caption: 'zip',
    alignment: 'center'
  },
  {
    dataField: 'tz',
    caption: 'tz',
    alignment: 'center'
  },
  {
    dataField: 'deleted',
    caption: 'deleted',
    alignment: 'center'
  }
];


export default function CompanyAudit() {
  const { id } = useParams();

  const [ store, setStore ] = useState<Array<any>>([]);

  const [ companyName, setCompanyName ] = useState<string | null>('');

  // we use a default period of 1 week for data on server
  const [ dateStart, setDateStart ] =
    useState<string>(moment().subtract(1, 'weeks').format(FULLDATE_FORMAT));
  const [ dateEnd, setDateEnd ] =
    useState<string>(moment().format(FULLDATE_FORMAT));

  return useMemo(
    () => (
      <div data-test={'company-audit'}>
        <Table
          backButton="/companies/list/"
          title={
            <Typography variant="h5" data-test={'back-button-title'}>
              Company {companyName}
            </Typography>
          }
          options={{
            moduleName: '',
            apiUrl: `/companies/${id}/audit`,
            dataSourceKey: 'companies_history',
            dataStoreKey: '',
            searchKey: ''
          }}
          onDataChange={(data: { [k: string]: any }) => {
            setCompanyName(data?.company?.name);
          }}
          filter={{
            withPageSize: false,
            inputs: [
              {
                type: 'rangeDatePicker',
                name: 'rangeDatePicker',
                label: 'Pick dates interval to filter data',
                startName: 'date_from',
                endName: 'date_to',
                dateRangeStart: dateStart,
                dateRangeEnd: dateEnd,
              }
            ],
          }}
          receivedStore={store}
          withSorting={false}
          noDataText={'No matching records found. Try to choose the correct filters.'}
          columns={[
            {
              caption: 'Who changed',
              cellRender: (data) => {
                return(
                  <span>{data.data.user_id ? data.data.user_id : 'Unknown'}</span>
                )
              },
              width: '8%'
            },
            {
              caption: 'Application',
              cellRender: (data) => {
                return(
                  <span>{data.data.application ? data.data.application : 'Unknown'}</span>
                )
              },
              width: '8%'
            },
            {
              dataField: 'description',
              caption: 'Operation',
              width: '8%'
            },
            {
              dataField: 'change_time',
              caption: 'When Made',
              width: '8%'
            },
            {
              dataField: 'Diff',
              cellRender: (data) => {
                return(
                  <GridTable
                    receivedStore={specifiedStore(data.data, ['time_zone'])?.diff}
                    columns={additionalColumns}
                    keyExpr='company_id'
                    dataRowRender={(data) => CompareRow(data, getKeysList(additionalColumns))}
                  />
                )
              },
              caption: 'Diff'
            }
          ]}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store, companyName, dateStart, dateEnd]
  );
}
