import React, { memo, useRef, useCallback } from 'react';
import { TableProps } from './types';
import GenericDataGrid from '../generic/dataGrid';
import TableWrapper, { TableWrapperRef } from './TableWrapper';

function Table({
  title,
  titleKey,
  isCustomTitle,
  filter,
  toolbar,
  backButton,
  exportData,
  onDataChange,
  ...genericDataGridProps
}: TableProps) {
  const currentTitleRef = useRef(title);
  const tableWrapperRef = useRef<TableWrapperRef>(null);

  const handleDataChange = useCallback((data: { [k: string]: unknown }) => {
    if (titleKey) {
      const additionalTitle = data[titleKey] as string;

      if (additionalTitle) {
        currentTitleRef.current = title + additionalTitle;
        if (tableWrapperRef.current) {
          tableWrapperRef.current.updateTitle(currentTitleRef.current as string);
        }
      }
    }

    onDataChange && onDataChange(data);
  }, [onDataChange, title, titleKey]);

  return (
    <TableWrapper
      ref={tableWrapperRef}
      title={isCustomTitle ? title : currentTitleRef.current}
      filter={filter}
      toolbar={toolbar}
      backButton={backButton}
      exportData={exportData}
    >
      <GenericDataGrid
        onDataChange={handleDataChange}
        {...genericDataGridProps}
      />
    </TableWrapper>
  );
};

export default memo(Table);
