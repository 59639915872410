import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { ContentHeader, getLocalAccessToken } from "./utils";
import { AxiosInternalError } from "./types";
import _ from "lodash";
import { LOGIN_TO_WD3_URL } from "../../modules/pages/login-to-wd/loginToWD3";
import { toast } from "react-toastify";

export const createApiInstanse = () => {
  const apiInstance = axios.create(ContentHeader);
  apiInstance.interceptors?.request.use(
    // TODO: fix eslint error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (config: AxiosRequestConfig) => {
      const accessToken = getLocalAccessToken()?.replaceAll('"', '');

      if (accessToken) {
        return {
          ...config,
          headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getLocalAccessToken()?.replaceAll(
              '"',
              ''
            )}`,
          } as unknown as AxiosRequestHeaders,
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error.data);
    }
  );
  apiInstance.interceptors?.response.use(
    (res) => {
      return _.get(res, 'data.data', res.data);
    },
    async (err: AxiosError<AxiosInternalError>) => {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          const responseURL: string = err.response.request?.responseURL || '';

          if (responseURL.includes(LOGIN_TO_WD3_URL)) {
            toast.error(`Error: ${err.response.data.error.message}`, {containerId: 'failed_login_to_WD3_toast_msg'})
          } else {
            localStorage.removeItem('user');
            window.location.href = '/';

            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        }
      }
      console.error('*** err: ', err);
      return Promise.reject(err.response?.data);
    }
  );
  return apiInstance;
}