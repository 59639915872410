import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import _ from 'lodash';
import { ValidationError } from '../../context/api/types';

export const showErrorMessage = <T extends FieldValues>(
  validationErrors: ValidationError,
  setError: UseFormSetError<T>
) => {
  if (_.size(validationErrors)) {
    _.forEach(validationErrors, (value, key) => {
      return setError(key as Path<T>, {
        type: 'manual',
        message: value[0],
      });
    });
  }
};
