import React from 'react';

import { SignEditProps } from '../types';
import { TextField, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { values } from 'lodash';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';

export default function SignEditModalContent(
    { control, signModelList, errors, setValue }: SignEditProps
) {
    const { eraseError } = useDataGrid();

    return (
      <>
        <Controller
          name="serial"
          control={control}
          rules={{
            required: 'CC Serial cannot be empty',
            minLength: {
              value: 8,
              message: 'CC Serial must be at least 8 digits long',
            },
            maxLength: {
              value: 8,
              message: 'CC Serial number must be no more than 8 digits long',
            }
        }}
          render={({ field, fieldState: { error }  }) => (
            <TextField
              helperText={error ? error.message : values(errors?.serial)}
              sx={{ marginBottom: 7 }}
              label='CC Serial Number*'
              fullWidth
              variant="standard"
              inputProps={{
                maxLength: 8
              }}
              {...field}
              onChange={(e) => {
                const name = 'serial';
                const hexReg = new RegExp(/^[0-9A-Fa-f]+$/);

                if(hexReg.test(e.target.value) || e.target.value === '') {
                  setValue(name, e.target.value);
                  eraseError(name);
                }
              }}
            />
          )}
        />
        
        <Controller
          name="sign_model"
          control={control}
          render={({ field }) => (
            <Select
              sx={{ marginBottom: 7 }}
              fullWidth
              variant="standard"
              labelId="demo-simple-select-standard-label"
              id="model-select"
              label="Signs Models"
              {...field}
            >
                {signModelList?.map((signModel) => (
                  <MenuItem value={signModel.value} key={signModel.value}>
                    {signModel.title}
                  </MenuItem>
                 ))}
            </Select>
          )}
        />
        
        <Controller
          name="device_serial"
          control={control}
          rules={{
            minLength: {
              value: 8,
              message: 'Device serial must be at least 8 digits long',
            },
            maxLength: {
              value: 8,
              message: 'Device serial number must be no more than 8 digits long',
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : values(errors?.device_serial)}
              sx={{ marginBottom: 7 }}
              label='Device Serial'
              fullWidth
              variant="standard"
              {...field}
              onChange={(e) => {
                const name = 'device_serial';

                setValue(name,  e.target.value);
                eraseError(name)
              }}
            />
          )}
        />
        
        <Controller
          name="sim"
          control={control}
          rules={{
            minLength: {
              value: 18,
              message: 'SIM number must be at least 18 digits long',
            },
            maxLength: {
              value: 22,
              message: 'SIM number must be no more than 22 digits long',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : values(errors?.sim)}
              sx={{ marginBottom: 7 }}
              label='SIM'
              fullWidth
              variant="standard"
              {...field}
              onChange={(e) => {
                const name = 'sim';
                const val = e.target.value
                  .replace(/[^0-9]/g, '');

                setValue(name, val);
                eraseError(name)
              }}
            />
          )}
        />

        <Controller
          name="beacon"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ height: '20px' }}
                  checked={!!field.value}
                  onChange={(e) => field.onChange(+e.target.checked)}
                />
              }
              label='With TL Beacons Systems'
              key=''
              sx={{ height: '20px', marginBottom: 7 }}
            />
          )}
        />

        <Controller
          name="normal_voltage"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ marginBottom: 9 }}
              inputProps={{
                type: 'number'
              }}
              label='Normal battery voltage (V)'
              fullWidth
              variant="standard"
              helperText={values(errors?.normal_voltage)}
              {...field}
              onChange={(e) => {
                const name = 'normal_voltage';

                setValue(name,  e.target.value);
                eraseError(name)
              }}
            />
          )}
        />
      </>
    );
};
