import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';
import { CheckboxSelectionFieldWrapper } from '../../styles';
import _ from 'lodash';

const SelectionCheckbox = ({
  field,
  input
}: FilterControlFieldProps<Types.SelectionCheckboxInput>) => {
  const { options, cb } = input;
  const [values, setValues] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (field.value === null) {
      setValues({});
    }
  }, [field.value]);

  useEffect(() => {
    if (!_.size(values)) {
      const newValues = options.reduce((prev, option: Types.SelectionCheckboxOption) => ({
        ...prev,
        [option.value]: (field.value === '' || field.value === null) ? false : +field.value === +option.value
      }), {});
      setValues(newValues);
    }
  }, [options, field.value, values]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newValue = { ...values, [e.target.value]: checked };
    const emptyFilter = _.uniq(Object.values(newValue)).length === 1;
    const trueKey = Object.keys(newValue).find(k => newValue[k] === true);

    setValues(newValue);
    field.onChange(emptyFilter ? '' : trueKey);

    if (cb) {
      cb(e, checked);
    }
  };

  return (
    <CheckboxSelectionFieldWrapper>
      {options.map(({ value, title }: Types.SelectionCheckboxOption) => (
        <div key={`wrap_${title}`}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ height: '20px', fontSize: '12px', fontWeight: '500' }}
              size="small"
              onChange={handleChange}
              checked={values[value] || false}
              value={value}
            />
          }
          label={title}
          key={title}
          sx={{ height: '20px', display: 'inline-block' }}
        />
        </div>
      ))}
    </CheckboxSelectionFieldWrapper>
  );
};

export default SelectionCheckbox;
