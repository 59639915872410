import { useEffect, useState } from 'react';
import {
  DeviceInfoWrapper,
  LeftColumn,
  RightColumn,
  Infolabel,
  MapContainer,
  ShowLogButton,
  ToastBox
} from './styles';
import MapWrapper from '../../../../../components/generic/map/map';
import Chart from '../../../../../components/generic/chart';
import { SignDetail } from '../types';
import NoMatch from '../../../no-match/index';
import ShowCompanyInfo from './components/CompanyInfo';
import FutureDates from './components/FutureDates';
import UpdateFV from './components/UpdateFV';
import { Link } from 'react-router-dom';
import { useAuth, useLoggedInUser } from '../../../../../context/auth';
import _ from 'lodash';
import { USERROLES } from '../../../../../constants/UserRoles';
import { useApi } from '../../../../../context/api';

export default function ShowInfoModal({ id }: { id: number | null }) {
  const { hasRoleAccess } = useAuth();
  const loggedInUser = useLoggedInUser();
  const [sign, setSign] = useState<SignDetail>();
  const [geo, setGeo] = useState<number[]>([]);
  const [chartData, setChartData] = useState<[number, number][]>([]);
  const [loading, setLoading] = useState(true);
  const [showingVer, setShowingVer] = useState(false);
  const [showingFutureDates, setShowingFutureDates] = useState(false);
  const { apiInstance } = useApi();
  const roles = loggedInUser?.roles || [];
  const rolesCommunication: number[] = [
    USERROLES.ROLE_SUPER_ADMIN,
    USERROLES.ROLE_POWER_USER,
    USERROLES.ROLE_RESTRICTED
  ];
  const rolesLog: number[] = [
    USERROLES.ROLE_SUPER_ADMIN
  ];

  async function fetchData(id: number) {
    const response: SignDetail = await apiInstance.get(`sign/detail/${id}`);
    setSign(response);

    if (response.geoCoordinates) {
      const geoCords = response.geoCoordinates.split(',');
      setGeo([+geoCords[0], +geoCords[1]]);
      setChartData(JSON.parse(response.chartData));
      setLoading(false);
    }
  }

  const updateInfo = () => {
    setLoading(true);
    id && fetchData(id)
  }

  useEffect(() => {
    id ? fetchData(id) : setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) return null;

  return (
    <DeviceInfoWrapper>
      {
        !_.isNull(id) && (id > 0 ?
          <>
            <LeftColumn>
              {(!showingVer && !showingFutureDates) && <ShowCompanyInfo sign={sign}/>}
              {showingFutureDates &&
                <FutureDates
                  locationId={sign?.sign.locationId}
                  sign={sign}
                  onClose={() => {
                    setShowingFutureDates((x) => !x);
                  }}
                  updateData={updateInfo}
                />
              }
              {
                (!showingVer && !showingFutureDates) &&
                hasRoleAccess(
                  [[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_POWER_USER]]
                ) && sign?.firmwareUpdateRequest &&
                <ToastBox>
                  Pending Firmware update request at {sign?.firmwareUpdateRequest?.time_req
                }, version to load: {sign?.firmwareUpdateRequest?.param_set_name}
                </ToastBox>
              }
              {showingVer && hasRoleAccess(
                [[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_POWER_USER]]
                ) &&
                <UpdateFV
                  deviceId={sign?.sign.id}
                  currentVersion={sign?.sign.firmwareVer}
                  onClose={() => {
                    setShowingVer((x) => !x);
                  }}
                  onFetchUpdate={() => {
                    updateInfo()
                    setShowingVer((x) => !x);
                  }}
                />
              }
              {(!showingVer && !showingFutureDates) &&
                <>
                  {roles.some(item => rolesCommunication.includes(item)) &&
                    <Link to={`sync-log/${sign?.sign.locationId}`}>
                      <ShowLogButton data-test={'showLogInfo'}>
                        Show Communication log
                      </ShowLogButton>
                    </Link>
                  }

                  {hasRoleAccess(
                      [[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_POWER_USER]]
                    ) &&
                    <ShowLogButton
                      data-test={'updateInfo'}
                      onClick={() => {
                        setShowingVer((x) => !x);
                      }}
                      disabled={!!sign?.firmwareUpdateRequest || !sign?.sign.locationId}
                    >
                      Update FW
                    </ShowLogButton>
                  }

                  {roles.some(item => rolesLog.includes(item)) &&
                  !roles.includes(USERROLES.ROLE_RESTRICTED) &&
                  !!sign?.radarHasFutureDates &&
                    <ShowLogButton
                      data-test={'deleteInfo'}
                      onClick={() => {
                        setShowingFutureDates((x) => !x);
                      }}
                    >
                      Delete Future Dates
                    </ShowLogButton>
                  }
                </>
              }
            </LeftColumn>

            <RightColumn>
              <Infolabel>Location</Infolabel>
              <MapContainer>
                <MapWrapper latLong={geo} geoImg={`${sign?.iconPath}${sign?.sign.signIcon}`}></MapWrapper>
              </MapContainer>

              <Infolabel sx={{ marginTop: '15px' }}>Power Supply Chart</Infolabel>
              <Chart data={chartData}></Chart>
            </RightColumn>
          </> : <NoMatch/>)
      }
    </DeviceInfoWrapper>
  );
}
