import { Box, Checkbox, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { CompaniesWrap, CompaniesList, Company, StyledTitle } from "./styles";
import { ActionBtn } from "../add-edit-user/styles";
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
type Props = {
    goBack: () => void;
    companies: Array<{
        cid: number;
        name: string;
    }>;
    selectedCompanies: Array<number>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const CustomCompanies = ({ goBack, companies, selectedCompanies, handleChange }: Props) => {
    const [searchValue, setSearchValue] = useState('');

    return (
        <Box sx={{height: '500px', maxWidth: '100%'}}>
            <StyledTitle>Select company:</StyledTitle>
            <CompaniesWrap>
                <TextField
                    variant="standard"
                    type="search"
                    value={searchValue}
                    onChange={({target: { value }}) => setSearchValue(value)}
                />
                {Boolean(companies.length) && <CompaniesList>
                        {companies
                            .filter(item =>item.name.toLowerCase().includes(searchValue.toLowerCase()))
                            .map(({ cid, name }) =>
                                <Company key={cid}>
                                    <Checkbox
                                        checked={selectedCompanies.includes(cid)}
                                        value={cid}
                                        onChange={handleChange}
                                    />
                                    {name}
                                </Company>)}
                    </CompaniesList>}
            </CompaniesWrap>
            <ActionBtn onClick={goBack}>
                <ArrowLeftIcon />
                Back
            </ActionBtn>
        </Box>
    )
}