import { Box } from "@mui/material"
import { CancelButton, SaveButton } from "../../styles"
import { MadalLayoutProps } from "./types"
import { BtnsWrap, ModalWrap } from "./styles"
import { TitleLabel } from "../companies/add-company/styles"
import { useAuth } from '../../../../context/auth';
import { USERROLES } from '../../../../constants/UserRoles';

export const ModalLayout = ({ title, content, onReject, onSubmit, cancelBtnDesc, submitBtnDesc }: MadalLayoutProps) => {
  const { hasRoleAccess } = useAuth();
  
 return (
   <ModalWrap>
    <TitleLabel>{title}</TitleLabel>
    <Box sx={{ width: '100%', color: 'rgba(0,0,0,.54)', textAlign: 'center' }}>
      {content}
    </Box>
    <BtnsWrap>
      {hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN],[USERROLES.ROLE_STAGING]]) && (
        <SaveButton onClick={onSubmit} variant="contained">
          {submitBtnDesc}
        </SaveButton>
      )}
      <CancelButton onClick={onReject}>
        {cancelBtnDesc}
      </CancelButton>
    </BtnsWrap>
  </ModalWrap>
 )
}
