import { styled } from '@mui/material';

export const OverviewWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px auto;
  max-width: 1920px;
`;

export const ChartWrapper = styled('div')`
  display: flex;
  width: 540px;
  height: 260px;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 30px;
  padding: 10px;
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
`;

export const Title = styled('div')`
  text-align: left;
  font-size: 25px;
  font-weight: bold;
`;

export const NoData = styled('div')`
  color: #333;
  font-size: 17px;
  opacity: .7;
`