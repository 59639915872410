import { useCallback, useEffect } from "react";
import DataGrid, { Column, IColumnProps } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import './dataGrid.scss';
import './simpleDataGrid.scss';
import { SimpleDataGridProps } from "./types";
import Pagination from '@mui/material/Pagination';

import { useDataGrid } from "./context";
import DataSource from "devextreme/data/data_source";


const SimpleDataGrid = ({
  columns,
  withPaging = false,
  totalPages,
  page,
  data,
  changePageTo
}:SimpleDataGridProps) => {
  const { dataSource, setDataSource } = useDataGrid();
  const columnComponent = useCallback((column: IColumnProps, index: number) => {
    const columnProps = { ...column };
    return (
      <Column
        allowSorting={false}
        key={`${columnProps.dataField}_${index}`}
        { ...columnProps }
      />
    )
  }, []);
  
  useEffect(() => {
    const dataSource = new DataSource({
      store: data
    });
    
    setDataSource(dataSource)
  }, [data, setDataSource]);
  
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    changePageTo(value);
  };
  
  return (
    <>
      <DataGrid
        dataSource={dataSource}
        className={"genericDataGrid"}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnReordering={false}
        paging={{
          enabled: false
        }}
        rowAlternationEnabled={true}
      >
        {columns.map(columnComponent)}
      
      </DataGrid>
      {withPaging && (
        <div className={'SimpleDataGrid-container'}>
          <Pagination
            className={'SimpleDataGrid-pagination'}
            count={totalPages}
            page={page}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={handleChange}
            data-test={'SimpleDataGridPagination'}
          />
        </div>
      )}
    </>
  )
};

export default SimpleDataGrid;
