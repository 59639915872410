import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { DataGridContextProvider } from '../components/generic/dataGrid';
import { FiltersContextProvider } from '../components/generic/filter/context';
import AppThemeProvider from '../theme/themeProvider';
import { AuthContextProvider } from './auth';
import { ConfirmProvider } from './dialog';
import { LoadingProvider } from '../components/Preloader/context';
import { ApiContextProvider } from './api';

const queryClient = new QueryClient();

export function GlobalProviders({ children }: { children: ReactNode }) {
  return (
    <ApiContextProvider>
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <ConfirmProvider>
            <AuthContextProvider>
              <FiltersContextProvider>
                <DataGridContextProvider>{children}</DataGridContextProvider>
              </FiltersContextProvider>
            </AuthContextProvider>
          </ConfirmProvider>
        </AppThemeProvider>
      </QueryClientProvider>
    </ApiContextProvider>
  );
}
