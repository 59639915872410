import Table from '../../../../src/components/Table';
import { ChangeCredentialsDiv, LoginLabel, Wrapper } from './styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { LoginToWDResponse } from './types';
import BuildIcon from '@mui/icons-material/Build';
import { ChangeCredentialsComponent } from './change-credentials';
import { Snackbar } from '@mui/material';
import { loginToWD3 } from './loginToWD3';
import { useApi } from '../../../context/api';

export default function LoginToWD() {
  const [loginToWDResponse, setLoginToWDResponse] = useState<LoginToWDResponse>();
  const [showChangeCredentials, setShowChangeCredentials] = useState<boolean>();
  const [snackbarMsg, setSnackbarMsg] = useState<string>();
  const { apiInstance } = useApi();

  const loginToWDResponseRef = useRef<LoginToWDResponse | undefined>();

  useEffect(() => {
    loginToWDResponseRef.current = loginToWDResponse;
  }, [loginToWDResponse]);

  const onDialogClose = useCallback(
    (info?: { showSnackbarMsg: boolean; message: string }) => {
      setShowChangeCredentials(false);
      if (info?.showSnackbarMsg) {
        setSnackbarMsg(info.message);
      }
    },
    []
  );

  const handleDataChange = (data: { [k: string]: unknown }) => {
    if (!loginToWDResponse) {
      setLoginToWDResponse(data as LoginToWDResponse);
    }
  };

  const loginToWD3Callback = useCallback(loginToWD3, []);

  const table = useMemo(
    () => (
      <Table
        title="Login to WD"
        toolbar={
          <ChangeCredentialsDiv onClick={() => setShowChangeCredentials(true)}>
            <BuildIcon sx={{ fontSize: 'large' }} />
            <span>Change Credentials</span>
          </ChangeCredentialsDiv>
        }
        onDataChange={handleDataChange}
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Search by company name',
            },
          ],
        }}
        options={{
          moduleName: 'logintowd',
          apiUrl: '/logintowd',
          dataSourceKey: 'wd',
          dataStoreKey: 'id',
        }}
        columns={[
          {
            caption: '',
          },
          {
            dataField: 'name',
            caption: 'Company Name',
          },
          {
            dataField: 'modules_info',
            caption: 'Modules',
          },
          {
            caption: 'Actions',
            cellRender: (data) => (
              <>
                {loginToWDResponseRef.current?.wd3_redirect && (
                  <LoginLabel
                    title="Login to current company"
                    onClick={() => loginToWD3Callback(apiInstance, data.data.id)}
                  >
                    <PowerSettingsNewOutlinedIcon /> WD3
                  </LoginLabel>
                )}
              </>
            ),
          },
        ]}
      ></Table>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginToWD3Callback]
  );

  return (
    <Wrapper>
      {table}
      {showChangeCredentials && (
        <ChangeCredentialsComponent
          defaultValues={{
            wd_name: loginToWDResponseRef.current?.wd_name || '',
            wd_login: loginToWDResponseRef.current?.wd_login || '',
          }}
          onClose={onDialogClose}
        ></ChangeCredentialsComponent>
      )}
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={6000}
        message={snackbarMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarMsg('')}
      />
    </Wrapper>
  );
}
