import { useCallback, useEffect, useState } from 'react';
import {
  CommLogHeaderCell,
  CommLogRow,
  CommLogTable,
  CommLogWrapper,
  MapContainer,
  NothingToShow,
  ShowLogTd,
} from './styles';
import MapWrapper from '../../../../../components/generic/map/map';
import Chart from '../../../../../components/generic/chart';
import { ParkingDetail, Level } from '../types';
import {
  DeviceInfoTable,
  DeviceInfoTableHeaderCell,
  DeviceInfoTableRow,
  DeviceInfoTableRowCell,
  DeviceInfoWrapper,
  Infolabel,
  LeftColumn,
  RightColumn,
} from '../../tcam/modal-content/styles';
import { Box } from '@mui/material';
import ParkingIcon from '../../../../../assets/img/parking.png';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ParkingScheduleInfo } from './parking-schedule-info';
import { useApi } from '../../../../../context/api';

export default function ModalContent({ id }: { id: number }) {
  const [parking, setParking] = useState<ParkingDetail>();
  const [geo, setGeo] = useState<number[]>([]);
  const [chartData, setChartData] = useState<[number, number][]>([]);
  const [loading, setLoading] = useState(true);
  const { apiInstance } = useApi();
  useEffect(() => {
    async function fetchData(id: number) {
      const response: ParkingDetail = await apiInstance.get(`parking/detail/${id}`);

      setParking(response);

      if (response.geoCoordinates) {
        const geoCords = response.geoCoordinates.split(',');
        setGeo([+geoCords[0], +geoCords[1]]);
        setChartData(JSON.parse(response.chart_data));
        setLoading(false);
      }
    }

    fetchData(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [selectedParkingLevelScheduleId, setSelectedParkingLevelScheduleId] =
    useState<Level['schedule_id']>();

  const showParkingLevelScheduleInfo = useCallback(
    (parkingLevelScheduleId: Level['schedule_id']) => {
      setSelectedParkingLevelScheduleId(parkingLevelScheduleId || 5);
    },
    []
  );

  if (loading) return null;

  return (
    <DeviceInfoWrapper>
      <LeftColumn>
        <Infolabel>Company info</Infolabel>
        <DeviceInfoTable>
          <tbody>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>Company</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.companyInfo.name}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Company Timezone
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.companyInfo.tz}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Invalidation Interval, seconds:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.readingIntervalParam}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Info for the last:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.fastStatParam}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
          </tbody>
        </DeviceInfoTable>

        <Infolabel sx={{ marginTop: '15px' }}>Parking info</Infolabel>
        <DeviceInfoTable>
          <tbody>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Parking Name:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.parking_name}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Location Name:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.location_name}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>Levels:</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.levels}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Device Time:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.deviceTime || '0000-00-00 00:00:00'}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Last Communication:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.lastCommunication || '0000-00-00 00:00:00'}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Free Spaces:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.freeSpaces}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>
                Total Spaces:
              </DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.totalSpaces}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
            <DeviceInfoTableRow>
              <DeviceInfoTableHeaderCell>Timezone:</DeviceInfoTableHeaderCell>
              <DeviceInfoTableRowCell>
                {parking?.parking.time_zone}
              </DeviceInfoTableRowCell>
            </DeviceInfoTableRow>
          </tbody>
        </DeviceInfoTable>

        <Infolabel sx={{ marginTop: '15px' }}>Communication log</Infolabel>
        <CommLogWrapper>
          <Box style={{ height: '100%', overflow: 'auto' }}>
            <CommLogTable>
              <thead style={{ position: 'sticky', top: 0 }}>
                <CommLogRow>
                  <CommLogHeaderCell>Request Time</CommLogHeaderCell>
                  <CommLogHeaderCell>Data</CommLogHeaderCell>
                </CommLogRow>
              </thead>
              <tbody>
                {parking?.sync_log?.map((log, i) => (
                  <CommLogRow key={i}>
                    <ShowLogTd sx={{ textAlign: 'left' }}>
                      {log.time_req}
                    </ShowLogTd>
                    <ShowLogTd sx={{ textAlign: 'left' }}>
                      {log.time_proc}
                    </ShowLogTd>
                  </CommLogRow>
                ))}
                {!parking?.sync_log ||
                  (parking?.sync_log.length === 0 && (
                    <CommLogRow>
                      <ShowLogTd colSpan={2}>
                        <NothingToShow>Nothing to show</NothingToShow>
                      </ShowLogTd>
                    </CommLogRow>
                  ))}
              </tbody>
            </CommLogTable>
          </Box>
        </CommLogWrapper>

        <Infolabel sx={{ marginTop: '15px' }}>
          Parking Status:{' '}
          {parking?.parkingStatus ? (
            <b>{`${parking.parkingStatus.status}, ${
              parking.parkingStatus.schedule_status
                ? 'Scheduled opened hours'
                : 'Open always'
            }`}</b>
          ) : (
            <b>Unknown</b>
          )}
        </Infolabel>
        <DeviceInfoTable>
          <tbody>
            {parking?.parkingStatus ? (
              <>
                {parking.levels.map((level) => (
                  <DeviceInfoTableRow key={level.level}>
                    <DeviceInfoTableHeaderCell>
                      Level {level.level} Status
                    </DeviceInfoTableHeaderCell>
                    <DeviceInfoTableRowCell>
                      {level.status}
                    </DeviceInfoTableRowCell>
                    <DeviceInfoTableRowCell>
                      {level.schedule_status
                        ? 'Scheduled opened hours'
                        : 'Open always'}
                    </DeviceInfoTableRowCell>
                    {level.hasSchedule && (
                      <DeviceInfoTableRowCell>
                        <a
                          href="#"
                          onClick={() => {
                            showParkingLevelScheduleInfo(level.schedule_id);
                          }}
                        >
                          <VisibilityOutlinedIcon
                            titleAccess="View Schedule"
                            htmlColor="#000"
                          />
                        </a>
                      </DeviceInfoTableRowCell>
                    )}
                  </DeviceInfoTableRow>
                ))}
                {selectedParkingLevelScheduleId && (
                  <>
                    <ParkingScheduleInfo
                      parkingId={parking?.parking.id}
                      parkingLevelScheduleId={selectedParkingLevelScheduleId}
                      onClose={() => {
                        setSelectedParkingLevelScheduleId(undefined);
                      }}
                    ></ParkingScheduleInfo>
                  </>
                )}
              </>
            ) : (
              <DeviceInfoTableRow>
                <td>
                  <NothingToShow>Nothing to show</NothingToShow>
                </td>
              </DeviceInfoTableRow>
            )}
          </tbody>
        </DeviceInfoTable>
      </LeftColumn>

      <RightColumn>
        <Infolabel>Location</Infolabel>
        <MapContainer>
          <MapWrapper latLong={geo} geoImg={ParkingIcon}></MapWrapper>
        </MapContainer>

        <Infolabel sx={{ marginTop: '15px' }}>Power Supply Chart</Infolabel>
        <Chart data={chartData}></Chart>
      </RightColumn>
    </DeviceInfoWrapper>
  );
}
