import { styled } from '@mui/material';

export const CardBoxStyle = styled('form')({
  overflowY: 'hidden',
  overflowX: 'hidden',
  height: '32rem',
  width: '25rem',
});

export const InputBox = styled('div')({
  padding: '20px 0px 30px 0px',
  borderRadius: 4,
  width: '100%',
});
export const ImageInputBox = styled(InputBox)({
  // position: "relative",
  // padding: "10% 0% 0% 20%",
});
export const TypoBox = styled(InputBox)({
  padding: '20px 0px 20px 0px',
});
export const TitleHeading = styled('p')({
  fontWeight: 300,
  fontFamily: "'Roboto' 'Helvetica' 'Arial' sans-serif;",
  fontSize: 50,
  margin: 0,
  textAlign: 'center',
  color: '#636363',
});
export const ErrorMessage = styled('p')({
  color: 'rgb(213, 0, 0)',
  fontSize: '12px',
});
