import { styled } from '@mui/material';

export const ResetPasswordWrap = styled('div')`
    width: 100%;
    padding: 10px;
    background: #4a89dc;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 100;
`

export const LeftSideWrap = styled('div')`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const TitleLabel = styled('h3')`
  text-align: center;
  padding: 0;
  color: #333;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 40px;
`;