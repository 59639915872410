import { useEffect, useState } from 'react';
import {
  DeviceInfoWrapper,
  LeftColumn,
  RightColumn,
  Infolabel,
  DeviceInfoTable,
  DeviceInfoTableRow,
  DeviceInfoTableHeaderCell,
  DeviceInfoTableRowCell,
  MapContainer,
  ShowLogButton,
  ShowLogTh,
  ShowLogTd,
  DeviceInfoTBody, DeviceInfoTableWrapper, DeviceInfoTableRowWithBorder
} from './styles';
import MapWrapper from '../../../../../components/generic/map/map';
import Chart from '../../../../../components/generic/chart';
import { TCamDetail } from '../types';
import TCamIcon from '../../../../../assets/img/tcam-icon.png';
import { useApi } from '../../../../../context/api';

export default function ModalContent({ id }: { id: number }) {
  const [tCam, setTCam] = useState<TCamDetail>();
  const [geo, setGeo] = useState<number[]>([]);
  const [chartData, setChartData] = useState<[number, number][]>([]);
  const [loading, setLoading] = useState(true);
  const [showingLog, setShowingLog] = useState(false);
  const { apiInstance } = useApi();
  useEffect(() => {
    async function fetchData(id: number) {
      const response: TCamDetail = await apiInstance.get(`tcam/detail/id/${id}`);

      setTCam(response);

      if (response.geoCoordinates) {
        const geoCords = response.geoCoordinates.split(',');
        setGeo([+geoCords[0], +geoCords[1]]);
        setChartData(JSON.parse(response.chart_data));
        setLoading(false);
      }
    }

    fetchData(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) return null;

  return (
    <DeviceInfoWrapper>
      <LeftColumn>
        {!showingLog && (
          <>
            <Infolabel>Company / location info</Infolabel>
            <DeviceInfoTable>
              <tbody>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>Company</DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.company.name}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Company Timezone
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.company.tz}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Invalidation Interval
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.invalid_interval}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Location
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.location}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
              </tbody>
            </DeviceInfoTable>

            <Infolabel sx={{ marginTop: '15px' }}>Camera info</Infolabel>
            <DeviceInfoTable>
              <tbody>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Last communication
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.ping_date}
                  </DeviceInfoTableRowCell>
                  <DeviceInfoTableHeaderCell>Serial</DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.serial}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Power Supply
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.voltage}
                  </DeviceInfoTableRowCell>
                  <DeviceInfoTableHeaderCell>
                    CPU temp
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.cpu_temp}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Camera model
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.camera.camera_model}
                  </DeviceInfoTableRowCell>
                  <DeviceInfoTableHeaderCell>
                    Related sign
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.sign.serial}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
                <DeviceInfoTableRow>
                  <DeviceInfoTableHeaderCell>
                    Sign communication
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.sign.last_radar_time}
                  </DeviceInfoTableRowCell>
                  <DeviceInfoTableHeaderCell>
                    Sign voltage
                  </DeviceInfoTableHeaderCell>
                  <DeviceInfoTableRowCell>
                    {tCam?.sign.battery}
                  </DeviceInfoTableRowCell>
                </DeviceInfoTableRow>
              </tbody>
            </DeviceInfoTable>
          </>
        )}

        {showingLog && (
          <>
            <Infolabel>Communication log</Infolabel>
            <DeviceInfoTable>
              <thead>
                <DeviceInfoTableRow>
                  <ShowLogTh>Request Time</ShowLogTh>
                  <ShowLogTh>Synchronization Time</ShowLogTh>
                </DeviceInfoTableRow>
              </thead>
            </DeviceInfoTable>
            <DeviceInfoTableWrapper>
              <DeviceInfoTable>
                <DeviceInfoTBody>
                  {tCam?.sync_log?.map((log) => (
                    <DeviceInfoTableRowWithBorder key={log.id}>
                      <ShowLogTd>{log.time_req}</ShowLogTd>
                      <ShowLogTd>{log.time_proc}</ShowLogTd>
                    </DeviceInfoTableRowWithBorder>
                  ))}
                </DeviceInfoTBody>
              </DeviceInfoTable>
            </DeviceInfoTableWrapper>
          </>
        )}

        <ShowLogButton
          onClick={() => {
            setShowingLog((x) => !x);
          }}
        >
          {showingLog ? 'Hide ' : 'Show '} Communication log
        </ShowLogButton>
      </LeftColumn>

      <RightColumn>
        <Infolabel>Location</Infolabel>
        <MapContainer>
          <MapWrapper latLong={geo} geoImg={TCamIcon}></MapWrapper>
        </MapContainer>

        <Infolabel sx={{ marginTop: '15px' }}>Power Supply Chart</Infolabel>
        <Chart data={chartData}></Chart>
      </RightColumn>
    </DeviceInfoWrapper>
  );
}
