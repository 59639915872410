import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Wrapper = styled('div')`
  width: 100%;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ToolbarButtonWrapper = styled('div')`
  padding: 0 15px;
`;

export const WrapperCell = styled('div')`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WrapperStatus = styled(Box)`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;
