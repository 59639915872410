import { Box, Button, Container, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import Table from '../../../../../components/Table';
import CompanyTcamMenu from './modal/CompanyTcamMenu';
import { useParams } from 'react-router-dom';
import GenericModal from '../../../../../components/generic/modal';
import NavTabs from '../../../../../components/NavTabs';
import OcrStatusModal from './modal/ModalOcrStatus';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';

export default function CompanyOcrStatus() {
  const { id } = useParams();

  const [companyInfo, setCompanyInfo] = useState<string>('');
  const [ocrStatusId, setOcrStatus] = useState<string | number | null>(null);
  const [type, setType] = useState<string | null>(null);
  const { hasRoleAccess } = useAuth();

  const handleModalClose = () => {
    setOcrStatus(null);
    setType(null);
  };

  const handleModalOpen = useCallback((type: string, deviceId: string) => {
    setOcrStatus(deviceId);
    setType(type);
  }, []);

  const handleOpenAddModal = () => {
    setType('create');
  };

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        title="OCR Statuses "
        titleKey="company_name"
        toolbar={
            hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
            <Button color="secondary" onClick={handleOpenAddModal}>
              {' '}
              + Add Status
            </Button>
          )
        }
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
          ],
        }}
        options={{
          moduleName: 'companies/tcam/ocr/list',
          apiUrl: `companies/tcam/ocr/list/${id}`,
          dataSourceKey: 'company_tcam_ocr',
          dataStoreKey: 'id',
          insert: { method: 'post', url: `/companies/tcam/ocr/add/${id}` },
          update: { method: 'post', url: `/companies/tcam/ocr/edit/${id}` },
          remove: { method: 'post', url: `/companies/tcam/ocr/delete/${id}` },
        }}
        columns={[
          {
            width: '2%',
          },
          {
            dataField: 'id',
            caption: 'ID',
            width: '24%',
            alignment: 'left',
            calculateSortValue: 'id',
            cellRender: ({ data }) => (
              <Box
                sx={{
                  opacity: `${!+data.editable ? '0.7' : '1'}`
                }}
              >
                {data.id}
              </Box>
            ),
          },
          {
            dataField: 'status',
            caption: 'Status',
            width: '35%',
            calculateSortValue: 'status',
            cellRender: ({ data }) => (
              <Box
                sx={{
                  opacity: `${!+data.editable ? '0.7' : '1'}`
                }}
              >
                {data.status}
              </Box>
            ),
          },
          {
            dataField: 'color',
            caption: 'Color',
            width: '35%',
            calculateSortValue: 'color',
            cellRender: ({ data }) => (
              <Box
                sx={{
                  background: `#${data.color}`,
                  padding: '5px',
                  width: '57px',
                  height: '27px',
                  color: '#FFFFFF',
                  border: '1px solid gainsboro',
                  borderRadius: '5px',
                  cursor: 'not-allowed',
                  opacity: `${!+data.editable ? '0.7' : '1'}`
                }}
              >
                {data.color}
              </Box>
            ),
          },
          {
            ...(hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && {
              ...{
                caption: '',
                cellRender: ({ data }: { data: any }) => (
                  <CompanyTcamMenu
                    handleModalOpen={handleModalOpen}
                    signId={data.id}
                    disabled={!+data.editable}
                  />
                ),
                width: '6%',
              },
            }),
          },
        ]}
      />
    );
  }, [id, handleModalOpen, hasRoleAccess]);

  const tabs = [
    {
      label: 'Cameras',
      value: '/companies/tcam/list/:id',
      to: `/companies/tcam/list/${id}`,
    },
    {
      label: 'OCR Statuses',
      value: '/companies/tcam/ocr/list/:id',
      to: `/companies/tcam/ocr/list/${id}`,
    },
    {
      label: 'TCAM Settings',
      value: '/companies/tcam/settings/:id',
      to: `/companies/tcam/settings/${id}`,
    },
  ];
  return (
    <>
      <Container maxWidth="xl">
        <NavTabs tabs={tabs} />
      </Container>
      {table}
      <GenericModal show={!!type} onClose={handleModalClose}>
        <OcrStatusModal ocrStatusId={ocrStatusId} companyId={id} onClose={handleModalClose} type={type} />
      </GenericModal>
    </>
  );
}
