import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';

export const SystemInfoWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`
export const SignSystemInfo = styled('div')`
  display: block;
  flex-grow: 1;
  flex-basis: 50%;
`
export const InfoTitle = styled('h4')`
  margin-top: 14px;
  margin-bottom: 0px;  
  padding-top: 0px;
  padding-bottom: 0px;
  color: rgba(0, 0, 0, .54);
  font-weight: normal;
  font-size: 24px;
`
export const ScheduleInfoTitle = styled(InfoTitle)`
  margin-top: 20px;
  margin-bottom: 10px;
`
export const Value = styled('div')`
  font-weight: bold;
`
export const Label = styled('div')`
  font-weight: bold;
`
export const OptionItem = styled(ListItem)`
  padding: 0px 0px 0px 8px;
`

export const LedBrightnessInfoWrapper = styled('div')`
  margin-right: -20px;
`
export const TableWrapper = styled('div')`
  margin-top: 20px;
`

export const AccordionDetailsWrapper = styled('div')`
  overflow: auto;
  padding: 0 20px;
`