import { Box, Button, styled } from '@mui/material';

export const MainWrapper = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 15px;
  margin-top: 15px;
`;

export const SaveButton = styled(Button)`
  background: #4a89dc;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1;
  margin: auto;
`;

export const CancelButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin: auto;
  font-weight: 500;
`;

export const ServiceModalForm = styled('form')`
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 80%;
`;

export const ServiceModalWrap = styled('div')`
  position: relative;
  padding: 20px;
`;

export const ErrorWrap = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled(Box)`
  padding: 8px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`;

export const FormError = styled('span')`
  position: absolute;
  left: 0;
  top: 50%;
  color: red;
`;