import { styled, Button, Box, Typography } from '@mui/material';

export const WelcomeWrapper = styled('h2')`
  color: #fff;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 100;
  margin-top: -55px;
  margin-left: 20px;
`;

export const EmailLabel = styled('span')`
  font-weight: 600;
`;

export const EmailLink = styled('a')`
  text-decoration: none;
  color: rgb(255, 64, 129);
  &:hover: {
    color: #23527c;
  }
`;

export const SubmitButton = styled(Button)`
  background: #4a89dc;
`;

export const BackButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
`;

export const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 30px;
  padding-top: 0;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 30px;
`;

export const FormError = styled('p')`
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 3px;
`;

export const CancelButton = styled(Button)`
  margin-top: 60px;
`;

export const StyledTypography = styled(Typography)`
  color: rgba(0, 0, 0, .54);
  font-size: 1.4rem;
  line-height: 2.5rem;
`;
