import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TokenEditModalContentFormFields } from '../TokenModalContent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useApi } from '../../../../../../context/api';
import _ from 'lodash';

type UsagePlan = {
  id: string;
  name: string;
  type: string;
};

type UsagePlansListResponse = {
  status: 'ERROR' | 'OK';
  usagePlans: UsagePlan[];
};

export default function UsagePlansDropdown(
  { setIsLocationsDisabled }: {setIsLocationsDisabled: (state: boolean) => void}
) {
  const [usagePlansList, setUsagePlansList] = useState<UsagePlan[]>([]);
  const { control, setValue } =
    useFormContext<TokenEditModalContentFormFields>();
  const { apiInstance } = useApi();
  const usagePlans = useWatch({ control, name: 'usagePlans' });
 
  
  useEffect(() => {
    async function fetchUsagePlansList() {
      const response: UsagePlansListResponse = await apiInstance.get(
        `aws/apikey/list/usage-plans`
      );

      setUsagePlansList(Object.values(response.usagePlans));
    }

    fetchUsagePlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (usagePlans) {
      const plansTypes: string[] = []
      
       for (const plan of usagePlans)  {
         usagePlansList.filter((i) => i.id === plan ? plansTypes.push(i.type) : '');
       }

      setIsLocationsDisabled(!_.includes(plansTypes, 'widget'));
    }
  }, [usagePlans, usagePlansList, setIsLocationsDisabled]);


  return (
    <Controller
      name="usagePlans"
      control={control}
      rules={{
        required: 'Required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <InputLabel
            sx={{fontSize: '14px', fontWeight: '700', marginBottom: '5px'}}
            htmlFor="user-plans-list">
              User plans list
          </InputLabel>
          <FormControl fullWidth error={!!error}>
            <Select
              fullWidth
              multiple
              variant="standard"
              labelId="user-simple-select-standard-label"
              data-test="input-usage-plans"
              id="user-select"
              label="User plans list"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected?.map((value: string) => {
                    const foundLabel =
                      usagePlansList?.find((item) => item.id === value)?.name ||
                      value;
                    return (
                      <Chip
                        sx={{backgroundColor: '#4a89dc', color: '#fff', borderRadius: '5px'}}
                        onMouseDown={(event) => event.stopPropagation()}
                        deleteIcon={<DeleteIcon sx={{fill: '#fff'}} />}
                        onDelete={() => {
                          setValue(
                            'usagePlans',
                            selected.filter((item: string) => item !== value)
                          );
                        }}
                        key={value}
                        label={foundLabel}
                      />
                    );
                  })}
                </Box>
              )}
              {...field}
            >
              {usagePlansList?.length &&
                usagePlansList?.map((usagePlan) => (
                  <MenuItem
                    sx={{':hover': {backgroundColor: '#4a89dc !important', color: '#fff'}}}
                    key={usagePlan.id}
                    value={usagePlan.id}
                  >
                    {usagePlan.name}
                  </MenuItem>
                ))}
            </Select>
            {error?.message && (
              <FormHelperText sx={{ marginLeft: '0px' }}>
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    />
  );
}
