import { Button, Container, Typography, Checkbox } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import Table from '../../../../../components/Table';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import NavTabs from '../../../../../components/NavTabs';
import { toast } from 'react-toastify';
import { useApi } from '../../../../../context/api';
import { useAuth } from '../../../../../context/auth';
import { USERROLES } from '../../../../../constants/UserRoles';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';

type CameraSettingsType = {
  [k: string]: {
    type: string;
    value: number;
  };
};

export default function CompanyCameraSettings() {
  const { id } = useParams();
  const { apiInstance } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<{ [key: string]: number }>({});
  const { hasRoleAccess } = useAuth();

  const handleDataChange = useCallback(
    (data: { company_name: string; company_tcam_settings: CameraSettingsType }) => {
      if (!_.size(settings)) {
        const _settings: { [key: string]: number } = {};

        _.forEach(data.company_tcam_settings, (val, key) => {
          _settings[key] = val.value;
        });

        setSettings(_settings);
      }
    },
    [settings]
  );

  const handleSave = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await apiInstance.post(`/companies/tcam/settings/${id}`, settings);
        setLoading(false);
        toast.success(_.get(response, 'message', 'Saved successfully'));
        return response;
      } catch (error) {
        setLoading(false);
        toast.error(_.get(error, 'data.error.message', 'Error while saving'));
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, id]
  );

  const handleDataFormat = (data: any) => {
    if ('company_tcam_settings' in data) {
      return Object.keys(data.company_tcam_settings).map((key) => {
        return {
          name: key,
          ...data.company_tcam_settings[key],
        };
      });
    }

    return [];
  };

  const customizeText = useCallback(({ value }: { value: string }) => {
    return value
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }, []);

  const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [event.target.value]: +event.target.checked,
    }));
  };

  const table = useMemo(() => {
    return (
      <Table
        backButton="/companies/list"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onDataChange={handleDataChange}
        withPaging={false}
        withSorting={false}
        title="Traffic Cameras System Settings "
        titleKey="company_name"
        toolbar={
          hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_POWER_USER], [USERROLES.ROLE_STAGING]]) && (
            <Button
              variant="contained"
              sx={{ backgroundColor: 'rgb(255, 64, 129)', marginRight: '15px' }}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </Button>
          )
        }
        options={{
          moduleName: 'companies/tcam/settings',
          apiUrl: `companies/tcam/settings/${id}`,
          dataSourceKey: 'company_tcam_settings',
          dataStoreKey: '',
        }}
        dataFormatter={handleDataFormat}
        columns={[
          {
            width: '2%',
          },
          {
            dataField: 'name',
            caption: 'Settings Name',
            width: '50%',
            alignment: 'left',
            customizeText: customizeText,
          },
          {
            dataField: 'value',
            caption: 'Settings Value',
            width: '50%',
            alignment: 'left',
            cellRender: (data: { data: { name: string; value: number } }) => (
              <Checkbox value={data.data.name} checked={!!settings[data.data.name]} onChange={handleSettingsChange} />
            ),
          },
        ]}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customizeText, settings, handleSave, hasRoleAccess]);

  const tabs = [
    {
      label: 'Cameras',
      value: '/companies/tcam/list/:id',
      to: `/companies/tcam/list/${id}`,
    },
    {
      label: 'OCR Statuses',
      value: '/companies/tcam/ocr/list/:id',
      to: `/companies/tcam/ocr/list/${id}`,
    },
    {
      label: 'TCAM Settings',
      value: '/companies/tcam/settings/:id',
      to: `/companies/tcam/settings/${id}`,
    },
  ];
  return (
    <>
      {loading ? <LoadPannelLoader/> : ''}
      <Container maxWidth="xl">
        <NavTabs tabs={tabs} />
      </Container>
      {table}
    </>
  );
}
