import React from 'react';
import { Tooltip } from "@mui/material";
import { WrapperCell } from '../../styles';

const Value = (
  { data }: { data: number | string | null }
) => (
    <Tooltip title={data}>
      <WrapperCell>{ data ? data : 'no data' }</WrapperCell>
    </Tooltip>
);

export default Value;
