import { radarCommands } from './constants';

import brightnessFrequencyConfigParse from './brightnessFrequencyConfig';
import serialParse from './serialParse';
import dateTimeParse from './dateTimeParse';
import scheduleParse from './scheduleParser';
import { CMD } from './types'

export default (cmd: CMD): any => {
  try {
    const command = cmd.getCommand();
    const commandLength = cmd.getCommandLength();
    const { hex: valueHex, buffer: valueBuffer } = cmd.getCommandValue();
    const { buffer: originBuffer} = cmd.getOrigin();

    let parsedCommand = null;

    switch (command) {
      case radarCommands.SERIAL:
        parsedCommand = serialParse(cmd);

        break;
      case radarCommands.LEDS_BRIGHTNESS_FREQUENCY:
        parsedCommand = brightnessFrequencyConfigParse(valueBuffer);
        break;

      case radarCommands.SYNC_TIME:
        parsedCommand = dateTimeParse(valueBuffer);
        break;

      case radarCommands.SCHEDULE:
        parsedCommand = scheduleParse(cmd);
        break;
      case '.':
        parsedCommand = null;
        break;
      default:
        return {};
    }

    return parsedCommand;
  } catch (error) {
    console.error(error);
    return null;
  }
};
