import { Button, styled } from '@mui/material';

export const FormWrapper = styled('form')`
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const ButtonsWrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const SaveButton = styled(Button)`
  background: #4a89dc;
  color: #fff;
  font-weight: 900;
`;

export const CancelButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin-left: 15px;
`;

export const AssignLabel = styled('h3')`
  text-align: center;
  padding: 0;
  color: #333;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 40px;
`;

export const SerialLabel = styled('h5')`
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #0000008a;
`;

export const WarningMsg = styled('div')`
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #ffecb3;
  color: #0000008a;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  border-radius: 2px;
  padding: 10px;
`;
