import React from 'react';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';
import { CancelButton, SaveButton, ServiceModalWrap, ButtonsWrapper } from '../../../../styles';
import EditModalContent from './modalComponents/editModal-content';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';
import { useApi } from '../../../../../../context/api';

type TcamType = {
  value: string | number;
  title: string;
};

type CameraModalProps = {
  cameraId: string | number | null;
  tcamTypes: Array<TcamType>;
  onClose: () => void;
  values: {
    normal_voltage: string;
    serial: string;
    type: number;
    sim?: string;
  };
  type: string | null;
  deviceId?: string;
};

const ServiceModal = ({ cameraId, tcamTypes, onClose, values, type, deviceId }: CameraModalProps) => {
  const { loading } = useApi();
  const { insert, update, move, errors } = useDataGrid();
  const defaultValues: any = values;
  const { handleSubmit, control, setValue } = useForm({
    mode: 'all',
    defaultValues,
  });

  const handleSave = handleSubmit((values) => {
    try {
      switch (type) {
        case 'edit':
          if (cameraId) {
            update(cameraId, values, onClose);
          }
          break;
        case 'create':
          insert(values, onClose);
          break;
        case 'delete':
          move(`/companies/tcam/delete/${deviceId}`, { id: cameraId }, onClose);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  });

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return 'Edit Camera';
      case 'create':
        return 'Add New Camera';
      case 'delete':
      default:
        return 'Confirm action';
    }
  };

  return (
    <ServiceModalWrap>
      {loading ? <LoadPannelLoader/> : ''}
      <form onSubmit={handleSave}>
        <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 8 }} align="center" data-test={'titleModal'}>
          {renderTitle()}
        </Typography>
        {type === 'delete' ? (
          <Typography sx={{ marginBottom: 5 }} data-test={'subtitleModal'} align="center">
            Are you sure you want to delete the camera?
          </Typography>
        ) : (
          <EditModalContent control={control} tcamTypes={tcamTypes} errors={errors} setValue={setValue} />
        )}
        <ButtonsWrapper sx={{ marginBottom: 5 }}>
          <CancelButton onClick={onClose}>{type === 'delete' ? 'Disagree' : 'Cancel'}</CancelButton>
          <SaveButton type="submit" variant="contained">
            {type === 'delete' ? 'Agree' : 'Save'}
          </SaveButton>
        </ButtonsWrapper>
      </form>
    </ServiceModalWrap>
  );
};

export default ServiceModal;
