import { Box, styled } from '@mui/material';

export const AppWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
`;
