import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { SignsMenuProps } from './types';
import React, {useState} from "react";
import AppsIcon from "@mui/icons-material/Apps";
import _ from 'lodash';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';
import { useApi } from '../../../../../context/api';
import { useDataGrid } from '../../../../../components/generic/dataGrid';

export default function CompanySignsMenu(
  {
    handleModalOpen,
    emptySelection
  }: SignsMenuProps
) {
    const { loading } = useApi();
    const { dataSource } = useDataGrid();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChoose = (type: string) => {
      handleModalOpen(type, emptySelection.current);
      handleClose();
    }

    const signsCount = dataSource?.items().length;

  return (
    <>
      {loading ? <LoadPannelLoader/> : ''}
      <Button
        color='secondary'
        onClick={() => handleModalOpen('create', '')}
      >
        + Add Sign
      </Button>
      <Button
        color='secondary'
        onClick={handleClick}
      >
        <AppsIcon fontSize="small" /> Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleChoose('moveAll')} disabled={!signsCount}>
          <ListItemIcon>
            <SwapHorizIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Move all to company</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleChoose('moveSelected')}
          disabled={!(_.size(emptySelection.current) > 1)}
        >
          <ListItemIcon>
            <SwapHorizIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Move selected to company</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
