import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import {
  CardBoxStyle,
  ErrorMessage,
  InputBox,
  TitleHeading,
  TypoBox,
} from './styles';
import { Credentials } from '../../../../context/commonInterface';
import { useAuth } from '../../../../context/auth';
import _ from 'lodash';
import { useState } from 'react';
import { showErrorMessage } from '../../../../components/Form/utils';

interface FormData {
  login: string;
  password: string;
}

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const onLoginHandler = handleSubmit(async ({ login, password }) => {
    const credentials: Credentials = {
      login,
      password,
    };

    setLoading(true);
  
    try {
      await signIn(credentials);
    } catch (error) {
      const apiErrors = _.get(error, 'error.validationErrors', null);

      if (apiErrors) {
        showErrorMessage(apiErrors, setError)
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <Box
      component={'div'}
      alignContent={'center'}
      justifyContent={'space-around'}
      display="flex"
    >
      <CardBoxStyle onSubmit={onLoginHandler} noValidate>
        <Card
          sx={{
            backgroundColor: '#fafafa',
            height: '77vh',
          }}
        >
          <CardContent>
            <TypoBox>
              <TitleHeading>Sign in</TitleHeading>
            </TypoBox>
            <InputBox>
              <TextField
                label="Login"
                variant="standard"
                {...register('login', { required: true })}
                sx={{ width: '100%' }}
                aria-invalid={errors.login ? 'true' : 'false'}
              />
              {errors.login && (
                <ErrorMessage>
                  {errors.login.message}
                </ErrorMessage>
              )}
            </InputBox>

            <InputBox>
              <TextField
                label="Password"
                variant="standard"
                type={'password'}
                {...register('password', { required: true })}
                sx={{ width: '100%' }}
                aria-invalid={errors.password ? 'true' : 'false'}
              />
              {errors.password && (
                <ErrorMessage>
                  {errors.password.message}
                </ErrorMessage>
              )}
            </InputBox>
          </CardContent>
          <CardActions>
            <InputBox sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                disabled={loading}
              >
                {loading ? 'Processing' : 'Login'}
              </Button>
            </InputBox>
          </CardActions>
        </Card>
      </CardBoxStyle>
    </Box>
  );
};

export default Login;
