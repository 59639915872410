import { DialogTitle, styled, Box } from '@mui/material';


export const StyledDialogTitle = styled(DialogTitle)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const ModalActionButtons = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
