import { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Table from '../../../../../components/Table';

import { Wrapper } from "../styles";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useApi } from '../../../../../context/api';
const FULLDATE_FORMAT = 'YYYY-MM-DD';

export default function SignLog() {
  const { id } = useParams();
  const [syncLogs, setSyncLogs] = useState<{ [key: string]: any } | null>(null);
  const [ dateStart ] = useState<string>(moment().subtract(1, 'weeks').format(FULLDATE_FORMAT));
  const [ dateEnd ] = useState<string>(moment().format(FULLDATE_FORMAT));
  const { apiInstance } = useApi();
  useEffect(() => {
    async function fetchSyncLogs() {
      const response: { [key: string]: { [key: string]: any } } = await apiInstance.get(`/sign/sync-log/${id}`);
      setSyncLogs(response.sync_log)
    }

    fetchSyncLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return useMemo(() => (
    <Wrapper>
      <Table
        backButton={"/sign"}
        isCustomTitle={true}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <Typography variant="h5">Sign Communication Log</Typography>
            {syncLogs && (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: 'italic',
                    margin: '15px 15px 10px 15px',
                    fontWeight: 500,
                    fontSize: '16px'
                  }}
                >
                  SN: {syncLogs?.serial},
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: 'italic',
                    margin: '15px 15px 10px 0px',
                    fontWeight: 500,
                    fontSize: '16px'
                  }}
                >
                  Owner: {syncLogs?.owner}
                </Typography>
              </>
            )}
          </Box>
        }
        filter={{
          withPageSize: false,
          inputs: [
            {
              type: 'rangeDatePicker',
              name: 'rangeDatePicker',
              label: 'Pick dates interval to filter data',
              startName: 'date_from',
              endName: 'date_to',
              dateRangeStart: dateStart,
              dateRangeEnd: dateEnd,
            }
          ],
        }}
        options={{
          moduleName: "sync_log",
          apiUrl: `/sign/sync-log/${id}`,
          dataSourceKey: "sync_log.sync_list",
          dataStoreKey: "id",
          paginationKey: 'sync_log'
        }}
        columns={[
          {
            dataField: 'time_req',
            caption: 'Request Time',
          },
          {
            dataField: 'time_proc',
            caption: 'Synchronization Time',
          },
          {
            dataField: 'set_name',
            caption: 'Data',
          },
          {
            name: '',
            caption: 'Log Info',
            width: 64,
            cellRender: () =>
              <IconButton data-test={`syncSetting`}><SettingsIcon /></IconButton>,
          },
        ]}
      />
    </Wrapper>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [syncLogs?.serial, syncLogs?.owner, id, dateEnd, dateStart]);
}
