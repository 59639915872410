import { Button, styled } from '@mui/material';

export const DeviceInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
`;

export const DeviceInfoTableWrapper = styled('div')`
  height: calc(55vh - 8px);
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
`

export const LeftColumn = styled('div')`
  flex-basis: 58%;
`;

export const RightColumn = styled('div')`
  flex-basis: 40%;
`;

export const Infolabel = styled('div')`
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  color: #0000008a;
`;

export const DeviceInfoTable = styled('table')`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff;
  width: 100%;
`;

export const DeviceInfoTBody = styled('tbody')`
  max-height: 100%;
`

export const DeviceInfoTableRow = styled('tr')`
  height: 48px;
  :hover {
    background: #eee;
  }
`;

export const DeviceInfoTableRowWithBorder = styled(DeviceInfoTableRow)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

export const DeviceInfoTableHeaderCell = styled('td')`
  font-weight: 800;
  height: 48px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #eee;
  color: #546a79;
  font-weight: bold;
  padding-left: 24px;
`;

export const DeviceInfoTableRowCell = styled('td')`
  padding-right: 24px;
  text-align: right;
  height: 48px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const MapContainer = styled('div')`
  width: 100%;
  height: 300px;
`;

export const ShowLogButton = styled(Button)`
  margin: 30px 0;
  background: #4a89dc;
  color: #fff;
  :hover {
    background: #3f51b5;
  }
`;

export const ShowLogTh = styled('th')`
  background: #4a89dc;
  color: #fff;
  text-align: left;
  height: 48px;
  padding-left: 24px;
  width: 50%;
  :nth-last-child(1){
    border-left: solid 1px rgba(255,255, 255, 0.36);
  }
`;

export const ShowLogTd = styled('td')`
  text-align: left;
  height: 48px;
  padding-left: 24px;
`;
