import React from 'react';

import { TCAMEditProps } from './types';
import {TextField, Select, MenuItem, InputLabel} from '@mui/material';
import { Controller } from 'react-hook-form';
import modifyError from '../../../utils/modifyError';
import { useDataGrid } from '../../../../../../../components/generic/dataGrid';

export default function SignEditModalContent(
    { control, tcamTypes, errors, setValue }: TCAMEditProps
) {
    const { eraseError } = useDataGrid();

    return (
      <>
       <Controller
          name="serial"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              helperText={modifyError(errors?.serial)}
              fullWidth
              label="MAC Address"
              sx={{ marginBottom: 7 }}
              required
              variant="standard"
              inputProps={{
                  maxLength: 17
              }}
              {...field}
              onChange={(e) => {
                const name = 'serial';
                const val = e.target.value
                  .replace(/[^0-9A-Fa-f]/g, '')
                  .replace(/(..)(?!$)/g, '$1:');

                setValue(name, val);
                eraseError(name);
              }}
            />
          )}
        />
        <Controller
          name="sim"
          control={control}
          rules={{
            minLength: {
              value: 18,
              message: 'SIM number must be at least 18 digits long',
            },
            maxLength: {
              value: 22,
              message: 'SIM number must be no more than 22 digits long',
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              helperText={error ? error.message : modifyError(errors?.sim)}
              fullWidth
              label="SIM"
              inputProps={{
                maxLength: 22
              }}
              sx={{ marginBottom: 7 }}
              variant="standard"
              {...field}
              onChange={(e) => {
                const name = 'sim';
                const val = e.target.value
                  .replace(/[^0-9]/g, '');

                setValue(name, val);
                eraseError(name)
              }}
            />
          )}
        />
        <Controller
          name="normal_voltage"
          control={control}
          render={({ field }) => (
            <TextField
              helperText={modifyError(errors?.normal_voltage)}
              fullWidth
              sx={{ marginBottom: 7 }}
              type='number'
              margin='normal'
              label="Normal Voltage (V) *"
              variant="standard"
              {...field}
              onChange={(e) => {
                const name = 'normal_voltage';

                setValue(name,  e.target.value);
                eraseError(name)
              }}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <>
              <InputLabel htmlFor="tz-select">Type *</InputLabel>
              <Select
                sx={{ marginBottom: 7 }}
                fullWidth
                required
                variant="standard"
                label="Type"
                {...field}
              >
                {tcamTypes.map((tcam) => (
                  <MenuItem value={tcam.value} key={tcam.value}>
                    {tcam.title}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </>
    );
};
