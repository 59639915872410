import React from 'react';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';
import SimpleSelect from './SimpleSelect';
import FetchSelect from './FetchSelect';

const SelectField = ({
  input,
  field,
}: FilterControlFieldProps<Types.SelectInput>) => {
  if (input.fetch) {
    return <FetchSelect input={input} field={field} />
  }

  return <SimpleSelect input={input} field={field} />
};

export default SelectField;
