import { useEffect, useState, useCallback } from 'react';
import { GoogleCharts } from 'google-charts';

export default function Chart({ data }: { data: Array<[number, number]> }) {
  const [chartEleRef, setchartEleRef] = useState<HTMLDivElement>();
  const eleRefCB = useCallback((node: HTMLDivElement) => {
    if (node) {
      setchartEleRef(node);
    }
  }, []);
  useEffect(() => {
    if (!chartEleRef) return;
    GoogleCharts.load(drawCharts, {
      packages: ['annotationchart'],
    });
    function drawCharts() {
      const converted: Array<[Date, number, string]> = [];
      const chartData = new GoogleCharts.api.visualization.DataTable(data);
      chartData.addColumn('datetime', 'Date');
      chartData.addColumn('number', 'Battery Voltage');
      chartData.addColumn('string', 'Battery Voltage title');
      data.forEach((e) => {
        converted.push([new Date(e[0] * 1000), e[1], '']);
      });
      chartData.addRows(converted);
      const chart = new GoogleCharts.api.visualization.AnnotationChart(
        chartEleRef
      );
      const today = new Date();
      const startZoom = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      const options: {
        displayAnnotations: boolean;
        legendPosition: string;
        displayZoomButtons: boolean;
        zoomStartTime: Date | null;
        zoomEndTime: Date | null;
        dateFormat: string;
      } = {
        displayAnnotations: false,
        legendPosition: 'newRow',
        displayZoomButtons: false,
        zoomStartTime: startZoom,
        zoomEndTime: new Date(),
        dateFormat: 'yyyy-MM-dd HH:mm',
      };
      if (
        converted.length !== 0 &&
        (today < converted[0][0] || startZoom > converted[0][0])
      ) {
        options.zoomStartTime = null;
        options.zoomEndTime = null;
      }

      if (chartData.getNumberOfRows() === 0) {
        GoogleCharts.api.visualization.errors.addError(
          chartEleRef,
          'No power supply data available for this sign'
        );
      } else {
        chart.draw(chartData, options);
      }
    }
    return () => {
      if (!chartEleRef) return;
      chartEleRef.innerHTML = '';
    };
  }, [data, chartEleRef]);

  return <div ref={eleRefCB} data-test={'chartInfo'}></div>;
}
