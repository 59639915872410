import { Controller, useFormContext } from 'react-hook-form';
import { TokenEditModalContentFormFields } from '../TokenModalContent';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../../../context/api';
import LoadPannelLoader from '../../../../../../components/LoadPanelLoader';

type GroupSite = { id: string; name: string };

type Site = {
  groupName: string;
  groupSites: GroupSite[];
};

type LocationsListResponse = {
  status: 'ERROR' | 'OK';
  sites: Site[];
};

export default function LocationsDropdown({isLocationsDisabled}:{ isLocationsDisabled: boolean }) {
  const [sitesList, setSitesList] = useState<Site[]>([]);
  const { id } = useParams();
  const { control, setValue } =
    useFormContext<TokenEditModalContentFormFields>();
  const { apiInstance, loading, setLoading } = useApi();
  useEffect(() => {
    async function fetchLocationsList() {
      setLoading(true);
      const response: LocationsListResponse = await apiInstance.get(
        `locationslist/${id}`
      );
      setLoading(false);

      setSitesList(response.sites);
    }
    fetchLocationsList();
  }, [id, apiInstance, setLoading]);

  const flattenLocationsArray = useMemo(() => {
    let resArray: GroupSite[] = [];
    for (let i = 0; i < sitesList.length; i++) {
      resArray = resArray.concat(sitesList[i].groupSites);
    }
    return resArray;
  }, [sitesList]);

  return (
    <Controller
      name="locationIds"
      control={control}
      render={({ field }) => (
        <Box>
          {loading ? <LoadPannelLoader/> : ''}
          <InputLabel
            sx={{fontSize: '14px', fontWeight: '700', marginBottom: '5px'}}
            htmlFor="locations-list">Locations list</InputLabel>
          <Select
            disabled={isLocationsDisabled}
            fullWidth
            variant="standard"
            labelId="locations-simple-select-standard-label"
            id="locations-select"
            label="Locations list"
            data-test="input-location-ids"
            multiple
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected?.map((value: string) => {
                    const foundLabel =
                      flattenLocationsArray.find((item) => {
                        return String(item.id) === String(value);
                      })?.name || value;

                    return (
                      <Chip
                        sx={{backgroundColor: '#4a89dc', color: '#fff', borderRadius: '5px'}}
                        onMouseDown={(event) => event.stopPropagation()}
                        deleteIcon={<DeleteIcon sx={{fill: '#fff'}} />}
                        onDelete={() => {
                          setValue(
                            'locationIds',
                            selected.filter((item: string) => item !== value)
                          );
                        }}
                        key={value}
                        label={foundLabel}
                      />
                    );
                  })}
                </Box>
              );
            }}
            {...field}
          >
            {sitesList?.map((site) =>
              site.groupSites?.map((groupSite, index) => {
                return index === 0 ? (
                  [
                    <ListSubheader
                      key={site.groupName}
                      style={{ fontWeight: 600 }}
                    >
                      {site.groupName}
                    </ListSubheader>,
                    <MenuItem
                      sx={{':hover': {backgroundColor: '#4a89dc !important', color: '#fff'}}}
                      value={groupSite.id}
                      key={groupSite.id}
                      >
                      {groupSite.name}
                    </MenuItem>,
                  ]
                ) : (
                  <MenuItem
                    sx={{':hover': {backgroundColor: '#4a89dc !important', color: '#fff'}}}
                    value={groupSite.id}
                    key={groupSite.id}
                  >
                    {groupSite.name}
                  </MenuItem>
                );
              })
            )}
          </Select>
        </Box>
      )}
    />
  );
}
