import { styled } from '@mui/material';

export const TreeViewWrap = styled('div')({
    margin: '0',
    padding: '0',
    listStyle: 'none',
    backgroundColor: 'inherit'
});

export const NodeWrap = styled('div')({
    margin: '10px 0',
    paddingLeft: '30px',
    color: '#fff'
});

export const ToggleBtn = styled('button')({
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
});

export const Triangle = styled('span')<{ isRotated?: boolean }>(({ isRotated = false }) => ({
    width: '0px',
    display: 'inline-block',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#fff',
    height: '0px',
    border: '4px solid transparent',
    borderTop: '8px solid #fff',
    position: 'relative',
    top: '5px',
    transformOrigin: '50% 25%',
    transform: `rotate(${isRotated ? '-90' : '0'}deg)`
  }));

  export const Value = styled('span')<{ isZeroValue: boolean }>(({ isZeroValue = false }) =>
    ({color: isZeroValue ? '#e6db74' : '#66d9ef'}))