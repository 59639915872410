export const USERROLES = {
  ROLE_SUPER_ADMIN: 1,
  ROLE_POWER_USER: 2,
  ROLE_USER: 3, //Support User
  ROLE_STAGING: 5,
  ROLE_RESTRICTED: 4,
} as const;

export const ROLES_LIST = [
  {
      "id": 1,
      "name": "Administrator"
  },
  {
      "id": 2,
      "name": "User"
  },
  {
      "id": 3,
      "name": "Reporter"
  },
  {
      "id": 5,
      "name": "Support"
  },
  {
      "id": 6,
      "name": "Super User"
  }
]
