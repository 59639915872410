import { NavLink } from 'react-router-dom';
import { styled, css, Box, Tabs } from '@mui/material';

export const NavWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    height: 40px;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavBar = styled('div')`
  button {
    font-weight: 700;
    font-size: 14px;
    height: 64px;
    line-height: 64px;
    padding: 0 24px;
    :active {
      border-bottom: 2px solid #9fa8da;
    }
  }
`;

export const UserMenu = styled('div')`
  margin: 0;
  padding: 12px 0;
`;

const navLinkCss = css`
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  height: 100%;
  line-height: 64px;
  padding: 0px 24px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: #757575;
`;

export const StyledMenuLink = styled('div')`
  ${navLinkCss}
  cursor: pointer;
  &.active {
    border-bottom: 4px solid #4a89dc;
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${navLinkCss}
  &.active {
    border-bottom: 4px solid #4a89dc;
  }
`;

export const StyledSubNavLink = styled(StyledNavLink)`
  ${navLinkCss}
  line-height: 40px;
  text-decoration: none;
  text-transform: capitalize;
  color: #000000de;
  padding: 6px 16px;
  font-weight: 300;
  width: 100%;
  &.active {
    border-bottom: none;
    color: #4a89dc;
  }
`;

export const StyledNavItem = styled(Box)`
  color: #000000de;
  font-weight: 300;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 6px 16px;
`