import { Button, styled } from '@mui/material';

export const ModuleButtonStyled = styled(Button)(
  ({ disabled }: { disabled?: boolean }) => ({
    disabled: disabled,
    opacity: disabled ? '0.6' : '1',
    cursor: 'pointer',
    lineHeight: '36px',
    verticalAlign: 'middle',
    padding: '1px',
    minWidth: '40px',
    width: '40px',
    height: '40px',
    background: '#9e9e9ecc',
    img: {
      width: '100%',
    },
    '&:hover': {
      background: '#9e9e9ecc',
    },
  })
);

export const DeviceCount = styled('div')`
  position: absolute;
  top: -7px;
  right: -14px;
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColorInput = styled('input')`
  width: 100%;
  display: block;
`;
