import React, { memo } from 'react';
import { useNavigate } from 'react-router';

import PieChartOverview, {
  DataItemType,
  DictionaryType,
  PaletteType,
} from '../../../components/pieChartOverview'

import { ChartWrapper, Title } from './styles';


type ChartOverviewPropsType = {
  data : DataItemType;
  dictionary : DictionaryType;
  palette : PaletteType;
  title: string;
  link: string;
};

const ChartOverview = ({ data, dictionary, palette, title, link}: ChartOverviewPropsType) => {
  const history = useNavigate();

  return (
    <ChartWrapper onClick={() => history(link)}>
      <Title>{title}</Title>
      <PieChartOverview
        data={data}
        dictionary={dictionary}
        palette={palette}
        link={link}
      />
    </ChartWrapper>
  );
};
export default memo(ChartOverview);
