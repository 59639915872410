import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, TextField, Snackbar } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import welcomeImg from '../../../../assets/img/welcome_card.jpg'
import { User } from '../../../../context/commonInterface';

import {
  EmailLabel, EmailLink, WelcomeWrapper, SubmitButton, BackButton, FormWrapper, ButtonsWrapper, FormError
} from './styles';
import { useApi } from '../../../../context/api';
import { ReactComponent as Spinner } from '../../../../assets/img/spinner.svg';
import { ChangePasswordAPIResponse, ErrorType, SubmitData } from './types';
import LoadPannelLoader from '../../../LoadPanelLoader';

const defaultValues = {
  old_password: '',
  password: '',
  password_repeat: ''
};

const UserProfileContent = ({ onClose }: { onClose: (msg: string) => void;}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    reset
  } = useForm({ mode: 'all', defaultValues });
  const { apiInstance } = useApi();
  const [isChangePassMode, setIsChangePassMode] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<Partial<User> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { old_password, password, password_repeat } = watch();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response: { identity: User } = await apiInstance.get('/profile');
        setIsLoading(false);
        const { identity: { username, email } } = response;

        setCurrentUser({ username, email });
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback((data: SubmitData) => {
    async function saveData() {
      setIsLoading(true);
      const result: ChangePasswordAPIResponse = await apiInstance.post(
        `/profile/passwordreset`,
        {
          old_password: data.old_password,
          password: data.password,
          password_repeat: data.password_repeat,
        }
      );
      setIsLoading(false);
      if (result.errors) {
        const { errors } = result;

        for (const key in errors) {
          setError(key as ErrorType, {message: Object.values(errors[key as ErrorType])[0]})
        }
        return;
      }
      reset();
      setIsChangePassMode(false);
      onClose(result.msg);
    }

    saveData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <img src={welcomeImg} alt='welcome-image' style={{ width: '100%' }} />
      <WelcomeWrapper>
        Welcome, {currentUser?.username}
      </WelcomeWrapper>
      {isLoading ? <LoadPannelLoader/> : ''}
      <Box sx={{ padding: '16px' }}>
        {isChangePassMode ? (
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <FormWrapper>
              <Box sx={{ width: 1 }}>
                <Controller
                  control={control}
                  name="old_password"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      autoComplete="old_password"
                      label="Old password*"
                      variant="standard"
                      type="password"
                      {...field}
                    />
                  )}
                />
                {errors?.old_password?.message && (
                  <FormError>{errors.old_password.message}</FormError>
                )}
              </Box>
              <Box sx={{ width: 1 }}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      autoComplete="password"
                      label="Password*"
                      variant="standard"
                      type="password"
                      {...field}
                    />
                  )}
                />
                {errors?.password?.message && (
                  <FormError>{errors.password.message}</FormError>
                )}
              </Box>
              <Box sx={{ width: 1 }}>
                <Controller
                  control={control}
                  name="password_repeat"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      autoComplete='password_repeat'
                      label="Password confirmation*"
                      variant="standard"
                      type="password"
                      {...field}
                    />
                  )}
                />
                {errors?.password_repeat?.message && (
                  <FormError>{errors.password_repeat.message}</FormError>
                )}
              </Box>
            </FormWrapper>

            <ButtonsWrapper>
              <SubmitButton
                variant='contained'
                type='submit'
                size='large'
                disabled={isLoading || !password || !old_password || !password_repeat}
                sx={isLoading ? {
                  display: 'flex',
                  gap: '10px',
                  ':disabled': {
                    color: '#fff',
                    backgroundColor: 'rgb(77, 75, 154)',
                  }
                } : undefined}
              >
                Change
              </SubmitButton>
              <BackButton
                sx={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                  '&:hover' : {
                    backgroundColor: 'hsla(0,0%,62%,.2)',
                    boxShadow: 'none',
                  }}}
                variant='contained' onClick={() => {
                  setIsChangePassMode(false);
                  reset();
                  }}>Back</BackButton>
            </ButtonsWrapper>
          </form>
        ) : (
          <>
            <Typography>
              <EmailLabel>Email: </EmailLabel>
              <EmailLink href={`mailto:${currentUser?.email}`}>{currentUser?.email}</EmailLink>
            </Typography>
            <SubmitButton
              onClick={() => setIsChangePassMode(true)}
              variant='contained'
              type='submit'
              size='large'
              disabled={false}
              sx={{ marginTop: '25px' }}
            >
              Change password
            </SubmitButton>
          </>
        )}
      </Box>
    </>
  );
};

export default UserProfileContent;

