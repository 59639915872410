import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  CompanyListResponse,
  LocationslistResponse,
  SignModel,
  SignModels,
  GroupLocation,
} from '../types';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, MenuItem } from '@mui/material';
import {
  AssignLabel,
  ButtonsWrapper,
  CancelButton,
  FormWrapper,
  SaveButton,
  SerialLabel,
  WarningMsg,
} from './styles';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { DefaultValues } from './types';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../../../../../context/api';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';


export interface IAssignDevice {
  deviceId: string;
  onClose: (info?: { showSnackbarMsg: boolean; message: string }) => void;
}

export function AssignDevice({ deviceId, onClose }: IAssignDevice) {
  const [loading, setLoading] = useState<boolean>(false);
  const [signModels, setSignModels] = useState<SignModel[]>();
  const [companiesList, setCompaniesList] = useState<CompanyListResponse>();
  const [groupLocations, setGroupLocations] = useState<GroupLocation[]>();
  const [selectedSignModelId, setSelectedSignModelId] = useState<number>();
  const [showLocationWarning, setShowLocationWarning] = useState<boolean>();
  const defaultValues: DefaultValues = {
    model_id: '',
    company_id: '',
    location_id: '',
  };
  const { apiInstance } = useApi();
  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'all',
    defaultValues,
  });
  const selectedCompanyId = watch('company_id');

  useEffect(() => {
    if (!deviceId) return;

    async function fetchSignModels() {
      const result: SignModels = await apiInstance.get(
        `companies/signs/list/filters/sign-models`
      );
      setSignModels(result.signs_models);
    }
    fetchSignModels();

    async function fetchCompanies() {
      try {
        setLoading(true);
        const result: CompanyListResponse = await apiInstance.get(
          `sign/assign/${deviceId}`
        );
        setLoading(false);
        setSelectedSignModelId(result.signModelId);
        setCompaniesList(result);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }

    }
    fetchCompanies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  useEffect(() => {
    if (!selectedSignModelId) return;
    if (signModels?.find((x) => x.id === selectedSignModelId)) {
      setValue('model_id', selectedSignModelId.toString());
    } else {
      setSelectedSignModelId(undefined);
    }
  }, [selectedSignModelId, setValue, signModels]);

  useEffect(() => {
    async function fetchLocations() {
      try {
        if (!selectedCompanyId) return;
        setLoading(true);
        const result: LocationslistResponse = await apiInstance.get(
          `locationslist/${selectedCompanyId}`
        );
        setLoading(false);
        setGroupLocations(result.sites);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId]);

  const handleLocationChange = useCallback(
    (
      e: SelectChangeEvent,
      field: ControllerRenderProps<DefaultValues, 'location_id'>
    ) => {
      field.onChange(e.target.value);
      const selectEle = document.querySelector('#location-select');
      const options = selectEle?.getElementsByTagName('option');
      let selectedOption: HTMLOptionElement | undefined;
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            selectedOption = options[i];
          }
        }
      }
      if (selectedOption?.dataset.radar) {
        setShowLocationWarning(true);
        return;
      }
      setShowLocationWarning(false);
    },
    []
  );

  const onSubmit = useCallback(
    (data: DefaultValues) => {
      if (data.company_id.length < 1 && !data.model_id) {
        toast.error('Please choose the parameters.');
        return;
      }

      async function assignDevice() {
        const reqBody: Partial<DefaultValues> = {};
        if (data['model_id']) {
          reqBody['model_id'] = data['model_id'];
        }
        reqBody['company_id'] = data['company_id'];
        if (data['location_id']) {
          reqBody['location_id'] = data['location_id'];
        }
        try {
          setLoading(true);
          const response: { message: string; status: string } = await apiInstance.post(
            `/sign/assign/${deviceId}`,
            reqBody
          );
          setLoading(false);
          toast.success(_.get(response, 'msg', 'Saved successfully'));
          onClose({ showSnackbarMsg: true, message: response?.message });
        } catch (error) {
          setLoading(false);
          console.error(error);
          toast.error(
            _.get(
              error, 'data.error.message' || 'error.message', 'Error while saving. Please check the parameters'
            )
          );
        }
      }

      assignDevice();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceId, onClose]
  );

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      {loading ? <LoadPannelLoader/> : ''}
      <AssignLabel>Assign to company</AssignLabel>
      <SerialLabel>
        Serial: <b>{companiesList?.unprocessed_serial}</b>
      </SerialLabel>
      <Box sx={{ width: 1 }}>
        <Controller
          name="model_id"
          control={control}
          defaultValue={selectedSignModelId?.toString()}
          render={({ field }) => (
            <>
              <InputLabel id="model-select">Signs Models</InputLabel>
              <Select
                fullWidth
                variant="standard"
                labelId="demo-simple-select-standard-label"
                id="model-select"
                label="Signs Models"
                disabled={!companiesList?.allowChangeSignModel}
                {...field}
                value={selectedSignModelId || field.value}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {signModels?.map((signModel) => (
                  <MenuItem value={signModel.id} key={signModel.id}>
                    {signModel.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        ></Controller>
      </Box>
      <Box sx={{ width: 1 }}>
        <Controller
          name="company_id"
          control={control}
          render={({ field }) => (
            <>
              <InputLabel id="company-select">Company</InputLabel>
              <Select
                fullWidth
                variant="standard"
                displayEmpty
                labelId="demo-simple-select-standard-label"
                id="company-select"
                label="Company"
                {...field}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {companiesList?.companies.map((company) => (
                  <MenuItem value={company.cid} key={company.cid}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </Box>
      <Box sx={{ width: 1 }}>
        <Controller
          name="location_id"
          control={control}
          render={({ field }) => (
            <>
              <InputLabel htmlFor="location-select">Location</InputLabel>
              <Select
                fullWidth
                variant="standard"
                native
                id="location-select"
                label="Location"
                {...field}
                value={field.value}
                onChange={(e) => handleLocationChange(e, field)}
              >
                <option aria-label="None" value="" />
                {groupLocations?.map((groupLocation) => (
                  <optgroup
                    label={groupLocation.groupName}
                    key={groupLocation.groupName}
                  >
                    {groupLocation.groupSites.map((location) => (
                      <option
                        data-radar={location.deviceSerial}
                        value={location.id}
                        key={location.id}
                      >
                        {location.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </>
          )}
        ></Controller>
      </Box>
      {showLocationWarning && (
        <WarningMsg>
          <ReportProblemIcon />
          <p>
            Warning! Location already has an assigned sign. Re-assigning will
            overwrite current location and old assigned sign settings
          </p>
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowLocationWarning(false)}
          >
            <CloseIcon />
          </Box>
        </WarningMsg>
      )}
      <ButtonsWrapper>
        <SaveButton type="submit" variant="contained" disabled={loading}>
          Save
        </SaveButton>
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </ButtonsWrapper>
    </FormWrapper>
  );
}
