import { styled } from '@mui/material';

export const MapContainer = styled('div')`
  width: 100%;
  height: 300px;
`;

export const CommLogWrapper = styled('div')`
  height: 400px;
  overflow: hidden;
`;

export const CommLogTable = styled('table')`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff;
  width: 100%;
`;

export const CommLogRow = styled('tr')`
  height: 48px;
  :hover {
    background: #eee;
  }
`;

export const CommLogHeaderCell = styled('td')`
  font-weight: 800;
  height: 48px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #eee;
  color: #546a79;
  font-weight: bold;
  padding-left: 24px;
`;

export const ShowLogTd = styled('td')`
  text-align: left;
  height: 48px;
  padding-left: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const NothingToShow = styled('p')`
  text-align: center;
`;
