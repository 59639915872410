import { useState } from 'react';
import {
  Infolabel,
  DeviceInfoTable,
  DeviceInfoTableRow,
  ShowLogTh,
  ShowLogTd,
  InfoLabelUnderline,
  ShowLogButton,
  SelectDatePicker,
} from '../styles';
import { CancelButton } from '../../../../../../components/Form/styles';
import { SignDetail } from '../../types';
import { get, keys } from 'lodash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { FieldValues } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useConfirm } from '../../../../../../context/dialog';
import { toast } from 'react-toastify';
import { useApi } from '../../../../../../context/api';

const FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default function FutureDates({
  sign,
  onClose,
  locationId,
  updateData
}: {
  sign: SignDetail | undefined;
  onClose: () => void;
  locationId: number | undefined;
  updateData: () => void;
}) {
  const { confirm } = useConfirm();
  const [minVal, setMinVal] = useState<Dayjs | unknown | null>(
    dayjs(sign?.futureDates.minDate) || dayjs()
  );
  const [maxVal, setMaxVal] = useState<Dayjs | unknown | null>(
    dayjs(sign?.futureDates.maxDate) || dayjs()
  );
  const matches = sign?.futureDates?.matches || [];
  const { apiInstance } = useApi();
  const handleRemove = () => {
    confirm(
      "Are you sure want to delete all future dates? This action can't be undone",
      async () => {
        try {
          const res = await apiInstance.post(`/sign/delete-future-date/${locationId}`, {
            date_start: (minVal as Dayjs).format(FORMAT),
            date_end: (maxVal as Dayjs).format(FORMAT),
          });
          updateData()
          onClose();
          toast.success(
            get(res, 'msg', 'Future dates are deleted successfully')
          );
        } catch (error) {
          toast.error(
            get(
              error,
              'error.message',
              'Something went wrong while processing your request'
            )
          );
        }
      }
    );
  };

  return (
    <>
      <Infolabel>Future Dates</Infolabel>
      <InfoLabelUnderline>
        We have detected that some stat/logs contains invalid values, please
        review the list below and confirm deleting corrupted data:
      </InfoLabelUnderline>
      <DeviceInfoTable>
        <tbody data-test={'futureTable'}>
          <DeviceInfoTableRow data-test={'headerRow'}>
            <ShowLogTh>Entity</ShowLogTh>
            <ShowLogTh>Rows Found</ShowLogTh>
            <ShowLogTh>Min Date</ShowLogTh>
            <ShowLogTh>Max Date</ShowLogTh>
          </DeviceInfoTableRow>
          {keys(matches).map((name) => (
            <DeviceInfoTableRow key={name} data-test={`infoRow-${name}`}>
              <ShowLogTd>{matches[name].category}</ShowLogTd>
              <ShowLogTd>{matches[name].count}</ShowLogTd>
              <ShowLogTd>{matches[name].time_min}</ShowLogTd>
              <ShowLogTd>
                {matches[name].time_max}({matches[name].diffForHumans})
              </ShowLogTd>
            </DeviceInfoTableRow>
          ))}
        </tbody>
      </DeviceInfoTable>
      <Infolabel>
        Please pick dates between which you want to delete invalid data
      </Infolabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SelectDatePicker
          label={'From'}
          value={minVal}
          onChange={(value) => setMinVal(value)}
          slots={{
            textField: (params: FieldValues) => (
              <TextField
                variant="standard"
                data-test={'from-input'}
                {...params}
              />
            ),
          }}
          maxDate={maxVal}
          minDate={dayjs(sign?.futureDates?.minDate)}
        />
        <SelectDatePicker
          label={'To'}
          value={maxVal}
          onChange={(value) => setMaxVal(value)}
          slots={{
            textField: (params: FieldValues) => (
              <TextField
                variant="standard"
                data-test={'to-input'}
                {...params}
              />
            ),
          }}
          maxDate={dayjs(sign?.futureDates?.maxDate)}
          minDate={minVal}
        />
      </LocalizationProvider>
      <ShowLogButton onClick={handleRemove} data-test={'confirmDelete'}>
        Confirm delete Future Dates
      </ShowLogButton>
      <CancelButton onClick={onClose} data-test={'cancelDelete'}>
        Cancel
      </CancelButton>
    </>
  );
}
