import { MixedInput } from '../../../../components/generic/filter/context/types';
import SubscriptionExpireControl from './SubscriptionExpireControl';

const subscriptionFilters: MixedInput[] = [
  {
    type: 'selectionCheckbox',
    name: 'hasSubscription',
    options: [
      {
        title: 'Active',
        value: 1
      },
      {
        title: 'Subsciption expired',
        value: 0
      }
    ]
  }
];

const locationFilters: MixedInput[] = [
  {
    type: 'selectionCheckbox',
    name: 'assigned',
    options: [
      {
        title: 'Assigned to location',
        value: 1
      },
      {
        title: 'Not Assigned to location',
        value: 0
      }
    ]
  }
];
const comunicationFilters: [MixedInput[]] = [
  [{
    type: 'selectionCheckbox',
    name: 'notCommunicated',
    options: [
      {
        title: 'Online',
        value: 0
      },
      {
        title: 'Not communicated',
        value: 1
      }
    ],
    styles: {
      width: '210px'
    }
  },
  {
    type: 'custom',
    name: 'notCommunicatedDays',
    component: SubscriptionExpireControl,
    styles: {
      justifyContent: 'flex-end'
    }
  },
]];


export const generalFilters = (moduleId: number): MixedInput[] => ([
  {
    type: 'text',
    name: 'global',
    label: 'Global Search',
  },
  {
    type: 'text',
    name: 'ccSerial',
    label: 'CC serial number',
  },
  {
    type: 'text',
    name: 'companyId',
    label: 'Account number',
  },
  {
    type: 'text',
    name: 'state',
    label: 'State'
  },
  {
    type: 'select',
    name: 'companyId',
    label: 'Company',
    fetch: {
      url: '/companies/names-list',
      formatter: (data =>
        data.accountList.map(({ id, name }: { id: number; name: string }) => ({ value: id, title: name }))
      )
    }
  },
  {
    type: 'select',
    name: 'modelId',
    label: 'Model',
    fetch: {
      url: `/devices/${moduleId}/models`,
      formatter: (data =>
        data.deviceModels.map(({ id, name }: { id: number; name: string }) => ({ value: id, title: name }))
      )
    }
  },
  {
    type: 'numberSelect',
    name: 'subscriptionWillExpireInDays',
    label: 'Subscription will expire in',
    options: [30, 45, 60, 90],
  },
]);

export const fullFiltersOverview:(MixedInput | MixedInput[])[] = [
    ...locationFilters, ...subscriptionFilters, ...comunicationFilters
];

export const partFiltersOverview: (MixedInput | MixedInput[])[] = [
  ...locationFilters, ...comunicationFilters
];

export const lowBatteryFilter: MixedInput = {
  type: 'checkbox',
  name: 'lowBattery',
  label: 'Low battery voltage',
};
