import {
  Box,
  Dialog,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Select from '@mui/material/Select';
import { Controller, useForm } from 'react-hook-form';
import {
  ButtonsWrapper,
  CancelButton,
  FormError,
  FormWrapper,
  SaveButton,
  StyledAllInputsWrapper,
  StyledInputWrapper,
  TitleLabel,
} from './styles';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AddCompanyFormDefaultValues,
  GetCompanyAPIResponse,
  ListTimezoneAPIResponse,
  Timezone,
} from './types';
import { useDataGrid } from '../../../../../components/generic/dataGrid';
import { useApi } from '../../../../../context/api';
import LoadPannelLoader from '../../../../../components/LoadPanelLoader';

export default function AddCompany({
  onClose,
  companyId,
}: {
  onClose: (info?: { showSnackbarMsg: boolean; message: string }) => void;
  companyId?: string;
}) {
  const { apiInstance, loading, setLoading } = useApi();
  const { insert, update, errors: serverErrors, setErrors } = useDataGrid();
  const defaultValues: AddCompanyFormDefaultValues = {
    name: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    speed_units: '1',
    time_zone: '',
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const [timeZones, setTimeZones] = useState<Timezone[]>([]);
  useEffect(() => {
    async function fetchTimezones() {
      setLoading(true);
      const response: ListTimezoneAPIResponse = await apiInstance.get(
        `service/timezoneslist`
      );
      setLoading(false);
      const result = await response.timeZonesList;
      setTimeZones(result);
    }

    async function fetchCompanyData() {
      setLoading(true);
      const response: GetCompanyAPIResponse = await apiInstance.get(
        `companies/edit/${companyId}/`
      );
      setLoading(false);
      const result = await response.company_info;
      setValue('address', result.address);
      setValue('city', result.city);
      setValue('country', result.country);
      setValue('name', result.name);
      setValue('speed_units', result.speed_units);
      setValue('state', result.state);
      setValue('time_zone', result.tz);
      setValue('zip', result.zip);

    }

    if (companyId) {
      fetchCompanyData();
    }
    fetchTimezones();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, setValue]);

  const handleClose = () => {
    reset();
    setErrors({});
    onClose();
  }

  const onSubmit = (data: AddCompanyFormDefaultValues) => {
    try {

      if (companyId) {
        update(companyId, data, handleClose);
      } else {
        insert(data, handleClose);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function radioGroupOnChangeHandler(
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ): void {
    setValue('speed_units', value as '0' | '1');
  }

  const preparedServerErrors = useMemo(() => {
    const errors: {[key: string]: string} = {};

    Object.keys(serverErrors).forEach((key) => {
      errors[key] = Object.values(serverErrors[key])[0] as string
    })

    return errors
  }, [serverErrors]);

  return (
    <Dialog open onClose={() => handleClose()}>
      {loading ? <LoadPannelLoader/> : ''}
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <TitleLabel>
          {companyId ? 'Edit company' : 'Add new company'}
        </TitleLabel>

        <Box sx={{ width: 1 }}>
          <StyledAllInputsWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: `Value is required and can't be empty`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Company name*"
                    variant="standard"
                    {...field}
                  />
                )}
              />
              {errors?.name?.message && (
                <FormError>{errors.name.message}</FormError>
              )}
              {preparedServerErrors.name && <FormError>{preparedServerErrors.name}</FormError>}
            </StyledInputWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: {
                    value: true,
                    message: `Value is required and can't be empty`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Address*"
                    variant="standard"
                    {...field}
                  />
                )}
              ></Controller>
              {errors?.address?.message && (
                <FormError>{errors.address.message}</FormError>
              )}
              {preparedServerErrors.address && <FormError>{preparedServerErrors.address}</FormError>}
            </StyledInputWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: `Value is required and can't be empty`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Country*"
                    variant="standard"
                    {...field}
                  />
                )}
              />
              {errors?.country?.message && (
                <FormError>{errors.country.message}</FormError>
              )}
              {preparedServerErrors.country && <FormError>{preparedServerErrors.country}</FormError>}
            </StyledInputWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="State"
                    variant="standard"
                    {...field}
                  />
                )}
              />
              {preparedServerErrors.state && <FormError>{preparedServerErrors.state}</FormError>}
            </StyledInputWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="city"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="City"
                    variant="standard"
                    {...field}
                  />
                )}
              />
              {preparedServerErrors.city && <FormError>{preparedServerErrors.city}</FormError>}
            </StyledInputWrapper>
            <StyledInputWrapper>
              <Controller
                control={control}
                name="zip"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Postcode"
                    variant="standard"
                    {...field}
                  />
                )}
              />
              {preparedServerErrors.zip && <FormError>{preparedServerErrors.zip}</FormError>}
            </StyledInputWrapper>
            <Controller
              control={control}
              name="speed_units"
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={field.value}
                  value={field.value}
                  name="radio-buttons-group"
                  style={{ width: '100%' }}
                  onChange={radioGroupOnChangeHandler}
                >
                  <StyledInputWrapper>
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="km/h"
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="mph"
                    />
                  </StyledInputWrapper>
                </RadioGroup>
              )}
            />

            <Controller
              control={control}
              name="time_zone"
              rules={{
                required: {
                  value: true,
                  message: `Value is required and can't be empty`,
                },
              }}
              render={({ field }) => (
                <>
                  <InputLabel htmlFor="tz-select">Choose timezone*</InputLabel>
                  <Select
                    native
                    fullWidth
                    variant="standard"
                    id="tz-select"
                    label="Choose timezone*"
                    {...field}
                    value={field.value}
                  >
                    <option aria-label="None" value=""></option>
                    {timeZones.map((timezone) => (
                      <optgroup label={timezone.name} key={timezone.name}>
                        {timezone.zones.map((zone) => (
                          <option key={zone.value} value={zone.value}>
                            {zone.label}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </Select>
                  {errors?.time_zone?.message && (
                    <FormError>{errors.time_zone.message}</FormError>
                  )}
                </>
              )}
            />
          </StyledAllInputsWrapper>
        </Box>

        <ButtonsWrapper>
          <SaveButton type="submit" variant="contained">
            Save
          </SaveButton>
          <CancelButton onClick={() => handleClose()}>Cancel</CancelButton>
        </ButtonsWrapper>
      </FormWrapper>
    </Dialog>
  );
}
