import MuiSelect from '@mui/material/Select/Select';
import { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Controller } from 'react-hook-form';
import { Label, LoadingStatusText, SearchInput, ServiceText } from './styles';
import { InfiniteScrollSelectProps, ScrollEvent } from './types';
import { useFetchOptions } from './useFetchOptions';

export const Select = ({
  loadItemsUrl,
  itemsPerPage = 50,
  control,
  defaultValue,
  name,
  label,
}: InfiniteScrollSelectProps) => {
  const [isOpen, setOpen] = useState(false);
  const {
    options,
    loading,
    pageInfo,
    searchValue,
    fetchOptions,
    handleSearchValue,
    handleClearState,
  } = useFetchOptions(loadItemsUrl, itemsPerPage);
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && options.length) {
      const inputElement =
        inputRef.current?.querySelector<HTMLInputElement>('#search-input');

      if (inputElement) {        
        inputElement.focus();
      }
    }
  }, [isOpen, options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {Boolean(label) && <Label>{label}</Label>}
          <MuiSelect
            variant="standard"
            onClose={(e) => {
              if (e.currentTarget !== inputRef.current) {
                setOpen(false);
                handleClearState();
              }
            }}
            renderValue={(option) => {
              if (!option) {
                return 'Select an option…';
              }

              return currentValue.name;
            }}
            open={isOpen}
            sx={{ width: '100%' }}
            SelectDisplayProps={{
              onClick: () => {
                fetchOptions();
                setOpen(true);
              },
            }}
            MenuProps={{
              disableAutoFocusItem: true,
              PaperProps: {
                onScroll: ({
                  target: { scrollHeight, scrollTop, clientHeight },
                }: ScrollEvent) => {
                  const isBottom =
                    Math.round(scrollHeight) ===
                    Math.round(scrollTop + clientHeight);

                  if (
                    isBottom &&
                    !loading &&
                    pageInfo.currentPage < pageInfo.total
                  ) {
                    fetchOptions();
                  }
                },
                style: { height: 200 },
              },
            }}
            {...field}
          >
            <SearchInput
              fullWidth
              variant="standard"
              ref={inputRef}
              inputProps={{
                id: 'search-input',
                style: { padding: '2px 10px' },
              }}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={({ target: { value } }) => {
                handleSearchValue(value);
                fetchOptions(value, true);
              }}
            />
            {loading && !!searchValue && (
              <LoadingStatusText>searching...</LoadingStatusText>
            )}
            {!options.length && !loading && (
              <ServiceText>No results found</ServiceText>
            )}
            {options.map(({ id, name }) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  onClick={() => setCurrentValue({ id, name })}
                >
                  {name}
                </MenuItem>
              );
            })}
            {loading && !searchValue && (
              <LoadingStatusText>loading...</LoadingStatusText>
            )}
          </MuiSelect>
        </>
      )}
    />
  );
};
