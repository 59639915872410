import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { FilterControlFieldProps } from '../types';
import * as Types from '../../context/types';
import _ from 'lodash';

const defaultArr = [ 1, 2, 3, 4, 5, 6, 7 ];
const NumbersSelect = ({
  field,
  input
}: FilterControlFieldProps<Types.NumberSelect>) => {
  const optionsArr:
    { value: string | number; title: string | number }[] | Array<number> =
    (input as Types.NumberSelect).options || defaultArr;

  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: '150px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {input.label && <Typography variant='body2' sx={{ marginRight: '5px' }}>{`${input.label}: `}</Typography>}
      <Select
        labelId={input.name}
        id={input.name}
        size="small"
        {...field}
      >
        <MenuItem value=""></MenuItem>
        {_.map(optionsArr,
          item => (<MenuItem value={item}>{item}</MenuItem>))
        }
      </Select>
      <span>{' days'}</span>
    </FormControl>
  );
};

export default NumbersSelect;
