import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { AddCommandWrapper, FieldBox, ParsedResponseWrapper } from '../styles';
import {
  ButtonsWrapper,
  FormError,
  FormWrapper
} from '../../../../../components/Form/styles';
import { useDataGrid } from '../../../../../components/generic/dataGrid';
import Modal from '../../../../../components/generic/modal';
import CommandHelpers, { commandRules } from './commands';
import useAerisService from './AerisService';
import _ from 'lodash';
import ParsedResponse from './ParsedResponse';

export type SignCommandType = {
  command: number | '';
  command_value: string;
  stop_proc: number | '';
  imsi: string;
  command_provider: 'scs' | 'sms'
}

export type CommandTypesType = {
  key: number;
  label: string;
}

export default function AddSignCommand(
  { onClose, open, commandTypes }: { onClose: () => void, open: boolean, commandTypes: CommandTypesType[] }
) {
  const { insert } = useDataGrid();
  const { sendSms, abort, status } = useAerisService();
  const [commandProvider, setCommandProvider] = useState<'sms' | 'scs'>('scs');
  const defaultValues: SignCommandType = {
    command: '',
    command_value: '',
    stop_proc: '',
    imsi: '',
    command_provider: 'scs'
  };
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommandProvider(event.target?.value as 'sms' | 'scs')
  };

  const onSubmit = useCallback(
    async (data: SignCommandType) => {
      try {
        if (commandProvider === 'sms') {
          const command = _.get(data, 'command', '');
          const commandValueRec = _.get(data, 'command_value');

          let hexCommand = null;
          let imsiAddress = null;

          imsiAddress = _.get(data, 'imsi', '');

          hexCommand = CommandHelpers.getCommand(+command, commandValueRec);

          sendSms(data.imsi, hexCommand as string, (error, response) => {
            if (!error) {
              insert(data, onClose);
            }
          });
        } else {
          insert(data, onClose);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [insert, onClose, sendSms, commandProvider]
  );

  const commandValueField =  commandRules[watch('command') as number];

  return (
    <Modal
      show={open}
      size={'md'}
      title={
        <Typography
          variant='h4'
          sx={{ marginTop: 5, marginBottom: 5 }}
          align='center'
        >
          Add Radar Command
        </Typography>
      }
      onClose={onClose}
    >
      <FormWrapper onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Box sx={{ width: 1 }}>
          <AddCommandWrapper>
            <FieldBox>
              <Controller
                  control={control}
                  name="command"
                  rules={{
                    required: {
                      value: true,
                      message: 'Required',
                    },
                  }}
                  render={({ field }) => (
                      <NativeSelect
                        data-test={'commandList'}
                        fullWidth
                        inputProps={{
                          name: 'command',
                          id: 'uncontrolled-native',
                        }}
                        {...field}
                      >
                        <option value={''}>
                          Choose command*
                        </option>
                        {commandTypes?.map((v: CommandTypesType) => (
                            commandProvider === 'scs' && v.key === 0 ? (
                              <option key={`${v.key}`} value={v.key} disabled={true}>
                                {`${v.label}  (enabled only for SMS)`}
                              </option>
                            ) : (commandProvider === 'sms' && v.key === 16 ?  (
                              <option key={`${v.key}`} value={v.key} disabled={true}>
                                {`${v.label}  (enabled only for SCS)`}
                              </option>
                            ) : <option key={`${v.key}`} value={v.key}>
                            {v.label}
                          </option>)
                        ))}
                      </NativeSelect>
                  )}
              ></Controller>
              <FormError data-test={'commandError'}>
                {errors?.command?.message ? errors.command.message : ''}
              </FormError>
            </FieldBox>
            {commandValueField?.rules?.required && (
              <FieldBox>
                <Controller
                  control={control}
                  name="command_value"
                  rules={{
                    ...commandValueField?.rules,
                    required: {
                      value: true,
                      message: 'Connection period must be from 1 to 255',
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      data-test={'commandValue'}
                      fullWidth
                      label="Command Value*"
                      variant="standard"
                      {...field}
                      {...commandValueField?.rules || {}}
                      required={true}
                      type={commandValueField?.type || 'text'}
                    />
                  )}
                />
              <FormError data-test={'valueError'}>
                {errors?.command_value?.message ? errors.command_value.message : ''}
              </FormError>
              </FieldBox>
            )}

            {commandProvider === 'scs'
              ? (
                <FieldBox>
                  <Controller
                    data-test={'processList'}
                    control={control}
                    name="stop_proc"
                    rules={{
                      required: {
                        value: true,
                        message: 'Required',
                      },
                    }}
                    render={({ field }) => (
                      <NativeSelect
                        fullWidth
                        inputProps={{
                          name: 'stop_proc',
                        }}
                        {...field}
                      >
                        <option value="">Choose process flag*</option>
                        <option value="0">Send command and continue communication session with Signs</option>
                        <option value="1">Send command and stop communication session with Signs</option>
                      </NativeSelect>
                    )}
                  ></Controller>
                  <FormError data-test={'processError'}>
                    {errors?.stop_proc?.message ? errors.stop_proc.message : ''}
                  </FormError>
                </FieldBox>
              )
              : commandProvider === 'sms' ? (
                <FieldBox>
                  <Controller
                    control={control}
                    name="imsi"
                    rules={{
                    }}
                    render={({ field }) => (
                      <TextField
                        data-test={'imsi'}
                        fullWidth
                        type="text"
                        label="IMSI*"
                        variant="standard"
                        {...field}
                      />
                    )}
                  ></Controller>
                  <FormError data-test={'imsiError'}>
                    {errors?.imsi?.message ? errors.imsi.message : ''}
                  </FormError>
                </FieldBox>
              ) : null
            }

            <Box sx={{ padding: '5px', flex: '50%', boxSizing: 'border-box' }}>
              <Typography>Send via:*</Typography>
              <Controller
                control={control}
                name="command_provider"
                render={({ field }) => (
                  <RadioGroup
                    row
                    sx={{ width: '70%', justifyContent: 'space-between' }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={field.value}
                    onChange={handleProviderChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="scs"
                      control={<Radio data-test={'scsRadio'}/>}
                      label="SCS"
                    />
                    <FormControlLabel
                      value="sms"
                      control={<Radio data-test={'smsRadio'}/>}
                      label="SMS"
                    />
                  </RadioGroup>
                )}
              ></Controller>
              {commandProvider === 'sms' && (
                <>
                  {
                    status.message &&
                    <Typography>Status: {status.message}</Typography>
                  }
                  {
                    status.responseFromRadar &&
                    <Typography>
                      Response from Radar: {status.responseFromRadar}
                    </Typography>
                  }
                  {
                    status.inProgress && (
                      <>
                        <Typography align="center" color={'red'}>
                          If you leave this page before receiving a response from
                          the sign, you will no longer be able to view it.
                        </Typography>
                        <LinearProgress />
                      </>
                    )
                  }
                </>
              )}
            </Box>
          </AddCommandWrapper>
        </Box>

        <ButtonsWrapper>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            data-test={'saveCommand'}
          >
            {commandProvider === 'scs' ? 'Save' : 'Send'}
          </Button>
          {status.inProgress ? (
            <Button
              color="secondary"
              onClick={() => abort({message: '', inProgress: false, responseFromRadar: ''})}
              data-test={'abortlCommand'}
            >
              Abort
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={() => onClose()}
              data-test={'cancelCommand'}
            >
              Cancel
            </Button>
          )}
        </ButtonsWrapper>
        <ParsedResponseWrapper>
          {/*Serial & Model */}
          {/* <ParsedResponse responseFromRadar='!0108AFFFE15F4BB9A03F.!0108AFFFE15F4BB9A03F.'/>*/}

          {/*Serial & Model */}
          {/*<ParsedResponse responseFromRadar='!0108AAFFE18F4B98A03F.'/>*/}

          {/*LEDs brightness and frequency settings */}
          {/*<ParsedResponse responseFromRadar='!0505194A4A0082.'/>*/}

          {/*unparsable response */}
          {/*<ParsedResponse responseFromRadar='!4508A03778320800A400.'/>*/}

          {/*Schedule response */}
          {/*<ParsedResponse responseFromRadar='!04127F00000A0C531E1F2000200A030304237400'/>*/}
          <ParsedResponse responseFromRadar={status.responseFromRadar}/>
        </ParsedResponseWrapper>
      </FormWrapper>
    </Modal>
  );
}