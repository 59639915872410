import { useState, useCallback, useRef } from 'react';
import { get, size, find, startCase } from 'lodash';

interface XhrOptions {
  method: string;
  headers: Record<string, string>;
  body?: any;
}

interface Status {
  inProgress: boolean;
  message: string;
  responseFromRadar: string;
}

const API_HOST = process.env.REACT_APP_AERIS_AERFRAME_API_HOST;
const API_VERSION = process.env.REACT_APP_AERIS_API_VERSION;
const ACCOUNT_ID = process.env.REACT_APP_AERIS_ACCOUNT_ID;
const APP_SHORT_NAME = process.env.REACT_APP_AERIS_APP_SHORT_NAME;
const API_KEY = process.env.REACT_APP_AERIS_API_KEY;
const CLIENT_CORRELATOR = process.env.REACT_APP_AERIS_CLIENT_CORRELATOR;

const sendSmsURL = `https://${API_HOST}/smsmessaging/${API_VERSION}/${ACCOUNT_ID}/outbound/${APP_SHORT_NAME}/requests?apiKey=${API_KEY}`;
const notificationChannelURL = `https://${API_HOST}/notificationchannel/${API_VERSION}/${ACCOUNT_ID}/channels?apiKey=${API_KEY}`;
const outboundSubscriptionsURL = `https://${API_HOST}/smsmessaging/${API_VERSION}/${ACCOUNT_ID}/outbound/${APP_SHORT_NAME}/subscriptions?apiKey=${API_KEY}`;
const inboundSubscriptionsURL = `https://${API_HOST}/smsmessaging/${API_VERSION}/${ACCOUNT_ID}/inbound/subscriptions?apiKey=${API_KEY}`;
const acknowledgeInboundSMSURL = `https://${API_HOST}/smsmessaging/${API_VERSION}/${ACCOUNT_ID}/acknowledgement?apiKey=${API_KEY}`;

const xhrRequest = (url: string, options: XhrOptions, callback: (error: any, data: any) => void) => {
  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status >= 200 && xhr.status < 400) {
        callback(null, { status: xhr.status, data: JSON.parse(xhr.responseText) });
      } else {
        callback({ status: xhr.status, data: JSON.parse(xhr.responseText) }, null);
      }
    }
  };
  xhr.open(options.method || 'GET', url, true);
  Object.entries(options.headers).forEach(([key, value]) => xhr.setRequestHeader(key, value));
  xhr.send(JSON.stringify(options.body));
};

const useAerisService = () => {
  const [status, setStatus] = useState<Status>({ inProgress: false, message: '', responseFromRadar: '' });
  const notificationChannelCallbackURL = useRef<string | null>(null);
  const longpollURL = useRef<string | null>(null);
  const notificationChannelHTTPRequest = useRef<XMLHttpRequest | null>(null);

  const getNotificationChannel = useCallback((callback: (error: any, data: any) => void) => {
    xhrRequest(notificationChannelURL, { method: 'GET', headers: {} }, callback);
  }, []);

  const createNotificationChannel = useCallback((callback: (error: any, data: any) => void) => {
    const createBody = {
      applicationTag: APP_SHORT_NAME,
      channelData: {
        maxNotifications: '15',
        type: "nc:LongPollingData"
      },
      channelLifetime: "7200",
      channelType: "LongPolling",
      clientCorrelator: CLIENT_CORRELATOR
    };

    xhrRequest(notificationChannelURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: createBody
    }, (error, data) => {
      if (!error) {
        console.log('data', data);
        const channelUrl = `${data.data.channelData.channelURL}?apiKey=${API_KEY}`;
        notificationChannelCallbackURL.current = data.data.callbackURL;
        longpollURL.current = channelUrl;
      }
      callback(error, data);
    });
  }, []);

  const getOutboundSubscriptions = useCallback(() => {
    return new Promise((resolve, reject) => {
      xhrRequest(outboundSubscriptionsURL, { method: "GET", headers: {} }, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }, []);

  const getInboundSubscriptions = useCallback(() => {
    return new Promise((resolve, reject) => {
      xhrRequest(inboundSubscriptionsURL, { method: "GET", headers: {} }, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }, []);

  const createOutboundSubscriptions = useCallback(() => {
    return new Promise((resolve, reject) => {
      const body = {
        callbackReference: {
          callbackData: `${APP_SHORT_NAME}-callback`,
          notifyURL: notificationChannelCallbackURL.current
        },
        filterCriteria: "SP:*"
      };

      xhrRequest(outboundSubscriptionsURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body
      }, (error, data) => {
        if (error) {
          console.error(error);
          reject(error);
        }
        resolve(data);
      });
    });
  }, []);

  const createInboundSubscriptions = useCallback(() => {
    return new Promise((resolve, reject) => {
      const body = {
        callbackReference: {
          callbackData: `${APP_SHORT_NAME}-mo-sms`,
          notifyURL: notificationChannelCallbackURL.current
        },
        criteria: "SP:*",
        destinationAddress: [APP_SHORT_NAME]
      };

      xhrRequest(inboundSubscriptionsURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body
      }, (error, data) => {
        if (error) {
          console.error(error);
          reject(error);
        }
        resolve(data);
      });
    });
  }, []);

  const createNotificationSubscriptions =
    useCallback((options: { createOutboundSubscriptions: boolean; createInboundSubscriptions: boolean }) => {
      return new Promise((resolve, reject) => {
        Promise.all([
          options.createInboundSubscriptions && createInboundSubscriptions(),
          options.createOutboundSubscriptions && createOutboundSubscriptions()
        ])
          .then(values => resolve(values))
          .catch(error => reject(error));
      });
  }, [createInboundSubscriptions, createOutboundSubscriptions]);

  const finishNotificationRequest = useCallback((status: Status) => {
    if (notificationChannelHTTPRequest.current) {
      notificationChannelHTTPRequest.current.abort();
    }
    setStatus(status);
  }, []);

  const acknowledgeInboundSMS = useCallback((messages: any) => {
    const body = {
      message: messages
    };
    return new Promise((resolve, reject) => {
      xhrRequest(acknowledgeInboundSMSURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body
      }, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }, []);

  const retriveSMSMessages = useCallback((inboundMessages: any) => {
    const messages = [];
    let messagesText = '';
    for (const i in inboundMessages) {
      const message = get(inboundMessages[i], 'inboundSMSMessage', null);
      if (message) {
        // Handle the message processing and setting the response text as per your requirements
        messagesText = messagesText + atob(message.message);
        messages.push({
          messageId: message.messageId,
          messageType: "Inbound",
          serviceCode: message.serviceCode,
          success: true
        });
      }
    }
    acknowledgeInboundSMS(messages);
    finishNotificationRequest({
      inProgress: false,
      message: 'Received a response from the sign',
      responseFromRadar: messagesText,
    });
  }, [acknowledgeInboundSMS, finishNotificationRequest]);

  const getMessageNotificationChannel = useCallback(() => {
    if (longpollURL.current) {
      notificationChannelHTTPRequest.current = new XMLHttpRequest();
      notificationChannelHTTPRequest.current.onreadystatechange = () => {
        if (notificationChannelHTTPRequest.current?.readyState === 4) {
          if (notificationChannelHTTPRequest.current.status === 200) {
            const moSMSMessageString = JSON.parse(notificationChannelHTTPRequest.current.responseText);
            if (
              size(moSMSMessageString.deliveryInfoNotification) > 0
              && size(get(moSMSMessageString.deliveryInfoNotification, '[0].deliveryInfo'))
            ) {
              for (const i in moSMSMessageString.deliveryInfoNotification[0].deliveryInfo) {
                setStatus({
                  inProgress: true,
                  message: startCase(moSMSMessageString.deliveryInfoNotification[0].deliveryInfo[i].deliveryStatus),
                  responseFromRadar: '',
                });
              }
            }
            if (size(moSMSMessageString.inboundSMSMessageNotification) > 0) {
              retriveSMSMessages(moSMSMessageString.inboundSMSMessageNotification);
            } else {
              getMessageNotificationChannel();
            }
          } else if (notificationChannelHTTPRequest.current.status === 409) {
            getMessageNotificationChannel();
          } else {
            try {
              const moSMSMessageString = JSON.parse(notificationChannelHTTPRequest.current.responseText);
              const serviceException =
                get(moSMSMessageString, 'serviceException.variables[0]', 'Unexpected problem, try again');
              finishNotificationRequest(serviceException);
            } catch (error) {
              console.log('error: ',error);
            }
            
          }
        }
      };
      try {
        notificationChannelHTTPRequest.current.open("GET", longpollURL.current, true);
        notificationChannelHTTPRequest.current.send();
      } catch (error) {
        console.log('error: ',error);
      }
      
    }
  }, [finishNotificationRequest, retriveSMSMessages]);

  const sendSms = useCallback((imsi: string, message: string, callback?: (error: any, data: any) => void) => {
    if (!imsi || !message) {
      setStatus({ inProgress: false, message: 'IMSI or message is missing', responseFromRadar: '' });
      return;
    }

    const sendMtSMSPayloadObject = {
      address: [imsi],
      senderAddress: APP_SHORT_NAME,
      outboundSMSTextMessage: { message },
      clientCorrelator: CLIENT_CORRELATOR,
      senderName: APP_SHORT_NAME
    };

    setStatus({ inProgress: true, message: 'Sending SMS...', responseFromRadar: '' });

    xhrRequest(sendSmsURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: sendMtSMSPayloadObject
    }, (error, data) => {
      if (error) {
        setStatus({ inProgress: false, message: 'Failed to send SMS', responseFromRadar: '' });
        if (callback) callback(error, null);
        return;
      }

      setStatus({ inProgress: true, message: 'SMS sent, waiting for response...', responseFromRadar: '' });
      getNotificationChannel((error, data) => {
        console.log('error', error, data);
        if (error) {
          setStatus({ inProgress: false, message: 'Failed to get notification channel', responseFromRadar: '' });
          if (callback) callback(error, null);
          return;
        }
        notificationChannelCallbackURL.current = data.data.notificationChannel[0].callbackURL;
        longpollURL.current = `${data.data.notificationChannel[0].channelData.channelURL}?apiKey=${API_KEY}`;

        if (notificationChannelCallbackURL.current) {
          Promise.all([getInboundSubscriptions(), getOutboundSubscriptions()])
            .then(([inboundSubscriptions, outboundSubscriptions]) => {
              const notifyInboundSubscription = 
                find(
                  get(inboundSubscriptions, 'data.subscription', []),
                  ({ callbackReference }) => get(callbackReference, 'notifyURL')
                    === notificationChannelCallbackURL.current
                );
              const notifyOutboundSubscription =
                find(get(outboundSubscriptions, 'data.deliveryReceiptSubscription', []), ({ callbackReference }) =>
                  get(callbackReference, 'notifyURL') === notificationChannelCallbackURL.current);

              if (!notifyInboundSubscription || !notifyOutboundSubscription) {
                createNotificationSubscriptions({
                  createOutboundSubscriptions: !notifyOutboundSubscription,
                  createInboundSubscriptions: !notifyInboundSubscription
                }).then(() => getMessageNotificationChannel());
              } else {
                getMessageNotificationChannel();
              }
            });
        } else {
          createNotificationChannel(() => {
            createNotificationSubscriptions({
              createOutboundSubscriptions: true,
              createInboundSubscriptions: true
            }).then(() => getMessageNotificationChannel());
          });
        }
        // if (callback) callback(null, data);
      });
    });
  }, [
    getNotificationChannel,
    createNotificationChannel,
    createNotificationSubscriptions,
    getMessageNotificationChannel,
    getInboundSubscriptions,
    getOutboundSubscriptions
  ]);

  return {
    sendSms,
    abort: finishNotificationRequest,
    status,
  };
};

export default useAerisService;
