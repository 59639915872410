import { useEffect, useState } from 'react';
import * as Types from '../../context/types';
import { Autocomplete, TextField } from '@mui/material';
import { FilterControlFieldProps } from '../types';

export default function AutocompleteInput({
  input,
  field,
}: FilterControlFieldProps<Types.AutocompleteInput>) {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] =
    useState<Types.AutocompleteOption | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<readonly Types.AutocompleteOption[]>(
    []
  );

  useEffect(() => {
    if(!field.value && selectedValue?.id) {
      setSelectedValue(null);
    }
  }, [field.value, selectedValue?.id]);

  useEffect(() => {
    const handleSearch = async () => {
      setLoading(true);
      if (searchValue === '') {
        setOptions(selectedValue ? [selectedValue] : []);
        setLoading(false);
        return;
      }
      if (input.cb) {
        try {
          const results = await input.cb(searchValue);
          setOptions(results);
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      }
    };

    const timeoutId = setTimeout(handleSearch, 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue, selectedValue, input]);

  return (
    <Autocomplete
      size="small"
      sx={{ width: 300 }}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      getOptionKey={option => option.id}
      options={options}
      loading={loading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedValue}
      noOptionsText="Please enter 2 or more characters"
      onInputChange={(_, newInputValue) => {
        setSearchValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={`Pick a ${input.label}`} fullWidth />
      )}
      onChange={(_, newValue) => {
        setSelectedValue(newValue);
        if (newValue) {
          setOptions([newValue, ...options.filter(opt => opt.id !== newValue.id)]);
        } else {
          setOptions([]);
        }
        field.onChange(newValue?.id);
      }}
    />
  );
}