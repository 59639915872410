import { AuthDataType } from './types';
import { ActionType, AuthContextActions } from './actions';

export const reducer = (
  state: AuthDataType,
  action: AuthContextActions
): AuthDataType => {
  switch (action.type) {
    case ActionType.SetAuthData:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
