import React from 'react';
import { map } from 'lodash';
import type { SchedulesTypes } from './types';

const Days = (data: SchedulesTypes): React.ReactElement<'table'> => (
  <table cellSpacing="0" cellPadding="0">
    <thead>
      <tr>
        {map(data.days[0], (_days: boolean, d: string) => {
          return (
            <th key={`day-${d}`} className={_days ? 'is-active' : 'is-no-active'}>
              {d}
            </th>
          );
        })}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          colSpan={7}
          style={{
            textAlign: 'left',
            padding: '.25rem',
            fontSize: '.75rem',
            fontWeight: 400,
            background: '#404040',
            height: '46px',
            color: 'white'
          }}
        >
          {data.days[1]}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Days;