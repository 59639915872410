import { Box, styled } from '@mui/material';

export const ModalWrap = styled(Box)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
`;

export const BtnsWrap = styled(Box)`
    padding: 8px;
    display: flex;
    width: 100%;
`;

export const ModulesWrap = styled(Box)`
    width: 100%;
    min-height: 160px;
    max-width: 250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ModuleItem = styled(Box)`
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
`