export const DEVICE_COMMANDS = {
  CUSTOM: 0,
  SERIAL: 1,
  SCHEDULE: 4,
  LEDS_BRIGHTNESS_FREQUENCY: 5,
  SYNC_TIME: 16,
  GSM_APN_DOMAIN: 35,
  CONNECTION_PERIOD: 41,
  REBOOT: 42,
  SERIAL_EXPANDED: 69
}

export const FULLDATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SHORTDATE_FORMAT = 'YYYY-MM-DD';
export const STARTDATE_FORMAT = 'YYYY-MM-DD 00:00:00';
export const DEFAULT_CAMERA_TYPE = 24;