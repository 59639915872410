import React, { useState, useEffect } from 'react';
import {
    InfolabelCenter,
    InfoLabelUnderline,
    ShowLogButton,
    StylesSelect
} from '../styles';
import { CancelButton } from '../../../../../../components/Form/styles';
import { FirmwareVer, ListFV } from '../../types';
import { MenuItem } from '@mui/material';
import { useConfirm } from '../../../../../../context/dialog';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useApi } from '../../../../../../context/api';

export default function UpdateFV(
    { onClose, onFetchUpdate, currentVersion, deviceId }:
      {
        onClose: () => void,
        onFetchUpdate: () => void,
        currentVersion: string | undefined,
        deviceId: number | undefined,
      }
) {
  const { confirm } = useConfirm();
  const [verList, setVerList] = useState<FirmwareVer[]>([]);
  const [selectedVer, setSelectedVer] = useState<null | string>('0');
  const { apiInstance } = useApi();
  useEffect(() => {
      async function fetchData() {
          const response: ListFV = await apiInstance.get('/sign/firmware-versions');

          setVerList(response?.firmwareVersions)
      }

      fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleRemove = () => {
    confirm(
      'Are you sure want to update the firmware?',
        async () => {
          try {
              const res = await apiInstance.post(
              `/sign/${deviceId}/update-firmware/`,
              {
                  param_set_id: selectedVer,
                }
              );
              onFetchUpdate();
              toast.success(get(res, 'msg', 'Saved successfully'));
          } catch (error) {
              toast.error(
                get(
                  error,
                 'error.message',
                    'No record matching the input was found'
                )
              );
          }
        }
    );
  };

  return (
      <>
        <InfoLabelUnderline>Firmware Update</InfoLabelUnderline>
        <InfolabelCenter>
            Pick a firmware version which you want to send to radar
        </InfolabelCenter>
        <InfolabelCenter>Current firmware version is: <b>{currentVersion}</b></InfolabelCenter>
        <StylesSelect
          fullWidth
          variant="standard"
          label="Select one"
          id="version-select"
          value={selectedVer}
          onChange={(e) => setSelectedVer(e.target.value as string)}
        >
          <MenuItem value="0">
            <em>Select one</em>
          </MenuItem>
          {verList?.map((verItem) => (
              <MenuItem value={verItem.id} key={verItem.id}>
                  {verItem.name}
              </MenuItem>
          ))}
        </StylesSelect>
        <ShowLogButton onClick={handleRemove}>Confirm</ShowLogButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </>
  );
}
