import React from 'react';

import Days from './days';
import Ds from './ds';
import Flashing from './flashing';
import type { SchedulesTypes } from './types';

import './styles/_index.scss';

type TableBodyProps = {
  data: SchedulesTypes;
};

const TableBody = ({
   data
}: TableBodyProps): React.ReactElement<'tbody'> => {

  return (
    <tbody className="tableDefaultBody">
      <tr className="tableDefaultRow">
        <td
          className={'tableDefaultField tableDefaultField-1'}
          style={{
            background: '#404040',
            color: 'white'
          }}
        >
          1
        </td>
        <td className={'tableDefaultField tableDefaultField-days'}>
          <Days {...data} />
        </td>
        <td className={'tableDefaultField tableDefaultField-ds'}>
          <Ds displaySettings = {data.displaySetting} />
        </td>
        <td className={'tableDefaultField tableDefaultField-flashing'}>
          <Flashing flashing = {data.flashing} />
        </td>
      </tr>
    </tbody>
  );
}

export default TableBody;