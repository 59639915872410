import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import welcomeImg from '../../../../assets/img/welcome_card.jpg'

import {
  EmailLabel, WelcomeWrapper, CancelButton, StyledTypography
} from './styles';
import { useApi } from '../../../../context/api';

type AboutContentProps = {
  onClose: () => void
};

const AboutContent = ({ onClose }: AboutContentProps) => {
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);
  const REACT_APP_VERSION = process.env
      .REACT_APP_VERSION as string;
      const { apiInstance } = useApi();
  useEffect(() => {
    (async () => {
      try {
        const response: { version: string } = await apiInstance.get('/version');
        const { version } = response;

        setCurrentVersion(version);
      } catch (error) {
        console.error(error);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img src={welcomeImg} alt='welcome-image' style={{ width: '100%' }} />
      <WelcomeWrapper>
        Support Tool
      </WelcomeWrapper>
      <Box sx={{ padding: '16px' }}>
        <StyledTypography>
          <span>Current Front version: </span>
          <EmailLabel>{REACT_APP_VERSION}</EmailLabel>
        </StyledTypography>
        <StyledTypography>
          <span>Current API version: </span>
          <EmailLabel>{currentVersion}</EmailLabel>
        </StyledTypography>
        <CancelButton
          variant='contained'
          onClick={onClose}
        >
          Close
        </CancelButton>
      </Box>
    </>
  );
};

export default AboutContent;

