import React from 'react';
import type { DisplaySettingsTypes } from './types';
import { map } from 'lodash';
const Ds = ({ displaySettings } : {displaySettings: DisplaySettingsTypes}): React.ReactElement<'table'> => {

  return (
    <table
      cellSpacing="0"
      cellPadding="0"
      style={{
        width: '100%'
      }}
    >
      <tbody className="tableDefaultBody tableDefaultBody-ds">
        {map(displaySettings, (_ds: Record<string, string>, _i: number) => (
          <tr className="tableDefaultRow" key={`ds-${_i}`}>
            <td
              className={`
              tableDefaultField
              tableDefaultField-ds2-min
              ${+_i === 0 ? '' : 'lr'}
              ${+_ds?.redAboveMin ? 'red' : ''}
            `}
            >
              {+_i === 0 ? `Min: ` : ''} {_ds?.min}
            </td>
            <td
              className={`
              tableDefaultField
              tableDefaultField-ds2-limit
              ${+_i === 0 ? '' : 'lr'}
              ${+_ds?.redAboveLimit ? 'red' : ''}
            `}
            >
              {+_i === 0 ? `Limit}: ` : ''} {_ds?.limit}
            </td>
            <td
              className={`
              tableDefaultField
              tableDefaultField-ds2-allowed
              ${+_i === 0 ? '' : 'lr'}
              ${+_ds?.redAboveTolerated ? 'red' : ''}
            `}
            >
              {+_i === 0 ? `Allowed: ` : ''} {_ds?.allowed}
            </td>
            <td
              className={`
              tableDefaultField
              tableDefaultField-ds2-max
              ${+_i === 0 ? '' : 'lr'}
              ${+_ds?.redAboveMax ? 'red' : ''}
            `}
            >
              {+_i === 0 ? `Max: ` : ''} {_ds?.max}
            </td>
            {_ds?.red && (
              <td
                className={`
                tableDefaultField
                tableDefaultField-ds2-red
                ${+_i === 0 ? '' : 'lr'}
              `}
              >
                {+_i === 0 ? `Red: ` : ''} {_ds?.red}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Ds;