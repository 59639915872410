import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';

export const ParkingNav = () => {
  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();
    
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }
    
    return null;
  }
  
  const { id } = useParams();
  const routeMatch = useRouteMatch([
    `/companies/parking-controllers/list/${id}`,
    `/companies/v-parkings/list/${id}`
  ]);
  const currentTab = routeMatch?.pattern?.path;
  
  return (
    <Tabs value={currentTab} sx={{marginBottom: '20px'}}>
      <Tab
        label="Controllers"
        value={`/companies/parking-controllers/list/${id}`}
        to={`/companies/parking-controllers/list/${id}`}
        component={Link}
      />
      <Tab
        label="V-Parking"
        value={`/companies/v-parkings/list/${id}`}
        to={`/companies/v-parkings/list/${id}`}
        component={Link}
      />
    </Tabs>
  )
}
