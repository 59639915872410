import type { SchedulesTypes } from './types';

type Props = {
  data: SchedulesTypes;
};

const TableHeader = ({ data }: Props): React.ReactElement<'thead'> => {

  return (
    <thead className="tableDefaultHeader" data-test='signs-schedules-tableDefaultHeader'>
      <tr>
        {data.days ? (
          <>
            <th className="tableDefaultHead tableDefaultHead-fat">
              <span>#</span>
            </th>
            <th className="tableDefaultHead tableDefaultHead-fat">
              <span>Days and Time</span>
            </th>
            <th className="tableDefaultHead tableDefaultHead-fat">
              <span>Display Settings</span>
            </th>
            <th className="tableDefaultHead tableDefaultHead-fat">
              <span>Flashing</span>
            </th>
          </>
        ) : (
          <th className="tableDefaultHead tableDefaultHead-fat" data-test='signs-schedules-tableDefaultHeader-empty' />
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
