import { Typography } from '@mui/material';
import { ErrorWrap } from '../styles';

const ErrorPage = () => {
  return (
    <ErrorWrap>
      <Typography variant="h1">404!</Typography>
      <Typography variant="h4">Page not found.</Typography>
    </ErrorWrap>
  );
};
export default ErrorPage;
