import { styled } from '@mui/system';

export const HomeWrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const Top = styled('div')<{ opacity?: number }>(({ opacity }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '21px',
  paddingTop: '7rem',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  display: 'inline-block',
  width: '100%',
  opacity: opacity || 0,
}));

export const Bracket = styled('span')`
  font-size: 2rem;
  position: relative;
  top: 2px;
  font-weight: 400;
`;

export const WD = styled('div')`
  line-height: 1;
  color: #4868a1;
  display: inline-block;
`;

export const ST = styled('span')`
  padding-left: 40px;
  color: #096309;
`;

export const UL = styled('ul')`
  list-style: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  color: #4868a1;
  padding: 0;
  display: inline-block;
  margin-top: 70px;
`;

export const Li = styled('li')`
  display: inline-block;
  font-size: 6.5rem;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  max-width: 2em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  color: #4868a1;
  font-weight: 400;
`;

export const HiddenLi = styled(Li)<{
  opacity?: number;
  width?: number | string;
}>(({ opacity, width }) => ({
  maxWidth: width || 0,
  opacity: opacity || 0,
}));
