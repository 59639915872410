import React from 'react';

const Flashing = ({
  flashing,
}: {
  flashing: {
    digits?: string;
    strobe?: string;
    message?: string;
  }
}): React.ReactElement<'table'> => {
  return (
    <table
      cellSpacing="0"
      cellPadding="0"
      style={{
        width: '100%'
      }}
    >
      <thead>
        <tr className="tableDefaultHead-flashing">
          <th>Digits</th>
          <th>Strobe</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody className="tableDefaultBody tableDefaultBody-flashing">
        <tr className="tableDefaultRow">
          <td className="tableDefaultField tableDefaultField-flashing2">{flashing?.digits}</td>
          <td className="tableDefaultField tableDefaultField-flashing2">{flashing?.strobe}</td>
          <td className="tableDefaultField tableDefaultField-flashing2">{flashing?.message}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Flashing;