import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Link,
  matchPath,
  useLocation,
} from 'react-router-dom';

interface NavTabsProps {
  tabs: Array<{
    value: string,
    label: string,
    to: string
  }>
};

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const NavTabs = ({ tabs }: NavTabsProps) => {
  const routeMatch = useRouteMatch(tabs.map(({ value }) => value));
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Tabs value={currentTab} data-test={'NavTabs'}>
      {tabs.map((tab, i) => (
        <Tab {...tab} key={tab.value} component={Link} tabIndex={i} />
      ))}
    </Tabs>
  );
};

export default NavTabs;
