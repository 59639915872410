import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Box, Button, Paper, Typography } from '@mui/material';
import { ButtonsContainer, Wrapper } from './styles';
import { GridProps } from './types';
import FilterButton from './FilterButton';
import { useNavigate, useLocation } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExportToCSV from './ExportCSV';

export interface TableWrapperRef {
  updateTitle: (newTitle: string) => void;
}

const TableWrapper = forwardRef<TableWrapperRef, GridProps>(({
  title,
  filter,
  toolbar,
  backButton,
  exportData,
  children,
}: GridProps, ref) => {
  const [currentTitle, setCurrentTitle] = useState(title);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = useRef<{ previousLocation: Location } | null>();

  useEffect(() => {
    if (location.state) {
      locationState.current = location.state;
    }
  }, [location.state]);

  const search = locationState.current?.previousLocation?.search
    ? locationState.current?.previousLocation?.search
    : '';

  useImperativeHandle(ref, () => ({
    updateTitle(newTitle: string) {
      setCurrentTitle(newTitle);
    }
  }));

  return (
    <Wrapper>
      <div data-test={'genericTable'}>
        <Paper sx={{ width: '100%' }} elevation={2}>
          {(backButton || currentTitle) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '25px',
                paddingBottom: '20px',
                paddingTop: '30px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {backButton && (
                  <Button
                    onClick={() => {
                      navigate(backButton + search, {
                        state: { previousLocation: location, backButton: true }
                      });
                      locationState.current = null;
                    }}
                    data-test={'backTable'}
                  >
                    <ChevronLeftIcon sx={{ color: 'black' }} />
                  </Button>
                )}
                {_.isString(currentTitle) ? (
                  <Typography data-test={'titleTable'} variant="h5">{currentTitle}</Typography>
                ) : (
                  title
                )}
              </Box>
              <ButtonsContainer>
                {toolbar && <Box data-test={'toolbarTable'}>{toolbar}</Box>}
                {exportData?.csv && (
                  <ExportToCSV {...exportData.csv} />
                )}
                {filter && _.size(filter) && (
                  <FilterButton data-test={'filterTable'} {...filter} />
                )}
              </ButtonsContainer>
            </Box>
          )}
          {children}
        </Paper>
      </div>
    </Wrapper>
  );
});

TableWrapper.displayName = 'TableWrapper';

export default TableWrapper;
