import { Controller, Control } from 'react-hook-form';
import { CustomInput, MixedInput } from '../context/types';
import AutocompleteInput from './AutocompleteInput';
import TextInput from './TextInput';
import CheckboxInput from './CheckboxInput';
import SelectInput from './SelectInput';
import DateSelect from './DateSelect';
import NumbersSelect from './NumbersSelect';
import { Box } from '@mui/material';
import PageSizeInput from './PageSizeInput';
import SelectionCheckboxInput from './SelectionCheckbox';
import RangeDateSelect from './RangeDateSelect';

type FilterControlProps = {
  inputs: Array<MixedInput | Array<MixedInput>>;
  control?: Control;
  showAll?: boolean;
};

export default function FilterControls({
  control,
  inputs,
  showAll
}: FilterControlProps): JSX.Element {
  const getFormControl = (input: MixedInput) => {
    const filterControlCollection = {
      text: TextInput,
      checkbox: CheckboxInput,
      select: SelectInput,
      autocomplete: AutocompleteInput,
      pagesize: PageSizeInput,
      datePicker: DateSelect,
      rangeDatePicker: RangeDateSelect,
      selectionCheckbox: SelectionCheckboxInput,
      numberSelect: NumbersSelect,
      custom: (input as CustomInput).component,
    };

    const FilterInputControl = filterControlCollection[input.type];

    return (
      <Controller
        key={input.name}
        name={input.name}
        control={control}
        render={({ field }) => (
          <FilterInputControl field={field} input={input} control={control} />
        )}
      />
    );
  };

  return (
    <>
      {inputs.map((input, i) => {
        if (Array.isArray(input)) {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '5px',
                ...(showAll && {
                  marginBottom: 3,
                  flexDirection: 'initial',
                  '> *': { width: '100%' }
                })
              }}
              key={i}
            >
              <FilterControls inputs={input} control={control} />
            </Box>
          );
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: '5px',
              verticalAlign: 'bottom',
              ...(showAll && {
                marginBottom: 3,
                flexDirection: 'initial',
                '> *': { width: '100%' }
              }),
              ...(input.styles ? input.styles : {})
            }}
            key={i}
          >
            {getFormControl(input)}
          </Box>
        );
      })}
    </>
  );
}
