import { ThemeProvider, createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: {
      main?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(111, 108, 221);',
    },
    secondary: {
      main: '#000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '500',
        },
      },
    }
  }
});

export default function AppThemeProvider({
  children,
}: {
  children: string | JSX.Element | JSX.Element[];
}) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
