import Table from '../../../../../../components/Table';
import GenericModal from '../../../../../../components/generic/modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  ControllerDisplayType,
  ControllerDisplayTypesResponse,
  ControllerType,
  ControllerTypesResponse,
  ControllerDisplayLinesResponse,
  ControllerInfoResponseType,
} from '../types';
import { USERROLES } from '../../../../../../constants/UserRoles';
import { useAuth } from '../../../../../../context/auth';
import ModalContent from './ModalContent';
import { useParams } from 'react-router-dom';
import CompanyParkingMenu from '../ParkingMenu';
import { ParkingNav } from '../ParkingNav';
import { useApi } from '../../../../../../context/api';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';

export enum ControllerTypes {
  Repeater = 2,
  Display = 4,
  MultiLotDisplay = 8,
  Sensor = 16,
  ParkTrak = 32
}

export const CompanyControllers = () => {
  const defaultParkingValues = {
    serial: '',
    gsm_module: 0,
    display_lines: '',
    display_type: '',
    controller_type: 2,
    access_key_PT: '',
    serial_PT: ''
  };
  
  const { hasRoleAccess } = useAuth();
  const { id } = useParams();
  const { apiInstance } = useApi();
  const { setErrors } = useDataGrid();
  
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [parkingControllerId, setParkingControllerId] = useState<string>('');
  const [controllerValues, setControllerValues] = useState<Record<string, string | number>>(defaultParkingValues);
  const [controllerTypes, setControllerTypes] = useState<ControllerType[] | []>([]);
  const [displayTypes, setDisplayTypes] = useState<ControllerDisplayType[] | []>([]);

  const handleModalOpen = useCallback(
    (type: string, deviceId?: string) => {
      const fetchCompaniesData = async () => {
        const checkNullValues = (obj: any) => {
          //useForm uncontrolled inputs can't have value 'null'
          Object.keys(obj).forEach((key) => {
            if(obj[key] === null) {
              obj[key] = '';
            }
          })
          
          return obj;
        }
        
        try {
          const { parking_controller_info }: ControllerInfoResponseType = await apiInstance.get(
            `/companies/parking-controllers/edit/${id}/${deviceId}`
          );
          
          const clearedData = checkNullValues(parking_controller_info)

          if (clearedData.gsm) clearedData.gsm_module = clearedData.gsm;

          setControllerValues(checkNullValues(parking_controller_info))
        } catch (e) {
          console.log(e);
        }
      }
      
      const openModal = () => {
        setModalType(type);
        setIsModalOpen(true);
        deviceId && setParkingControllerId(deviceId);
      }
      
      type === 'edit' ? fetchCompaniesData().then(() => openModal()) : openModal();
    }, [apiInstance, id])
  
  const handleModalClose = () => {
    setIsModalOpen(false);
    setParkingControllerId('');
    setControllerValues(defaultParkingValues);
    setErrors({});
  }
  
  useEffect(() => {
    const getControllersTypes = async () => {
      try {
        const {controller_types}: ControllerTypesResponse = await apiInstance.get(
          `/companies/parking-controllers/filters/list-controller-types`
        );

        setControllerTypes(controller_types)
        
      } catch (e) {
        console.log(e);
      }
    }
    const getDisplayTypes = async () => {
      try {
        const {display_types}: ControllerDisplayTypesResponse = await apiInstance.get(
          `/companies/parking-controllers/filters/list-display-types`
        );
        
        setDisplayTypes(display_types)
        
      } catch (e) {
        console.log(e);
      }
    }
    
    getControllersTypes();
    getDisplayTypes();
  }, [apiInstance, id]);
  
  const table = useMemo(() => (
    <Table
      backButton="/companies/list"
      title="Controllers "
      titleKey="company_name"
      toolbar={
        hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]) && (
          <Button color="secondary" onClick={() => handleModalOpen('create')}>
            {' '}
            + Add Contriller
          </Button>
        )
      }
      filter={{
        inputs: [
          {
            type: 'text',
            name: 'global',
            label: 'Global Search',
          },
          {
            type: 'text',
            name: 'serial',
            label: 'Serial Number',
          },
          {
            type: 'select',
            options: [
              {value: '1', title: 'On'},
              {value: '0', title: 'Off'}
            ],
            name: 'gsm',
            label: 'GSM',
          },
          {
            type: 'select',
            name: 'controller_type',
            label: 'Controller type',
            fetch: {
              url: '/companies/parking-controllers/filters/list-controller-types',
              formatter: (data: ControllerTypesResponse) =>
                data.controller_types.map((type) => ({
                  value: type.id,
                  title: type.name,
                }))
            },
          },
          {
            type: 'select',
            name: 'display_lines',
            label: 'Display lines',
            fetch: {
              url: '/companies/parking-controllers/filters/list-display-lines',
              formatter: (data: ControllerDisplayLinesResponse) =>
                data.display_lines.map((type) => ({
                  value: type,
                  title: `${type}`
                }))
            },
          },
          {
            type: 'select',
            name: 'location',
            label: 'Locations',
            fetch: {
              url: `/companies/parking-controllers/filters/locations/${id}`,
              formatter: (data: { locations: Array<any> }) =>
                data.locations.map((location) => ({
                  value: location.id,
                  title: location.name
                }))
            },
          },
          {
            type: 'select',
            name: 'virtual_parking',
            label: 'Virtual-Parking',
            fetch: {
              url: `/companies/v-parkings/list/${id}/full-list`,
              formatter: (data: { virtual_parkings: Array<any> }) =>
                data.virtual_parkings.map((vp) => ({
                  value: vp.id,
                  title: vp.name
                }))
            },
          },
        ],
      }}
      options={{
        moduleName: 'companies/parking-controllers/list',
        apiUrl: `companies/parking-controllers/list/${id}`,
        dataSourceKey: 'parking_controllers',
        dataStoreKey: 'id',
        insert: { method: 'post', url: `/companies/parking-controllers/add/${id}` },
        update: { method: 'post', url: `/companies/parking-controllers/edit/${id}` },
        move: { method: 'post', url: `/companies/parking-controllers/delete/${id}`},
      }}
      columns={[
        {
          dataField: 'serial',
          caption: 'SN',
          width: '150px',
        },
        {
          dataField: 'controller_type',
          caption: 'Controller type',
        },
        {
          dataField: 'gsm',
          caption: 'GSM',
          cellRender: (data: { data: any }) => (
            <>
              {data.data.gsm === '1' ? <CheckIcon fontSize={'small'}/> : <CloseIcon fontSize={'small'}/>}
            </>
          ),
          alignment: 'center',
          width: '10%',
        },
        {
          dataField: 'display_lines',
          caption: 'Display (lines)',
          width: '10%',
        },
        {
          dataField: 'location',
          caption: 'Location',
          cellRender: (data: { data: any }) => (
            <>{data.data.location || 'Location not found'}</>
          ),
          width: '10%',
        },
        {
          dataField: 'virtual_parking',
          caption: 'Virtual Parking',
          cellRender: (data: { data: any }) => (
            <>{data.data.virtual_parking || 'Not assigned'}</>
          ),
          width: '20%',
        },
        {
          caption: '',
          cellRender: (data: { data: any }) => (
            <CompanyParkingMenu handleModalOpen={handleModalOpen} signId={data.data.id} />
          ),
          visible: hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN], [USERROLES.ROLE_STAGING]]),
          width: '6%'
        }
      ]}
    />
  ), [
    id,
    handleModalOpen,
    hasRoleAccess
  ])
  
  return (
    <>
      <ParkingNav />
      {table}
      <GenericModal show={isModalOpen} onClose={() => handleModalClose()}>
        <ModalContent
          type={modalType}
          parkingControllerId={parkingControllerId}
          values={controllerValues}
          controllerTypes={controllerTypes}
          displayTypes={displayTypes}
          onClose={handleModalClose}
        />
      </GenericModal>
    </>
  )
}
