import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const AddCommandWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 0;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FieldBox  = styled(Box)`
  margin-bottom: 10px;
  width: 100%;
`;

export const ParsedResponseWrapper = styled('div')`
  width: 100%;
  overflow: hidden;
  padding: 10px;
`