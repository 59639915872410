import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export const Wrapper = styled(Paper)`
  width: 50%; 
  margin: 5% auto;
  padding: 30px;
  
`;

export const LoadingWrap = styled('div')`
  width: 100%;
  text-align: center;
`;

export const Title = styled(Typography)`
  display: inline-block;
  font-weight: 700;
  width: 35%;
  margin-right: 5%;
`;
