import React, { useState, useEffect } from 'react';
import { ParkingControllers, displayIconsObj } from '../../../types';
import { InputLabel, FormControl, MenuItem, Select, Box, Chip, Checkbox, FormControlLabel } from '@mui/material';
import { Image, ImageWrap, Filter, MenuOptionWrap } from './styles'
import DeleteIcon from '@mui/icons-material/Delete';
import _ from "lodash";
import {Controller, useWatch} from 'react-hook-form';
import TextField from '@mui/material/TextField';

const displayTypes = [
    { value: 'all', title: 'All', showIcons: [ 1, 2, 3, 4, 5, 6 ]},
    { value: 'gsm', title: 'Controllers with GSM', showIcons: [ 5, 6 ] },
    { value: '2', title: 'Repeater', showIcons: [ 1, 5, ] },
    { value: '4', title: 'Display', showIcons: [ 2, 6 ] },
    { value: '16', title: 'Sensor', showIcons: [ 3 ] },
    { value: '32', title: 'ParkTrak', showIcons: [ 4 ] },
];
const displayIcons: displayIconsObj[] = [
    { img: 'repeater', title: 'Repeater', id: 1, type: 2 },
    { img: 'display', title: 'Display', id: 2, type: 4 },
    { img: 'sensor', title: 'Sensor', id: 3, type: 16 },
    { img: 'sensor', title: 'ParkTrak', id: 4, type: 32 },
    { img: 'repeater_gsm', title: 'Repeater(GSM)', id: 5, type: 2 },
    { img: 'display_gsm', title: 'Display(GSM)', id: 6, type: 4 },
]

export default function ParkingSettingsToEdit(
    { control, controllersList, setValue }:
      { control: any, controllersList: ParkingControllers[], setValue: any }
) {
  const displayType = useWatch({ control, name: 'display_type' });
  const [ listWithIcons, setListWithIcons ] = useState<ParkingControllers[]>([]);
  const currentType = _.filter(displayTypes, type => type.value === displayType)[0];
  const currentIcons =
    _.filter(displayIcons,(icon: displayIconsObj) => _.includes(currentType.showIcons, icon.id));
  
  const chooseAll = (evt : { target: { checked: boolean } }) => {
      setValue('display_type', 'all');
      setValue(
        'assigned_parking_controllers',
        evt.target.checked ? _.map(listWithIcons, c => (c.id)) : []
      );
  };

  useEffect(() => {
      setListWithIcons(_.map(controllersList, controller => {
       const newController = {...controller};
       const arrayIcons = _.filter(displayIcons, icon =>icon.type === newController.controller_type);

       newController.img = _.size(arrayIcons) > 1 ? arrayIcons[+newController.gsm]?.img : arrayIcons[0]?.img;
       return newController;
     }));
  }, [controllersList]);

  const filteredControllersList: ParkingControllers[] = currentType.value === 'all' ? listWithIcons :
    _.filter(listWithIcons,(controller) => {
        switch (currentType.value) {
          case 'gsm':
            return (controller.controller_type === 2 || controller.controller_type === 4) && !!+controller.gsm;
          default:
            return controller.controller_type === +currentType.value;
        }
    });

  return (
      <>
        <Controller
          name="display_type"
          control={control}
          render={({ field }) => (
            <TextField
              select
              sx={{ marginBottom: 7 }}
              fullWidth
              variant="standard"
              id="displayType-select"
              label="Display types"
              {...field}
            >
              {displayTypes?.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
          <Filter>
            {_.map(currentIcons, (icon: displayIconsObj) => (
              <ImageWrap key={icon.id}>
                <Image src={`/media/controllers_types/controler_type_${icon.img}.png`}/>
                  {icon.title}
              </ImageWrap>
            ))}
          </Filter>
          <FormControlLabel
            sx={{ marginTop: 4 }}
            control={
              <Checkbox
                onChange={chooseAll}
              />
            }
            label="Select all controllers"
          />
          <Controller
            name="assigned_parking_controllers"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth  sx={{ marginTop: 1 }}>
                <InputLabel id='move-modal-controller-select'>Select Controllers</InputLabel>
                <Select
                  fullWidth
                  multiple
                  variant='standard'
                  labelId='move-modal-controller-select'
                  id='controller-select'
                  renderValue={(selected) =>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {_.filter(listWithIcons, controller =>
                          _.indexOf(selected, controller.id) >= 0).map((controller: ParkingControllers) => (
                           <Chip
                             onMouseDown={(event) =>
                               event.stopPropagation()
                             }
                             icon={
                               <Image
                                 src={`/media/controllers_types/controler_type_${controller?.img}.png`}
                                 margin='0 10px'
                               />
                             }
                             deleteIcon={<DeleteIcon />}
                             onDelete={() => {
                               setValue('assigned_parking_controllers',
                                 _.filter(selected, parking => parking !== controller.id)
                               )
                             }}
                             label={controller?.serial}
                             key={controller.id}/>
                      ))}
                    </Box>
                  }
                  {...field}
                >
                  {_.size(filteredControllersList) > 0 && filteredControllersList?.map((controller) => (
                    <MenuItem value={controller.id} key={controller.id}>
                      <MenuOptionWrap>
                        <Image
                          src={`/media/controllers_types/controler_type_${controller?.img}.png`}
                          margin='0 10px'
                        />
                       {controller?.serial}
                      </MenuOptionWrap>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
      </>
  );
};
