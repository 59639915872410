// import { Exception } from '../../../library/exception';

export default () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const exception = new Error({
    type: 'single',
    simple: true,
    msg: 'Command is not full. Could not parse'
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  exception.notification();
}