import { Tooltip } from '@mui/material';
import React from 'react';
import { WrapperStatus } from '../../styles';

interface StatusProps {
  success: boolean;
  disabled?: boolean;
  children: string | React.ReactNode;
  dangerColor?: string;
  successColor?: string;
}

const Status = (
  { success, disabled = false, dangerColor = 'red', successColor = '#1ccc1c', children }: StatusProps
) => {
  const statusColor = success ? successColor : disabled ? 'inherit' : dangerColor;

  return (
    <Tooltip title={children}>
      <WrapperStatus
        sx={{
          color: statusColor
        }}>{children}</WrapperStatus>
    </Tooltip>
  );
};

export default Status;
