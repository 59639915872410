import { useState } from "react"
import { CompanyModalType } from "./types";

export const useCompanyModal = () => {
    const [isOpen, setOpen] = useState(false);
    const [type, setType] = useState<CompanyModalType>(CompanyModalType.COMPANY_MODULES);
    const setModalState = (isOpen: boolean, type = CompanyModalType.COMPANY_MODULES) => {
        setOpen(isOpen);
        setType(type);
    }

    return ({
        isOpen,
        type,
        setModalState
    })
}