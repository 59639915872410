import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import {
  ColorBox,
  ItemTitle,
  ListItemGroup,
  ListItemWrapper,
  CustomList,
  CustomListItem
} from './styles';
import {
  ChartDataItemType,
  LegendDataItemType,
  LegendDataType
} from './types';

type CustomLegendProps = {
  legendData: LegendDataType,
  // eslint-disable-next-line
  instanceEl: any
}

const CustomLegend = ({ legendData, instanceEl } : CustomLegendProps) => {
  const history = useNavigate();

  const showSubList = (item:  LegendDataItemType) => !((
      item.originalLink === '/tcam' ||
      item.originalLink === '/dc' ||
      item.originalLink === '/vms'
    ) &&
    item.type === 'activeNotAssigned');

  const mouseOverValueHandler = (item: ChartDataItemType) => {
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.title)[0]?.hover()
  };
  const mouseLeaveValueHandler = (item: ChartDataItemType) => {
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.title)[0]?.clearHover()
  };
  const mouseOverTotalHandler = (item: LegendDataItemType) => {
    instanceEl?.instance?.getAllSeries()[1]?.getPointsByArg(item.items[0].title)[0]?.hover();
    !showSubList(item) && item.items[0].value !== 0 &&
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.items[0].title)[0]?.hover()
    !showSubList(item) && item.items[1].value !== 0 &&
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.items[1].title)[0]?.hover()
  };
  const mouseLeaveTotalHandler = (item: LegendDataItemType) => {
    instanceEl?.instance?.getAllSeries()[1]?.getPointsByArg(item.items[0].title)[0]?.clearHover();
    !showSubList(item) && item.items[0].value !== 0 &&
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.items[0].title)[0]?.clearHover();
    !showSubList(item) && item.items[1].value !== 0 &&
    instanceEl?.instance?.getAllSeries()[0]?.getPointsByArg(item.items[1].title)[0]?.clearHover();
  }

  const onClickHandler = (
    e: React.MouseEvent,
    item: LegendDataItemType | ChartDataItemType
  ) => {
    e.stopPropagation();
    history(item.link)
  }
  const calculateExpiredValue = (item: LegendDataItemType | ChartDataItemType) => {
    return item.originalLink === '/tcam' && item.type === 'expired' ? 'N/A' : 0;
  }

  const calculateItemValue = (item: LegendDataItemType) => {
    return item.total || calculateExpiredValue(item);
  }

  const calculateSubItemValue = (item: ChartDataItemType) => {
    return item.value || calculateExpiredValue(item);
  }

  return (
    <>
      <CustomList data-test={'legend'}>
        {
          legendData.map((item: LegendDataItemType, i: number) => {
            return(
              <ListItemGroup key={item.title + i}>
                <CustomList className={'total-item'}>
                  <CustomListItem
                    onMouseOver={() => mouseOverTotalHandler(item)}
                    onMouseLeave={() => mouseLeaveTotalHandler(item)}
                    onClick={(e) => onClickHandler(e, item)}
                  >
                    <ListItemWrapper>
                      <ColorBox background={item.color}/>
                      <ItemTitle>
                        {item.title} ({calculateItemValue(item)})
                      </ItemTitle>
                    </ListItemWrapper>
                  </CustomListItem >
                  <CustomList>
                    {
                      showSubList(item) &&
                      item.items.map((subItem: ChartDataItemType, i: number) => {
                        return(
                          <CustomListItem
                            key={item.title + i}
                            className={'value-item'}
                            onMouseOver={() => mouseOverValueHandler(subItem)}
                            onMouseLeave={() => mouseLeaveValueHandler(subItem)}
                            onClick={(e) => onClickHandler(e,subItem)}
                          >
                            <ListItemWrapper>
                              <ColorBox background={subItem.color}/>
                              <ItemTitle>
                                {subItem.title.slice(item.title.length + 2)} ({calculateSubItemValue(subItem)})
                              </ItemTitle>
                            </ListItemWrapper>
                          </CustomListItem>
                        )
                      })
                    }
                  </CustomList>
                </CustomList>
              </ListItemGroup>
            )
          })
        }
      </CustomList>
    </>
  )
}

export default  memo(CustomLegend);