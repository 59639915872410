import { useEffect, useState, memo } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { dictionary, palette } from './data';

import { MODULES } from '../../../constants/Modules';
import ChartOverview from './ChartOverview'
import { NoData, OverviewWrapper } from './styles';
import { ResponseChartDataType, ResponseType } from '../../../components/pieChartOverview';
import { useApi } from '../../../context/api';
import { useAuth } from '../../../context/auth';
import LoadPannelLoader from '../../../components/LoadPanelLoader';


const Overview = () => {
  const [data, setData] = useState<ResponseChartDataType>({});
  const { apiInstance, loading, setLoading } = useApi();
  const { state: { user } } = useAuth();
  const hasAccess = (module: number) => {
    return user?.access_to_modules.indexOf(module) !== -1;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const result: ResponseType = await apiInstance.get(`/overview`);
        setData(result?.chartData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(
          _.get(
            error, 'message', 'Error while getting the data. Please check the parameters'
          )
        );
      }
    }

    fetchData();
  }, [apiInstance, setLoading]);

  return (
    <OverviewWrapper data-test={'overview'}>
      {loading ? <LoadPannelLoader/> : ''}
      {data[MODULES.MODULE_SIGN] && hasAccess(MODULES.MODULE_SIGN) &&
        <ChartOverview
          data={data[MODULES.MODULE_SIGN]}
          title={'Signs'}
          dictionary={dictionary}
          palette={palette}
          link="/sign"
        />
      }
      {data[MODULES.MODULE_DC] && hasAccess(MODULES.MODULE_DC) &&
        <ChartOverview
          data={data[MODULES.MODULE_DC]}
          title={'DC'}
          dictionary={dictionary}
          palette={palette}
          link="/dc"
        />
      }
      {data[MODULES.MODULE_VMS] && hasAccess(MODULES.MODULE_VMS) &&
        <ChartOverview
          data={data[MODULES.MODULE_VMS]}
          title={'VMS'}
          dictionary={dictionary}
          palette={palette}
          link="/vms"
        />
      }
      {data[MODULES.MODULE_TCAM] && hasAccess(MODULES.MODULE_TCAM) &&
        <ChartOverview
          data={data[MODULES.MODULE_TCAM]}
          title={'TCAM'}
          dictionary={dictionary}
          palette={palette}
          link="/tcam"
        />
      }
      {
        !hasAccess(MODULES.MODULE_SIGN)  &&
        !hasAccess(MODULES.MODULE_DC) &&
        !hasAccess(MODULES.MODULE_VMS) &&
        !hasAccess(MODULES.MODULE_TCAM) &&
        <NoData>No data</NoData>
      }
    </OverviewWrapper>
  );
};
export default memo(Overview);
