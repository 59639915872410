import { Typography } from '@mui/material';

const VmsDeleteModalContent = ({ type }: { type: string }) => {
  const renderTitle = () => {
    switch (type) {
      case 'delete':
        return 'Are you sure you want to delete the vms?';
    }
  };
  return (
    <Typography variant='subtitle1'  sx={{ marginBottom: 8 }} align='center'>
      {renderTitle()}
    </Typography>
    );
};

export default VmsDeleteModalContent;
