import Table from '../../../../components/Table';
import { AssignButton, EmptyLocLabel, Wrapper } from './styles';
import { useCallback, useMemo, useState } from 'react';
import { Company, UnprocessedDevice } from './types';
import { AssignDevice } from './assign-device';
import { Dialog, DialogContent, Snackbar } from '@mui/material';
import _ from 'lodash';

export default function UnassignedSigns() {
  const [snackbarMsg, setSnackbarMsg] = useState<string>();

  const [deviceId, setDeviceId] = useState<UnprocessedDevice['id']>();
  const openAssignDeviceModal = useCallback((device: UnprocessedDevice) => {
    setDeviceId(device.id);
  }, []);

  const closeAssignDeviceModal = useCallback(
    (info?: { showSnackbarMsg: boolean; message: string }) => {
      setDeviceId(undefined);
      if (info?.showSnackbarMsg) {
        setSnackbarMsg(info.message);
      }
    },
    []
  );

  const table = useMemo(
    () => (
      <Table
        title="Unassigned devices"
        filter={{
          inputs: [
            {
              type: 'text',
              name: 'global',
              label: 'Global Search',
            },
            {
              type: 'text',
              name: 'serial',
              label: 'Serial Number',
            },
            [
              {
                type: 'checkbox',
                name: 'empty_company',
                label: 'Empty company',
              },
              {
                type: 'checkbox',
                name: 'empty_location',
                label: 'Empty location',
              },
            ],
            {
              type: 'select',
              name: 'company',
              label: 'Companies',
              fetch: {
                url: '/sign-lost',
                formatter: (data: { companies: Company[] }) =>
                  data.companies.map((c) => ({ value: c.cid, title: c.name }))
              },
            },
          ],
        }}
        options={{
          moduleName: 'unprocessedEvents',
          apiUrl: '/sign-lost',
          dataSourceKey: 'unprocessedEvents',
          dataStoreKey: 'id',
        }}
        columns={[
          {
            dataField: 'serial',
            caption: 'Serial',
          },
          {
            dataField: 'ftime',
            caption: 'F-time',
          },
          {
            dataField: 'stime',
            caption: 'L-time',
          },
          {
            dataField: 'companyName',
            caption: 'Company Name',
            cellRender: ({ data }) => (
              <div>
                {data.companyName ? data.companyName : 'Company not found'}
              </div>
            ),
          },
          {
            caption: '',
            cellRender: ({ data }) => (
              <AssignButton
                onClick={() => {
                  openAssignDeviceModal(data);
                }}
              >
                Assign
                {
                  <EmptyLocLabel>
                    {!data.companyName ? 'empty company' : 'empty location'}
                  </EmptyLocLabel>
                }
              </AssignButton>
            ),
          },
        ]}
      ></Table>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openAssignDeviceModal]
  );

  return (
    <>
      <Wrapper>{table}</Wrapper>
      {deviceId && (
        <Dialog
          open={!!deviceId}
          onClose={() => closeAssignDeviceModal}
          sx={{ borderRadius: 0 }}
        >
          <DialogContent>
            <AssignDevice
              deviceId={deviceId}
              onClose={closeAssignDeviceModal}
            />
          </DialogContent>
        </Dialog>
      )}
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={6000}
        message={snackbarMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarMsg('')}
      />
    </>
  );
}
