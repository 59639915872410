import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button,
  Typography,
} from '@mui/material';
import Table from '../../../components/Table';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, useParams } from 'react-router-dom';
import UserModalContent from './user-modal-content';
import CompaniesUsersMenu from './CompaniesUsersMenu';
import GenericModal from '../../../components/generic/modal';
import CustomColorCell from '../../../components/Table/Columns/CustomColor';
import {
  CompanyInfo,
  EditUserResponse,
  RolesList,
  RolesListResponse
} from './types';
import _ from "lodash";
import { useApi } from '../../../context/api';
import { useDataGrid } from '../../../components/generic/dataGrid';
import { USERROLES, ROLES_LIST } from '../../../constants/UserRoles';
import { useAuth } from '../../../context/auth';

export default function CompaniesUsers() {
  const { hasRoleAccess } = useAuth();
  const { apiInstance } = useApi();
  const { id } = useParams();
  const { setErrors } = useDataGrid();
  const defaultUserValues = {
    login: '',
    email: '',
    fullName: '',
    blocked: '',
    password: '',
    confirmPassword: '',
    roleIds: [],
    locale: 'en',
  };
  const [user, setUser] = useState<any>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [userValues, setUserValues] = useState<any>(defaultUserValues);
  const [rolesList, setRolesList] = useState<RolesList[]>([]);

  useEffect(() => {
    async function fetchData() {
      const responseRolesList: RolesListResponse = await apiInstance.get(`/companies/users/list/filters/roles`);

      if (_.size(responseRolesList.rolesList) > 0) {
        setRolesList(responseRolesList.rolesList);
      }
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setUserValues(defaultUserValues);
    setIsModalOpen(false);
    setUser('');
  };

  const openModal = (type: string) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleModalOpen = useCallback(
      (type: string, user: any) => {
    async function fetchUserData(){
      const response: EditUserResponse = await apiInstance.get(`/companies/users/edit/${id}/${user.id}`);

      setUserValues({
        login: response.userInfo.login,
        email: response.userInfo.email,
        fullName: response.userInfo.fullName,
        blocked: response.userInfo.blocked,
        roleIds: response.userInfo.roleIds,
        locale: response.userInfo.locale,
      });
    }

    setModalType(type);
    setUser(user)
    switch (type) {
      case 'edit':
        fetchUserData().then(() => openModal(type));
        break;
      default:
        openModal(type);
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ id, setUserValues ])

  const table = useMemo(
    () => {

      return (
        <Table
          backButton="/companies/list"
          title="Company users "
          titleKey="companyName"
          toolbar={
            <Button color='secondary' onClick={() => openModal('create')}>
              {' '}
              + Add new User
            </Button>
          }
          filter={{
            inputs: [
              {
                type: 'text',
                name: 'global',
                label: 'Global Search',
              }
            ]
          }}
          options={{
            moduleName: 'companies/users/list',
            apiUrl: `/companies/users/list/${id}`,
            dataSourceKey: 'companiesUsers',
            dataStoreKey: 'id',
            insert: { method: 'post', url: `/companies/users/add/${id}` },
            update: { method: 'post', url: `/companies/users/edit/${id}` },
            remove: { method: 'post', url: `/companies/users/delete/${id}` }
          }}
          columns={[
            {
              caption: '',
              visible: hasRoleAccess([[USERROLES.ROLE_SUPER_ADMIN]]),
              cellRender: (data: { data: any }) => (
                <Link
                  to={`/companies/${id}/users/${data.data.id}/audit`}
                  style={{
                    paddingTop: '6px',
                    display: 'block',
                    color: '#0000008a',
                  }}
                >
                  <AccessTimeIcon fontSize="small" color="inherit" />
                </Link>
              ),
              width: '3%',
            },
            {
              dataField: 'login',
              caption: 'Login',
              cellRender: (data: {data: { deleted: number; login: string; }}) => (
                <CustomColorCell data ={data.data}/>
              )
            },
            {
              dataField: 'fullName',
              caption: 'Full Name'
            },
            {
              dataField: 'roleIds',
              caption: 'Access Level',
              cellRender: (data: { data: { roleIds: Array<number>}}) => (
                  data.data.roleIds.map(roleId => ROLES_LIST.find(role => role.id === roleId)?.name).join(', ')
                )
            },
            {
              dataField: 'blocked',
              caption: 'Blocked',
              alignment: 'left'
            },
            {
              dataField: 'locale',
              caption: 'Locale'
            },
            {
              caption: '',
              cellRender: (data: { data: any }) => (
                <>
                  {!data.data.deleted ?
                    <CompaniesUsersMenu
                      user={data.data}
                      handleModalOpen={handleModalOpen}
                    />
                    : ''}
                </>
              ),
              width: '6%',
            },
          ]}
        />
      )
    },
    [ id, handleModalOpen, hasRoleAccess ]
  );

  const handleClose = () => {
    setErrors({});
    handleModalClose();
  }

  return (
    <>
      {table}

      <GenericModal
        show={isModalOpen}
        onClose={handleClose}
      >
       <UserModalContent
         type={modalType}
         onClose={handleClose}
         user={user}
         rolesList={rolesList}
         values={userValues}
         companyId={id as string}
       />
      </GenericModal>
    </>
  );
}
