import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { CancelButton, SaveButton, ServiceModalWrap } from '../../../../styles';
import { useDataGrid } from '../../../../../../components/generic/dataGrid';
import { useForm } from 'react-hook-form';
import EditModalContent from './modalComponents/editModal-content';
import DeleteModalContent from './modalComponents/deleteModal-content';
import { ControllerTypes } from './Controllers';
import { useParams } from 'react-router-dom';

export default function ModalContent(
    {
      type, parkingControllerId, values, controllerTypes, onClose, displayLines, displayTypes
    }: any
) {
  const { id } = useParams();
  const { insert, update, move, errors: validationErrors } = useDataGrid();
  const defaultValues: any = values;

  const {
    handleSubmit,
    control,
    setError,
    clearErrors
  } = useForm({ mode: 'all', defaultValues });

  const renderContent = () => {
    switch (type) {
      case 'edit':
      case 'create':
        return (
          <EditModalContent
            control={control}
            controllerTypes={controllerTypes}
            clearErrors={clearErrors}
            displayLines={displayLines}
            displayTypes={displayTypes}
          />
        );
      case 'delete':
      default:
        return (<DeleteModalContent type={type}/>);
    }
  };

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return 'Edit controller';
      case 'create':
        return 'Add controller';
      case 'delete':
        return 'Confirm action';
    }
  }

  const saveControllerInfo = handleSubmit(async (values) => {
    const cleanedValues: any =  {
      serial: values.serial,
      controller_type: values.controller_type,
    };
    
    switch (cleanedValues.controller_type) {
      case ControllerTypes.Repeater:
        cleanedValues.gsm_module = values.gsm_module;
        break;
        
      case ControllerTypes.Display:
        cleanedValues.display_lines = values.display_lines;
        
        if (values.display_type !== '') {
          cleanedValues.display_type = values.display_type;
        }
        
        cleanedValues.gsm_module = values.gsm_module;
        break;
        
      case ControllerTypes.MultiLotDisplay:
        cleanedValues.display_lines = values.display_lines;
        
        if (values.display_type !== '') {
          cleanedValues.display_type = values.display_type;
        }
        
        cleanedValues.gsm_module = 1;
        break;

      case ControllerTypes.Sensor:
        break;
        
      case ControllerTypes.ParkTrak:
        cleanedValues.gsm_module = 1;
        cleanedValues.serial_PT = values.serial_PT;
        cleanedValues.access_key_PT = values.access_key_PT;
        break;
    }
    
    switch (type) {
      case 'edit':
        parkingControllerId &&  update(parkingControllerId, cleanedValues, onClose);
        break;
      case 'create':
        insert(cleanedValues, onClose);
        break;
      case 'delete':
        move(`/companies/parking-controllers/delete/${id}`, {id: parkingControllerId}, onClose);
        break;
    }
  });
  
  useEffect(() => {
    for (const key in validationErrors) {
      setError(key as never, {
        message: Object.values(validationErrors[key])[0] as string,
      });
    }
  }, [setError, validationErrors]);

  return (
    <ServiceModalWrap>
      <form onSubmit={saveControllerInfo}>
        <Typography variant='h4' sx={{ marginTop: 5, marginBottom: 8 }} align='center'>
          {renderTitle()}
        </Typography>
        {renderContent()}

        <Box
          sx={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            marginBottom: 5,
            marginTop: 5
          }}
        >
          <CancelButton onClick={onClose}>
            { type === 'delete' ? 'Disagree' : 'Cancel' }
          </CancelButton>
          <SaveButton type="submit" variant="contained">
            { type === 'delete' ? 'Agree' : 'Save' }
          </SaveButton>
        </Box>
      </form>
    </ServiceModalWrap>
  );
};
