import React from 'react';

import { SignSubscriptionProps } from '../types';
import { Typography } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import FeaturedDatePicker from "../../../../../../components/DatePicker";
import dayjs from 'dayjs';

export default function SignSubscribeModalContent(
    { sign, control, setValue }: SignSubscriptionProps
) {
  const startDateWatcher = useWatch({ control, name: 'start_subscription' });
  const endDateWatcher = useWatch({ control, name: 'end_subscription' });
  
  const checkDates = () => {
    if(startDateWatcher > endDateWatcher) {
      setValue('end_subscription', startDateWatcher)
    }
  }
  
    return (
      <>
        <Typography variant='h4' sx={{ marginBottom: 5 }} align='center'>
          Add Subscription
        </Typography>
        {!!sign.serial && <Typography variant='h6' sx={{  marginBottom: 5 }}>
          Serial: {sign.serial}
        </Typography>}
        <Controller
          name="start_subscription"
          control={control}
          render={({ field }) => (
            <FeaturedDatePicker
              field={field}
              callbackAccept={checkDates}
              label={"Start Date"}
              styles={{
                marginRight: 5
              }}
            />
           )}
        />
        <Controller
          name="end_subscription"
          control={control}
          render={({ field }) => (
            <FeaturedDatePicker
              field={field}
              label={"End Date"}
              minDate={dayjs(startDateWatcher)}
            />
          )}
        />
      </>
    );
};
