import React, { createContext, useContext, useState, useCallback, ReactNode, FunctionComponent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

type ConfirmContextType = {
  confirm: (content: ReactNode, confirmCallback: () => void) => void;
};

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

interface ConfirmProviderProps {
  children: ReactNode;
}

export const ConfirmProvider: FunctionComponent<ConfirmProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<ReactNode>(null);
  const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);

  const confirm = useCallback((content: ReactNode, confirmCallback: () => void) => {
    setDialogContent(content);
    setOnConfirm(() => confirmCallback);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    if(onConfirm) {
      onConfirm();
    }
    handleClose();
  }, [onConfirm, handleClose]);

  const ConfirmDialog = () => (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{"Confirm action"}</DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Agree</Button>
        <Button onClick={handleClose}>Disagree</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <ConfirmDialog />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context;
};