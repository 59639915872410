import { Button, styled, Select, List, ListItem, Box } from '@mui/material';
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import WarningIcon from '@mui/icons-material/Warning';

export const DeviceInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: 670px;
  padding: 2rem 1rem;
  @media (max-width: 900px) {
    display: block;
  }
`;

export const LeftColumn = styled('div')`
  flex-basis: 58%;
`;

export const RightColumn = styled('div')`
  flex-basis: 40%;
`;

export const Infolabel = styled('div')`
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  color: #0000008a;
`;

export const InfolabelCenter = styled(Infolabel)`
  text-align: center;
`;
export const InfoLabelUnderline = styled(Infolabel)`
  border-bottom: 1px solid #e5e5e5;
`;

export const DeviceInfoTable = styled('table')`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: #fff;
  width: 100%;
`;

export const DeviceTable = styled(DeviceInfoTable)`
  border-left: none;
  border-right: none;
`;

export const DeviceInfoTableRow = styled('tr')`
  height: 48px;
  :hover {
    background: #eee;
  }
`;

export const DeviceTableRow = styled(DeviceInfoTableRow)`
  height: 28px;
`;

export const DeviceInfoTableHeaderCell = styled('td')`
  height: 48px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #eee;
  color: #546a79;
  font-weight: bold;
  padding-left: 24px;
`;

export const DeviceInfoTableRowCell = styled('td')`
  padding-right: 24px;
  text-align: right;
  height: 48px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: ${props => props.color || 'inherit'};
`;

export const DeviceTableRowLeftCell = styled(DeviceInfoTableRowCell)`
  height: 28px;
  text-align: left;
  padding-left: 20px;
`;

export const DeviceTableRowRightCell = styled(DeviceInfoTableRowCell)`
  height: 28px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  text-align: right;
  padding-right: 20px;
`;


export const MapContainer = styled('div')`
  width: 100%;
  height: 300px;
`;

export const ShowLogButton = styled(Button)`
  margin: 30px 10px 30px 0;
  background: #4a89dc;
  color: #fff;
  :hover {
    background: #3f51b5;
  }
`;

export const ShowLogTh = styled('th')`
  background: #4a89dc;
  color: #fff;
  text-align: left;
  height: 48px;
  padding-left: 24px;
`;

export const ShowLogTd = styled('td')`
  text-align: left;
  height: 48px;
  padding-left: 24px;
`;

export const SelectDatePicker = styled(MobileDateTimePicker)`
  margin: 15px 10px 30px 0;
  width: 130px;
`;

export const StylesSelect = styled(Select)`
  color: rgba(0, 0, 0, .54);
  max-width: 65%;
  margin-right: 30px;
`;

export const Warning  = styled(WarningIcon)`
  font-size: 13px;
`;

export const ToastBox  = styled(Box)`
  font-weight: bold;
  background: #D9EDF7;
  color: #74A2B9;
  margin: 10px 10px -20px 0px;
  padding: 20px;
`;
