import { createGlobalStyle } from "styled-components";
import indexBG from "../assets/img/index/indexBG_compres.png";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    opacity: 1;
    background: #f3f3f3 url(${indexBG}) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  em {
    font-style: initial;
  }
`;

export default GlobalStyle;
