import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const FilterWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  .MuiBox-root {
    line-height: 1;
  }

  .MuiInput-input {

    &#mui-component-select-items_per_page {
      font-size: 14px;
      margin-left: 5px;
    }

    > em {
      font-style: normal;
    }
  }
`;

export const FormWrapper = styled('form')`
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const InputField = styled('div')`
  margin: 5px;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const ResetButton = styled(Button)`
  background: #9e9e9e33;
  color: #000;
  margin-right: 15px;

  &:hover {
    background-color: rgb(200 200 200);
  }
`;

export const SearchButton = styled(Button)`
  background: #4a89dc;
`;

export const CheckboxFieldWrapper = styled(Box)`
  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
  }
`;

export const CheckboxSelectionFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
`;
