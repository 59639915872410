import { styled } from '@mui/system';

export const Wrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const LoginLabel = styled('span')`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff4081;
`;

export const ChangeCredentialsDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #000;
    font-size: 14px;
    line-height: 36px;
    text-transform: uppercase;
    cursor: pointer;
  }
`;
